import { CheckIcon } from 'assets/icons/CheckIcon'
import { IdleBellIcon } from 'assets/icons/IdleBellIcon'
import { LightningSlashIcon } from 'assets/icons/LightningSlashIcon'
import { PauseIcon } from 'assets/icons/PauseIcon'
import { PendingIcon } from 'assets/icons/PendingIcon'
import { PlayIcon } from 'assets/icons/PlayIcon'
import { SettingsIcon } from 'assets/icons/SettingsIcons'
import { StopIcon } from 'assets/icons/StopIcon'
import { WarningTriangleIcon } from 'assets/icons/WarningTriangleIcon'

export const dashboardAlertCards = [
  { name: 'Problem Reports', prop: 'reports' },
  { name: 'Restricted Area Breaches', prop: 'restrictedArea' },
  { name: 'Delayed Tasks', prop: 'delayedTasks' },
  { name: 'Unusual Activity Detection', prop: 'unusualActivity' },
  { name: 'Resource Overuse Alert', prop: 'resourceOveruse' },
  { name: 'Unusual Fuel Usage', prop: 'unusualFuel' },
]

export const inventoryOptins = [{ label: 'All Items', value: 1 }]

export const areaData = [
  {
    name: '8AM',
    actualCost: 2400,
    estimatedCost: 2400,
  },
  {
    name: '10AM',
    actualCost: 1398,
    estimatedCost: 2210,
  },
  {
    name: '12AM',
    actualCost: 9800,
    estimatedCost: 2290,
  },
  {
    name: '2PM',
    actualCost: 3908,
    estimatedCost: 2000,
  },
  {
    name: '4PM',
    actualCost: 4800,
    estimatedCost: 2181,
  },
  {
    name: '6PM',
    actualCost: 3800,
    estimatedCost: 2500,
  },
  {
    name: '8PM',
    actualCost: 4300,
    estimatedCost: 2100,
  },
]

export const machineryData = [
  {
    name: 'Free',
    value: 5,
  },
  {
    name: 'Busy',
    value: 10,
  },
  {
    name: 'In Maintenence',
    value: 15,
  },
  {
    name: 'Paused',
    value: 10,
  },
  {
    name: 'Broken',
    value: 20,
  },
]

export const machineryStatusesData = [
  {
    id: 1,
    name: 'Free',
    color: '#ff9900',
    icon: IdleBellIcon,
    iconColor: '#ff9900',
  },
  {
    id: 2,
    name: 'Busy',
    color: '#00B04C',
    icon: PendingIcon,
    iconColor: '#00B04C',
  },
  {
    id: 3,
    name: 'Broken',
    color: '#FF4F46',
    icon: WarningTriangleIcon,
    iconColor: '#FF4F46',
  },
  {
    id: 4,
    name: 'In Maintenance',
    color: '#1CBBFF',
    icon: SettingsIcon,
    iconColor: '#1CBBFF',
  },
  {
    id: 5,
    name: 'Paused',
    color: '#7E571B',
    icon: PauseIcon,
    iconColor: '#7E571B',
  },
]
// NotStarted = 0, InProgress = 1, Paused = 2, Completed = 3, Reported = 4, Idle = 5, AbleToContinue = 6, Stopped = 7

export const taskStatusesData = [
  {
    id: 0,
    name: 'Not Started',
    icon: LightningSlashIcon,
  },
  {
    id: 1,
    name: 'In Progress',
    icon: PendingIcon,
  },
  {
    id: 2,
    name: 'Paused',
    icon: PauseIcon,
  },
  {
    id: 3,
    name: 'Completed',
    icon: CheckIcon,
  },
  {
    id: 4,
    name: 'Reported',
    icon: WarningTriangleIcon,
  },
  {
    id: 5,
    name: 'Idle',
    icon: IdleBellIcon,
  },
  {
    id: 6,
    name: 'Able To Continue',
    icon: PlayIcon,
  },
  {
    id: 7,
    name: 'Stopped',
    icon: StopIcon,
  },
]
