import { CloseIcon } from 'assets/icons/CloseIcon'
import { Modal } from 'components/Modal/Modal'
import { useState } from 'react'
import { CursorPointer, Flex, P } from 'styling/GlobalStyles'
import { BottomImagesContainer, ImagesSliderContainer, MainImagePreview } from './ImagesSlider.styled'
export const ImagesSlider = ({ images, setImagesModal, details }: { images: string[], setImagesModal: any, details?: string }) => {

    const [currentImage, setCurrentImage] = useState(images[0])
    return <Modal black noMaxH>
        <Flex width='100%' end="true">
            <CursorPointer onClick={() => setImagesModal({ open: false })}>
                <CloseIcon black />
            </CursorPointer>
        </Flex>
        <ImagesSliderContainer>
            <MainImagePreview>
                <img src={currentImage} alt='' />
                <P>{details}</P>
            </MainImagePreview>
            <BottomImagesContainer>
                {images.map((i, k) => <img key={k} src={i} alt={''} onClick={() => setCurrentImage(i)} />)}
            </BottomImagesContainer>
        </ImagesSliderContainer>
    </Modal>
}