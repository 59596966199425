import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IDashboardSlice } from 'types/dashboard.interface'
import { baseUrl } from 'utils/axios'
import { handleErrors } from 'utils/helpers'

const initialState: IDashboardSlice = {
  isLoading: false,
  finances: null,
  machinery: null,
  shifts: null,
  inventoryItems: null,
  taskStatuses: null,
  taskCycleStatuses:null,
  alerts: null,
}

export const getDashboardData = createAsyncThunk('dashboard/data', async (_, thunkAPI) => {
  try {
    const resp = await baseUrl.get('dashboard')
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getDelayedTaks = createAsyncThunk(
  'dashboard/delayedTasks',
  async (params, thunkAPI) => {
    try {
      const resp = await baseUrl.get('dashboard/delayed-tasks', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [getDashboardData.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDashboardData.fulfilled.type]: (state: IDashboardSlice, { payload }:{payload:IDashboardSlice}) => { // TODO: Ispitati da li je svaki prop na mestu za svaki slucaj
      state.isLoading = false
      state.finances = payload.finances
      state.machinery = payload.machinery
      state.shifts = payload.shifts
      state.taskStatuses = payload.taskStatuses
      state.inventoryItems = payload.inventoryItems
      state.taskCycleStatuses = payload.taskCycleStatuses
      state.alerts = payload.alerts
    },
    [getDashboardData.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default dashboardSlice.reducer
