import React from 'react'
import {
  Title,
  TabFooter,
  Button,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
  Animation,
} from 'styling/GlobalStyles'
import { IDepartmentsState, IDepratmentsTab } from 'types/departments.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { INameId } from 'constants/globalTypes'

export const DepartmentWorkflowsTab = ({ state, setState, handleSubmit }: IDepratmentsTab) => {
  const handleAdd = (item: any) => {
    setState((prev: IDepartmentsState) => {
      const copy = { ...prev }
      if (copy.workflowTemplates.some((w) => w.id == item.id)) {
        copy.workflowTemplates = copy.workflowTemplates.filter((t) => t.id !== item.id)
      } else {
        copy.workflowTemplates = [...copy.workflowTemplates, item]
      }
      return copy
    })
  }

  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Workflows</Title>
          {state.workflowTemplates?.map((item: INameId) => {
            return (
              <SelectedItem
                setState={setState}
                key={item.id}
                data={item}
                type='workflowTemplates'
              />
            )
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='workflow/template/company'
          selectedList={state.workflowTemplates}
          placeholder='Search Workflows'
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}

