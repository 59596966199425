import React from 'react'
import { H2 } from 'styling/GlobalStyles'

export const NoItems = ({ title, marginTop }: { title: string, marginTop?: string }) => {
  return (
    <>
      <H2 style={{ marginTop }} >No {title} to display</H2>
    </>
  )
}
