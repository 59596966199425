import React from 'react'


export const DeleteIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 4.375C2.5 4.02982 2.77982 3.75 3.125 3.75H16.875C17.2202 3.75 17.5 4.02982 17.5 4.375C17.5 4.72018 17.2202 5 16.875 5H3.125C2.77982 5 2.5 4.72018 2.5 4.375Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.125 7.5C8.47018 7.5 8.75 7.77982 8.75 8.125V13.125C8.75 13.4702 8.47018 13.75 8.125 13.75C7.77982 13.75 7.5 13.4702 7.5 13.125V8.125C7.5 7.77982 7.77982 7.5 8.125 7.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.875 7.5C12.2202 7.5 12.5 7.77982 12.5 8.125V13.125C12.5 13.4702 12.2202 13.75 11.875 13.75C11.5298 13.75 11.25 13.4702 11.25 13.125V8.125C11.25 7.77982 11.5298 7.5 11.875 7.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.375 3.75C4.72018 3.75 5 4.02982 5 4.375V16.25H15V4.375C15 4.02982 15.2798 3.75 15.625 3.75C15.9702 3.75 16.25 4.02982 16.25 4.375V16.25C16.25 16.5815 16.1183 16.8995 15.8839 17.1339C15.6495 17.3683 15.3315 17.5 15 17.5H5C4.66848 17.5 4.35054 17.3683 4.11612 17.1339C3.8817 16.8995 3.75 16.5815 3.75 16.25V4.375C3.75 4.02982 4.02982 3.75 4.375 3.75Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.79918 1.79917C7.15081 1.44754 7.62772 1.25 8.125 1.25H11.875C12.3723 1.25 12.8492 1.44754 13.2008 1.79917C13.5525 2.15081 13.75 2.62772 13.75 3.125V4.375C13.75 4.72018 13.4702 5 13.125 5C12.7798 5 12.5 4.72018 12.5 4.375V3.125C12.5 2.95924 12.4342 2.80027 12.3169 2.68306C12.1997 2.56585 12.0408 2.5 11.875 2.5H8.125C7.95924 2.5 7.80027 2.56585 7.68306 2.68306C7.56585 2.80027 7.5 2.95924 7.5 3.125V4.375C7.5 4.72018 7.22018 5 6.875 5C6.52982 5 6.25 4.72018 6.25 4.375V3.125C6.25 2.62772 6.44754 2.15081 6.79918 1.79917Z'
    />
  </svg>
)
