import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IDBEmployeesSlice, IDBEmployeeTable } from 'types/db-employees.interface'
import { baseUrl } from 'utils/axios'
import { handleErrors, formatParams } from 'utils/helpers'
import { ISingleDBEmployee } from './types'

const initialState: IDBEmployeesSlice = {
  isLoading: false,
  data: [],
  totalCount: 0,
  totalPages: 0,
  noEmployees: false,
  singleEmployee: null,
}


export const getDBEmployees = createAsyncThunk(
  'dashboardEmployees/get',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<IDBEmployeeTable>> = await baseUrl.get('dashboard/employees', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const getSingleDBEmployee = createAsyncThunk(
  'dashboardEmployees/getSingle',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<ISingleDBEmployee> = await baseUrl.get('dashboard/employee-details', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const dashboardSlice = createSlice({
  name: 'dbEmployeesSlice',
  initialState,
  reducers: {
    clearDBEmployeesList: (state) => {
      state.data = []
      state.totalCount = 0
      state.totalPages = 0
    },
    clearSingleDBEmployee: (state) => {
      state.singleEmployee = null
    },
  },
  extraReducers: {
    [getDBEmployees.pending.type]: (state) => {
      state.isLoading = true
    },
    [getDBEmployees.fulfilled.type]: (state: IDBEmployeesSlice, { payload }:{payload:IPaginatedResponse<IDBEmployeeTable>}) => {
      state.data = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noEmployees = state.data.length < 1
      state.isLoading = false
    },
    [getDBEmployees.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleDBEmployee.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleDBEmployee.fulfilled.type]: (state: IDBEmployeesSlice, { payload }:{payload:ISingleDBEmployee}) => {
      state.singleEmployee = payload
      state.isLoading = false
    },
    [getSingleDBEmployee.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearDBEmployeesList, clearSingleDBEmployee } = dashboardSlice.actions

export default dashboardSlice.reducer
