import React from 'react'
import { SelectWrapper, Label } from '../Input/Input.styled'
import { HonestSelect } from './HonestSelect'
import { IHonestSelectInput } from 'types/select.interface'

export const HonestSelectInput = (props: IHonestSelectInput) => {
  return (
    <SelectWrapper view={props.view} width={props.width} invalid={props.invalid ? 'true' : ''} nomarg={props.nomarg}>
      <Label htmlFor={props.id} labelText={props.labelText}>
        {props.labelText}
      </Label>
      <HonestSelect errorWhenEmpty={props.errorWhenEmpty} menuTop={props.menuTop} noX={props.noX} invalid={props.invalid} isNone={props.isNone} {...props} />
    </SelectWrapper>
  )
}
