import React from 'react'

export const FilterIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.28905 3.125C3.28898 3.125 3.28911 3.125 3.28905 3.125H16.7109C16.9534 3.12508 17.1908 3.19567 17.3938 3.32818C17.5969 3.46068 17.757 3.64938 17.8547 3.87129C17.9524 4.0932 17.9835 4.33873 17.9441 4.57798C17.9048 4.81676 17.7971 5.039 17.6339 5.21771C17.6336 5.21806 17.6333 5.2184 17.633 5.21875L12.5 10.8674V15.2867C12.5013 15.4946 12.4505 15.6995 12.3521 15.8827C12.2538 16.0655 12.1113 16.2209 11.9376 16.3344L9.44005 17.9968L9.43752 17.9985C9.24996 18.122 9.03259 18.1928 8.80825 18.2032C8.58391 18.2137 8.36089 18.1636 8.16264 18.058C7.96439 17.9525 7.79823 17.7955 7.68163 17.6036C7.56503 17.4117 7.50229 17.1918 7.50002 16.9673L7.49995 16.9609L7.49998 10.8674L7.49833 10.8657L2.36697 5.21875C2.3667 5.21844 2.36642 5.21814 2.36614 5.21783C2.20294 5.0391 2.09514 4.81682 2.05584 4.57798C2.01646 4.33873 2.04752 4.0932 2.14523 3.87129C2.24293 3.64938 2.40307 3.46068 2.60612 3.32818C2.80904 3.19576 3.04607 3.12517 3.28837 3.125M16.7107 4.375H3.28925L3.29066 4.37655L8.42107 10.0223C8.63447 10.2527 8.7521 10.5556 8.74998 10.8696V16.9546L11.25 15.2905L11.25 15.2891L11.25 10.8696C11.2479 10.5556 11.3655 10.2527 11.5789 10.0223L16.7093 4.37655L16.7107 4.375Z'
    />
  </svg>
)
