import React from 'react'

interface ICheck {
  valid?: boolean
  white?: boolean
}

const Check = ({ valid, white }: ICheck) => {
  return white ? (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'>
      <path
        d='M17.0769 5.70996L7.90862 14.8783L3.74121 10.7109'
        stroke='#fff'
        strokeWidth='1.66697'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ) : !valid ? (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'>
      <path
        opacity={!valid && '0.2'}
        d='M17.0769 5.70996L7.90862 14.8783L3.74121 10.7109'
        stroke={!valid ? '#464255' : '#ff9900'}
        strokeWidth='1.66697'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ) : (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12' fill='none'>
      <path
        d='M14.6304 1.36694L5.46209 10.5353L1.29468 6.36784'
        stroke='#FF9900'
        strokeWidth='1.66697'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Check
