export const FoldMapIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.10634 2.97356C8.19006 3.30843 7.98646 3.64777 7.65158 3.73149L3.125 4.86313V14.8247L7.34842 13.7688C7.68329 13.6851 8.02262 13.8887 8.10634 14.2236C8.19006 14.5584 7.98646 14.8978 7.65158 14.9815L2.65158 16.2315C2.46487 16.2782 2.26707 16.2362 2.11537 16.1178C1.96367 15.9993 1.875 15.8176 1.875 15.6251V4.37515C1.875 4.08836 2.07019 3.83837 2.34842 3.76881L7.34842 2.51881C7.68329 2.43509 8.02262 2.63869 8.10634 2.97356Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.17142 2.59334C7.35568 2.47946 7.58577 2.46911 7.77951 2.56598L12.7795 5.06598C12.9912 5.17185 13.125 5.38827 13.125 5.625V16.875C13.125 17.0916 13.0128 17.2928 12.8286 17.4067C12.6443 17.5205 12.4142 17.5309 12.2205 17.434L7.22049 14.934C7.00875 14.8281 6.875 14.6117 6.875 14.375V3.125C6.875 2.90839 6.98716 2.70722 7.17142 2.59334ZM8.125 4.13627V13.9887L11.875 15.8637V6.01127L8.125 4.13627Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.8846 3.88238C18.0363 4.00082 18.125 4.18255 18.125 4.37501V15.625C18.125 15.9118 17.9298 16.1618 17.6516 16.2313L12.6516 17.4813C12.3167 17.5651 11.9774 17.3615 11.8937 17.0266C11.8099 16.6917 12.0135 16.3524 12.3484 16.2687L16.875 15.137V5.17549L12.6516 6.23134C12.3167 6.31506 11.9774 6.11146 11.8937 5.77659C11.8099 5.44172 12.0135 5.10238 12.3484 5.01867L17.3484 3.76867C17.5351 3.72199 17.7329 3.76394 17.8846 3.88238Z'
    />
  </svg>
)
