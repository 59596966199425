import React from 'react'
import Check from 'assets/icons/Check'
import { GridItem } from 'styling/GlobalStyles'
import { useDrag } from 'react-dnd'
/*eslint-disable*/
export const DraggableItem = ({ data, valid, keyNum }: IDraggableItem) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'item',
    item: data,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))
  return (
    <GridItem ref={drag} valid={valid} style={{ opacity: isDragging ? '0' : '1' }} key={keyNum}>
      {data.name}
      <Check valid={valid} />
    </GridItem>
  )
}

interface IDraggableItem {
  data: {
    name: string
    id: number
  }
  valid: boolean
  keyNum: number
}
