import React from 'react'


export const OrangeChevron = (
  <svg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17 1L6 12L1 7'
      stroke='#FF9900'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
