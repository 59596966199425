import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { baseUrl } from '../../utils/axios'
import { handleErrors, formatParams } from 'utils/helpers'
import { IField } from 'types/fields.interface'
import { IPaginatedResponse } from 'types/api/general.interface'
import { INameId } from 'constants/globalTypes'
import { AxiosResponse } from 'axios'
import { IWorkerComponentsSingleResponseRequest } from 'types/api/workerComponentsApi.interface'
import { IWorkerComponentsSlice } from 'types/worker-components.interface'

const initialState:IWorkerComponentsSlice = {
  isLoading: false,
  workerComponents: [],
  singleWorkerComponent: null,
  totalCount: null,
  totalPages: null,
  noWorkerComponents: false,
  noSubfields: false,
}

export const getWorkerComponents = createAsyncThunk(
  'workerComponents/getWorkerComponents',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('worker-component-fields', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleWorkerComponent = createAsyncThunk(
  'workerComponents/getSingleWorkerComponent',
  async (id: number, thunkAPI) => {
    try {
      const resp:AxiosResponse<IWorkerComponentsSingleResponseRequest> = await baseUrl.get(`worker-component-fields/${id}`)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addWorkerComponent = createAsyncThunk(
  'workerComponents/addWorkerComponent',
  async (field: IField, thunkAPI) => {
    if (field.dropDownItems.length < 1) {
      delete field.dropDownItems
    }
    try {
      const resp = await baseUrl.post('worker-component-fields', field, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const editWorkerComponent = createAsyncThunk(
  'workerComponents/editWorkerComponent',
  async (field: IField, thunkAPI) => {
    const { id } = field
    delete field.id
    if (field.dropDownItems.length < 1) {
      delete field.dropDownItems
    }
    try {
      const resp = await baseUrl.put('worker-component-fields/' + id, field, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteWorkerComponent = createAsyncThunk(
  'workerComponents/deleteWorkerComponent',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('worker-component-fields/' + id)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const workerComponentsSlice = createSlice({
  name: 'workerComponents',
  initialState,
  reducers: {
    clearWorkerComponent: (state) => {
      state.singleWorkerComponent = null
    },
    clearWorkerComponentsList: (state) => {
      state.workerComponents = []
      state.totalCount = null
      state.totalPages = null
    },
  },

  extraReducers: {
    [getWorkerComponents.pending.type]: (state) => {
      state.isLoading = true
    },
    [getWorkerComponents.fulfilled.type]: (state, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.isLoading = false
      state.workerComponents = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noWorkerComponents = payload.data.length < 1
    },
    [getWorkerComponents.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleWorkerComponent.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleWorkerComponent.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleWorkerComponent = payload
      if (payload.dropDownItems) {
        state.noSubfields = payload.dropDownItems.length < 1
      }
    },
    [getSingleWorkerComponent.rejected.type]: (state) => {
      state.isLoading = false
    },
    [addWorkerComponent.pending.type]: (state) => {
      state.isLoading = true
    },
    [addWorkerComponent.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.workerComponents = [...state.workerComponents, payload]
      state.noWorkerComponents = false
      toast.success('Worker Component Added')
    },
    [addWorkerComponent.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [editWorkerComponent.pending.type]: (state) => {
      state.isLoading = true
    },
    [editWorkerComponent.fulfilled.type]: (state, { payload }) => {
      state.singleWorkerComponent = payload
      state.isLoading = false
      state.noSubfields = payload.dropDownItems.length < 1
      toast.success('Worker Component Edited')
    },
    [editWorkerComponent.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteWorkerComponent.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteWorkerComponent.fulfilled.type]: (state, { payload }) => {
      state.workerComponents = state.workerComponents.filter(
        (item) => item.id !== payload,
      )
      if (state.workerComponents.length < 1) {
        state.noWorkerComponents = true
      }
      state.isLoading = false
      toast.success('Worker Component Deleted')
    },
    [deleteWorkerComponent.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearWorkerComponent, clearWorkerComponentsList } = workerComponentsSlice.actions

export default workerComponentsSlice.reducer
