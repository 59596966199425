import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../utils/axios'
import { toast } from 'react-toastify'
import { ICompany, ICompaniesSlice } from 'types/companies.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { ICompaniesPatchRequest, ICompanySingle } from 'types/api/companiesApi.interface'
import { INameId } from 'constants/globalTypes'

const initialState: ICompaniesSlice = {
  isLoading: false,
  isEditing: false,
  isSingleLoading: false,
  companies: [],
  singleCompany: {
    id: null,
    name: '',
    industry: null,
    taxNumber: '',
    contact: {
      address: '',
      email: '',
      phoneNumber: '',
    },
    representative: {
      name: '',
      position: '',
      phoneNumber: '',
      email: '',
    },
    settings: {
      shouldIncludeTraffic: false,
    },
  },
  totalCount: 0,
  totalPages: 0,
  noCompanies: false,
}

export const getCompanies = createAsyncThunk(
  'companies/getCompanies',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<ICompanySingle>> = await baseUrl.get('companies', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleCompany = createAsyncThunk(
  'companies/getSingleCompany',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<ICompanySingle> = await baseUrl.get('companies/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addCompany = createAsyncThunk(
  'companies/addCompany',
  async (company: ICompany, thunkAPI) => {
    try {
      const resp = await baseUrl.post('companies', company, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async (company: ICompany, thunkAPI) => {
    try {
      const {industry} = company
      delete company.industry
      const obj:ICompaniesPatchRequest = { ...company, industryId: industry.id }
      await baseUrl.patch(`companies/${company.id}`, obj, thunkAPI)

      return company
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteCompany = createAsyncThunk(
  'companies/deleteCompany',
  async (id: string, thunkAPI) => {
    try {
      await baseUrl.delete('companies/' + id, thunkAPI)

      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getCompaniesSimple = createAsyncThunk(
  'companies/getCompaniesSimple',
  async (id: number | null, thunkAPI) => {
    const findId = id ? `?industryId=${id}` : ''
    try {
      const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get(`companies/simple${findId}`, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    handleEdit: (state) => {
      state.isEditing = true
    },
    handleAddCompany: (state) => {
      state.isEditing = false
    },
    clearCompaniesList: (state) => {
      state.companies = []
      state.totalCount = 0
      state.totalPages = 0
    },
    clearCompany: (state) => {
      state.singleCompany = null
    },
  },

  extraReducers: {
    [getCompanies.pending.type]: (state) => {
      state.isLoading = true
    },
    [getCompanies.fulfilled.type]: (state:ICompaniesSlice, { payload }:{payload:IPaginatedResponse<ICompanySingle>}) => {
      state.companies = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.isLoading = false
      state.noCompanies = state.companies.length < 1
    },
    [getCompanies.rejected.type]: (state) => {
      state.isLoading = false
    },
    [getSingleCompany.pending.type]: (state) => {
      state.isSingleLoading = true
    },
    [getSingleCompany.fulfilled.type]: (state:ICompaniesSlice, { payload }:{payload:ICompanySingle}) => {
      state.singleCompany = payload
      state.isSingleLoading = false
    },
    [getSingleCompany.rejected.type]: (state) => {
      state.isSingleLoading = false
    },
    [addCompany.pending.type]: (state) => {
      state.isLoading = true
    },
    [addCompany.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      if (state.companies.length < 5) {
        state.companies.push(payload)
        state.noCompanies = false
      }
      toast.success('Company added')
    },
    [addCompany.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateCompany.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateCompany.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const foundIndex = state.companies.findIndex((item) => item.id === payload.id)
      state.companies[foundIndex] = payload
      toast.success('Company updated')
    },
    [updateCompany.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteCompany.fulfilled.type]: (state:ICompaniesSlice, { payload }:{payload:number}) => {
      state.isLoading = false
      state.companies = state.companies.filter((item) => item.id !== payload)
      state.noCompanies = state.companies.length < 1
      toast.success('Company deleted')
    },
    [deleteCompany.rejected.type]: (state, { payload }) => {
      state.isLoading = false

      handleErrors(payload)
    },
    [getCompaniesSimple.pending.type]: (state) => {
      state.isLoading = true
    },
    [getCompaniesSimple.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false

      state.companies = payload.data
    },
    [getCompaniesSimple.rejected.type]: (state) => {
      state.isLoading = false
    },
  },
})

export const { handleEdit, handleAddCompany, clearCompaniesList, clearCompany } =
  companiesSlice.actions
export default companiesSlice.reducer
