import React from 'react'

export const IndustriesIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.625 13.75C5.625 13.4048 5.90482 13.125 6.25 13.125H8.4375C8.78268 13.125 9.0625 13.4048 9.0625 13.75C9.0625 14.0952 8.78268 14.375 8.4375 14.375H6.25C5.90482 14.375 5.625 14.0952 5.625 13.75Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.9375 13.75C10.9375 13.4048 11.2173 13.125 11.5625 13.125H13.75C14.0952 13.125 14.375 13.4048 14.375 13.75C14.375 14.0952 14.0952 14.375 13.75 14.375H11.5625C11.2173 14.375 10.9375 14.0952 10.9375 13.75Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.84549 6.31599C3.05723 6.21012 3.31061 6.23296 3.5 6.375L7.5 9.375V6.875C7.5 6.63827 7.63375 6.42186 7.84549 6.31599C8.05723 6.21012 8.31061 6.23296 8.5 6.375L13.3333 10H16.875C17.2202 10 17.5 10.2798 17.5 10.625V16.875C17.5 17.2202 17.2202 17.5 16.875 17.5C16.5298 17.5 16.25 17.2202 16.25 16.875V11.25H13.125C12.9898 11.25 12.8582 11.2061 12.75 11.125L8.75 8.125V10.625C8.75 10.8617 8.61625 11.0782 8.40451 11.184C8.19277 11.2899 7.93939 11.267 7.75 11.125L3.75 8.125V16.875C3.75 17.2202 3.47018 17.5 3.125 17.5C2.77982 17.5 2.5 17.2202 2.5 16.875V6.875C2.5 6.63827 2.63375 6.42186 2.84549 6.31599Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.625 16.875C0.625 16.5298 0.904822 16.25 1.25 16.25H18.75C19.0952 16.25 19.375 16.5298 19.375 16.875C19.375 17.2202 19.0952 17.5 18.75 17.5H1.25C0.904822 17.5 0.625 17.2202 0.625 16.875Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.6641 1.25H15.0878C15.3889 1.2509 15.6796 1.36047 15.9064 1.55854C16.1328 1.7563 16.2803 2.02904 16.322 2.32674C16.3221 2.3272 16.3221 2.32766 16.3222 2.32813L17.4937 10.5367C17.5425 10.8784 17.305 11.195 16.9633 11.2437C16.6216 11.2925 16.3051 11.055 16.2563 10.7133L15.0841 2.5H13.666L13.6656 2.50264L12.6109 9.86982C12.562 10.2115 12.2453 10.4489 11.9036 10.3999C11.5619 10.351 11.3246 10.0344 11.3735 9.69268L12.4278 2.32813C12.4279 2.32762 12.428 2.3271 12.428 2.32659C12.4697 2.02895 12.6173 1.75627 12.8437 1.55854C13.0704 1.36047 13.3611 1.25091 13.6622 1.25L13.6641 1.25Z'
    />
  </svg>
)
