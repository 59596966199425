import React from 'react'
import { CollapseItemMachine } from 'pages/ResourcesAdmin/Roles/CollapseItemMachine'
import {
  Title,
  Button,
  Animation,
  SelectedItemsContainer,
  TabFooter,
  ButtonsContainer,
  ItemsPickContainer,
} from 'styling/GlobalStyles'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { INameId } from 'constants/globalTypes'
import { CancelBtn } from 'components/Button/CancelBtn'
import { IEmployee, IEmployeesTab } from 'types/users.interface'

export const EmployeesMachineryTab = ({ state, setState, setActive }: IEmployeesTab) => {
  const handleAdd = (item: INameId) => {
    if (!state?.machines?.some((i) => i.id == item.id)) {
      const parsedItem = {
        id: item.id,
        priority: 0,
        rating: 0,
        name: item.name,
      }
      setState((prev) => {
        const copy = { ...prev }
        copy.machines.push(parsedItem)
        return copy
      })
    } else {
      setState((prev: IEmployee) => {
        const copy = { ...prev }
        copy.machines = copy.machines.filter((it) => it.id !== item.id)
        return copy
      })
    }
  }



  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Machines</Title>
          {state.machines &&
            state.machines.map((item) => {
              return <CollapseItemMachine nonModel setState={setState} key={item.id} data={item} />
            })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='machines'
          selectedList={state.machines}
          placeholder='Search machines'
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={() => setActive('Capabilities')}>
            Next
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}

