export const UserIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 3.125C7.58375 3.125 5.625 5.08375 5.625 7.5C5.625 9.91625 7.58375 11.875 10 11.875C12.4162 11.875 14.375 9.91625 14.375 7.5C14.375 5.08375 12.4162 3.125 10 3.125ZM4.375 7.5C4.375 4.3934 6.8934 1.875 10 1.875C13.1066 1.875 15.625 4.3934 15.625 7.5C15.625 10.6066 13.1066 13.125 10 13.125C6.8934 13.125 4.375 10.6066 4.375 7.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.99998 13.124C8.57362 13.124 7.17239 13.4995 5.93716 14.2128C4.70193 14.926 3.67622 15.9518 2.96315 17.1872C2.79059 17.4861 2.40836 17.5886 2.10941 17.416C1.81046 17.2435 1.708 16.8612 1.88056 16.5623C2.70333 15.1369 3.88684 13.9532 5.31211 13.1303C6.73738 12.3073 8.35418 11.874 9.99998 11.874C11.6458 11.874 13.2626 12.3073 14.6879 13.1303C16.1131 13.9532 17.2966 15.1369 18.1194 16.5623C18.292 16.8612 18.1895 17.2435 17.8906 17.416C17.5916 17.5886 17.2094 17.4861 17.0368 17.1872C16.3237 15.9518 15.298 14.926 14.0628 14.2128C12.8276 13.4995 11.4263 13.124 9.99998 13.124Z'
    />
  </svg>
)
