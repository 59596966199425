/*eslint-disable*/
import { HonestSelectInput } from '../../../components/HonestSelect/HonestSelectInput'
import { Input } from '../../../components/Input/Input'
import { Animation, Button, ButtonsContainer, Flex, ItemsGrid, TabFooter } from '../../../styling/GlobalStyles'
import { useDispatch, useSelector } from 'react-redux'
import { CancelBtn } from 'components/Button/CancelBtn'
import { getSingleInventoryCategory } from 'features/inventory/invSettingsCategoriesSlice'
import { AppDispatch, RootState } from 'store'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { standardFieldValidation, validateSelect } from 'utils/validationUtils'
import { toast } from 'react-toastify'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { useEffect } from 'react'
import { IItemsTab } from 'types/inventory.interface'
import { IInventoryCategoryResponse } from 'types/api/categoriesApi.interface'

export const InventoryItemsSpecificationTab = ({
  state,
  setState,
  setActive,
  invalidFields,
  setInvalidFields,
  invalidCategoryFields,
  setInvalidCategoryFields,
}: IItemsTab) => {
  const dispatch = useDispatch<AppDispatch>()
  const { singleInventoryCategory } = useSelector((state: RootState) => state.inventoryCategories)

  useEffect(() => {
    state?.category?.id && dispatch(getSingleInventoryCategory(state?.category?.id))
  }, [state?.category?.id])
  useFocusFirstInput()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    standardFieldValidation(e, setInvalidFields)
    setState((prev) => ({ ...prev, [name]: value }))
  }

  const handleSelect = async (value: ISelectValue, name: string) => {
    validateSelect(value, name, setInvalidFields)
    const copy = structuredClone(state)
    if (name === 'location') {
      copy.location.id = value.value
      copy.location.name = value.label
    } else {
      copy.category.id = value.value
      copy.category.name = value.label
      await dispatch(getSingleInventoryCategory(value.value)).then(d => {
        copy.fieldValues = (d.payload as IInventoryCategoryResponse).fields
        setInvalidCategoryFields((d.payload as IInventoryCategoryResponse).fields.map((f) => f.name))
      })
    }
    setState(copy)
  }

  const handleSelectField = (value: ISelectValue, id: number, name: string) => {
    const newState = structuredClone(state)
    validateSelect(value, name, setInvalidCategoryFields)
    const fieldIndex = newState?.fieldValues?.findIndex((f) => f.id == id)
    if (fieldIndex > -1) {
      newState.fieldValues[fieldIndex].value = value.value
    } else {
      const fields = (singleInventoryCategory as any)?.fields.find(f => f.id == id)
      newState.fieldValues.push({ ...fields, value: value.value })
    }
    // newState.fieldValues.map((item) => ({ id: item.id, value: item.value }))
    setState(newState)
  }

  const handleInputField = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id, type } = e.target as HTMLInputElement
    const newState = structuredClone(state)
    standardFieldValidation(e, setInvalidCategoryFields)
    const objIndex = newState.fieldValues.findIndex((item) => item.id == id)
    if (objIndex > -1) {
      newState.fieldValues[objIndex].value = type === 'number' ? Number(value) : value
    } else {
      const field = (singleInventoryCategory as any)?.fields.find(f => f.id == id)
      newState.fieldValues.push({ ...field, value })
    }
    setState(newState)
  }

  const renderFields = () => {

    const missingFields = (singleInventoryCategory as any)?.fields?.filter(f => !state.fieldValues.some(sf => sf.id == f.id)).map(f => ({ ...f, id: f.id }))
    let fields = state?.fieldValues
    if (missingFields) {
      fields = [...state?.fieldValues, ...missingFields]
    }

    const forReturn = fields?.map((field) => {
      if (field.dataType == 1) {
        return (
          <Input
            id={field.id}
            key={field.id}
            name={field.name}
            value={field.value}
            type='text'
            labelText={field.name}
            onChange={handleInputField}
            placeholder={'Enter text ' + field.name}
            invalid={invalidCategoryFields.includes(field.name) ? 'true' : ''}
          />
        )
      } else if (field.dataType == 2) {
        const getValue = () => {
          const foundField = state.fieldValues.find((f) => f.id == field.id)
          return foundField ? foundField.value : ''
        }

        return (
          <Input
            id={field.id}
            key={field.id}
            name={field.name}
            value={getValue()}
            type='number'
            labelText={field.name}
            onChange={handleInputField}
            placeholder={'Enter ' + field.name}
            invalid={invalidCategoryFields.includes(field.name) ? 'true' : ''}
          />
        )
      } else {
        const optionsForSelect = typeof field.dropDownOptions
          ? field.dropDownOptions.map((item) => {
            if (typeof item === 'object') {
              return {
                label: item.name,
                value: Number(item.id),
              }
            } else {
              return {
                label: item,
                value: item,
              }
            }
          })
          : []

        const defaultValue = () => {
          const foundOption = field.dropDownOptions.find((o) =>
            typeof field.value === 'object' ? o?.id === field?.value.id : o?.id === field?.value,
          )
          return foundOption?.id
        }

        return (
          <HonestSelectInput
            key={field.id}
            name={field.id}
            onChange={(value: ISelectValue) => handleSelectField(value, field.id, field.name)}
            labelText={field.name}
            defaultValue={defaultValue()}
            options={optionsForSelect}
            invalid={invalidCategoryFields.includes(field.name)}
          />
        )
      }
    })

    return forReturn
  }

  const parseSelectData = (data: any) => {
    return data ? data.data.map((item: any) => ({ label: item.name, value: item.id })) : []
  }

  const handleNext = () => {
    if (invalidFields.length > 0 || invalidCategoryFields.length > 0) {
      toast.error('All fields are required')
      return
    }
    setActive('Machines')
  }

  return (
    <Animation>
      <ItemsGrid columns={3} gap='0 1.5rem'>
        <Input
          value={state?.name}
          name='name'
          onChange={handleChange}
          type='text'
          placeholder='Enter name'
          labelText='Item Name'
          invalid={invalidFields.includes('name') ? 'true' : ''}
        />
        <Input
          value={state?.model}
          name='model'
          onChange={handleChange}
          type='text'
          placeholder='Enter model'
          labelText='Item Model'
          invalid={invalidFields.includes('model') ? 'true' : ''}
        />
        <Input
          value={state?.quantity}
          name='quantity'
          onChange={handleChange}
          type='number'
          placeholder='Enter quantity'
          labelText='Quantity'
          invalid={invalidFields.includes('quantity') ? 'true' : ''}
        />
        <Input
          value={state?.price}
          name='price'
          onChange={handleChange}
          type='number'
          placeholder='Enter price'
          labelText='Item price'
          invalid={invalidFields.includes('price') ? 'true' : ''}
        />
        <Input
          value={state?.sku}
          name='sku'
          onChange={handleChange}
          type='text'
          placeholder='Enter SKU'
          labelText='SKU'
          invalid={invalidFields.includes('sku') ? 'true' : ''}
        />
        <HonestSelectAsyncInput
          link='locations'
          placeholder='Search locations'
          name='location'
          labelText='Location'
          defaultValue={state?.location.name}
          onChange={(value: ISelectValue) => handleSelect(value, 'location')}
          parserFunction={parseSelectData}
          invalid={invalidFields.includes('location') ? 'true' : ''}
        />
        <Input
          value={state?.minLimit}
          name='minLimit'
          onChange={handleChange}
          type='number'
          placeholder='Enter minimum limit'
          labelText='Minimum Limit'
          invalid={invalidFields.includes('minLimit') ? 'true' : ''}
        />
        <Input
          value={state?.maxLimit}
          name='maxLimit'
          onChange={handleChange}
          type='number'
          placeholder='Enter maximum limit'
          labelText='Maximum Limit'
          invalid={invalidFields.includes('maxLimit') ? 'true' : ''}
        />
        <HonestSelectAsyncInput
          link='inventory-categories'
          placeholder='Search categories'
          name='category'
          labelText='Category'
          defaultValue={state?.category.name}
          onChange={(value: ISelectValue) => handleSelect(value, 'category')}
          parserFunction={parseSelectData}
          invalid={invalidFields.includes('category') ? 'true' : ''}
        />

        {renderFields()}
      </ItemsGrid>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleNext}>
            Next
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}
