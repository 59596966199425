import React from 'react'

export const StarOrange = () => {
  return (
    <>
      <svg
        width='18'
        height='17'
        viewBox='0 0 18 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_608_10056)'>
          <path
            d='M8.69276 1.47333L10.839 5.82133L15.6384 6.52284L12.1656 9.90539L12.9852 14.684L8.69276 12.4267L4.40032 14.684L5.21991 9.90539L1.74707 6.52284L6.54654 5.82133L8.69276 1.47333Z'
            stroke='#FF9900'
            strokeWidth='1.66697'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_608_10056'>
            <rect
              width='16.6697'
              height='16.6697'
              fill='white'
              transform='translate(0.358398 0.0841675)'
            />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
