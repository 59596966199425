import { CancelBtn } from 'components/Button/CancelBtn'
import { Input } from 'components/Input/Input'
import { Animation, Button, Flex, H2, TabFooter } from 'styling/GlobalStyles'
import { IProcessTemplateTab } from 'types/processTemplates'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { Type27Options } from './processTemplateData'

export const ProcessTemplatesDataTab = ({ state, setState, handleSubmit, view }: IProcessTemplateTab) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: number, type: string, index: number) => {
        const { value } = e.target
        setState(prev => {
            const copy = structuredClone(prev)
            // const index = copy.processFunction[type].findIndex(c => c.component == id)
            // copy.processFunction.inputs[index].componentGivenName = value

            copy.processFunction[type] = copy.processFunction[type].map(t => {
                if (t.component == id && t.index == index) {
                    t.componentGivenName = value
                }
                return t
            })

            return copy
        })
    }

    const handleSelect = (type: ISelectValue, id: number, displayInput?: boolean) => {
        setState(prev => {
            const copy = structuredClone(prev)
            if (displayInput) {
                const index = copy.processFunction.displayInputs.findIndex(d => d.component == id)
                copy.processFunction.displayInputs[index].value = type
            } else {
                const index = copy.processFunction.steps.findIndex(s => s.id == id)
                copy.processFunction.steps[index].operationType = type
            }
            return copy
        })
    }

    const parseOperations = (data: any) => {
        return data ? data.data.map((o) => ({ label: o.name, value: o.id })) : []
    }

    return state.processFunction ? <Animation>

        <Flex width='100%' gap='5rem' >
            <Flex gap='1rem' column >
                <H2>Components</H2>
                {state.processFunction?.inputs.map(i => <Input
                    key={i.component}
                    type='text'
                    value={i.componentGivenName}
                    onChange={e => handleChange(e, i.component, 'inputs', i.index)}
                    placeholder='Enter name'
                    labelText={i.name + ' Input name'}
                    invalid={i.componentGivenName?.length < 2 ? 'true' : ''}
                    width='15rem'
                    nomarg
                    view={view}
                />)}

                {state.processFunction?.outputs.map(o => <Input
                    key={o.component}
                    type='text'
                    value={o.componentGivenName}
                    onChange={e => handleChange(e, o.component, 'outputs', o.index)}
                    placeholder='Enter name'
                    labelText={o.name || '' + ' Output name'}
                    invalid={o.componentGivenName?.length < 2 ? 'true' : ''}
                    width='15rem'
                    nomarg
                    view={view}
                />)}

                {state.processFunction?.displayInputs.map(d => <HonestSelectInput
                    labelText={d.name}
                    options={Type27Options}
                    key={d.component}
                    onChange={(type: ISelectValue) => handleSelect(type, d.component, true)}
                    defaultValue={d.value?.value}
                    menuTop={true}
                />)}
            </Flex>
            <Flex gap='1rem' width='20rem' column >
                <H2>Steps</H2>
                {
                    state.processFunction?.steps.map(s =>
                        <HonestSelectAsyncInput
                            key={s.id}
                            nomarg
                            link='operations/users/operation-types'
                            placeholder='Select operation type'
                            name={s.id.toString()}
                            labelText={s.name}
                            parserFunction={parseOperations}
                            defaultValue={s.operationType?.label}
                            onChange={(type: ISelectValue) => handleSelect(type, s.id)}
                            invalid={!s.operationType?.label}
                            view={view}
                        />
                    )
                }

            </Flex>
        </Flex>

        {handleSubmit && (
            <TabFooter>
                <Flex width='100%' end='true'>
                    <CancelBtn />
                    <Button ml='15' height='40' onClick={handleSubmit} orange>
                        Save
                    </Button>
                </Flex>
            </TabFooter>
        )}
    </Animation> : <Animation><H2>Select function first to fill the data</H2></Animation>
}