import React from 'react'
import { TabFooter, Button, Flex, Animation } from 'styling/GlobalStyles'
import { Input } from 'components/Input/Input'
import { CancelBtn } from 'components/Button/CancelBtn'
import { IWorkflowAddPage, IWorkflowTab } from 'types/workflows.interface'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const WorkflowSpecificationTab = ({ state, setState, handleSubmit }: IWorkflowTab) => {
  useFocusFirstInput()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: IWorkflowAddPage) => {
      const copy = { ...prev }
      copy.name = e.target.value
      return copy
    })
  }

  return (
    <Animation>
      <Flex gap='1rem'>
        <Input
          type='text'
          value={state.name}
          onChange={handleChange}
          placeholder='Workflow name'
          labelText='Workflow name'
          invalid={state?.name?.length < 2 ? 'true' : ''}
          width='15rem'
        />
      </Flex>
      {handleSubmit && (
        <TabFooter>
          <Flex width='100%' end='true'>
            <CancelBtn />
            <Button ml='15' height='40' onClick={handleSubmit} orange>
              Save
            </Button>
          </Flex>
        </TabFooter>
      )}
    </Animation>
  )
}
