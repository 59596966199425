import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store'
// import './i18n';
import { Authenticator } from '@aws-amplify/ui-react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import { ThemeProvider } from 'styled-components'
import { theme } from './styling/GlobalStyles'
import OverlayProvider from 'OverlayContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Suspense fallback='Loading...'>
    <Provider store={store}>
      <Authenticator.Provider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <OverlayProvider>
                <App />
              </OverlayProvider>
            </AuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Authenticator.Provider>
    </Provider>
  </Suspense>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
