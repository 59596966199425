import React, { useEffect, useState } from 'react'
import { PageWrapper, Flex, H1WithBack, ContentWrapper, Separator } from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { DepartmentBasicInfoTab } from './DepartmentBasicInfoTab'
import { DepartmentLocationsTab } from './DepartmentLocationsTab'
import { DepartmentTasksTab } from './DepartmentTasksTab'
import { DepartmentRoleAccessTab } from './DepartmentRoleAccessTab'
import { IDepartmentsState } from 'types/departments.interface'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import {
  clearDepartment,
  getSingleDepartment,
  updateDepartment,
} from 'features/departments/departmentsSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DepartmentWorkflowsTab } from './DepartmentWorkflowsTab'
import { DepartmentSupervisorsTab } from './DepartmentSupervisorsTab'
import { DropDownTabsContainer } from 'components/DropDownTabsContainer/DropDownTabsContainer'
import { DepartmentProcessesTab } from './DepartmentProcessesTab'
import { DepartmentCyclesTab } from './DepartmentCyclesTab'

export const EditDepartment = () => {
  const [active, setActive] = useState('Basic Info')
  const [state, setState] = useState<IDepartmentsState>({
    name: '',
    roles: [],
    locations: [],
    tasks: [],
    workflowTemplates: [],
    processTemplates: [],
    supervisors: [],
    taskCycleTemplates: []
  })
  const { singleDepartment } = useSelector((state: RootState) => state.departments)
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getSingleDepartment(id))
    return () => {
      dispatch(clearDepartment())
    }
  }, [])

  useEffect(() => {
    singleDepartment && setState(singleDepartment)
  }, [singleDepartment])

  const handleSubmit = async () => {
    if (state.name.length < 2) {
      toast.warn('Department name is not valid')
    } else {
      const resp = await dispatch(updateDepartment({ ...state, id: parseInt(id) }))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/departments')
      }
    }
  }

  const renderTabs = () => {
    switch (active) {
      case 'Basic Info':
        return (
          <DepartmentBasicInfoTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Locations':
        return (
          <DepartmentLocationsTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Tasks':
        return <DepartmentTasksTab state={state} setState={setState} handleSubmit={handleSubmit} />
      case 'Workflows':
        return (
          <DepartmentWorkflowsTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Task Cycles':
        return (
          <DepartmentCyclesTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Process Templates':
        return (
          <DepartmentProcessesTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Role access':
        return (
          <DepartmentRoleAccessTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Supervisors':
        return (
          <DepartmentSupervisorsTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
    }
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between>
          <H1WithBack
            arrow
            title={'Edit ' + (singleDepartment?.name ? singleDepartment.name : '')}
          />
          <DropDownTabsContainer
            tabs={['Basic Info', 'Locations', 'Tasks', 'Workflows', 'Task Cycles', 'Role access', 'Supervisors', 'Process Templates']}
            setActive={setActive}
            active={active}
          />
        </Flex>
        <Separator />
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
