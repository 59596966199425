import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts'

export const CustomLabel = ({
  viewBox,
  labelText,
  value,
}: {
  viewBox?: { cx: number; cy: number }
  labelText?: string
  value?: string
}) => {
  const { cx, cy } = viewBox

  return (
    <g>
      <text
        x={100}
        y={100}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='15'
      >
        {labelText}
      </text>
      <text
        x={cx + 3}
        y={cy + 3}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fill='#464255'
        fontSize={17}
        fontWeight='700'
      >
        {value}
      </text>
    </g>
  )
}


export const Chart = ({ data, modifiedData = [], chartColors = [] }: IChart) => {
  return (
    <ResponsiveContainer width={100} height={100}>
      <PieChart width={80} height={80}>
        <Pie data={modifiedData} dataKey='value' innerRadius={30} outerRadius={50}>
          {modifiedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
          ))}
          <Label
            content={
              <CustomLabel value={((data[0].value * 100) / data[1].value).toFixed(0) + '%'} />
            }
            position='center'
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

interface IChart {
  data: { value: number, name: string }[]
  modifiedData: { value: number, name: string }[]
  chartColors: string[]
  color: string
}
