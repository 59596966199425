import React, { useEffect, useState, useRef } from 'react'
import {
    Flex,
    ProfilePicture,
    Table,
    Th,
    Tr,
    PageWrapper,
    PageFooter,
    Separator,
    CursorPointer,
    EditContainer,
    H1WithBack,
} from '../../../styling/GlobalStyles'
import { useNavigate } from 'react-router-dom'
import { Pagination } from '../../../components/Pagination/Pagination'
import Avatar from '../../../assets/images/profileAvatar.png'
import { Rating } from '../../Employees/Rating'
import { NavBar } from '../../../components/NavBar/NavBar'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { handlePagination, getParams } from 'utils/helpers'
import { Td } from 'components/Td/Td'
import { Modal } from 'components/Modal/Modal'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { ImageModalFrame } from '../../Employees/Employees.styled'
import { Filter } from 'components/Filter/Filter'
import { FilterEnum, LinkEnum } from 'components/Filter/SingleFilter'
import { clearDBEmployeesList, getDBEmployees } from 'features/dashboard/dbEmployeesSlice'
import { Status } from 'components/Status/Status'
import { ArrowLeftColorless } from 'assets/icons/ArrowLeftColorless'
import useQueryPagination from 'hooks/useQueryPagination'

export const DBEmployees = () => {
    const { noEmployees, data, totalPages } = useSelector((state: RootState) => state.dbEmployees)
    const navigate = useNavigate()
    const [params, setParams] = useState<any>(getParams({ location }))
    const [imageModal, setImageModal] = useState({ isOpen: false, image: null })


    useQueryPagination({ fetchFunctions: [getDBEmployees], cleanUpFunctions: [clearDBEmployeesList], params })

    const filters = [
        {
            type: FilterEnum.ASYNC_SELECT,
            link: LinkEnum.SHIFTS,
            name: 'Shifts',
            state: null,
            companyId: true,
            single: true,
            propName: 'shiftId'
        },
    ]

    const ImageModal = () => {
        const imageModalRef = useRef(null)

        useEffect(() => {
            const outSideClick = (e) => {
                if (!imageModalRef.current.contains(e.target)) {
                    setImageModal({ isOpen: false, image: null })
                }
            }

            document.addEventListener('mousedown', outSideClick)

            return () => {
                document.removeEventListener('mousedown', outSideClick)
            }
        }, [])

        return (
            <Flex column gap='2rem' ref={imageModalRef}>
                <Flex end='true' width='100%'>
                    <CursorPointer onClick={() => setImageModal({ isOpen: false, image: null })}>
                        <CloseIcon />
                    </CursorPointer>
                </Flex>
                <ImageModalFrame>
                    <img
                        src={imageModal.image || Avatar}
                        alt='profile-image'
                        onError={({ currentTarget }) => (currentTarget.src = Avatar)}
                    />
                </ImageModalFrame>
            </Flex>
        )
    }

    return (
        <>
            <NavBar />
            <PageWrapper noFooter={totalPages < 2}>
                <Flex center between>
                    <H1WithBack arrow title='Employees' link='/dashboard' />
                    <Filter options={filters} setPageParams={setParams} />
                </Flex>
                <Separator />
                {noEmployees ? (
                    <NoItems title='Employees' />
                ) : (
                    <Table>
                        <Th next >
                            <Td w='20%' marginLeft='3rem' noTooltip text='Full Name' />
                            <Td w='15%' noTooltip text='Phone' />
                            <Td w='20%' noTooltip text='Role' />
                            <Td w='15%' noTooltip text='Performance' />
                            <Td w='15%' noTooltip text='Status' />
                        </Th>

                        {data &&
                            data.map((item) => {
                                return (
                                    <Tr key={item.id}>
                                        <Td w='2%' noTooltip>
                                            <Flex center>
                                                <ProfilePicture mr='20'>
                                                    <img
                                                        src={item.presignedImageUrl || Avatar}
                                                        alt=''
                                                        onClick={() =>
                                                            setImageModal({ isOpen: true, image: item.presignedImageUrl || Avatar })
                                                        }
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null
                                                            currentTarget.src = Avatar
                                                        }}
                                                    />
                                                </ProfilePicture>
                                            </Flex>
                                        </Td>
                                        <Td w='16%' text={item.firstName.concat(' ' + item.lastName)} />
                                        <Td w='11%' text={item?.phone} />
                                        <Td w='17%' text={item?.roleName} />
                                        <Td w='11.5%' noTooltip>
                                            <Rating rating={item.rating ? item.rating.toFixed(1) : ''} />
                                        </Td>
                                        <Td w='18.5%' noTooltip>
                                            <p>{item.isActive} </p>
                                            <Status status={item.isActive ? 'Active' : 'Inactive'} />
                                        </Td>
                                        <Td noTooltip>

                                            <EditContainer onClick={() => navigate('/dashboard/employees/' + item.id)} >
                                                <Flex style={{ transform: 'rotate(180deg)' }} className='arrow-table' >
                                                    {ArrowLeftColorless}
                                                </Flex>
                                            </EditContainer>
                                        </Td>
                                    </Tr>
                                )
                            })}
                    </Table>
                )}
                {imageModal.isOpen && (
                    <Modal black>
                        <ImageModal />
                    </Modal>
                )}
            </PageWrapper>
            {Math.ceil(totalPages) > 2 && <PageFooter>
                <Pagination
                    setPage={(page) => handlePagination(page, setParams, 30)}
                    pageCount={Math.ceil(totalPages)}
                    forcePage={(params.page ? params.page : 1) - 1}
                />
            </PageFooter>}
        </>
    )
}
