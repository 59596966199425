import React, { useEffect, useState } from 'react'
import { TriggersBasicInfo } from './IssueTriggersBasicInfo'
import {
  ContentWrapper,
  Flex,
  H1WithBack,
  PageWrapper,
  Tabs,
  TabsContainer,
} from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { TriggersCompaniesTab } from './IssueTriggersCompaniesTab'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'store'
import { getSingleIssue } from 'features/issues/issuesSlice'
import { addIssueTrigger } from 'features/triggers/issueTriggersSlice'
import { useNavigate } from 'react-router-dom'
import { IssueTriggersTasksTab } from './IssueTriggersTasksTab'
import { toast } from 'react-toastify'
import { getCompaniesSimple } from 'features/companies/companiesSlice'
import { issueTriggersBasicInfoInputs } from './issueTriggersValidation'
import { IssueTriggersProcessesTab } from './IssueTriggersProcessesTab'
import { IssueTriggersCyclesTab } from './IssueTriggersCyclesTab'
import { issueTriggersInitialState } from './issueTriggersData'
import { IIssueTriggerState } from 'types/issue-triggers.interface'

export const AddTriggers = () => {
  const { singleIssue } = useSelector((store: any) => store.issues)
  const [active, setActive] = useState('Basic Info')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [invalidFields, setInvalidFields] = useState(issueTriggersBasicInfoInputs)
  const [state, setState] = useState<IIssueTriggerState>(issueTriggersInitialState)


  const handleSubmit = async () => {
    if (invalidFields.length > 0) {
      toast.warn('All basic info fields must be valid.')
      return
    }


    const resp = await dispatch(addIssueTrigger(state))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/triggers-issue')
    }
  }
  const renderTabs = () => {
    if (active === 'Basic Info') {
      return (
        <TriggersBasicInfo
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          triggerSimpleRequest={triggerSimpleRequest}
        />
      )
    } else if (active === 'Companies') {
      return (
        <TriggersCompaniesTab
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          singleIssue={singleIssue}
        />
      )
    } else if (active === 'Tasks') {
      return <IssueTriggersTasksTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
    else if (active === 'Cycles') {
      return <IssueTriggersCyclesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
    else if (active === 'Processes') {
      return <IssueTriggersProcessesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
  }

  const triggerSimpleRequest = (value) => {
    dispatch(getSingleIssue(value))
    setState({ ...state, companies: [] })
  }
  useEffect(() => {
    dispatch(getCompaniesSimple(singleIssue?.industry?.id))
  }, [singleIssue])

  const handleCompanies = () => {
    if (state.issue?.name && 'Companies') {
      setActive('Companies')
      return
    }
    toast.error('Please select an issue event')
  }

  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title='Add Issue Trigger' />
          <TabsContainer>
            <Tabs active={active === 'Basic Info'} onClick={() => setActive('Basic Info')}>
              Basic Info
            </Tabs>
            <Tabs active={active === 'Companies'} onClick={() => handleCompanies()}>
              Companies
            </Tabs>
            <Tabs active={active === 'Tasks'} onClick={() => setActive('Tasks')}>
              Tasks
            </Tabs>
            <Tabs active={active === 'Cycles'} onClick={() => setActive('Cycles')}>
              Cycles
            </Tabs>
            <Tabs active={active === 'Processes'} onClick={() => setActive('Processes')}>
              Processes
            </Tabs>
          </TabsContainer>
        </Flex>
        <Flex between></Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}
