import React, { useState } from 'react'
import { addClientMachinery } from 'features/clientMachinery/clientMachinerySlice'
import { useDispatch } from 'react-redux'
import { NavBar } from '../../components/NavBar/NavBar'
import {
  Flex,
  H1WithBack,
  TabsContainer,
  Tabs,
  PageWrapper,
  ContentWrapper,
} from '../../styling/GlobalStyles'
import { MachineryCapabilitiesTab } from './MachineryCapabilitiesTab'
import { MachinerySpecificationTab } from './MachinerySpecificationTab'
import { AppDispatch } from 'store'
import { IMachinery } from 'types/machinery.interface'
import { useNavigate } from 'react-router-dom'
import { machinerySpecificationTabInputs } from './machineryValidation'
import { toast } from 'react-toastify'

export const AddMachinery = () => {
  const [active, setActive] = useState('Specification')
  const [invalidFields, setInvalidFields] = useState(machinerySpecificationTabInputs)
  const [state, setState] = useState<IMachinery>({
    name: '',
    machineCategoryId: null,
    machineryModelId: null,
    ownership: null,
    costPerHour: null,
    hoursWorked: null,
    lifetime: null,
    status: null,
    machineCost: null,
    purchaseDate: null,
    maintenanceCosts: [{ year: null, q1: null, q2: null, q3: null, q4: null }],
    operations: [],
    machineCategory: null,
    machineryModel: null,
    usageType: null,
    vehicle: null,
  })
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (invalidFields.filter(f => f !== 'status').length > 0) {
      toast.warning('All specification fields except vehicle and status are required.')
      return
    }


    dispatch(addClientMachinery(state))
    navigate('/machinery')
  }

  console.log(state)

  const renderTabs = () => {
    if (active == 'Specification') {
      return (
        <MachinerySpecificationTab
          state={state}
          setState={setState}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          handleSubmit={handleSubmit}
          setActive={setActive}
          isAdding={true}
        />
      )
    } else if (active == 'Capabilities') {
      return (
        <MachineryCapabilitiesTab state={state} setState={setState} handleSubmit={handleSubmit} />
      )
    } else {
      return (
        <MachinerySpecificationTab
          state={state}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          setState={setState}
          handleSubmit={handleSubmit}
          setActive={setActive}
        />
      )
    }
  }

  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack title='Add Machinery' arrow />

          <TabsContainer>
            <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
              Basic Info
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}
