import React from 'react'
import { TabFooter, Button, Flex, Animation } from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { ICycleTab } from 'types/cycles.interface'

import { SingleStep } from './SingleStep'

export const CycleStepsTab = ({ state, setState, handleSubmit, view }: ICycleTab) => {
    useFocusFirstInput()

    const renderSteps = () => {
        return state?.steps.map((s, i: number) => <SingleStep key={i} setState={setState} state={state} index={i} view={view} />)
    }

    return (
        <Animation >
            <Flex style={{ minHeight: 'calc(100vh - 17rem)' }} gap='1rem' wrap  >
                {renderSteps()}
            </Flex>

            {handleSubmit && (
                <TabFooter contract >
                    <Flex width='100%' end='true'>
                        <CancelBtn />
                        <Button ml='15' height='40' onClick={handleSubmit} orange>
                            Save
                        </Button>
                    </Flex>
                </TabFooter>
            )}
        </Animation>
    )
}
