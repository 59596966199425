import React, { useEffect, useState } from 'react'
import { NavBar } from '../../../components/NavBar/NavBar'
import {
  PageWrapper,
  Flex,
  Button,
  MainHeadline,
  GridItem,
  GridWrapper,
  Separator,
  ItemsGrid,
  H1WithBack,
} from '../../../styling/GlobalStyles'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import RemoveCircle from '../../../assets/icons/RemoveCircle'
import { AppDispatch, RootState } from 'store'
import {
  clearInventoryCategory,
  getSingleInventoryCategory,
  updateInventoryCategory,
} from 'features/inventory/invSettingsCategoriesSlice'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { ITableItem } from 'types/global.interface'
import { IInvenotryCategoryField } from 'types/inventory.interface'

export const SingleInvSettingsCategory = () => {
  const { singleInventoryCategory, noFields } = useSelector(
    (state: RootState) => state.inventoryCategories,
  )
  const [fields, setFields] = useState(singleInventoryCategory?.fields)
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()

  useEffect(() => {
    dispatch(getSingleInventoryCategory(id))
    return () => {
      dispatch(clearInventoryCategory())
    }
  }, [])

  useEffect(() => {
    singleInventoryCategory ? setFields(singleInventoryCategory.fields) : null
  }, [singleInventoryCategory])

  const handleRemove = (itemId: number) => {
    const modified = fields.filter((item: IInvenotryCategoryField) => item.id !== itemId)
    const array = modified.map((item: IInvenotryCategoryField) => item.id)
    setFields(modified)
    dispatch(updateInventoryCategory({ id: Number(id), fields: array }))
  }
  return (
    <>
      <NavBar />
      <PageWrapper>
        <Flex between>
          <MainHeadline>{singleInventoryCategory && singleInventoryCategory.name}</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add fields
          </Button>
        </Flex>
        <Separator />
        <H1WithBack arrow title='All fields' link='/inventory-categories' />
        <GridWrapper>
          <ItemsGrid columns={3}>
            {!noFields ? (
              fields?.map((item: ITableItem) => {
                const { id, name } = item
                return (
                  <GridItem key={id}>
                    {name}
                    <Flex center justcenter onClick={() => handleRemove(id)}>
                      <RemoveCircle />
                    </Flex>
                  </GridItem>
                )
              })
            ) : (
              <NoItems title='fields' />
            )}
          </ItemsGrid>
        </GridWrapper>
      </PageWrapper>
      {/* {Math.ceil(operations.totalCount / 30) > 1 && (
        <Pagination
          setPage={(selected) => navigate('?page=' + selected + '&limit=30')}
          pageCount={Math.ceil(operations.totalCount / 30)}
          forcePage={page - 1}
        />
      )} */}
    </>
  )
}
