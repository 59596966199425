import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Check from '../../../assets/icons/Check'
import { NavBar } from '../../../components/NavBar/NavBar'
import { Pagination } from '../../../components/Pagination/Pagination'
import {
  Button,
  Flex,
  GridItem,
  H1WithBack,
  ItemsGrid,
  MainHeadline,
  PageFooter,
  PageWrapper,
  Separator,
} from '../../../styling/GlobalStyles'
import { useNavigate, useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import {
  getSingleInventoryCategory,
  updateInventoryCategory,
} from 'features/inventory/invSettingsCategoriesSlice'
import { clearInventoryFieldsList, getInvSettingsFields } from 'features/inventory/invSettingsFieldsSlice'
import { getParams, handlePagination } from 'utils/helpers'
import { CancelBtn } from 'components/Button/CancelBtn'
import useQueryPagination from 'hooks/useQueryPagination'

export const AddFieldsInvCategory = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { fields, totalPages } = useSelector((store: RootState) => store.invSettingsFields)
  const { singleInventoryCategory, totalCount } = useSelector(
    (state: RootState) => state.inventoryCategories,
  )
  const [array, setArray] = useState([])
  const navigate = useNavigate()
  const { id } = useParams()
  const [params, setParams] = useState<any>(getParams({ location }))

  const handleSubmit = async () => {
    const resp = await dispatch(updateInventoryCategory({ id: Number(id), fields: array }))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/inventory-categories/' + id)
    }
  }

  useEffect(() => {
    singleInventoryCategory?.fields?.length && setArray(singleInventoryCategory.fields.map(f => f.id))
  }, [singleInventoryCategory])

  useEffect(() => {
    dispatch(getSingleInventoryCategory(id))
  }, [])


  useQueryPagination({ fetchFunctions: [getInvSettingsFields], cleanUpFunctions: [clearInventoryFieldsList], params })

  const handleAdd = (id: number) => {
    if (array.some((i) => i == id)) {
      setArray((prev) => prev.filter((i) => i !== id))
    } else {
      setArray((prev) => [...prev, id])
    }
  }

  return (
    <>
      <NavBar input placeholder='Search fields..' setParams={setParams} limit={5} />
      <PageWrapper>
        <MainHeadline>Add fields</MainHeadline>
        <Separator />
        <H1WithBack title='All Fields' />
        <ItemsGrid columns={3} mt='1.5rem'>
          {fields &&
            fields.map((item) => {
              return (
                <GridItem
                  key={item.id}
                  valid={array.some((i) => i == item.id)}
                  onClick={() => handleAdd(item.id)}
                >
                  {item.name}
                  <Check valid={array.some((i) => i == item.id)} />
                </GridItem>
              )
            })}
        </ItemsGrid>
      </PageWrapper>
      <PageFooter>
        {totalCount > 30 ? (
          <Flex width='100%' between center>
            <Pagination
              setPage={(page) => handlePagination(page, setParams)}
              pageCount={Math.ceil(totalPages)}
              forcePage={(params.page ? params.page : 1) - 1}
            />
            <Flex gap='1rem'>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button orange onClick={handleSubmit}>
                Save
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex gap='1rem'>
            <CancelBtn />
            <Button orange onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        )}
      </PageFooter>
    </>
  )
}
