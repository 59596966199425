import React, { useState } from 'react'
import { PageWrapper, Flex, H1WithBack, ContentWrapper, Separator } from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { DepartmentBasicInfoTab } from './DepartmentBasicInfoTab'
import { DepartmentLocationsTab } from './DepartmentLocationsTab'
import { DepartmentTasksTab } from './DepartmentTasksTab'
import { DepartmentRoleAccessTab } from './DepartmentRoleAccessTab'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { addDepartment } from 'features/departments/departmentsSlice'
import { IDepartmentsState } from 'types/departments.interface'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { DepartmentWorkflowsTab } from './DepartmentWorkflowsTab'
import { DropDownTabsContainer } from 'components/DropDownTabsContainer/DropDownTabsContainer'
import { DepartmentSupervisorsTab } from './DepartmentSupervisorsTab'
import { DepartmentProcessesTab } from './DepartmentProcessesTab'
import { DepartmentCyclesTab } from './DepartmentCyclesTab'

export const AddDepartment = () => {
  const [active, setActive] = useState('Basic Info')
  const [state, setState] = useState<IDepartmentsState>({
    name: '',
    roles: [],
    locations: [],
    tasks: [],
    workflowTemplates: [],
    processTemplates: [],
    supervisors: [],
    taskCycleTemplates: []
  })
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (state.name.length < 2) {
      toast.warn('Department name is not valid')
    } else {
      const resp = await dispatch(addDepartment(state))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/departments')
      }
    }
  }

  const renderTabs = () => {
    switch (active) {
      case 'Basic Info':
        return (
          <DepartmentBasicInfoTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Locations':
        return (
          <DepartmentLocationsTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Tasks':
        return <DepartmentTasksTab state={state} setState={setState} handleSubmit={handleSubmit} />
      case 'Workflows':
        return (
          <DepartmentWorkflowsTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Task Cycles':
        return (
          <DepartmentCyclesTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Process Templates':
        return (
          <DepartmentProcessesTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Role access':
        return (
          <DepartmentRoleAccessTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Supervisors':
        return (
          <DepartmentSupervisorsTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
    }
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack arrow title='Add Department' mb='1rem' />
          <DropDownTabsContainer
            tabs={['Basic Info', 'Locations', 'Tasks', 'Workflows', 'Task Cycles', 'Role access', 'Supervisors', 'Process Templates']}
            setActive={setActive}
            active={active}
          />
        </Flex>
        <Separator />
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
