import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleErrors } from 'utils/helpers'
import { baseUrl } from '../../utils/axios'
import { IUnitsState } from './unitsSliceTypes'

const initialState:IUnitsState = {
  isLoading: false,
  units: [],
}

export const getUnits = createAsyncThunk(
  'units/getUnits',
  async (_ , thunkAPI) => {
    try {
       const response = await baseUrl.get('/units')
     return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)


const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {},
  extraReducers: {
    [getUnits.pending.type]: (state) => {
      state.isLoading = true
    },
    [getUnits.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.units = payload
    },
    [getUnits.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)

    },
  },
})

export default unitsSlice.reducer
