import React, { useState } from 'react'
import { CancelBtn } from 'components/Button/CancelBtn'
import { Input } from '../../../components/Input/Input'
import { NavBar } from '../../../components/NavBar/NavBar'
import {
  Animation,
  Button,
  ButtonsContainer,
  H1WithBack,
  ItemsGrid,
  PageFooter,
  PageWrapper,
  Separator,
} from '../../../styling/GlobalStyles'
import { useDispatch } from 'react-redux'
import { addSupplier } from 'features/supliers/suppliersSlice'
import { AppDispatch } from 'store'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { useNavigate } from 'react-router-dom'
import { suppliersValidationList } from './suppliersValidation'
import { toast } from 'react-toastify'
import { standardFieldValidation, validateSelect } from 'utils/validationUtils'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const AddSupplier = () => {
  const [state, setState] = useState({
    name: '',
    phoneNumber: 0,
    email: '',
    address: '',
    vatId: '',
    status: null,
  })
  const [invalidFields, setInvalidFields] = useState(suppliersValidationList)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  useFocusFirstInput()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    standardFieldValidation(e, setInvalidFields)

    setState((prev) => {
      const copy = { ...prev }
      copy[name] = value
      return copy
    })
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    validateSelect(value, name, setInvalidFields)

    setState((prev) => {
      const copy = { ...prev }
      copy.status = value.value
      return copy
    })
  }
  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault()

    if (invalidFields.length > 0) {
      toast.warn('All fields must be valid')
      return
    }
    const resp = await dispatch(addSupplier(state))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/inventory-supliers')
    }
  }

  const options = [
    { label: 'Active', value: 1 },
    { label: 'Inactive', value: 2 },
  ]

  return (
    <>
      <NavBar />
      <Animation>
        <PageWrapper>
          <H1WithBack arrow title='Add Supplier' />
          <Separator />
          <ItemsGrid columns={3}>
            <Input
              type='text'
              placeholder='Enter name'
              labelText='Suppliers Name'
              name='name'
              onChange={handleChange}
              value={state.name}
              invalid={invalidFields.includes('name') ? 'true' : ''}
            />
            <Input
              name='phoneNumber'
              onChange={handleChange}
              value={state.phoneNumber}
              type='tel'
              placeholder='Enter phone number'
              labelText='Phone Number'
              invalid={invalidFields.includes('phoneNumber') ? 'true' : ''}
            />
            <Input
              type='email'
              value={state.email}
              onChange={handleChange}
              name='email'
              labelText='Email'
              placeholder='Enter email'
              invalid={invalidFields.includes('email') ? 'true' : ''}
            />
            <Input
              type='text'
              labelText='Address'
              placeholder='Enter address'
              value={state.address}
              name='address'
              onChange={handleChange}
              invalid={invalidFields.includes('address') ? 'true' : ''}
            />
            <Input
              type='text'
              labelText='VAT ID'
              placeholder='Enter vat ID'
              name='vatId'
              value={state.vatId}
              onChange={handleChange}
              invalid={invalidFields.includes('vatId') ? 'true' : ''}
            />
            <HonestSelectInput
              options={options}
              labelText='Status'
              name='status'
              onChange={handleSelect}
              invalid={invalidFields.includes('status')}
            />
          </ItemsGrid>
        </PageWrapper>
        <PageFooter>
          <ButtonsContainer>
            <CancelBtn />
            <Button orange onClick={handleSubmit}>
              Save
            </Button>
          </ButtonsContainer>
        </PageFooter>
      </Animation>
    </>
  )
}
