import React from 'react'
import { ModalContainer, ModalWrapper } from './Modal.styled'
import { AnimationModal } from 'styling/GlobalStyles'


export const Modal = ({ black, noMaxH, children, maxWidth, width, noBack, zIndex }: IModal) => {
  return (
    <ModalWrapper noBack={noBack} black={black} zIndex={zIndex} >
      <AnimationModal>
        <ModalContainer maxWidth={maxWidth} width={width} noMaxHeight={noMaxH}>
          {children}
        </ModalContainer>
      </AnimationModal>
    </ModalWrapper>
  )
}

interface IModal {
  black?: boolean
  noMaxH?: boolean
  children: React.ReactNode
  maxWidth?: boolean
  width?: string
  noBack?: boolean
  zIndex?: string
}