/*eslint-disable*/

import React, { SetStateAction, useEffect } from 'react'
import {
  ItemsGrid,
  Button,
  Flex,
  Animation,
  TabFooter,
  SpecificationWrapper,
} from '../../../styling/GlobalStyles'
import { Input } from '../../../components/Input/Input'
import { machineryModelList } from '../../Employees/dummyList'
import { useDispatch, useSelector } from 'react-redux'
import { HonestSelectInput } from '../../../components/HonestSelect/HonestSelectInput'
import { CancelBtn } from 'components/Button/CancelBtn'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { standardFieldValidation, validateSelect } from 'utils/validationUtils'
import { clearCategories, clearCategory, getCategories, getSingleCategory } from 'features/categories/categoriesSlice'
import { AppDispatch, RootState } from 'store'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { IMachineModel, IMachineModelTab } from 'types/machineModels.interface'
import { ISingleCategory } from 'types/categories.interface'

export const ModelsSpecificationTab = ({
  state,
  setState,
  handleSubmit,
  invalidFields,
  setInvalidFields,
  invalidCategoryFields,
  setInvalidCategoryFields,
}: ISpecificationTab) => {
  const { category, categories } = useSelector((state: RootState) => state.categories)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getCategories(null))

    return (() => {
      dispatch(clearCategories())
      dispatch(clearCategory())
    })

  }, [])


  useEffect(() => {
    state?.category?.value && dispatch(getSingleCategory((state?.category?.value))).then(resp => {
      if (resp.meta.requestStatus == 'fulfilled') {
        //  @ts-ignore
        const fields = resp.payload.fields.filter(f => !state?.fields.some(sf => sf.fieldId == f.id))
        setInvalidCategoryFields(prev => ([...prev, ...fields.map(f => f.name)]))
      }
    })
  }, [state?.category?.value])


  useFocusFirstInput()

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    standardFieldValidation(e, setInvalidFields)
    const { name, value } = e.target as HTMLInputElement
    const newState = { ...state }
    newState[name] = value
    setState(newState)
  }

  const handleInputField = (e) => {
    standardFieldValidation(e, setInvalidCategoryFields)
    const value = e.target.value
    const id = e.target.id
    const newState = structuredClone(state)
    const objIndex = newState.fields.findIndex((item) => item.fieldId == id)
    if (objIndex > -1) {
      newState.fields[objIndex].value = value
    } else {
      //  @ts-ignore
      newState.fields.push({ fieldId: id, value })
    }
    setState(newState)
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    validateSelect(value, name, setInvalidFields)
    const newState: IMachineModel = structuredClone(state)
    if (name == 'fuelType') {
      newState.fuelType = value.value
    } else {
      const foundCategory: ISingleCategory = categories.find(c => c.id === value.value)

      newState.category = value
      //  @ts-ignore // TODO:Regulisati
      newState.fields = foundCategory ? foundCategory.fields.map(f => ({ name: f.name, dataType: f.dataType, dropDownItems: f.dropDownItems, dropDownOptions: f.dropDownItems.map(d => d.name), fieldId: f.id, id: f.id, unitId: f.unitId, value: null })) : []
      if (!foundCategory) {
        setInvalidCategoryFields([])
      } else {
        setInvalidCategoryFields(foundCategory.fields.map(f => f.name))
      }

    }

    setState(newState)
  }

  const handleSelectField = (value: ISelectValue, id: string | number, name: string) => {
    validateSelect(value, name, setInvalidCategoryFields)
    const newState = { ...state }
    const fieldIndex = newState?.fields.findIndex((f) => f.fieldId == id)
    if (fieldIndex > -1) {
      newState.fields[fieldIndex].value = value.value
    } else {
      //  @ts-ignore
      newState.fields.push({ fieldId: Number(id), value: value.value })
    }
    setState(newState)
  }

  const renderFields = () => {
    const missingFields = (category as any)?.category && (category as any)?.category.fields.filter(f => !state?.fields?.some(sf => sf.fieldId == f.id)).map(f => ({ ...f, fieldId: f.id }))
    let fields = state?.fields ? [...state?.fields] : []
    if (missingFields) {
      fields = [...fields, ...missingFields]
    }
    const forReturn = fields.map((field) => {
      const getValue = () => {
        const foundField = state.fields.find((f) => f.fieldId == field.fieldId)
        return foundField ? foundField.value : null
      }
      if (field.dataType == 1) {
        return (
          <Input
            id={field.fieldId}
            key={field.fieldId}
            value={getValue()}
            name={field.name}
            type='text'
            labelText={field.name}
            onChange={handleInputField}
            placeholder={'Enter ' + field.name}
            invalid={invalidCategoryFields.includes(field.name) ? 'true' : ''}
          />
        )
      } else if (field.dataType == 2) {
        const getValue = () => {
          const foundField = state.fields?.find((f) => f.fieldId == field.fieldId)
          return foundField ? foundField.value : field.value
        }

        return (
          <Input
            id={field.fieldId}
            key={field.fieldId}
            name={field.name}
            value={getValue()}
            type='number'
            labelText={field.name}
            onChange={handleInputField}
            placeholder={'Enter ' + field.name}
            invalid={invalidCategoryFields.includes(field.name) ? 'true' : ''}
          />
        )
      } else {
        const optionsForSelect = field.dropDownOptions
          ? field.dropDownOptions.map((item: string) => ({
            label: item,
            value: item,
          }))
          : []

        const defaultValue = () => {
          const foundField = state.fields?.find((f) => f.fieldId == field.fieldId)
          return foundField ? foundField.value : null
        }

        return (
          <HonestSelectInput
            key={field.fieldId}
            name={String(field.fieldId)}
            onChange={(value: ISelectValue) => handleSelectField(value, field.fieldId, field.name)}
            labelText={field.name}
            defaultValue={defaultValue()}
            options={optionsForSelect}
            invalid={invalidCategoryFields.includes(field.name)}
          />
        )
      }
    })
    return forReturn
  }

  const parseCategories = (data: any) => {
    return data
      ? data.map((item: any) => ({ label: item.category.name, value: item.category.id }))
      : []
  }

  return (
    state && (
      <>
        <Animation>
          <SpecificationWrapper>
            <ItemsGrid colgap='5em' columns={3}>
              <Input
                name='model'
                value={state.model}
                type='text'
                labelText='Model'
                onChange={handleInput}
                placeholder='Enter model'
                invalid={invalidFields.includes('model') ? 'true' : ''}
              />
              <Input
                name='make'
                value={state.make}
                type='text'
                labelText='Make'
                onChange={handleInput}
                placeholder='Enter make'
                invalid={invalidFields.includes('make') ? 'true' : ''}
              />
              <HonestSelectInput
                defaultValue={state.fuelType}
                labelText='Fuel type'
                name='fuelType'
                onChange={(value: ISelectValue, name: string) => handleSelect(value, name)}
                options={machineryModelList}
                invalid={invalidFields.includes('fuelType')}
              />
              <HonestSelectAsyncInput
                link='categories'
                placeholder='Search categories'
                name='category'
                labelText='Category'
                defaultValue={state.category?.label}
                onChange={(value: ISelectValue) => handleSelect(value, 'category')}
                parserFunction={parseCategories}
                invalid={invalidFields.includes('category')}
              />
              {renderFields()}
            </ItemsGrid>
          </SpecificationWrapper>
          <TabFooter>
            <Flex end='true' gap='1em'>
              <CancelBtn />
              <Button orange onClick={handleSubmit}>
                Save
              </Button>
            </Flex>
          </TabFooter>
        </Animation>
      </>
    )
  )
}


export interface ISpecificationTab extends IMachineModelTab {
  invalidFields: string[]
  setInvalidFields: React.Dispatch<SetStateAction<string[]>>
  invalidCategoryFields: string[]
  setInvalidCategoryFields: React.Dispatch<SetStateAction<string[]>>
}
