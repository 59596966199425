export const itemsValidationList = [
    'name',
    'quantity',
    'minLimit',
    'maxLimit',
    'category',
    'location',
    'sku',
    'model',
    'price',
  ]