import { configureStore } from '@reduxjs/toolkit'
import userSlice from './features/user/userSlice'
import operationsSlice from './features/operations/operationsSlice'
import fieldsSlice from './features/fields/fieldsSlice'
import categoriesSlice from './features/categories/categoriesSlice'
import companiesSlice from './features/companies/companiesSlice'
import machineModelsSlice from './features/machineModels/machineModelsSlice'
import zonesSlice from 'features/zones/zonesSlice'
import clientRolesSlice from 'features/roles/clientRolesSlice'
import roleTemplatesSlice from 'features/roles/roleTemplatesSlice'
import usersSlice from 'features/users(Employees)/usersSlice'
import shiftsSlice from 'features/shifts/shiftsSlice'
import workScheduleSlice from 'features/work-schedules/workScheduleSlice'
import globalSlice from 'features/global/globalSlice'
import clientMachinerySlice from 'features/clientMachinery/clientMachinerySlice'
import parametersSlice from 'features/parameters/parametersSlice'
import unitsSlice from 'features/units/unitsSlice'
import tasksSlice from 'features/tasks/tasksSlice'
import issuesSlice from 'features/issues/issuesSlice'
import industriesSlice from 'features/industries/industriesSlice'
import locationsSlice from 'features/locations/locationsSlice'
import departmentsSlice from 'features/departments/departmentsSlice'
import inventoryItemsSlice from 'features/inventory/inventoryItemsSlice'
import invSettingsCategoriesSlice from 'features/inventory/invSettingsCategoriesSlice'
import issueTriggersSlice from './features/triggers/issueTriggersSlice'
import tasksTriggersSlice from 'features/triggers/tasksTriggersSlice'
import suppliersSlice from 'features/supliers/suppliersSlice'
import machineryCatogoriesSlice from 'features/categories/machineryCategories'
import financesSlice from 'features/finances/financesSlice'
import workflowsSlice from 'features/workflows/workflowsSlice'
import invSettingsFieldsSlice from 'features/inventory/invSettingsFieldsSlice'
import globalViewSlice from 'features/globalView/globalViewSlice'
import reportsSlice from 'features/reports/reportsSlice'
import workerComponentsSlice from 'features/workerComponents/workerComponentsSlice'
import dashboardSlice from 'features/dashboard/dashboardSlice'
import dbEmployeesSlice from 'features/dashboard/dbEmployeesSlice'
import dbTasksSlice from 'features/dashboard/dbTasksSlice'
import dbMachinesSlice from 'features/dashboard/dbMachinesSlice'
import processTemplatesSlice from 'features/processTemplates/processTemplatesSlice'
import cyclesSlice from 'features/cycles/cyclesSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    operations: operationsSlice,
    fields: fieldsSlice,
    categories: categoriesSlice,
    companies: companiesSlice,
    machineModels: machineModelsSlice,
    zones: zonesSlice,
    clientRoles: clientRolesSlice,
    roleTemplates: roleTemplatesSlice,
    users: usersSlice,
    shifts: shiftsSlice,
    workSchedules: workScheduleSlice,
    globals: globalSlice,
    clientMachinery: clientMachinerySlice,
    parameters: parametersSlice,
    units: unitsSlice,
    tasks: tasksSlice,
    issues: issuesSlice,
    industries: industriesSlice,
    locations: locationsSlice,
    departments: departmentsSlice,
    inventoryItems: inventoryItemsSlice,
    invSettingsFields: invSettingsFieldsSlice,
    inventoryCategories: invSettingsCategoriesSlice,
    issueTriggers: issueTriggersSlice,
    taskTriggers: tasksTriggersSlice,
    suppliers: suppliersSlice,
    machineryCategories: machineryCatogoriesSlice,
    finances: financesSlice,
    workflows: workflowsSlice,
    procesTemplates: processTemplatesSlice,
    globalView: globalViewSlice,
    reports: reportsSlice,
    workerComponents: workerComponentsSlice,
    dashboard: dashboardSlice,
    dbEmployees: dbEmployeesSlice,
    dbTasks: dbTasksSlice,
    dbMachines: dbMachinesSlice,
    cycles:cyclesSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
