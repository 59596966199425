import React from 'react'

interface IAddCircle {
  white?: boolean
}

export const AddCircle: React.FC<IAddCircle> = ({ white }: { white?: boolean }) => {
  return !white ? (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='23' viewBox='0 0 22 23' fill='none'>
      <path
        d='M10.9997 20.3398C16.0623 20.3398 20.1663 16.2357 20.1663 11.1731C20.1663 6.11053 16.0623 2.00647 10.9997 2.00647C5.93706 2.00647 1.83301 6.11053 1.83301 11.1731C1.83301 16.2357 5.93706 20.3398 10.9997 20.3398Z'
        stroke='#464255'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 7.50647V14.8398'
        stroke='#464255'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.33301 11.1731H14.6663'
        stroke='#464255'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ) : (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='23' viewBox='0 0 22 23' fill='none'>
      <path
        d='M10.9997 20.3398C16.0623 20.3398 20.1663 16.2357 20.1663 11.1731C20.1663 6.11053 16.0623 2.00647 10.9997 2.00647C5.93706 2.00647 1.83301 6.11053 1.83301 11.1731C1.83301 16.2357 5.93706 20.3398 10.9997 20.3398Z'
        stroke='#fff'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 7.50647V14.8398'
        stroke='#fff'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.33301 11.1731H14.6663'
        stroke='#fff'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}



export const AddCircleWhite = (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.9997 20.1668C16.0623 20.1668 20.1663 16.0628 20.1663 11.0002C20.1663 5.93755 16.0623 1.8335 10.9997 1.8335C5.93706 1.8335 1.83301 5.93755 1.83301 11.0002C1.83301 16.0628 5.93706 20.1668 10.9997 20.1668Z'
      stroke='white'
      strokeWidth='1.66782'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 7.3335V14.6668'
      stroke='white'
      strokeWidth='1.66782'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.33301 11H14.6663'
      stroke='white'
      strokeWidth='1.66782'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
