import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { removeDuplicates } from 'utils/helpers'
import axios from 'axios'
/* eslint-disable*/

const usePaginate = ( //  TODO: Prebaciti u objekat
  link,
  params,
  fixedList,
  parserFunction,
  setList,
  additionalTrigger,
  companyId?,
  resetList?,
  leaveDuplicates?,
  customSelectedProp?,
  setLoading?
) => {
  const [totalPages,setTotalPages] = useState(1) 
  useEffect(() => {
    const fetchData = async () => {
      const session = await Auth.currentSession()
      setLoading && setLoading(true)

      link &&
        axios
          .get(process.env.REACT_APP_API + '/' + link, {
            headers: { Authorization: `Bearer ${session.getAccessToken().getJwtToken()}` },
            params: companyId
              ? {
                  ...params,
                  companyId: session.getAccessToken().payload['cognito:groups'][1].split('_')[1],
                }
              : params,
          })
          .then((res) => {
            setTotalPages(res.data.totalPages || 0)
            setList((prev) => {
              let copy = prev ? structuredClone(prev) : []

              if (resetList) {
                copy = res.data.data
              } else {
                if (parserFunction) {
                  copy = [...copy, ...parserFunction(res.data)]
                } else {
                  copy = [...copy, ...res.data.data]
                }
              }
              return leaveDuplicates ? copy : removeDuplicates(copy, true, customSelectedProp)
            })
            setLoading && setLoading(false)
          })
    }
    if (!fixedList) {
      const paginationEnded = params.page!==1 &&( totalPages < params.page)
      !paginationEnded && fetchData()
    }
  }, [params, additionalTrigger])
}

export default usePaginate
