import React, { SetStateAction } from 'react'
import { Input } from 'components/Input/Input'
import { TabFooter, Flex, Button, Animation } from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'

export const IndustryBasicInfoTab = ({ state, setState, handleSubmit }: IIndustryBasicInfoTab) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    setState({ ...state, [name]: e.target.value })
  }

  return (
    <Animation>
      <Input
        type='text'
        placeholder='Industry name'
        labelText='Industry name'
        width='20rem'
        value={state?.name}
        onChange={handleChange}
        name='name'
        invalid={state?.name?.length < 1 ? 'true' : ''}
      />

      <TabFooter>
        <Flex width='100%' end='true'>
          <CancelBtn />
          <Button ml='15' height='40' orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>
  )
}

interface IIndustryBasicInfoTab {
  state: {
    name: string
  }
  setState: React.Dispatch<SetStateAction<{ name: string }>>
  handleSubmit: () => Promise<void>
}

