import React from 'react'
import { CollapseItemText } from 'components/CollapseItem/CollapseItem.styled'
import { BlackCircle, Flex, Collapsable } from '../../../styling/GlobalStyles'
import RemoveCircle from '../../../assets/icons/RemoveCircle'
import { INameId } from 'constants/globalTypes'

export const TriggersCollapseItem = ({ data, setState, prop }: ITriggerCollapseItem) => {
  const handleRemove = () => {
    setState((prev) => {
      const copy = { ...prev }
      copy[prop] = copy[prop].filter((i) => {
        return i.id !== data.id
      })
      return copy
    })
  }

  return (
    <Collapsable expanded={false}>
      <Flex mb='20'>
        <CollapseItemText expanded={false}>{data && data.name}</CollapseItemText>
        <BlackCircle onClick={handleRemove}>
          <RemoveCircle white />
        </BlackCircle>
      </Flex>
    </Collapsable>
  )
}


interface ITriggerCollapseItem {
  data: INameId
  setState: React.Dispatch<React.SetStateAction<any>>
  type?: string
  prop?: string
}