import React from 'react'

export const TasksIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.71521 13.4351C2.88609 13.1369 3.26207 13.0362 3.555 13.2101L10.614 17.4014L17.6731 13.2101C17.966 13.0362 18.342 13.1369 18.5128 13.4351C18.6837 13.7332 18.5848 14.1159 18.2918 14.2899L10.9234 18.6649C10.7322 18.7784 10.4958 18.7784 10.3046 18.6649L2.93621 14.2899C2.64328 14.1159 2.54434 13.7332 2.71521 13.4351Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.71521 9.68508C2.88609 9.38693 3.26207 9.28622 3.555 9.46014L10.614 13.6514L17.6731 9.46014C17.966 9.28622 18.342 9.38693 18.5128 9.68508C18.6837 9.98324 18.5848 10.3659 18.2918 10.5399L10.9234 14.9149C10.7322 15.0284 10.4958 15.0284 10.3046 14.9149L2.93621 10.5399C2.64328 10.3659 2.54434 9.98324 2.71521 9.68508Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.3046 1.33514C10.4958 1.22162 10.7322 1.22162 10.9234 1.33514L18.2918 5.71014C18.4805 5.82214 18.5965 6.02771 18.5965 6.25C18.5965 6.47229 18.4805 6.67786 18.2918 6.78986L10.9234 11.1649C10.7322 11.2784 10.4958 11.2784 10.3046 11.1649L2.9362 6.78986C2.74756 6.67786 2.63156 6.47229 2.63156 6.25C2.63156 6.02771 2.74756 5.82214 2.9362 5.71014L10.3046 1.33514ZM4.46423 6.25L10.614 9.90144L16.7638 6.25L10.614 2.59856L4.46423 6.25Z'
      fill='#464255'
    />
  </svg>
)
