import React from 'react'

export const LocationIcon = (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75ZM2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 0.9375C10.3452 0.9375 10.625 1.21732 10.625 1.5625V4.6875C10.625 5.03268 10.3452 5.3125 10 5.3125C9.65482 5.3125 9.375 5.03268 9.375 4.6875V1.5625C9.375 1.21732 9.65482 0.9375 10 0.9375Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.9375 10C0.9375 9.65482 1.21732 9.375 1.5625 9.375H4.6875C5.03268 9.375 5.3125 9.65482 5.3125 10C5.3125 10.3452 5.03268 10.625 4.6875 10.625H1.5625C1.21732 10.625 0.9375 10.3452 0.9375 10Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 14.6875C10.3452 14.6875 10.625 14.9673 10.625 15.3125V18.4375C10.625 18.7827 10.3452 19.0625 10 19.0625C9.65482 19.0625 9.375 18.7827 9.375 18.4375V15.3125C9.375 14.9673 9.65482 14.6875 10 14.6875Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.6875 10C14.6875 9.65482 14.9673 9.375 15.3125 9.375H18.4375C18.7827 9.375 19.0625 9.65482 19.0625 10C19.0625 10.3452 18.7827 10.625 18.4375 10.625H15.3125C14.9673 10.625 14.6875 10.3452 14.6875 10Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 8.125C8.96447 8.125 8.125 8.96447 8.125 10C8.125 11.0355 8.96447 11.875 10 11.875C11.0355 11.875 11.875 11.0355 11.875 10C11.875 8.96447 11.0355 8.125 10 8.125ZM6.875 10C6.875 8.27411 8.27411 6.875 10 6.875C11.7259 6.875 13.125 8.27411 13.125 10C13.125 11.7259 11.7259 13.125 10 13.125C8.27411 13.125 6.875 11.7259 6.875 10Z'
        />
    </svg>
)
