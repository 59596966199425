export const roleList = [{ value: 'Wheel Loader Operators', label: 'Wheel Loader Operators' }]
export const shiftsList = [
  { value: 'All Shifts', label: 'All Shifts' },
  { value: 'Morning Shift', label: 'Morning Shift' },
  { value: 'Night Shift', label: 'Night Shift' },
  { value: 'Day Shift', label: 'Day Shift' },
]
export const machineryModelList = [
  { value: 1, label: 'Petrol' },
  { value: 2, label: 'Diesel' },
  { value: 3, label: 'Electricity' },
]

export const rating = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]
