import { NavBar } from 'components/NavBar/NavBar'
import { addClientRole } from 'features/roles/clientRolesSlice'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Flex,
  TabsContainer,
  Tabs,
  H1WithBack,
  PageWrapper,
  ContentWrapper,
} from 'styling/GlobalStyles'
import { CapabilitiesRolesTab } from './CapabilitiesRolesTab'
import { InfoRolesTab } from './InfoRolesTab'
import { MachineryRolesTab } from './MachineryRolesTab'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from 'store'
import { IRole } from 'types/roles.interface'
import { toast } from 'react-toastify'
import { PermissionsRolesTab } from './PermissionsRolesTab'
import { rolesInitialState } from './rolesData'
import { IOperation } from 'types/operations.interface'

export const AddRole = () => {
  const [active, setActive] = useState('Info')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [state, setState] = useState<IRole>(rolesInitialState)

  const handleSubmit = async () => {
    if (state.name.length < 1) {
      toast.warn('Role name must be filled')
      return
    }
    if (
      state.operations.length > 0 &&
      state.operations.some((o: IOperation) => o.types.some((t) => t.parameters.some((p) => !p.value)))
    ) {
      toast.warn('All operation type values must be filled')
      return
    }
    const newState = { ...state }



    const resp = await dispatch(addClientRole(newState))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/roles')
    }
  }
  const renderTabs = () => {
    if (active === 'Info') {
      return <InfoRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    } else if (active === 'Machines') {
      return <MachineryRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    } else if (active == 'Permissions') {
      return <PermissionsRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
    return <CapabilitiesRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack arrow title='Add Role' />
          <TabsContainer>
            <Tabs active={active === 'Info'} onClick={() => setActive('Info')}>
              Info
            </Tabs>
            <Tabs active={active === 'Machines'} onClick={() => setActive('Machines')}>
              Machines
            </Tabs>
            <Tabs active={active === 'Permissions'} onClick={() => setActive('Permissions')}>
              Permissions
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
