import styled from 'styled-components'
import { theme } from '../../styling/GlobalStyles'

interface TdProps {
  center?: boolean
  noTooltip?: boolean
  svgColor?: string
  cutText?:boolean
}

export const TdTooltip = styled.div<{left?:boolean}>`
  position: absolute;
  top: 0;
  ${({left})=>left ? 'right:0;' : 'left:0;'}
  background-color: ${theme.colorGray};
  color: white;
  width: max-content;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.1s ease-in;
  transform-origin: top;
  transform: scaleY(0);
  height: 100%;
  z-index: 9999 !important;
`

export const TdWrapper = styled.div<TdProps>`
  pointer-events: all;
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  ${(props) => props.center && 'justify-content: center;'}
  transition: all 250ms ease-out;
  z-index: 0 !important;
  ${(props) => !props.noTooltip && '&:hover { .tooltip { transform: scaleY(1)} }'}
  svg {
    ${(props) => props.svgColor && 'fill:' + props.svgColor};
  }

    ${(props) => props.cutText && `
    
    p{
      text-overflow: ellipsis;
      overflow: hidden; 
      width: 160px; 
      white-space: nowrap;
      }

    `};
`

export const TdTextButton = styled.p`
 color:${(props) => props.theme && props.theme.colorGray};
 text-decoration: underline;
 cursor: pointer;
`

