import React from 'react'
import { CollapseItem } from 'components/CollapseItem/CollapseItem'
import {
  Title,
  Button,
  Animation,
  TabFooter,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
} from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IOperation } from 'types/operations.interface'
import { IEmployeesTab } from 'types/users.interface'

export const EmployeeCapabilitiesTab = ({ handleSubmit, state, setState }: IEmployeesTab) => {
  const handleAdd = (item: IOperation) => {
    if (!state?.operations?.some((i: IOperation) => i.id == item.id)) {
      const parsedTypes = item.types.map((type) => {
        return {
          ...type,
          rating: 0,
          priority: 0,
          operationTypeId: Number(type.id),
          parameters: type.parameters.map((param) => {
            return { ...param, operationTypeParameterId: Number(param.parameterId), value: null }
          }),
        }
      })
      const parsedItem = { ...item, types: parsedTypes }
      setState((prev) => {
        const copy = { ...prev }
        copy.operations = [...copy.operations, parsedItem]
        return copy
      })
    } else {
      setState((prev) => {
        const copy = { ...prev }
        copy.operations = copy.operations.filter((it) => it.id !== item.id)
        return copy
      })
    }
  }

  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Capabilities</Title>
          {state.operations.map((item: IOperation) => {
            return <CollapseItem params setState={setState} key={item.id} data={item} />
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='operations/filled'
          selectedList={state.operations}
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}
