import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IGetAllMachinerySingleGetResponse, IMachineryPostRequest, IMachinerySingleResponse, IMachineryStatusesResponse } from 'types/api/machineryApi.interface'
import { IAvailabilityCategory, IAvailabilityForDisplay, IMachinery, IMachinerySlice } from 'types/machinery.interface'
import { formatDate, handleErrors, formatParams } from 'utils/helpers'
import { baseUrl } from '../../utils/axios'

const initialState:IMachinerySlice = {
  isLoading: false,
  clientMachines: [],
  singleClientMachine: null,
  machineryStatuses: {
    total: 0,
    free: 0,
    busy: 0,
    broken: 0,
  },
  totalPages: 0,
  pageCount: 0,
  noMachines: false,
}

export const getClientMachinery = createAsyncThunk(
  'clientMachinery/getClientMachinery',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<IGetAllMachinerySingleGetResponse>> = await baseUrl.get('machines', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getMachineryStatuses = createAsyncThunk(
  'clientMachinery/getMachineryStatuses',
  async (_, thunkAPI) => {
    try {
      const resp:AxiosResponse<IMachineryStatusesResponse> = await baseUrl.get('machines-availability/count')
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleClientMachinery = createAsyncThunk(
  'clientMachinery/getSingleClientMachinery',
  async (id: string, thunkAPI) => {
    try {
      const {data}:{data:IMachinerySingleResponse} = await baseUrl.get('machines/' + id)
      const type = data.vehicle?.type == 2 ? 'almaksVehicle' : 'frotcomVehicle'
      const obj:IMachinery ={
        machineCategoryId: data?.machineCategory?.id,
        machineryModelId: data?.machineryModel?.id,
        operations:data.operations?.map((o) => {
          const copyO = { ...o }
          return {
            ...copyO,
            types: copyO.types.map((t) => {
              const copyT = { ...t }
              return {
                ...copyT,
                id: copyT.operationTypeId,
              }
            }),
          }
        }),
        vehicle:data.vehicle ? data.vehicle[type] : null,
        hoursWorked:data.hoursWorked,
        ownership:data.ownership,
        costPerHour:data.costPerHour,
        maintenanceCosts:data.maintenanceCosts,
        machineCategory:data.machineCategory,
        machineryModel:data.machineryModel,
        mileage:data.mileage,
        lifetime:data.lifetime,
        purchaseDate:data.purchaseDate,
        status:data.status,
        name:data.name,
        usageType:data.usageType,
        id:data.id,
        machineCost:data.machineCost
      }


      return obj
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addClientMachinery = createAsyncThunk(
  'addMachinery/clientMachinery',
  async (machine: IMachinery, thunkAPI) => {
    try {

    const forSubmit: IMachineryPostRequest ={
      operationTypes:machine.operations
      .map((item) => {
        const final = item.types.map((i) => {
          return {
            operationTypeId: i.operationTypeId || i.id,
            rating: i.rating ? i.rating : 0,
            priority: i.priority ? i.priority : 0,
          }
        })
        return final
      })
      .flat(),
      machineCategoryId: machine.machineCategory.id,
      machineryModelId :machine.machineryModel.id,
      vehicleId: machine.vehicle.id,
      status: machine.status,
      hoursWorked:machine.hoursWorked,
      usageType:machine.usageType,
      ownership:machine.ownership,
      purchaseDate:machine.purchaseDate,
      lifetime:machine.lifetime,
      costPerHour:machine.costPerHour,
      maintenanceCosts:machine.maintenanceCosts,
      machineCost:machine.machineCost,
      mileage:machine.mileage,
      name:machine.name
    }

    if (!machine.status) {
      delete forSubmit.status
    }
      if (machine.ownership === 1) {
        forSubmit.purchaseDate = formatDate(new Date(forSubmit.purchaseDate))
      } else {
        delete forSubmit.purchaseDate
      }
      const resp = await baseUrl.post('machines', forSubmit, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteClientMachinery = createAsyncThunk(
  'deleteMachinery/clientMachinery',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('machines/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

interface IEditMachinePayload extends IMachinery {oldStatus:number}

export const updateClientMachinery = createAsyncThunk(
  'patchClientMachinery/updateClientMachinery',
  async (machine: IEditMachinePayload, thunkAPI) => {
    try {
      const copy:any = structuredClone(machine)
      const { id } = machine
      copy.vehicleId = machine.vehicle?.id || null
      copy.operationTypes = machine.operations
        .map((item) => {
          const final = item.types.map((i) => {
            return {
              operationTypeId: i.id,
              rating: i.rating ? i.rating : 0,
              priority: i.priority ? i.priority : 0,
            }
          })
          return final
        })
        .flat()
      delete copy.operations
      copy.machineCategoryId = machine.machineCategory.id
      copy.machineryModelId = machine.machineryModel.id
      delete copy.id
      if (copy.ownership === 1) {
        copy.purchaseDate = formatDate(new Date(machine.purchaseDate))
      } else {
        delete copy.purchaseDate
      }

      if(copy.status === copy.oldStatus){
        delete copy.status
      }
      delete copy.oldStatus

      const resp = await baseUrl.put('machines/' + id, copy, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const clientMachinerySlice = createSlice({
  name: 'clientMachinery',
  initialState,
  reducers: {
    cleanClientMachines: (state) => {
      state.clientMachines = []
    },
    cleanSingleClientMachine: (state) => {
      state.singleClientMachine = null
    },
  },
  extraReducers: {
    [getClientMachinery.pending.type]: (state) => {
      state.isLoading = true
    },
    [getClientMachinery.fulfilled.type]: (state: IMachinerySlice, { payload }:{payload:IPaginatedResponse<IGetAllMachinerySingleGetResponse>}) => {
      state.isLoading = false
      state.noMachines = payload.data.length < 1
      state.clientMachines = payload.data
      state.totalPages = payload.totalPages
    },
    [getClientMachinery.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleClientMachinery.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleClientMachinery.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleClientMachine = payload
    },
    [getSingleClientMachinery.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getMachineryStatuses.pending.type]: (state) => {
      state.isLoading = true
    },
    [getMachineryStatuses.fulfilled.type]: (state:IMachinerySlice, { payload }:{payload:IMachineryStatusesResponse}) => {
      state.isLoading = false
      const obj: IAvailabilityForDisplay = {
        free: 0,
        busy: 0,
        broken: 0,
        inMaintenance: 0,
        total: 0,
      }
      obj.free = payload.free.reduce((a: number, c: IAvailabilityCategory) => a + c.count, 0)
      obj.busy = payload.busy.reduce((a: number, c: IAvailabilityCategory) => a + c.count, 0)
      obj.broken = payload.broken.reduce((a: number, c: IAvailabilityCategory) => a + c.count, 0)
      obj.inMaintenance = payload.inMaintenance.reduce(
        (a: number, c: IAvailabilityCategory) => a + c.count,
        0,
      )
      obj.total = obj.broken + obj.busy + obj.free

      state.machineryStatuses = obj
    },
    [getMachineryStatuses.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addClientMachinery.pending.type]: (state) => {
      state.isLoading = true
    },
    [addClientMachinery.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      toast.success('Machinery added')
      state.clientMachines.push(payload)
      state.noMachines = false
    },
    [addClientMachinery.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateClientMachinery.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateClientMachinery.fulfilled.type]: (state:IMachinerySlice, { payload }) => {
      state.isLoading = false
      state.singleClientMachine = payload
      toast.success('Machinery updated')
    },
    [updateClientMachinery.rejected.type]: (state, { payload }) => {
      handleErrors(payload)
      state.isLoading = false
    },
    [deleteClientMachinery.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteClientMachinery.fulfilled.type]: (state:IMachinerySlice, { payload }:{payload:number}) => {
      state.isLoading = false
      state.clientMachines = state.clientMachines.filter((i) => i.id !== payload)
      state.noMachines = state.clientMachines.length < 1
      toast.success('Machinery deleted')
    },
    [deleteClientMachinery.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})
export const { cleanClientMachines, cleanSingleClientMachine } = clientMachinerySlice.actions
export default clientMachinerySlice.reducer
