import styled from 'styled-components'

export const ImagesSliderContainer = styled.div`
  height: 80vh;
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
`
export const MainImagePreview = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  border-radius: 8px;

  img {
    width: 90%;
    object-fit: contain;
    border-radius: 10px;
    height: 80%;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  }
`
export const BottomImagesContainer = styled.div`
  display: flex;
  gap: 1rem;
  height: 20%;
  overflow: auto;
  width: 100%;
  scrollbar-width: thin;

  img {
    height: 100%;
    width: 15rem;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }
`
