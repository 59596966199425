import React, { useState } from 'react'
import {
  PageFooter,
  Animation,
  Flex,
  Title,
  Button,
  SelectedItemsContainer,
  H2,
} from 'styling/GlobalStyles'
import { ITasksTab, ITaskState, ITaskWorkUnitAmount } from 'types/tasks.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { Input } from 'components/Input/Input'
import { AddOrangeBtn } from 'components/Button/AddOrangeBtn'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { toast } from 'react-toastify'
import { taskWorkerAmountInvalidFields } from './taskValidation'
import { standardFieldValidation } from 'utils/validationUtils'
import { useDispatch } from 'react-redux'
import { addWorkUnitAmounts } from 'features/tasks/tasksSlice'
import { useParams } from 'react-router-dom'
import { AppDispatch } from 'store'

export const TaskWorkUnitTab = ({ state, setState }: ITasksTab) => {
  const [selectedAmount, setSelectedAmount] = useState<ITaskWorkUnitAmount>(null)
  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()
  const handleAdd = () => {

    if (state.workUnitAmounts.some((w: ITaskWorkUnitAmount) => w.isCurrentlyAdding)) {
      toast.warn('Currently creating item must be saved first.')
      return
    }

    const newAmount = {
      rangeFrom: 0,
      rangeTo: 0,
      maxWorkUnitAmount: 0,
      id: state.workUnitAmounts.length + 1,
      isCurrentlyAdding: true
    }
    setState((prev: ITaskState) => {
      const copy = structuredClone(prev)
      copy.workUnitAmounts.push(newAmount)
      return copy
    })
    setSelectedAmount(newAmount)
    setInvalidFields(taskWorkerAmountInvalidFields)
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement
    standardFieldValidation(e, setInvalidFields)
    setSelectedAmount((prev) => {
      const copy = { ...prev }
      copy[name] = Number(value)
      return copy
    })
  }

  const saveSelectedAmount = async (forDispatch?: boolean) => {
    const copy = structuredClone(state)

    if (selectedAmount) {
      const index = state.workUnitAmounts.findIndex(
        (w: ITaskWorkUnitAmount) => w.id === selectedAmount.id,
      )
      const amountCopy = structuredClone(selectedAmount)
      delete amountCopy.isCurrentlyAdding
      copy.workUnitAmounts[index] = amountCopy
    }


    const resetValues = () => {
      setState(copy)
      const forSubmit = structuredClone(copy)
      delete forSubmit.selectedOperation
      delete forSubmit.workUnitAmounts
      setSelectedAmount(null)
    }

    if (forDispatch) {
      const resp = await dispatch(
        addWorkUnitAmounts({
          workUnitAmountPerRanges: copy.workUnitAmounts.map((ua) => ({
            ...ua,
            rangeFrom: Number(ua.rangeFrom),
            rangeTo: Number(ua.rangeTo),
          })),
          taskTemplateId: id,
        }),
      )

      if (resp.meta.requestStatus === 'fulfilled') {
        resetValues()
      }
      return
    }

    resetValues()
  }

  const handleSave = () => {

    if (invalidFields.length > 0) {
      toast.error('All fields must be filled')
      return
    }
    if (selectedAmount?.rangeTo < selectedAmount?.rangeFrom) {
      toast.error('Field Range From cannot be greater than Range To')
      return
    }
    saveSelectedAmount(true)
  }

  const handleSelectAmount = (item: ITaskWorkUnitAmount) => {
    if (invalidFields.length > 0) {
      toast.error('All fields must be filled')
      return
    }
    if (selectedAmount) {
      saveSelectedAmount()
    }
    setSelectedAmount(item)
  }

  const handleRemoveCurrent = () => {
    setInvalidFields([])
  }

  return (
    <Animation>
      <Flex between center>
        <SelectedItemsContainer>
          <Flex between mb='15'>
            <Title pad='0 20px'>Work Unit Amounts</Title>
            <AddOrangeBtn onClick={handleAdd} />
          </Flex>
          {state.workUnitAmounts?.map((item: ITaskWorkUnitAmount, i: number) => (
            <SelectedItem
              setState={setState}
              onClick={() => handleSelectAmount(item)}
              data={{
                ...item, name: item.isCurrentlyAdding ? 'New Work Unit Amount' :
                  'From ' + item.rangeFrom + ' To ' + item.rangeTo
              }}
              key={i}
              onRemove={item.isCurrentlyAdding && handleRemoveCurrent}
              type='workUnitAmounts'
            />
          ))}
        </SelectedItemsContainer>
        {selectedAmount &&
          state.workUnitAmounts?.some((i: ITaskWorkUnitAmount) => i.id === selectedAmount.id) ? (
          <Flex column gap='1rem' width='35%'>
            <H2>{selectedAmount.id == (state.workUnitAmounts.length) ? 'New Work Unit Amount'
              : 'From ' + selectedAmount.rangeFrom + ' To ' + selectedAmount.rangeTo}
            </H2>
            <Input
              value={selectedAmount.rangeFrom || ''}
              type='number'
              name='rangeFrom'
              labelText='Range From'
              onChange={handleChange}
              placeholder={'Enter Range From'}
              nomarg
            />
            <Input
              value={selectedAmount.rangeTo || ''}
              type='number'
              name='rangeTo'
              labelText='Range To'
              onChange={handleChange}
              placeholder={'Enter Range To'}
              nomarg
            />
            <Input
              value={selectedAmount.maxWorkUnitAmount || ''}
              type='number'
              name='maxWorkUnitAmount'
              labelText='Max Work Unit Amount'
              onChange={handleChange}
              placeholder={'Enter Max Unit Amount'}
              nomarg
            />
          </Flex>
        ) : (
          <Flex width='40%' column gap='1rem' center>
            <H2>No Work Unit Amount selected</H2>
            <Button onClick={handleAdd} orange>
              Add new
            </Button>
          </Flex>
        )}
      </Flex>

      <PageFooter>
        <Flex gap='1rem'>
          <CancelBtn />
          <Button orange onClick={handleSave}>
            Save
          </Button>
        </Flex>
      </PageFooter>
    </Animation>
  )
}

