export const companyInputs = [
  'main-name',
  'representative-position',
  'industryId',
  'contact-address',
  'representative-name',
  'contact-phoneNumber',
  'representative-phoneNumber',
  'representative-username',
  'contact-email',
  'representative-email',
  'main-taxNumber',
]

export const companiesInitialState = {
  name: '',
  industryId: null,
  industry: { name: null, id: null },
  contact: {
    address: '',
    email: '',
    phoneNumber: '+',
  },
  representative: {
    name: '',
    position: '',
    phoneNumber: '+',
    email: '',
    username: ''
  },
  taxNumber: '',
  settings: {
    shouldIncludeTraffic: false,
  }
}