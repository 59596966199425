import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Spinner } from './components/Loading/Loading'
import React from 'react'
export const SpinnerCondition = () => {
  const userIsLoading = useSelector((state: RootState) => state.users.isLoading)
  const allOperationsLoading = useSelector((state: RootState) => state.operations.isLoading)
  const allMachineModelsLoading = useSelector((state: RootState) => state.machineModels.isLoading)
  const singleCategoryLoading = useSelector((state: RootState) => state.categories.isLoadingSingle)
  const shiftsLoading = useSelector((state: RootState) => state.shifts.isLoading)
  const allFieldsLoading = useSelector((state: RootState) => state.fields.isLoading)
  const allCategoriesLoading = useSelector((state: any) => state.categories.isLoading)
  const clientRoleLoading = useSelector((state: RootState) => state.clientRoles.isLoading)
  const roleTemplateLoading = useSelector((state: RootState) => state.roleTemplates.isLoading)
  const companyLoading = useSelector((state: RootState) => state.companies.isLoading)
  const singleCompanyLoading = useSelector((state: RootState) => state.companies.isSingleLoading)
  const zoneLoading = useSelector((state: RootState) => state.zones.isLoading)
  const singleZoneLoading = useSelector((state: RootState) => state.zones.isSingleZoneLoading)
  const usersLoading = useSelector((state: RootState) => state.users.isLoading)
  const workSchedulesLoading = useSelector((state: RootState) => state.workSchedules.isLoading)
  const singleFieldLoading = useSelector((state: RootState) => state.fields.isSingleLoading)
  const industriesLoading = useSelector((state: RootState) => state.industries.isLoading)
  const issuesLoading = useSelector((state: RootState) => state.issues.isLoading)
  const tasksLoading = useSelector((state: RootState) => state.tasks.isLoading)
  const locationsLoading = useSelector((state: RootState) => state.locations.isLoading)
  const departmentsLoading = useSelector((state: RootState) => state.departments.isLoading)
  const inventoryItemsLoading = useSelector((state: RootState) => state.inventoryItems.isLoading)
  const issueTriggersLoading = useSelector((state: RootState) => state.issueTriggers.isLoading)
  const taskTriggersLoading = useSelector((state: RootState) => state.taskTriggers.isLoading)
  const clientMachineryLoading = useSelector((state: RootState) => state.clientMachinery.isLoading)
  const suppliersLoading = useSelector((state: RootState) => state.suppliers.isLoading)
  const financesLoading = useSelector((state: RootState) => state.finances.isLoading)
  const workflowsLoading = useSelector((state: RootState) => state.workflows.isLoading)
  const processTemplates = useSelector((state: RootState) => state.procesTemplates.isLoading)
  const globalViewLoading = useSelector((state: RootState) => state.globalView.isLoading)
  const reportsLoading = useSelector((state: RootState) => state.reports.isLoading)
  const workerComponentsLoading = useSelector((state: RootState) => state.workerComponents.isLoading)
  const dashboardLoading = useSelector((state: RootState) => state.dashboard.isLoading)
  const dbEmployeesLoading = useSelector((state: RootState) => state.dbEmployees.isLoading)
  const dbTasksLoading = useSelector((state: RootState) => state.dbTasks.isLoading)
  const dbMachinesLoading = useSelector((state: RootState) => state.dbMachines.isLoading)
  const cyclesLoading = useSelector((state: RootState) => state.cycles.isLoading)

  const invSettingsFieldsLoading = useSelector(
    (state: RootState) => state.invSettingsFields.isLoading,
  )
  const invSettingsFieldsSingleLoading = useSelector(
    (state: RootState) => state.invSettingsFields.isSingleLoading,
  )
  const inventoryCategoriesLoading = useSelector(
    (state: RootState) => state.inventoryCategories.isLoading,
  )

  const cond =
    userIsLoading ||
    allOperationsLoading ||
    allFieldsLoading ||
    allCategoriesLoading ||
    allMachineModelsLoading ||
    singleCategoryLoading ||
    clientRoleLoading ||
    companyLoading ||
    roleTemplateLoading ||
    singleCompanyLoading ||
    zoneLoading ||
    singleZoneLoading ||
    usersLoading ||
    shiftsLoading ||
    workSchedulesLoading ||
    singleFieldLoading ||
    industriesLoading ||
    issuesLoading ||
    tasksLoading ||
    locationsLoading ||
    departmentsLoading ||
    inventoryItemsLoading ||
    invSettingsFieldsLoading ||
    invSettingsFieldsSingleLoading ||
    inventoryCategoriesLoading ||
    issueTriggersLoading ||
    taskTriggersLoading ||
    clientMachineryLoading ||
    suppliersLoading ||
    financesLoading ||
    workflowsLoading ||
    processTemplates ||
    globalViewLoading ||
    reportsLoading ||
    workerComponentsLoading ||
    dashboardLoading ||
    dbEmployeesLoading ||
    dbTasksLoading ||
    dbMachinesLoading ||
    cyclesLoading

  return <Spinner loading={cond} />
}
