import { useState } from 'react'
import { TasksTabs } from 'pages/Tasks/DragComponents/Tasks.styled'
import { DropDownOptionTabs, DropDownTab } from './DropDownTabsContainer.styled'
import Chevron from 'assets/icons/Chevron'

export const DropDownTabsContainer = ({
  active,
  setActive,
  tabs,
  customHandleClick,
}: IDropDownTabsContainer) => {
  const options = tabs.filter((t) => t !== active)
  const [showDropDown, setShowDropDown] = useState(false)
  return (
    <DropDownTab
      active={true}
      tabIndex={1}
      onClick={() => setShowDropDown(!showDropDown)}
      onBlur={() => setShowDropDown(false)}
    >
      {active}
      <Chevron />
      <DropDownOptionTabs showDropDown={showDropDown}>
        {options.map((t, i) => (
          <TasksTabs
            key={i}
            active={false}
            onClick={t == customHandleClick?.tab ? customHandleClick.fn : () => setActive(t)}
          >
            {t}
          </TasksTabs>
        ))}
      </DropDownOptionTabs>
    </DropDownTab>
  )
}

interface IDropDownTabsContainer {
  active: string
  setActive: any
  customHandleClick?: {
    fn: any
    tab: string
  }
  tabs: any[]
}
