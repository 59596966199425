import React, { useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import {
  PageWrapper,
  Flex,
  MainHeadline,
  Button,
  Separator,
  GridWrapper,
  ItemsGrid,
  GridItem,
  PageFooter,
} from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { Pagination } from 'components/Pagination/Pagination'
import { setConfirmModal } from 'features/global/globalSlice'
import {
  clearTaskTriggersList,
  deleteTaskTrigger,
  getTasksTriggers,
} from 'features/triggers/tasksTriggersSlice'
import { getParams, handlePagination } from 'utils/helpers'
import { ITableItem, ModalTypes } from 'types/global.interface'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import useQueryPagination from 'hooks/useQueryPagination'

export const TasksTriggers = () => {
  const navigate = useNavigate()
  const { taskTriggers, totalPages, noItems } = useSelector((store: RootState) => store.taskTriggers)
  const dispatch = useDispatch<AppDispatch>()
  const [params, setParams] = useState<any>(getParams({ location }))

  useQueryPagination({ fetchFunctions: [getTasksTriggers], cleanUpFunctions: [clearTaskTriggersList], params })

  const handleRemove = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteTaskTrigger(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: taskTriggers.length < 2,
        },
        word: 'delete task trigger',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Task Triggers...' setParams={setParams} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Task Triggers</MainHeadline>
          <Button orange onClick={() => navigate('/triggers-task/add')}>
            Add Task Trigger
          </Button>
        </Flex>
        <Separator />
        <GridWrapper>
          {noItems ? <NoItems title='Task Triggers' /> : <ItemsGrid columns={3}>
            {taskTriggers.map((item: ITableItem) => {
              return (
                <GridItem key={item.id}>
                  <p onClick={() => navigate('/triggers-task/edit/' + item.id)}>{item.name}</p>
                  <Flex center justcenter onClick={() => handleRemove(item.id)}>
                    <RemoveCircle />
                  </Flex>
                </GridItem>
              )
            })}
          </ItemsGrid>}
        </GridWrapper>
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
