import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { INameId } from 'constants/globalTypes'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import { ICyclePostRequest, ICycleSingleResponse } from 'types/api/taskCyclesApi.interface'
import {  ICyclesSliceState, ICycleState } from 'types/cycles.interface'

import { handleErrors, formatParams } from 'utils/helpers'
import { baseUrl } from '../../utils/axios'

const initialState: ICyclesSliceState = {
  isLoading: false,
  cycles: [],
  totalCount: 0,
  totalPages: 0,
  singleCycle: null,
  noCycles: false,
}

export const getCycles = createAsyncThunk(
  'cycles/getCycles',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('/task/cycle/template', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleCycle = createAsyncThunk(
  'cycles/getSingleCycle',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<ICycleSingleResponse> = await baseUrl.get('/task/cycle/template/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addCycle = createAsyncThunk(
  'cycles/addCycle',
  async (cycle: ICycleState, thunkAPI) => {
    try {
      const formatedObject: ICyclePostRequest = {
        name:cycle.name,
        operationTypeId:cycle.operationType.id,
        componentIndexForNumberOfWorkers: cycle.components.findIndex(c=>c.name == cycle.componentIndexForNumberOfWorkers.label),
        components:cycle.components.map((c,index:number)=>({...c, index})),
        steps:cycle.steps.map((s, index:number)=>({...s,index,componentIndexForLocation:cycle.components.findIndex(c=>c.name == s.componentIndexForLocation.name)})),
        companyIds:cycle.companies.map(c=>c.id)
    }

      const response = await baseUrl.post('/task/cycle/template', formatedObject)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

// export const editProcessTem = createAsyncThunk(
//   'workflows/editWorkflow',
//   async (workflow: IWorkflow, thunkAPI) => {
//     try {
//       const response = await baseUrl.patch('/workflow/template/' + workflow.id, workflow)
//       return response.data
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error)
//     }
//   },
// )

export const deleteCycle = createAsyncThunk(
  'cycles/deleteCycle',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('/task/cycle/template/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const cyclesSlice = createSlice({
  name: 'cycles',
  initialState,
  reducers: {
    clearCycles: (state) => {
      state.cycles = []
      state.totalCount = 0
      state.totalPages = 0
    },
    clearSingleCycle: (state) => {
      state.singleCycle = null
    },
  },
  extraReducers: {
    [getCycles.pending.type]: (state) => {
      state.isLoading = true
    },
    [getCycles.fulfilled.type]: (state, { payload }: { payload: any }) => {
      state.isLoading = false
      state.cycles = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noCycles = payload.data.length < 1
    },
    [getCycles.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleCycle.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleCycle.fulfilled.type]: (
      state,
      { payload }: { payload: ICycleSingleResponse },
    ) => {
        const forNumberOfWorkers = payload.components.find(c=>c.index == payload.componentIndexForNumberOfWorkers)
        state.singleCycle = {
        name:payload.name,
        companies:payload.companies.map(c=>c.company),
        components:payload.components,
        steps:payload.steps.map(s=>{
          const ifl = payload.components.find(c=>c.index == s.componentIndexForLocation)
          return {...s,componentIndexForLocation:{name:ifl.name,id:ifl.index}}
        }),
        operationType:payload.operationType,
        componentIndexForNumberOfWorkers: {label:forNumberOfWorkers.name,value:forNumberOfWorkers.index}
      }
      state.isLoading = false
    },
    [getSingleCycle.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleCycle.pending.type]: (state) => {
      state.isLoading = true
    },
    [addCycle.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      if (state.cycles.length < 30) {
        state.cycles.push({ id: payload.id, name: payload.name })
      }
      state.noCycles = false
      toast.success('Cycle successfully added.')
    },
    [addCycle.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    // [editWorkflow.pending.type]: (state) => {
    //   state.isLoading = true
    // },
    // [editWorkflow.fulfilled.type]: (state, { payload }) => {
    //   state.isLoading = false
    //   if (state.workflows.length < 30) {
    //     state.workflows.push({ id: payload.id, name: payload.name })
    //   }
    //   state.noWorkflows = false
    //   toast.success('Workflow successfully updated.')
    // },
    // [editWorkflow.rejected.type]: (state, { payload }) => {
    //   state.isLoading = false
    //   handleErrors(payload)
    // },
    [deleteCycle.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteCycle.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.cycles = state.cycles.filter((c) => c.id !== payload)
      state.noCycles = state.cycles.length < 1
      toast.success('Cycle successfully deleted.')
    },
    [deleteCycle.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearCycles, clearSingleCycle } =
  cyclesSlice.actions
export default cyclesSlice.reducer
