import React, { ChangeEventHandler } from 'react'
import { SearchIcon } from '../../assets/icons/SearchIcon'
import { NavBarInput } from '../NavBar/NavBar.styled'
import { SearchBarWrapper } from './SearchBar.styled'

export const SearchBar = ({ placeholder, onChange }: ISearchBar) => {
  return (
    <SearchBarWrapper relative>
      <NavBarInput>
        {SearchIcon}
        <input
          type='text'
          onChange={onChange}
          placeholder={placeholder ? placeholder : 'Search capabilities'}
        />
      </NavBarInput>
    </SearchBarWrapper>
  )
}


interface ISearchBar {
  placeholder: string
  onChange: ChangeEventHandler<HTMLInputElement>
}
