/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react'
import { Input } from '../../components/Input/Input'
import {
  Flex,
  Button,
  BlackCircle,
  AddButton,
  Separator,
  CursorPointer,
  WhiteBackground,
  PageWrapper,
  H1WithBack,
  PageFooter,
  Duplicate,
} from '../../styling/GlobalStyles'
import RemoveCircle from '../../assets/icons/RemoveCircle'
import { AddCircle } from '../../assets/icons/AddCircle'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearOperation,
  getSingleOperation,
  updateOperation,
} from '../../features/operations/operationsSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'
import { NavBar } from '../../components/NavBar/NavBar'
import { HonestSelectInput } from '../../components/HonestSelect/HonestSelectInput'
import { AppDispatch, RootState } from 'store'
import { getParameters } from 'features/parameters/parametersSlice'
import { IParameters } from 'types/parameters.interface'
import { IOperationParameter, IOperationType } from 'types/operations.interface'
import { ISelectValue } from 'constants/globalTypes'
import { CancelBtn } from 'components/Button/CancelBtn'
import { DuplicateIcon } from 'assets/icons/DuplicateIcon'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const EditOperation = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [nameInvalid, setNameInvalid] = useState(false)
  const [descInvalid, setDescInvalid] = useState(false)
  const [operationTypes, setOperationTypes] = useState<any>([
    { id: 1, name: '', parameters: ['min'] },
  ])
  const { id } = useParams()
  const { singleOperation } = useSelector((store: RootState) => store.operations)
  const fetchedParameters = useSelector((state: RootState) => state.parameters.parameters)
  const navigate = useNavigate()

  useFocusFirstInput()

  useEffect(() => {
    dispatch(getSingleOperation(id))
    dispatch(getParameters())
    return () => {
      dispatch(clearOperation())
    }
  }, [])

  useEffect(() => {
    if (singleOperation) {
      setName(singleOperation.name)
      setDescription(singleOperation.description)

      const ar = []
      if (Array.isArray(singleOperation.types)) {
        for (const a of singleOperation.types) {
          const b = structuredClone(a)
          if (!b.id) {
            b.id = v4()
          }
          ar.push(b)
        }
      }
      setOperationTypes(ar)
    }
  }, [singleOperation])

  const handleSubmit = async () => {
    if (
      name.length < 1 ||
      description.length < 1 ||
      operationTypes.some((item: IOperationType) => item.invalid === true || !item.name)
    ) {
      toast.error('Fields must not be empty')
    } else if (
      operationTypes.some((item: IOperationType) =>
        item.parameters.some((p: IOperationParameter) => !p.parameterId || !p.unitId),
      )
    ) {
      toast.error('All operation parameters and units must be filled')
    } else {
      const resp = await dispatch(
        updateOperation({
          id: Number(id),
          types: operationTypes.map((type: IOperationType) => ({
            id: typeof type.id == 'string' ? null : type.id,
            name: type.name,
            parameters: type.parameters.map((param) => {
              const ob = {
                parameterId: param.parameterId,
                id: param.id,
                unitId: param.unitId,
              }
              if (param.newField) {
                delete ob.id
              }
              return ob
            }),
          })),
          name,
          description,
        }),
      )
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate(-1)
      }
    }
  }

  const checkTypeValid = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOperationTypes((prev: IOperationType[]) => {
      const newAr = [...prev]
      const objIndex = newAr.findIndex((obj) => obj.id == e.target.name)
      newAr[objIndex].invalid = e.target.value.length < 1
      return newAr
    })
  }

  const handleRemoveParameters = (itemId: number | string, paramId: number | string) => {
    const newAr = [...operationTypes]
    const objIndex = newAr.findIndex((obj) => obj.id === itemId)
    newAr[objIndex].parameters = newAr[objIndex].parameters.filter(
      (parameter: IOperationParameter) => parameter.id !== paramId,
    )

    setOperationTypes(newAr)
  }

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOperationTypes((prev: IOperationType[]) => {
      const newAr = [...prev]
      const objIndex = newAr.findIndex((obj) => obj.id == e.target.name)
      if (objIndex > -1) {
        newAr[objIndex].name = e.target.value
      } else {
        newAr.push({
          id: v4(),
          name: e.target.value,
          invalid: false,
          parameters: [{ id: v4(), name: '', unit: '', parameterId: null, unitId: null }],
        })
      }
      return newAr
    })
  }

  const handleAddTypes = (id: number | string) => {
    setOperationTypes((prev: IOperationType[]) => {
      const newAr = [...prev]
      const objIndex = newAr.findIndex((obj) => obj.id === id)

      newAr[objIndex].parameters.push({
        id: v4(),
        invalid: false,
        name: '',
        unit: '',
        parameterId: null,
        unitId: null,
        newField: true,
      })
      return newAr
    })
  }

  const handleParamUnit = (
    value: ISelectValue,
    itemId: string | number,
    paramId: number | string,
    name: string,
  ) => {
    setOperationTypes((prev: IOperationType[]) => {
      const copy = [...prev]
      const objIndex = copy.findIndex((item: IOperationType) => item.id === itemId)
      const paramIndex = copy[objIndex].parameters.findIndex(
        (item: IOperationParameter) => item.id == paramId,
      )

      if (name == 'parameter') {
        copy[objIndex].parameters[paramIndex].parameterId = value.value
        if (!Number.isInteger(copy[objIndex].parameters[paramIndex].id)) {
          copy[objIndex].parameters[paramIndex].id = value.value
        }
      } else {
        copy[objIndex].parameters[paramIndex].unitId = value.value
      }

      copy[objIndex].parameters[paramIndex][name == 'parameter' ? 'name' : 'unit'] = value.label
      if (name == 'parameter') {
        copy[objIndex].parameters[paramIndex].unitId = null
        copy[objIndex].parameters[paramIndex].unit = null
      }
      return copy
    })
  }

  const handleSelectList = (itemId: number | string, paramId: number | string) => {
    const typesIndex = operationTypes.findIndex((item: IOperationType) => item.id === itemId)
    const parameterIndex = operationTypes[typesIndex].parameters.findIndex(
      (item: IOperationParameter) => item.id == paramId,
    )
    const name = operationTypes[typesIndex].parameters[parameterIndex].name
    const foundValues = fetchedParameters.find((item) => item.name == name)
    // @ts-ignore
    return foundValues ? foundValues.units.map((item: IParameters) => ({ label: item.name, value: item.id }))
      : []
  }

  const handleDuplicate = (item: IOperationType) => {
    const newItem = {
      ...item,
      id: v4(),
      parameters: item.parameters.map((param) => ({ ...param, id: param.parameterId })),
    }
    setOperationTypes((prev: IOperationType[]) => [...prev, newItem])
  }

  const parametersForSelect = (item: IOperationType) => {
    const selectedParameters = item.parameters.map((p) => p.parameterId)
    return fetchedParameters.filter((fp) => !selectedParameters.some((sp) => sp == fp.id))
  }


  return (
    <>
      <NavBar />
      <PageWrapper>
        <H1WithBack arrow title={'Edit ' + singleOperation?.name ? singleOperation?.name : ''} />
        <Separator />
        <form>
          <Flex width='100%' between>
            <WhiteBackground animate width='30%'>
              <Flex height='100%' width='100%' column>
                <Input
                  type='text'
                  placeholder='Enter the name of operation'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setName(e.target.value)
                    e.target.value.length < 1 ? setNameInvalid(true) : setNameInvalid(false)
                  }}
                  labelText={'Operation Title*'}
                  invalid={nameInvalid ? 'true' : null}
                  value={name}
                />
                <Input
                  type='textarea'
                  minHeight='270px'
                  placeholder='Enter description'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDescription(e.target.value)
                    e.target.value.length < 1 ? setDescInvalid(true) : setDescInvalid(false)
                  }}
                  nomarg
                  labelText={'Operation Description'}
                  value={description}
                  invalid={descInvalid ? 'true' : null}
                />
              </Flex>
            </WhiteBackground>
            <div style={{ width: '65%' }}>
              {operationTypes.map((item: IOperationType) => {
                return (
                  <WhiteBackground key={item.id ? item.id : 1} animate mb='25px'>
                    <Flex>
                      <Input
                        placeholder='Enter name of type'
                        value={item.name}
                        labelText='Operation type*'
                        type='text'
                        width='42%'
                        nomarg
                        name={item.id}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          inputChange(e)
                          checkTypeValid(e)
                        }}
                        invalid={item.invalid ? 'true' : null}
                      />
                      <Flex width='40%' column>
                        {item &&
                          item.parameters.map((param) => {
                            return (
                              <Flex
                                m='0 1rem'
                                mb='16'
                                gap='1rem'
                                width='fit-content'
                                key={param.id ? param.id : 1}
                              >
                                <HonestSelectInput
                                  labelText={item.parameters.indexOf(param) < 1 && 'Parameter'}
                                  defaultValue={param.parameterId}
                                  width='7em'
                                  nomarg
                                  onChange={(value: ISelectValue) =>
                                    handleParamUnit(value, item.id, param.id, 'parameter')
                                  }
                                  options={parametersForSelect(item)}
                                  api
                                  name='parameter'
                                  optionsForDefault={fetchedParameters}
                                />

                                {handleSelectList(item.id, param.id).length > 0 && (
                                  <HonestSelectInput
                                    labelText={item.parameters.indexOf(param) < 1 && 'Unit'}
                                    defaultValue={param.unitId}
                                    width='7em'
                                    nomarg
                                    onChange={(value: ISelectValue) =>
                                      handleParamUnit(value, item.id, param.id, 'unit')
                                    }
                                    options={handleSelectList(item.id, param.id)}
                                    name='unit'
                                  />
                                )}

                                <CursorPointer
                                  start='true'
                                  mt={item.parameters.indexOf(param) < 1 && '15px'}
                                  onClick={() => handleRemoveParameters(item.id, param.id)}
                                  invisible={!(item.parameters.length > 1)}
                                  width='7em'
                                >
                                  <RemoveCircle />
                                </CursorPointer>
                              </Flex>
                            )
                          })}
                        <CursorPointer
                          start='true'
                          onClick={() => handleAddTypes(item.id)}
                          ml='1rem'
                          mt='5px'
                        >
                          <AddCircle />
                        </CursorPointer>
                      </Flex>

                      {operationTypes.length > 1 && (
                        <BlackCircle
                          absolute
                          top='10px'
                          right='10px'
                          onClick={() => {
                            setOperationTypes((prev: IOperationType[]) =>
                              prev.filter((i) => i.id !== item.id),
                            )
                          }}
                        >
                          <RemoveCircle white />
                        </BlackCircle>
                      )}
                    </Flex>
                    <Duplicate onClick={() => handleDuplicate(item)}>{DuplicateIcon}</Duplicate>
                  </WhiteBackground>
                )
              })}
              <AddButton
                width='42%'
                height='40px'
                orange
                onClick={() => {
                  setOperationTypes((prev: IOperationType[]) => [
                    ...prev,
                    {
                      id: v4(),
                      name: '',
                      parameters: [{ id: v4(), name: 'Time', unit: 'min', unitId: 0 }],
                    },
                  ])
                }}
              >
                <AddCircle white />
                Add new type
              </AddButton>
            </div>
          </Flex>
        </form>
      </PageWrapper>
      <PageFooter>
        <Flex width='100%' end='true'>
          <CancelBtn />
          <Button ml='15' height='40' orange onClick={handleSubmit}>
            Update
          </Button>
        </Flex>
      </PageFooter>
    </>
  )
}
