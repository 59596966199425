import React from 'react'
import {
  Animation,
  Flex,
  Title,
  TabFooter,
  Button,
  SelectedItemsContainer,
} from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { TriggersCollapseItem } from './IssueTriggersCollapseItem'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IIssueTriggersTab } from 'types/issue-triggers.interface'
import { INameId } from 'constants/globalTypes'
import { ISingleIssue } from 'types/issues.interface'

export const TriggersCompaniesTab = ({ state, setState, handleSubmit, singleIssue }: ITriggersCompaniesTab) => {
  const handleAdd = (item: any) => {
    setState((prev) => {
      const copy = { ...prev }
      const exists = copy.companies.find((company) => company.id === item.id)
      if (exists) {
        copy.companies = copy.companies.filter((i) => i.id !== item.id)
      } else {
        copy.companies.push(item)
      }
      return copy
    })
  }

  return (
    <Animation>
      <Flex between gap='2rem'>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Companies</Title>
          {state.companies
            ?.filter((company: INameId) => state.companies.some((i) => company.id == i.id))
            .map((item: INameId) => {
              return (
                <TriggersCollapseItem
                  prop='companies'
                  setState={setState}
                  key={item.id}
                  data={item}
                />
              )
            })}
        </SelectedItemsContainer>
        <ItemsPick
          selectedList={state.companies}
          handleAdd={handleAdd}
          link='companies/simple'
          extraParams={{ industryId: singleIssue?.industry?.id }}
          placeholder='search companies'
        />
      </Flex>
      <TabFooter>
        <Flex gap='1rem'>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>
  )
}


interface ITriggersCompaniesTab extends IIssueTriggersTab {
  singleIssue?: ISingleIssue
}