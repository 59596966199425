import { NavBar } from 'components/NavBar/NavBar'
import { clearSingleReport, getSingleReport } from 'features/reports/reportsSlice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { Animation, Flex, H1, H1WithBack, H2, PageWrapper, Separator } from 'styling/GlobalStyles'
import { formatDateForDisplay } from 'utils/helpers'

export const SingleReport = () => {

    const { id } = useParams()
    const dispatch = useDispatch<AppDispatch>()
    const { singleReport } = useSelector((state: RootState) => state.reports)

    useEffect(() => {
        dispatch(getSingleReport(Number(id)))
        return () => {
            dispatch(clearSingleReport())
        }
    }, [])
    return <>
        <NavBar />
        <Animation>
            <PageWrapper noFooter >
                <H1WithBack arrow title={'Report ' + id} />
                <Separator />
                {singleReport && <><Flex width={screen.availWidth < 500 ? '100%' : '50%'} column gap='1rem' padding='0 .5rem' >
                    <Flex between> <H2>Date:</H2>{formatDateForDisplay(singleReport.date)}</Flex>
                    <Flex between> <H2>Total Fuel Cost:</H2>{singleReport.fuelCostTotal}</Flex>
                    <Flex between> <H2>Estimated Total Cost:</H2>{singleReport.estimatedCostTotal}</Flex>
                    <Flex between> <H2>Actual Total Cost:</H2>{singleReport.actualCostTotal}</Flex>
                    <Flex between> <H2>Completed Tasks:</H2>{singleReport.completedTaskCount}</Flex>
                </Flex>
                    <Flex width={screen.availWidth < 500 ? '100%' : '50%'} column gap='1rem' mt='40'>
                        <H1>Shifts:</H1>
                        {singleReport.shiftData.map(s => <Flex style={{ borderBottom: '1px solid gray' }} key={s.shiftId} column gap='.2rem' >
                            <Flex between padding={screen.availWidth < 500 ? '0 .5rem' : '0 2rem'}>
                                <H2>Shift Name</H2>{s.shiftName}
                            </Flex>
                            <Flex between padding={screen.availWidth < 500 ? '0 .5rem' : '0 2rem'}>
                                <H2>User Count</H2>{s.userCount}
                            </Flex>
                        </Flex>)}
                    </Flex>
                    <Flex width={screen.availWidth < 500 ? '100%' : '50%'} column gap='1rem' mt='40'>
                        <H1>Reported Issues:</H1>
                        {singleReport.reportedIssues.length > 0 ? singleReport.reportedIssues.map(i => <Flex key={i.id} column style={{ borderBottom: '1px solid gray' }} gap='.2rem' >
                            <Flex between padding={screen.availWidth < 500 ? '0 .5rem' : '0 2rem'}>
                                <H2>Type</H2>{i.type}
                            </Flex>
                            <Flex between padding={screen.availWidth < 500 ? '0 .5rem' : '0 2rem'}>
                                <H2>Description</H2>{i.description}
                            </Flex>
                            <Flex between padding={screen.availWidth < 500 ? '0 .5rem' : '0 2rem'}>
                                <H2>Task Name</H2>{i.taskName}
                            </Flex>
                            <Flex between padding={screen.availWidth < 500 ? '0 .5rem' : '0 2rem'}>
                                <H2>Solution Task Name</H2>{i.solutionTaskName}
                            </Flex>
                            <Flex between padding={screen.availWidth < 500 ? '0 .5rem' : '0 2rem'}>
                                <H2>Is Resolved</H2>{i.isResolved ? 'True' : 'False'}
                            </Flex>
                        </Flex>) : <p>No Issues Reported</p>}
                    </Flex>
                </>}
            </PageWrapper>
        </Animation>
    </>
}


export interface ISingleReport {
    id: number;
    date: string;
    fuelCostTotal: number;
    estimatedCostTotal: number;
    actualCostTotal: number;
    completedTaskCount: number;
    shiftData: {
        shiftId: number;
        shiftName: string;
        userCount: number;
    }[];
    reportedIssues: {
        id: number;
        type: string;
        description: string;
        taskName: string;
        solutionTaskName: string;
        isResolved: boolean;
    }[];
}