import { Label } from 'components/Input/Input.styled'
import React from 'react'
import { Flex, TextDisplay } from 'styling/GlobalStyles'

interface ITextDisplayCard {
  border?: boolean
  labelText?: string
  title?: string | number
  width?: string
}

export const TextDisplayCard = ({ labelText, title, width, border }: ITextDisplayCard) => {
  return (
    <Flex column width={width}>
      <Label labelText={labelText}>{labelText}</Label>
      <TextDisplay border={border} width={width}>
        {title}
      </TextDisplay>
    </Flex>
  )
}
