import React from 'react'


export const Headphones = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.2666 11.3918V7.62818C2.2666 6.13091 2.86149 4.69497 3.92039 3.63624C4.97929 2.57751 6.41547 1.98273 7.91299 1.98273C9.4105 1.98273 10.8467 2.57751 11.9056 3.63624C12.9645 4.69497 13.5594 6.13091 13.5594 7.62818V11.3918'
      stroke='#FF9900'
      strokeWidth='1.69032'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5594 12.0191C13.5594 12.3518 13.4272 12.6709 13.1919 12.9062C12.9566 13.1415 12.6374 13.2736 12.3046 13.2736H11.6772C11.3445 13.2736 11.0253 13.1415 10.79 12.9062C10.5547 12.6709 10.4225 12.3518 10.4225 12.0191V10.1373C10.4225 9.80454 10.5547 9.48544 10.79 9.25017C11.0253 9.0149 11.3445 8.88272 11.6772 8.88272H13.5594V12.0191ZM2.2666 12.0191C2.2666 12.3518 2.3988 12.6709 2.63411 12.9062C2.86942 13.1415 3.18857 13.2736 3.52135 13.2736H4.14873C4.48151 13.2736 4.80066 13.1415 5.03598 12.9062C5.27129 12.6709 5.40348 12.3518 5.40348 12.0191V10.1373C5.40348 9.80454 5.27129 9.48544 5.03598 9.25017C4.80066 9.0149 4.48151 8.88272 4.14873 8.88272H2.2666V12.0191Z'
      stroke='#FF9900'
      strokeWidth='1.69032'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
