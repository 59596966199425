import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IShiftsSlice,ISingleShift } from 'types/shifts.interface'
import { baseUrl } from 'utils/axios'
import { handleErrors, formatParams } from 'utils/helpers'

const initialState: IShiftsSlice = { // TODO ispitai oblike shifta 
  isLoading: false,
  shifts: [],
  singleShift: {
    id: null,
    name: '',
    info: '',
    absence: false,
    absenceType: null,
    shiftStart: null,
    minutes: null,
  },
  noShifts: false,
  totalCount: 0,
  totalPages: 0,
}



export const addShift = createAsyncThunk(
  'shifts/addShift',
  async (shift: ISingleShift, thunkAPI) => {
    try {
      const resp = await baseUrl.post('/shifts', shift, thunkAPI)
      console.log(shift)
      resp.data.absence = resp.data.absenceType
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const editShift = createAsyncThunk(
  'shifts/editShift',
  async (shift: ISingleShift, thunkAPI) => {
    try {
      const { id } = shift
      delete shift.id
      await baseUrl.patch('/shifts/' + id, shift, thunkAPI)
      return { ...shift, id }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getShifts = createAsyncThunk('shifts/getShifts', async (params: any, thunkAPI) => {
  try {
    params = formatParams(params)
    const resp: AxiosResponse<IPaginatedResponse<ISingleShift>> = await baseUrl.get('shifts', { params })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getSingleShift = createAsyncThunk(
  'shifts/getSingleShift',
  async (id: number, thunkAPI) => {
    try {
      const resp = await baseUrl.get('shifts/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteShift = createAsyncThunk('shifts/deleteShift', async (id: number, thunkAPI) => {
  try {
    await baseUrl.delete('shifts/' + id, thunkAPI)
    return id
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const shiftsSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
    clearShiftsList: (state) => {
      state.shifts = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getShifts.pending.type]: (state) => {
      state.isLoading = true
    },
    [getShifts.fulfilled.type]: (state:IShiftsSlice, { payload }:{payload:IPaginatedResponse<ISingleShift>}) => {
      state.shifts = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.isLoading = false
      state.noShifts = state.shifts.length < 1
    },
    [getShifts.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleShift.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleShift.fulfilled.type]: (state:IShiftsSlice, { payload}:{payload:ISingleShift}) => {
      state.singleShift = payload
      state.isLoading = false
    },
    [getSingleShift.rejected.type]: (state:IShiftsSlice, { payload }:{payload:ISingleShift}) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addShift.fulfilled.type]: (state: IShiftsSlice, { payload }:{payload:ISingleShift}) => {
      if (state.shifts.length < 5) {
        state.shifts = [...state.shifts, payload]
      }
      state.noShifts = false
      state.isLoading = false
      toast.success('Shift added')
    },
    [addShift.pending.type]: (state) => {
      state.isLoading = true
    },
    [addShift.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [editShift.pending.type]: (state) => {
      state.isLoading = true
    },
    [editShift.fulfilled.type]: (state: IShiftsSlice, { payload }:{payload:ISingleShift}) => {
      const shiftIndex = state.shifts.findIndex((shift) => shift.id == payload.id)
      state.shifts[shiftIndex] = payload
      state.isLoading = false
      toast.success('Shift updated')
    },
    [editShift.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteShift.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteShift.fulfilled.type]: (state: any, { payload }) => {
      state.shifts = state.shifts.filter((shift: ISingleShift) => shift.id !== payload)
      state.isLoading = false
      state.noShifts = state.shifts.length < 1
      toast.success('Shift deleted')
    },
    [deleteShift.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})
export const { clearShiftsList } = shiftsSlice.actions

export default shiftsSlice.reducer
