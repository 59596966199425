import React from 'react'
import {
  Title,
  PageFooter,
  Button,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
  Animation,
} from 'styling/GlobalStyles'
import { IDepartmentsState, IDepratmentsTab } from 'types/departments.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { INameId } from 'constants/globalTypes'

export const DepartmentSupervisorsTab = ({ state, setState, handleSubmit }: IDepratmentsTab) => {
  const handleAdd = (item: any) => {
    setState((prev: IDepartmentsState) => {
      const copy = { ...prev }
      if (copy.supervisors.some((l) => l.id === item.id)) {
        copy.supervisors = copy.supervisors.filter((l) => l.id !== item.id)
      } else {
        copy.supervisors = [...copy.supervisors, item]
      }
      return copy
    })
  }
  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Supervisor</Title>
          {state.supervisors.map((item: INameId) => {
            return (
              <SelectedItem setState={setState} key={item.id} data={item} type='supervisors' />
            )
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='users'
          extraParams={{ accessGroup: 'supervisor' }}
          selectedList={state.supervisors}
          placeholder='Search supervisors'
        />
      </ItemsPickContainer>
      <PageFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </PageFooter>
    </Animation>
  )
}

