import RemoveCircle from 'assets/icons/RemoveCircle'
import { FieldsModal } from 'components/Modal/FieldsModal'
import { Modal } from 'components/Modal/Modal'
import { NavBar } from 'components/NavBar/NavBar'
import { setConfirmModal } from 'features/global/globalSlice'
import {
  clearInventoryFieldsList,
  deleteInvField,
  getInvSettingsFields,
} from 'features/inventory/invSettingsFieldsSlice'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import {
  Button,
  Flex,
  GridItem,
  ItemsGrid,
  MainHeadline,
  PageFooter,
  PageWrapper,
  Separator,
} from 'styling/GlobalStyles'
import { Pagination } from 'components/Pagination/Pagination'
import { getParams, handlePagination } from 'utils/helpers'
import { ITableItem, ModalTypes } from 'types/global.interface'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import useQueryPagination from 'hooks/useQueryPagination'

export const InvSettingsFields = () => {
  const [modal, setModal] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location }))
  const { fields, totalPages, noFields } = useSelector((store: RootState) => store.invSettingsFields)

  useQueryPagination({ fetchFunctions: [getInvSettingsFields], cleanUpFunctions: [clearInventoryFieldsList], params })

  const removeInvField = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteInvField(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: fields.length < 2,
        },
        word: 'delete field',
      }),
    )
  }

  return (
    <>
      <PageWrapper>
        <NavBar defaultValue={params.q} input placeholder='Search Inventory Fields...' setParams={setParams} />
        <Flex between>
          <MainHeadline>Inventory Settings Fields</MainHeadline>
          <Button orange onClick={() => setModal(true)}>
            Add Field
          </Button>
        </Flex>
        <Separator />
        {noFields ? <NoItems title='Fields' /> : <ItemsGrid columns={3} mt='25px'>
          {fields &&
            fields.map((item: ITableItem) => {
              return (
                <GridItem key={item.id}>
                  <p onClick={() => navigate('/inventory-fields/' + item.id)}>{item.name}</p>

                  <Flex between center onClick={() => removeInvField(item.id)}>
                    <RemoveCircle />
                  </Flex>
                </GridItem>
              )
            })}
        </ItemsGrid>}

        {modal && (
          <Modal black>
            <FieldsModal close={setModal} inventory />
          </Modal>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
