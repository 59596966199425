import React, { useEffect, useState } from 'react'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { Input } from 'components/Input/Input'
import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import {
  Animation,
  Button,
  ButtonsContainer,
  H1WithBack,
  ItemsGrid,
  PageFooter,
  PageWrapper,
  Separator,
} from 'styling/GlobalStyles'
import { addLocation } from 'features/locations/locationsSlice'
import { useNavigate } from 'react-router-dom'
import { ILocationState } from 'types/locations.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { locationsInputs } from './locationsValidation'
import {
  standardFieldValidation,
  validateSelect,
  validationWithCoordinates,
} from 'utils/validationUtils'
import { ISelectValue } from 'constants/globalTypes'
import { toast } from 'react-toastify'
import { getMachineryCategories } from 'features/categories/machineryCategories'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const AddLocation = () => {
  const { machineryCategoriesForSelect } = useSelector((store: RootState) => store.machineryCategories)
  const dispatch = useDispatch<AppDispatch>()
  const [invalidFields, setInvalidFields] = useState(locationsInputs)

  const navigate = useNavigate()
  const [state, setState] = useState<ILocationState>({
    name: '',
    type: 'static',
    coordinates: {
      latitude: null,
      longitude: null,
    },
    areaSize: null,
    machineryCategory: null,
    machinery: null,
  })

  useFocusFirstInput()

  useEffect(() => {
    dispatch(getMachineryCategories(null))
  }, [])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name.split('-')
    const { value, type } = e.target as HTMLInputElement

    if (name[0] === 'coordinates') {
      validationWithCoordinates(e, setInvalidFields)
      setState((prev) => {
        const copy = { ...prev }
        copy.coordinates[name[1]] = Number(value)
        return copy
      })
      return
    }
    standardFieldValidation(e, setInvalidFields)
    setState({
      ...state,
      [(e.target as HTMLInputElement).name]: type === 'text' ? value : Number(value),
    })
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    if (name === 'type') {
      setInvalidFields((prev: string[]) => {
        let copy = [...prev]
        if (value.label === 'static') {
          copy = copy.filter((f: string) => !f.includes('machinery'))
          copy = [...copy, 'coordinates-longitude', 'coordinates-latitude']
        } else {
          copy = copy.filter((f: string) => !f.includes('coord'))
          copy = [...copy, 'machinery']
        }
        return copy
      })
    }
    validateSelect(value, name, setInvalidFields)
    setState({ ...state, [name]: value.value })
  }
  const locationType = [
    {
      value: 'static',
      label: 'static',
    },
    // { value: 'mobile', label: 'mobile' },
  ]

  const machinesOptions = [{ value: 2, label: 'Machine 1' }]

  const handleSubmit = async () => {
    if (invalidFields.length < 1) {
      if (state.type === 'static') {
        delete state.machinery
        delete state.machineryCategory
      }
      const resp = await dispatch(addLocation(state))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/locations')
      }
    } else {
      toast.warn('All fields must be valid.')
    }
  }

  return (
    <>
      <NavBar />
      <Animation>
        <PageWrapper>
          <H1WithBack arrow title='Add Location' />
          <Separator />
          <ItemsGrid columns={2} width='80%'>
            <Input
              placeholder='Enter name of location'
              labelText='Location Name'
              name='name'
              value={state.name}
              invalid={invalidFields.includes('name') ? 'true' : ''}
              onChange={handleChange}
            />
            <HonestSelectInput
              options={locationType}
              labelText='Location Type'
              name='type'
              invalid={invalidFields.includes('type') ? 'true' : ''}
              defaultValue={state.type}
              onChange={handleSelect}
            />
            {state.type === 'static' ? (
              <>
                <Input
                  placeholder='Enter latitude'
                  labelText='Latitude'
                  type='number'
                  name='coordinates-latitude'
                  value={state.coordinates.latitude}
                  onChange={handleChange}
                  invalid={invalidFields.includes('coordinates-latitude') ? 'true' : ''}
                />
                <Input
                  placeholder='Enter longitude'
                  labelText='Longitude'
                  type='number'
                  name='coordinates-longitude'
                  value={state.coordinates.longitude}
                  invalid={invalidFields.includes('coordinates-longitude') ? 'true' : ''}
                  onChange={handleChange}
                />
                <Input
                  placeholder='Enter location area size'
                  labelText='Location Area Size'
                  name='areaSize'
                  type='number'
                  value={state.areaSize}
                  onChange={handleChange}
                  invalid={invalidFields.includes('areaSize') ? 'true' : ''}
                />
              </>
            ) : (
              <>
                <HonestSelectInput
                  labelText='Machinery Categories'
                  options={machineryCategoriesForSelect} // TODO: Probati da se zameni sa Async
                  defaultValue={state.machineryCategory}
                  name='machineryCategory'
                  onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
                  invalid={invalidFields.includes('machineryCategory') ? 'true' : ''}
                />
                <HonestSelectInput
                  labelText='Machinery'
                  options={machinesOptions}
                  defaultValue={state.machinery}
                  name='machinery'
                  onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
                  invalid={invalidFields.includes('machinery') ? 'true' : ''}
                />
              </>
            )}
          </ItemsGrid>
        </PageWrapper>
        <PageFooter>
          <ButtonsContainer>
            <CancelBtn />
            <Button orange onClick={handleSubmit}>
              Save
            </Button>
          </ButtonsContainer>
        </PageFooter>
      </Animation>
    </>
  )
}
