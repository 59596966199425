import React, { useEffect, useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import { PageWrapper, H1WithBack, Separator, Flex } from 'styling/GlobalStyles'
import { TaskOperationTab } from './TaskOperationTab'
import { TaskDesignTab } from './TaskDesignTab'
import { TasksTabs, TasksTabsContainer } from './DragComponents/Tasks.styled'
import { useDispatch, useSelector } from 'react-redux'
import { addTask, getTasksLogics } from 'features/tasks/tasksSlice'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { TaskComponentsTab } from './TaskComponentsTab'
import { ITaskState } from 'types/tasks.interface'
import { tasksInitialState } from './taskData'

export const AddTask = () => {
  const [active, setActive] = useState('Operation')
  const [state, setState] = useState<ITaskState>(tasksInitialState)

  const { createdId } = useSelector((store: RootState) => store.tasks)
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const handleSubmit = async () => {
    if (!state.operationTypeId) {
      toast.warn('Task capability must be selected.')
      return
    }
    if (state.components.some((c) => !c.componentType)) {
      toast.warn('Components must have set component type.')
      return
    }
    if (state.operationTypeParameterValues.some((t) => !t.minimumValue || (t.parameterId == 1 && !t.desiredValue))) {
      toast.warn('Capabilities must have set value.')
      return
    }
    if (state.toolTypeComponents.some((t) => !t.name)) {
      toast.warn('Tool type components must have set name.')
      return
    }


    await dispatch(addTask(state))
  }

  useEffect(() => {
    dispatch(getTasksLogics())
  }, [])
  useEffect(() => {
    if (createdId) {
      navigate('/tasks/' + createdId + '/edit/logic')
    }
  }, [createdId])

  const renderTabs = () => {
    switch (active) {
      case 'Operation':
        return <TaskOperationTab state={state} setState={setState} handleSubmit={handleSubmit} />
      case 'Design':
        return <TaskDesignTab state={state} setState={setState} handleSubmit={handleSubmit} />
      case 'Components':
        return <TaskComponentsTab state={state} setState={setState} handleSubmit={handleSubmit} />
      default:
        return <TaskOperationTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
  }
  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between center>
          <H1WithBack arrow title='Add Task' />
          <TasksTabsContainer>
            <TasksTabs active={active === 'Operation'} onClick={() => setActive('Operation')}>
              Operation
            </TasksTabs>
            <TasksTabs active={active === 'Design'} onClick={() => setActive('Design')}>
              Design
            </TasksTabs>
            <TasksTabs active={active === 'Components'} onClick={() => setActive('Components')}>
              Components
            </TasksTabs>
          </TasksTabsContainer>
        </Flex>
        <Separator />

        {renderTabs()}
      </PageWrapper>
    </div>
  )
}
