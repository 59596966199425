import React, { useState, useEffect, useRef } from 'react'
import {
  Flex,
  MainHeadline,
  P1,
  ProfilePicture,
  Tabs,
  TabsContainer,
  Table,
  Th,
  Tr,
  PageFooter,
  PageWrapper,
  Button,
  ContentWrapper,
  Separator,
  TableWrapper,
} from '../../styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import { CircleButton, ShiftInput } from './Shifts.styled'
import { NavBar } from '../../components/NavBar/NavBar'
import { useNavigate } from 'react-router-dom'
import { clearShiftsList, getShifts } from 'features/shifts/shiftsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { clearWorkSchedule, getWorkSchedules } from 'features/work-schedules/workScheduleSlice'
import { addZero, convertToDate, getParams, handlePagination } from 'utils/helpers'
import Avatar from '../../assets/images/profileAvatar.png'
import { v4 } from 'uuid'
import { HonestWeekPickerInput } from 'components/HonestWeekPicker/HonestWeekPickerInput'
import { AppDispatch } from 'store'
import { Pagination } from 'components/Pagination/Pagination'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { ISingleEmployeeShift } from 'types/shifts.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const Shifts = () => {
  const [active, setActive] = useState('daily')
  const [checked, setChecked] = useState([])
  const { shifts } = useSelector((store: any) => store.shifts)
  const ref = useRef()
  const { workSchedules, totalPages } = useSelector((store: any) => store.workSchedules)
  const [dropDown, setDropdown] = useState([])
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location }))

  useQueryPagination({ fetchFunctions: [getWorkSchedules], cleanUpFunctions: [clearWorkSchedule], params, otherFunctions: [() => setChecked([])] })

  useEffect(() => {
    Array.isArray(shifts) && setDropdown([...shifts])
  }, [shifts])

  useEffect(() => {
    dispatch(getShifts(null))
    return () => {
      dispatch(clearShiftsList())
    }
  }, [])

  const toggleAll = () => {
    if (checked.length < workSchedules.length) {
      for (const a of workSchedules) {
        const id = a.userData.id

        if (!checked.includes(id)) {
          setChecked((prev) => [...prev, id])
        }
      }
    } else {
      setChecked([])
    }
  }

  const toggle = (id: number) => {
    if (checked.includes(id)) {
      setChecked((prev) => {
        return prev.filter((item) => item !== id)
      })
    } else {
      setChecked((prev) => (!prev ? [id] : [...prev, id]))
    }
  }

  const handleWeekPick = (from: Date, to: Date) => {
    setParams((prev) => {
      const copy = { ...prev }
      copy.dateFrom = convertToDate(from)
      copy.dateTo = convertToDate(to)
      copy.page = 1
      copy.limit = 30
      return copy
    })
  }

  const getHeaderDays = () => {
    const arr = []
    const day = new Date(params.dateFrom)
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    for (let i = 1; i <= 7; i++) {
      const currentDay = `${days[day.getDay()]} ${addZero(day.getDate())}. ${addZero(
        day.getMonth() + 1,
      )}.`
      arr.push(<Td w='10%' noTooltip key={v4()} text={currentDay} />)

      day.setDate(day.getDate() + 1)
    }

    return arr
  }

  const renderShiftInputs = (dates, userId, index: number) => {
    const arr = []
    const day = new Date(params.dateFrom)
    let shiftForTheDay = ''

    for (let i = 1; i <= 7; i++) {
      shiftForTheDay = dates.find((date) => {
        return new Date(date.date).getTime() === new Date(day).getTime()
      })
      arr.push(
        <Td w='10%' key={userId} noTooltip>
          <ShiftInput
            labelText='Morning Shift'
            name='shift'
            placeholder='12:30-21:00h'
            list={dropDown && dropDown}
            day={new Date(day)}
            defaultShift={shiftForTheDay}
            userId={checked.length > 0 ? checked : userId}
            up={index > 2}
            resetChecked={setChecked}
          />
        </Td>,
      )
      day.setDate(day.getDate() + 1)
    }

    return arr
  }

  const handleSelect = (value: ISelectValue) => {
    setParams((prev) => {
      const copy = { ...prev }
      copy.role = value.value
      return copy
    })
  }

  const parseRoles = (data: any) => {
    return data.data.map((item: any) => ({ label: item.name, value: item.id }))
  }

  return (
    <>
      <NavBar defaultValue={params.employeeName} input placeholder='Search Shifts...' setParams={setParams} queryName='employeeName' limit={30} />
      <PageWrapper noFooter={totalPages < 2}>
        <Flex center between>
          <MainHeadline>Shifts</MainHeadline>
          <Button orange onClick={() => navigate('/shifts/add')}>
            Add Shift
          </Button>
        </Flex>
        <Separator />
        <TabsContainer mediaQShifts>
          <Tabs mediaQShifts active={active === 'daily'} onClick={() => setActive('daily')}>
            Daily
          </Tabs>
          <Tabs mediaQShifts>Weekly</Tabs>
          <Tabs mediaQShifts>Monthly</Tabs>
          <Tabs mediaQShifts>All Time</Tabs>
          <HonestSelectAsyncInput
            link='roles'
            placeholder='Search role'
            name='roleId'
            labelText='Role'
            // defaultValue={state?.role?.name}
            onChange={(value: ISelectValue) => handleSelect(value)}
            parserFunction={parseRoles}
          />
          <HonestWeekPickerInput min nomarg onChange={handleWeekPick} />
        </TabsContainer>
        <ContentWrapper noPadding>
          <TableWrapper>
            <Table ref={ref}>
              <Th>
                <Td w='3%' noTooltip>
                  <CircleButton
                    onClick={toggleAll}
                    checked={checked.length === workSchedules?.length}
                  />
                </Td>
                <Td w='27%' text='Employee' noTooltip />
                {getHeaderDays()}
              </Th>
              {workSchedules &&
                workSchedules.map((item: ISingleEmployeeShift, index: number) => {
                  const id = item.userData.id
                  return (
                    <Tr key={id}>
                      <Td noTooltip w='30%'>
                        <Flex center gap='1rem'>
                          <CircleButton
                            orange
                            checked={checked.includes(id)}
                            onClick={() => toggle(id)}
                          />
                          <ProfilePicture mr='20'>
                            <img
                              src={item.userData.profileImage || Avatar}
                              alt=''
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = Avatar
                              }}
                            />
                          </ProfilePicture>
                          <P1>{item.userData.name}</P1>
                        </Flex>
                      </Td>
                      {renderShiftInputs(item.schedule, item.userData.id, index)}
                    </Tr>
                  )
                })}
            </Table>
          </TableWrapper>
        </ContentWrapper>
      </PageWrapper>
      {Math.ceil(totalPages) > 2 && <PageFooter>
        <PageFooter>
          <Pagination
            setPage={(page) => handlePagination(page, setParams, 30)}
            pageCount={Math.ceil(totalPages)}
            forcePage={(params.page ? params.page : 1) - 1}
          />
        </PageFooter>
      </PageFooter>}
    </>
  )
}
