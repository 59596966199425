import React, { useState } from 'react'
import { BlackCircle, Collapsable, Flex, ItemsGrid } from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { rating } from 'pages/Employees/dummyList'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { CollapseItemText } from 'components/CollapseItem/CollapseItem.styled'
import Chevron from 'assets/icons/Chevron'
import { ISelectValue } from 'constants/globalTypes'
import { IInventoryItem, IItemMachineModel } from 'types/inventory.interface'

export const CollapseItemMachineryItems = ({ data, setState }: ICollapseItemMachineryItems) => {
  const [expanded, setExpanded] = useState(true)
  const handleRemove = () => {
    setState((prev) => {
      const copy = { ...prev }
      copy.machineModels = copy.machineModels.filter((item) => item.id !== data.id)
      return copy
    })
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    setState((prev) => {
      const copy = { ...prev }
      const objIndex = copy.machineModels.findIndex((obj) => obj.id === data.id)
      copy.machineModels[objIndex][name] = Number(value.value)
      return copy
    })
  }

  return (
    <Collapsable expanded={expanded}>
      <Flex mb='20'>
        <CollapseItemText expanded={expanded} onClick={() => setExpanded((prev) => !prev)}>
          {data.name} <Chevron gray />
        </CollapseItemText>
        <BlackCircle onClick={handleRemove}>
          <RemoveCircle white />
        </BlackCircle>
      </Flex>
      <>
        <ItemsGrid mb='1rem' mt='10px' columns={4} colgap='1em' gap='1em'>
          <HonestSelectInput
            nomarg
            name='rating'
            labelText='Performance'
            defaultValue={String(data.rating)}
            onChange={(value: ISelectValue, name: string) => handleSelect(value, name)}
            options={rating}
          />
          <HonestSelectInput
            nomarg
            name='priority'
            labelText='Priority'
            defaultValue={String(data.priority)}
            onChange={(value: ISelectValue, name: string) => handleSelect(value, name)}
            options={rating}
          />
        </ItemsGrid>
      </>
    </Collapsable>
  )
}


interface ICollapseItemMachineryItems {
  data: IItemMachineModel
  setState: React.Dispatch<React.SetStateAction<IInventoryItem>>
}