import React, { useState } from 'react'
import {
  Button,
  Flex,
  MainHeadline,
  PageWrapper,
  Separator,
  PageFooter,
  GridWrapper,
  ItemsGrid,
  GridItem,
} from '../../styling/GlobalStyles'
import { NavBar } from '../../components/NavBar/NavBar'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { Pagination } from 'components/Pagination/Pagination'
import { getParams, handlePagination } from 'utils/helpers'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { setConfirmModal } from 'features/global/globalSlice'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { ITableItem, ModalTypes } from 'types/global.interface'
import { clearProcesTemplatesList, deleteProcessTemplate, getProcessTemplates } from 'features/processTemplates/processTemplatesSlice'
import useQueryPagination from 'hooks/useQueryPagination'

export const ProcessTemplates = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [params, setParams] = useState<any>(getParams({ location }))
  const { processTemplates, noProcessTemplates, totalPages } = useSelector((state: RootState) => state.procesTemplates)

  useQueryPagination({ fetchFunctions: [getProcessTemplates], cleanUpFunctions: [clearProcesTemplatesList], params })

  const handleDeleteProcessTemplate = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteProcessTemplate(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: processTemplates.length < 2,
        },
        word: 'delete process template',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Process Templates...' setParams={setParams} limit={30} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Process Templates</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add Process Template
          </Button>
        </Flex>
        <Separator />
        {false ? (
          <NoItems title='Workflows' />
        ) : (
          <GridWrapper>
            <ItemsGrid columns={3}>
              {!noProcessTemplates ? (
                processTemplates.map((w: ITableItem) => {
                  return (
                    <GridItem key={w.id}>
                      <p onClick={() => navigate('/process-templates/' + w.id)}>{w.name}</p>
                      <Flex center justcenter onClick={() => handleDeleteProcessTemplate(w.id)}>
                        <RemoveCircle />
                      </Flex>
                    </GridItem>
                  )
                })
              ) : (
                <NoItems title='Process Templates' />
              )}
            </ItemsGrid>
          </GridWrapper>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 5)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
