import useAuth from 'hooks/useAuth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, H1, Logo } from '../../styling/GlobalStyles'
import { ErrorWrapper } from './Error.styled'

export const Error: React.FC = () => {
  const { isAdmin } = useAuth()
  const navigate = useNavigate()

  const findRedirect = () => {
    if (isAdmin) {
      return 'operations'
    }
    return 'employees'
  }

  return (
    <ErrorWrapper>
      <div>
        <Logo />
        <H1>Page not found</H1>
        <Button onClick={() => navigate(findRedirect())}>Go back</Button>
      </div>
    </ErrorWrapper>
  )
}
