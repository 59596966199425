import { useState } from 'react'
import { DatePickerInput } from 'components/DatePicker/DatePicker'
import { NavBar } from 'components/NavBar/NavBar'
import {
  Flex,
  MainHeadline,
  PageControls,
  PageFooter,
  PageWrapper,
  Separator,
  Table,
  Th,
  Tr,
} from 'styling/GlobalStyles'
import { endOfWeek, startOfWeek } from 'date-fns'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Td } from 'components/Td/Td'
import { Options } from 'components/Options/Options'
import { Pagination } from 'components/Pagination/Pagination'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { clearReportsList, getReports } from 'features/reports/reportsSlice'
import { convertToDate, formatDateForDisplay, getParams, handlePagination } from 'utils/helpers'
import useQueryPagination from 'hooks/useQueryPagination'

export const Reports = () => {
  const { reports, noReports, totalPages } = useSelector((state: RootState) => state.reports)
  const [params, setParams] = useState<any>(getParams({
    location, limit: 5, ap: {
      from: convertToDate(startOfWeek(new Date(), { weekStartsOn: 1 }))
      , to: convertToDate(endOfWeek(new Date(), { weekStartsOn: 1 }))
    }
  }))


  useQueryPagination({ fetchFunctions: [getReports], cleanUpFunctions: [clearReportsList], params })

  const setDates = (value: string, name: string) => {
    setParams(prev => {
      const copy = structuredClone(prev)
      copy[name] = value
      return copy
    })
  }

  return (
    <>
      <NavBar />
      <PageWrapper>
        <PageControls>
          <MainHeadline>Reports</MainHeadline>
          <Flex gap='1rem'>
            <DatePickerInput
              maxDate={new Date(params.to)}
              label='Start date'
              date={new Date(params.from)}
              setDate={(value) => setDates(convertToDate(value), 'from')}
            />
            <DatePickerInput
              minDate={new Date(params.from)}
              label='End date'
              date={new Date(params.to)}
              setDate={(value) => setDates(convertToDate(value), 'to')}
            />
          </Flex>
        </PageControls>
        <Separator />
        {noReports ? (
          <NoItems title='Reports' />
        ) : (
          <Table>
            <Th next>
              <Td w='20%' noTooltip text={'REPORT ID'} />
              <Td w='5%' noTooltip text='DATE' />
            </Th>
            {reports.map((item) => {
              return (
                <Tr key={item.id}>
                  <Td w='20%' text={item.id} />
                  <Td w='75%' text={formatDateForDisplay(item.date)} />
                  <Td w='5%'>
                    <Options item={item} title='Report' />
                  </Td>
                </Tr>
              )
            })}
          </Table>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 5)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
