import React, { useEffect } from 'react'
import { Flex, Button, Separator, H1WithBack, PageWrapper } from '../../styling/GlobalStyles'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearOperation, getSingleOperation } from '../../features/operations/operationsSlice'
import { NavBar } from '../../components/NavBar/NavBar'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { AppDispatch, RootState } from 'store'

export const OperationPreview = () => {
  const {
    singleOperation,
  } = useSelector((store: RootState) => store.operations)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    dispatch(getSingleOperation(id))
    return () => {
      dispatch(clearOperation())
    }
  }, [])

  return (
    <>
      <NavBar />
      <PageWrapper>
        <Flex between center>
          <Flex width='100%' between center>
            <H1WithBack arrow title={singleOperation?.name} />
            <Button
              orange
              onClick={(e) => {
                e.preventDefault()
                navigate('edit')
              }}
            >
              Edit
            </Button>
          </Flex>
        </Flex>
        <Separator />
        <Flex width='100%' between>
          <Flex width='60%' column gap='1rem'>
            {singleOperation?.types &&
              singleOperation?.types.map((item, key: number) => {
                return (
                  <div key={key}>
                    <Flex gap='2rem' key={key}>
                      <TextDisplayCard
                        width='15rem'
                        title={item.name}
                        labelText='Operation type name'
                      />
                      <Flex column gap='1rem'>
                        {item &&
                          item.parameters.map((param, key) => {
                            return (
                              <Flex key={key} width='fit-content' gap='1rem'>
                                <TextDisplayCard
                                  width='10rem'
                                  title={param.name}
                                  labelText='Parameter name'
                                />
                                <TextDisplayCard title={param.unit} labelText='Parameter unit' />
                              </Flex>
                            )
                          })}
                      </Flex>
                    </Flex>
                    <Separator />
                  </div>
                )
              })}
          </Flex>

          <Flex width='40%' column>
            <TextDisplayCard
              title={singleOperation?.description}
              labelText='Operation description'
            />
          </Flex>
        </Flex>
      </PageWrapper>
    </>
  )
}
