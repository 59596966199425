import React, { useEffect, useState } from 'react'
import {
  Flex,
  H1WithBack,
  TabsContainer,
  Tabs,
  PageWrapper,
  ContentWrapper,
} from '../../styling/GlobalStyles'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { AddEmployeerWrapper } from './AddEmployee.styled'
import { EmployeeBasicInfoTab } from './EmployeeBasicInfoTab'
import { EmployeesContractsTab } from './EmployeesContractsTab'
import { NavBar } from '../../components/NavBar/NavBar'
import { EmployeesMachineryTab } from './EmployeesMachineryTab'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearEmployee, getSingleUser, updateUser } from 'features/users(Employees)/usersSlice'
import { AppDispatch, RootState } from 'store'
import { toast } from 'react-toastify'
import { IContract, IEmployee } from 'types/users.interface'
import { EmployeeCapabilitiesTab } from './EmployeeCapabilitiestab'
import { employeesInitialState } from './employeesData'

export const EditEmployee = () => {
  const [active, setActive] = useState('Basic Info')
  const dispatch = useDispatch<AppDispatch>()
  const { singleUser } = useSelector((store: RootState) => store.users)
  const [invalidFields, setInvalidFields] = useState([])
  const navigate = useNavigate()
  const { id } = useParams()
  const [state, setState] = useState<IEmployee>(employeesInitialState)

  useEffect(() => {
    dispatch(getSingleUser(id))
    return () => {
      dispatch(clearEmployee())
    }
  }, [id])

  useEffect(() => {
    if (singleUser) {
      setState(structuredClone(singleUser))
    }
  }, [singleUser])


  const handleSubmit = async () => {
    const copy = structuredClone(state)
    if (invalidFields.length > 0) {
      toast.warn('All basic info fields must be valid')
      return
    }
    const resp = await dispatch(updateUser(copy))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/employees')
    }
  }
  const renderTabs = () => {
    if (active === 'Basic Info') {
      return (
        <EmployeeBasicInfoTab
          setState={setState}
          state={state}
          setActive={setActive}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        />
      )
    } else if (active === 'Contract') {
      return <EmployeesContractsTab setState={setState} state={state} setActive={setActive} />
    } else if (active === 'Machines') {
      return <EmployeesMachineryTab setState={setState} state={state} setActive={setActive} />
    } else if (active === 'Capabilities') {
      return (
        <EmployeeCapabilitiesTab
          setState={setState}
          state={state}
          setActive={setActive}
          handleSubmit={handleSubmit}
        />
      )
    }
  }

  const checkContractTab = (name: string) => {
    if (state.contracts.some((c: IContract) => !c.grossSalary || !c.netSalary)) {
      toast.warn('Contracts must be saved first')
    } else {
      setActive(name)
    }
  }

  return (
    <AddEmployeerWrapper>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack arrow title='Edit Employee' />
          <TabsContainer mediaQShifts >
            <Tabs active={active === 'Basic Info'} onClick={() => checkContractTab('Basic Info')}>
              Basic Info
            </Tabs>
            <Tabs active={active === 'Contract'} onClick={() => setActive('Contract')}>
              Contract
            </Tabs>
            <Tabs active={active === 'Machines'} onClick={() => checkContractTab('Machines')}>
              Machines
            </Tabs>
            <Tabs
              active={active === 'Capabilities'}
              onClick={() => checkContractTab('Capabilities')}
            >
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>

        <ContentWrapper noFooter>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </AddEmployeerWrapper>
  )
}
