import styled from 'styled-components'

export const ErrorWrapper = styled.div`
  background-color: #fafafa;
  width: 100vw;
  height: 100vh;
  padding: 3rem 0;
  div {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
  }
`
