import React, { useEffect } from 'react'
import { Animation, Button, Flex, TabFooter } from '../../../styling/GlobalStyles'
import { Input } from '../../../components/Input/Input'
import { CancelBtn } from 'components/Button/CancelBtn'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { IRolesTab } from 'types/roles.interface'

export const InfoRolesTab = ({ state, setState, handleSubmit }: IRolesTab) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, name: e.target.value }))
  }

  const handleEnter = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEnter)

    return () => {
      window.removeEventListener('keydown', handleEnter)
    }
  })

  useFocusFirstInput()

  return (
    <Animation>
      <div>
        <Flex width={screen.availWidth < 500 ? '100%' : '30%'}>
          <Input
            type='text'
            labelText='Role title'
            placeholder='Enter title'
            name='name'
            value={state?.name}
            onChange={handleChange}
            invalid={!state.name ? 'true' : ''}
          />
        </Flex>
      </div>
      <TabFooter>
        <Flex end='true' gap='1em'>
          <CancelBtn />
          <Button orange type='button' onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>
  )
}
