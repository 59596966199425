import React from 'react'


export const inventoryIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.25 5C1.25 4.30964 1.80964 3.75 2.5 3.75H17.5C18.1904 3.75 18.75 4.30964 18.75 5V6.875C18.75 7.56536 18.1904 8.125 17.5 8.125H2.5C1.80964 8.125 1.25 7.56536 1.25 6.875V5ZM17.5 5H2.5V6.875H17.5V5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.125 6.875C3.47018 6.875 3.75 7.15482 3.75 7.5V15H16.25V7.5C16.25 7.15482 16.5298 6.875 16.875 6.875C17.2202 6.875 17.5 7.15482 17.5 7.5V15C17.5 15.3315 17.3683 15.6495 17.1339 15.8839C16.8995 16.1183 16.5815 16.25 16.25 16.25H3.75C3.41848 16.25 3.10054 16.1183 2.86612 15.8839C2.6317 15.6495 2.5 15.3315 2.5 15V7.5C2.5 7.15482 2.77982 6.875 3.125 6.875Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.5 10.625C7.5 10.2798 7.77982 10 8.125 10H11.875C12.2202 10 12.5 10.2798 12.5 10.625C12.5 10.9702 12.2202 11.25 11.875 11.25H8.125C7.77982 11.25 7.5 10.9702 7.5 10.625Z'
    />
  </svg>
)
