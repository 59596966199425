import React from 'react'
import { IHonestAsyncSelectInput } from 'types/select.interface'
import { SelectWrapper, Label } from '../Input/Input.styled'
import { HonestAsyncSelect } from './HonestAsyncSelect'

export const HonestSelectAsyncInput = (props: IHonestAsyncSelectInput) => {
  return (
    <SelectWrapper
      key={props.keyProp}
      width={props.width}
      invalid={props.invalid ? 'true' : ''}
      nomarg={props.nomarg}
      view={props.view}
    >
      <Label htmlFor={props.id} labelText={props.labelText}>
        {props.labelText}
      </Label>
      <HonestAsyncSelect invalid={props.invalid} {...props} />
    </SelectWrapper>
  )
}
