import { ISingleTaskTrigger } from 'types/task-triggers.interface';

export const taskTriggersInitialState:ISingleTaskTrigger = {
    name: '',
    type: null,
    minutes: null,
    companies: [],
    taskTemplate: { id: null, name: '' },
    nextTaskTemplate: { id: null, name: '' },
    nextTaskType: { id: 1, name: 'Task' },
    nextWorkflowTemplate: { id: null, name: '' },
  }