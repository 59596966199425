import { NavBar } from 'components/NavBar/NavBar'
import { clearIssue, getSingleIssue, updateIssue } from 'features/issues/issuesSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import {
  Flex,
  TabsContainer,
  Tabs,
  H1WithBack,
  PageWrapper,
  ContentWrapper,
} from 'styling/GlobalStyles'
import { ISingleIssue } from 'types/issues.interface'
import { isEmpty } from 'utils/helpers'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { IssueInfo } from './IssueInfoTab'

export const EditIssue = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { id } = useParams()
  const { singleIssue } = useSelector((store: RootState) => store.issues)
  const [active, setActive] = useState('BasicInfo')
  const [state, setState] = useState<ISingleIssue>({
    name: '',
    industry: { id: null, name: null },
  })

  useFocusFirstInput()

  const handleSubmit = async () => {
    if (state.industry.id && state.name.length >= 2) {
      const resp = await dispatch(updateIssue({ ...state, id }))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/issues')
      }
    } else {
      toast.warn('Both fields must be filled and valid')
    }
  }

  useEffect(() => {
    dispatch(getSingleIssue(id))

    return () => {
      dispatch(clearIssue())
    }
  }, [])

  useEffect(() => {
    if (singleIssue && !isEmpty(singleIssue)) {
      setState(structuredClone(singleIssue))
    }
  }, [singleIssue])

  const renderTabs = () => {
    if (active === 'BasicInfo') {
      return <IssueInfo state={state} setState={setState} handleSubmit={handleSubmit} />
    } else if (active === 'Trigers') {
    }
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title={'Edit ' + (singleIssue?.name ? singleIssue.name : '')} />
          <TabsContainer>
            <Tabs active={active === 'BasicInfo'} onClick={() => setActive('BasicInfo')}>
              Basic Info
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
