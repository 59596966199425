import React from 'react'
import {
  PageFooter,
  Animation,
  Flex,
  Title,
  Button,
  SelectedItemsContainer,
} from 'styling/GlobalStyles'
import { ITasksTab, ITaskState, ITaskWComponent } from 'types/tasks.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { TaskNonCollapseItem } from './TaskNonCollapseItem'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { Input } from 'components/Input/Input'

export const TaskComponentsTab = ({ state, setState, handleSubmit }: ITasksTab) => {

  const handleAdd = (item: ITaskWComponent) => {
    setState((prev: ITaskState) => {
      const copy = JSON.parse(JSON.stringify(prev))
      if (!copy.workerComponents.some((c: ITaskWComponent) => c.id === item.id)) {
        copy.workerComponents.push(item)
      } else {
        copy.workerComponents = copy.workerComponents.filter((c: ITaskWComponent) => c.id !== item.id)
      }
      return copy
    })
  }

  const parseTools = (data: any) => {
    return data
      ? [
        ...data.map((item: any) => ({ label: item.name, value: item.id })),
        { label: 'All', value: data.length },
      ]
      : []
  }

  // const parseUnits = (data:any)=>{
  //   console.log(data)
  //   return data
  // }

  const handleSelect = (value: ISelectValue) => {
    setState((prev: ITaskState) => {
      const copy = structuredClone(prev)
      if (value.label !== 'CLEAR') {
        copy.toolTypeComponents.push({ component: value, name: '', unit: null })
      } else {
        copy.toolTypeComponents = []
      }
      return copy
    })
  }

  const handleSelectRemove = (item: ISelectValue) => {
    setState((prev: ITaskState) => {
      const copy = { ...prev }
      copy.toolTypeComponents = copy.toolTypeComponents.filter((i) => i.component.value !== item.value)
      return copy
    })
  }

  const handleComponentName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setState((prev: ITaskState) => {
      const copy = { ...prev }
      copy.toolTypeComponents = copy.toolTypeComponents.map(c => c.component.label == name ? { ...c, name: value } : c)
      return copy
    })
  }

  const getComponentName = (name: string) => state.toolTypeComponents.find(c => c.component.label == name).name || ''

  const handleUnitSelect = (value: ISelectValue) => {
    setState((prev: ITaskState) => {
      const copy = { ...prev }
      copy.toolTypeComponents = copy.toolTypeComponents.map(c => c.component.label == 'Item' ? { ...c, unit: value } : c)
      return copy
    })
  }

  const getSelectedUnit = () => state.toolTypeComponents.find(c => c.component.label == 'Item').unit?.label || ''

  return (
    <Animation>
      <Flex between minHeight='600px' gap='2rem'>
        <Flex column width='100%'>
          <HonestSelectAsyncInput
            name='toolComponents'
            link='tool-type-components'
            onChange={handleSelect}
            defaultValue={state.toolTypeComponents.map(c => ({ label: c.component.label, value: c.component.value }))}
            labelText='Tool components'
            parserFunction={parseTools}
            handleRemove={handleSelectRemove}
            // invalid={state.toolTypeComponents.length < 1}
            multi
          />
          {state.toolTypeComponents.some(c => c.component.value == 1) && <Flex width='100%' gap='1rem' >
            <Input labelText='Machinery Name' placeholder='Enter name' name='Machinery' value={getComponentName('Machinery')} onChange={handleComponentName} />
          </Flex>}
          {state.toolTypeComponents.some(c => c.component.value == 2) && <Flex width='100%' column >
            <Input labelText='Item Name' placeholder='Enter name' name='Item' value={getComponentName('Item')} onChange={handleComponentName} />
            <HonestSelectAsyncInput
              name='units'
              link='units'
              onChange={handleUnitSelect}
              defaultValue={getSelectedUnit}
              labelText='Item Unit'
              parserFunction={parseTools}

            // invalid={state.toolTypeComponents.length < 1}
            />
          </Flex>}
        </Flex>

        <Flex minWidth='70%' between gap='2rem'>
          <SelectedItemsContainer>
            <Title fontSize='0.9rem' pad='0 20px'>
              Selected Worker Components
            </Title>
            {state.workerComponents?.map((item: ITaskWComponent) => {
              return <TaskNonCollapseItem setState={setState} data={item} key={item.id} />
            })}
          </SelectedItemsContainer>
          <ItemsPick
            selectedList={state.workerComponents?.map((wc: ITaskWComponent) => ({
              ...wc,
              id: wc.id,
            }))}
            link='worker-component-fields'
            handleAdd={handleAdd}
            placeholder='Search components'
            oneColumn={true}
          />
        </Flex>
      </Flex>
      <PageFooter>
        <Flex gap='1rem'>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </PageFooter>
    </Animation>
  )
}
