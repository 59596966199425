import React, { useState } from 'react'
import { DeleteIcon } from 'assets/icons/DeleteIcon'
import { DuplicateIcon } from 'assets/icons/DuplicateIcon'
import { EditIcon } from 'assets/icons/EditIcon'
import { FileIcon } from 'assets/icons/FileIcon'
import { ThreeDots } from 'assets/icons/ThreeDots'
import { useNavigate } from 'react-router-dom'
import { Dropdown, EditContainer, Flex } from 'styling/GlobalStyles'
import { handleOptionBtn } from 'utils/helpers'
/*eslint-disable */

export const Options = ({
  item,
  title,
  handleRemove,
  removeCompany,
  removeLocation,
  handleDuplicate,
  invisible
}: {
  item: any
  title: string
  handleRemove?: any
  removeCompany?: (id) => void
  removeLocation?: (id) => void
  handleDuplicate?: any
  invisible?: boolean
  /* eslint-enable */
}) => {
  const [editID, setEditID] = useState(false)
  const [showEditMenu, setShowEditMenu] = useState(false)
  const navigate = useNavigate()

  const handleViewEmployee = (id: string) => {
    if (location.pathname == '/') {
      navigate('employees/' + id)
    } else {
      navigate(id)
    }
  }
  const findId = () => {
    if (title === 'MachineModel') {
      return editID === item.machineModel.id
    }
    return editID === item.id
  }
  return (
    <>
      <EditContainer
        tabIndex={0}
        invisible={invisible}
        onBlur={() => setShowEditMenu(false)}
        onClick={() => {
          handleOptionBtn(item, setEditID, setShowEditMenu, Number(editID))
        }}
        show={editID === item.id && showEditMenu}
      >
        <span>{ThreeDots}</span>

        {findId() ? (
          <Dropdown show={showEditMenu} left>
            {title === 'Employee' ? (
              <>
                <Flex gap='10px' onClick={() => handleViewEmployee(item.id.toString() + '/edit')}>
                  {FileIcon}Edit {title}
                </Flex>
                <Flex gap='10px' onClick={handleRemove}>
                  {DeleteIcon} Delete Employee
                </Flex>
              </>
            ) : title === 'Machine Model' ? (
              <>
                <Flex gap='10px' onClick={() => navigate(item.id.toString())}>
                  {EditIcon} Edit Model
                </Flex>
                <Flex gap='10px' onClick={handleRemove}>
                  {DeleteIcon} Delete Model
                </Flex>
              </>
            ) : title === 'Task' ? (
              <>
                <Flex gap='10px' onClick={() => navigate('/tasks/' + item.id + '/edit')}>
                  {EditIcon} Edit task
                </Flex>
                <Flex gap='10px' onClick={handleRemove}>
                  {DeleteIcon}Delete task
                </Flex>
                <Flex gap='10px' onClick={handleDuplicate}>
                  {DuplicateIcon}Duplicate task
                </Flex>
              </>
            ) : title === 'Company' ? (
              <>
                <Flex
                  gap='10px'
                  onClick={() => {
                    navigate('edit/' + item.id)
                  }}
                >
                  {EditIcon} Edit company
                </Flex>

                <Flex gap='10px' onClick={() => removeCompany(item.id)}>
                  {DeleteIcon}Delete company
                </Flex>
              </>
            ) : title === 'Location' ? (
              <>
                <Flex gap='10px' onClick={() => navigate('edit/' + item.id)}>
                  {FileIcon}Edit location
                </Flex>
                <Flex gap='10px' onClick={() => removeLocation(item.id)}>
                  {DeleteIcon}Delete location
                </Flex>
              </>
            ) : title === 'Inventory Item' ? (
              <>
                <Flex gap='10px' onClick={() => navigate('edit/' + item.id)}>
                  {FileIcon}Edit Item
                </Flex>
                <Flex gap='10px' onClick={handleRemove}>
                  {DeleteIcon}Delete item
                </Flex>
              </>
            ) : title === 'Supplier' ? (
              <>
                <Flex gap='10px' onClick={() => navigate(item.id.toString())}>
                  {FileIcon}Edit Supplier
                </Flex>
                <Flex gap='10px' onClick={handleRemove}>
                  {DeleteIcon}Delete Supplier
                </Flex>
              </>
            ) : title === 'Machinery' ? (
              <>
                <Flex
                  gap='10px'
                  onClick={() => {
                    navigate(item.id.toString())
                  }}
                >
                  {EditIcon}Edit Machinery
                </Flex>
                <Flex gap='10px' onClick={handleRemove}>
                  {DeleteIcon} Delete Machinery
                </Flex>
              </>
            ) : title === 'Report' ? (
              <>
                <Flex
                  gap='10px'
                  onClick={() => {
                    navigate(item.id.toString())
                  }}
                >
                  {EditIcon} View Report
                </Flex>
                {/* <Flex gap='10px' onClick={handleRemove}>
                  {DownloadIcon} Download Report
                </Flex> */}
              </>
            ) : (
              <>
                <Flex gap='10px' onClick={() => navigate(item.id)}>
                  {FileIcon}View {title}
                </Flex>
              </>
            )}
          </Dropdown>
        ) : null}
      </EditContainer>
    </>
  )
}
