import React, { useState } from 'react'
import { BlackCircle, Collapsable, Flex, ItemsGrid, SmallText } from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { CollapseItemText } from 'components/CollapseItem/CollapseItem.styled'
import Chevron from 'assets/icons/Chevron'
import { Input } from 'components/Input/Input'
import { ITaskoperationTypeParameterValue, ITaskSelectedOperation, ITaskState } from 'types/tasks.interface'
import { IOperationType } from 'types/operations.interface'

export const CollapseItemOperationTask = ({ state, data, setState }: ICollapseItemOperationTask) => {
  const [expanded, setExpanded] = useState(true)
  const handleRemove = () => {
    setState((prev: ITaskState) => {
      const copy = { ...prev }
      copy.selectedOperation = null
      copy.operationTypeParameterValues = []
      copy.name = ''
      copy.operationTypeId = null
      return copy
    })
  }

  const handleParamInput = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setState((prev: ITaskState) => {
      const copy = structuredClone(prev)
      const foundParamIndex = copy.operationTypeParameterValues.findIndex(
        (pv: ITaskoperationTypeParameterValue) => pv.operationTypeParameterId == Number(e.target.id),
      )
      if (name === 'edge') {
        copy.operationTypeParameterValues[foundParamIndex].minimumValue =
          e.target.valueAsNumber || null
      } else {
        copy.operationTypeParameterValues[foundParamIndex].desiredValue =
          e.target.valueAsNumber || null
      }
      return copy
    })
  }

  return (
    <Collapsable expanded={expanded}>
      <Flex mb='20'>
        <CollapseItemText expanded={expanded} onClick={() => setExpanded((prev) => !prev)}>
          {data?.name} <Chevron gray />
        </CollapseItemText>
        <BlackCircle onClick={handleRemove}>
          <RemoveCircle white />
        </BlackCircle>
      </Flex>
      {data.types
        .filter((i: IOperationType) => i.id == state.operationTypeId)
        .map((item: IOperationType, key: number) => {
          return (
            <div key={key}>
              <CollapseItemText mb='5px' expanded={expanded} white>
                {item.name}
              </CollapseItemText>
              <SmallText bold>Edge requirements</SmallText>
              <ItemsGrid mb='1rem' mt='10px' columns={4} colgap='1em' gap='1em'>
                {item.parameters.map((param, key) => {
                  const found = state.operationTypeParameterValues.find(
                    (pv: ITaskoperationTypeParameterValue) =>
                      pv.operationTypeParameterId == param.id,
                  )
                  let value = null
                  if (found) {
                    value = found.minimumValue
                  }

                  return (
                    <Input
                      key={key}
                      id={param.id}
                      labelText={param.name + ' (' + param.unit + ')'}
                      type='number'
                      placeholder='10'
                      value={value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleParamInput(e, 'edge')
                      }
                    />
                  )
                })}
              </ItemsGrid>
              <SmallText bold>Desired speed</SmallText>
              <ItemsGrid mb='1rem' mt='10px' columns={4} colgap='1em' gap='1em'>
                {item.parameters.map((param, key) => {
                  const found = state.operationTypeParameterValues.find(
                    (pv: ITaskoperationTypeParameterValue) =>
                      (pv.operationTypeParameterId == param.id)
                  )
                  let value = null
                  if (found) {
                    value = found.desiredValue
                  }

                  return param.parameterId == 1 ? (
                    <Input
                      key={key}
                      placeholder='10'
                      labelText={param.name + ' (' + param.unit + ')'}
                      type='number'
                      id={param.id}
                      value={value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleParamInput(e, 'desired')
                      }
                    />
                  ) : null
                })}
              </ItemsGrid>
            </div>
          )
        })}
    </Collapsable>
  )
}


interface ICollapseItemOperationTask {
  setState: React.Dispatch<React.SetStateAction<ITaskState>>
  state: ITaskState
  data: ITaskSelectedOperation
}