// export enum TypeOfVegetation {
//     grass = 1,
//     trees_plantation = 2,
//     bushes = 3,
//     stones = 4,
//     forest = 5,
//     plantations = 6,
//   }

import { ILatLong, IPolygon } from 'types/zones.interface'

/*eslint-disable*/
export const typeOfVegatationList = [
  {
    value: 1,
    label: 'Grass',
  },
  {
    value: 2,
    label: 'Trees plantation',
  },
  {
    value: 3,
    label: 'Bushes',
  },
  {
    value: 4,
    label: 'Stones',
  },
  {
    value: 5,
    label: 'Forest',
  },
  {
    value: 6,
    label: 'Plantations',
  },
]

export enum TypeOfAreas {
  zones = 1,
  sections = 2,
  blocks = 3,
  pits = 4,
}

export enum AreaColors {
  zones = '#27AE60',
  sections = '#EB5757',
  blocks = '#2F80ED',
  pits = '#FF9900',
}

export const getCenter = (area: IPolygon, zonesLabels?) => {
  const latitudes = area.path.map((it: ILatLong) => it.lat)
  const longitudes = area.path.map((it: ILatLong) => it.lng)
  const center = {
    ...area,
    lat: (Math.min(...latitudes) + Math.max(...latitudes)) / 2,
    lng: (Math.min(...longitudes) + Math.max(...longitudes)) / 2,
  }

  if (!zonesLabels) {
    return center
  }
  zonesLabels.push(center)
}
