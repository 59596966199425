import React, { useState, useEffect, useRef } from 'react'
import {
  ZoneMenuItem,
  ZoneMenuTitle,
  ZonesMenuContainer,
  ZonesOrangeCircle,
  ZonesSideMenu,
  ZonesAddContainer,
  LatLongContainer,
  AreaInputsContainer,
  ZonesSelectContainer,
  ZonesInputsWrapper,
  ZonesMapContainer,
} from './Zones.styled'
import { WorldIcon } from 'assets/icons/WorldIcon'
import { ICreating, ILatLong, IMapTab, IMapPosition, ISelectZone } from 'types/zones.interface'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { CursorPointer, H2, Flex, MapHTMLComponentsOverlay } from 'styling/GlobalStyles'
import { RestartIcon } from 'assets/icons/RestartIcon'
import Check from 'assets/icons/Check'
import { Input } from 'components/Input/Input'
import { PlusIcon } from 'assets/icons/PlusIcon'
import { toast } from 'react-toastify'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import {
  standardFieldValidation,
  validateSelect,
  validationWithCoordinates,
} from 'utils/validationUtils'
import { zonesCoordsValidation, zonesInputValidation } from './zonesValidation'
import { AreaColors, getCenter, TypeOfAreas, typeOfVegatationList } from './zoneConstants'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { computeArea, LatLng } from 'spherical-geometry-js'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { checkInvalid, handleStopPropagation } from 'utils/helpers'
import { DeleteIcon } from 'assets/icons/DeleteIcon'
import { setConfirmModal } from 'features/global/globalSlice'
import { Wrapper } from '@googlemaps/react-wrapper'

export const MapTab = ({ creating, setCreating, handleSubmit, handleDelete }: IMapTab) => {
  const [mapType, setMapType] = useState('satellite')
  const [menuOpen, setMenuOpen] = useState(false)
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false)
  const [selectZone, setSelectZone] = useState<ISelectZone>({ open: false, id: null })
  const [currentlyAdding, setCurrentlyAdding] = useState<ILatLong>({ lat: null, lng: null })
  const [invalidFields, setInvalidFields] = useState<string[]>(zonesInputValidation)
  const [invalidFieldsCoords, setInvalidFieldsCoords] = useState<string[]>(zonesCoordsValidation)
  const [mapPosition, setMapPosition] = useState<IMapPosition>({
    zoom: 2,
    center: { lat: 0, lng: 0 },
  })
  const [map, setMap] = useState<any>()
  const currentlyAddingMarkers = useRef([])
  const { zones, blocks, sections, pits } = useSelector((store: RootState) => store.zones)
  const currentlyCreatingPolygon = useRef(null)
  const loadedPolygons = useRef([])
  const [centerSet, setCenterSet] = useState(false)

  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleAddCoords()
      }
    }
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [currentlyAdding])


  useEffect(() => {
    if (map && zones.length > 0 && !centerSet) {
      const center = getCenter(zones[0])
      map.setZoom(20)
      map.setCenter(center)
      setCenterSet(true)
    }
  }, [map])


  useEffect(() => {
    if (map) {
      loadedPolygons.current.forEach(lp => {
        lp.polygon.setMap(null)
        lp.label.setMap(null)
      })
      loadedPolygons.current = []
      const forRetrun = []
      zones.forEach((z: ICreating) => forRetrun.push({ ...z, color: getColor(1) }))
      sections.forEach((s: ICreating) => forRetrun.push({ ...s, color: getColor(2) }))
      blocks.forEach((b: ICreating) => forRetrun.push({ ...b, color: getColor(3) }))
      pits.forEach((p: ICreating) => forRetrun.push({ ...p, color: getColor(4) }))

      forRetrun.forEach((p) => {
        // Create label for polygon
        const center = getCenter(p)
        const content = document.createElement('p')
        content.innerText = p.areaName
        content.className = 'white-text'
        // forRetrun.push({ id: 'creating', path: creating.path, color: getColor(creating.type) })

        if (loadedPolygons.current.some((lp) => lp.id == p.id + '_' + p.type)) {
          const foundIndex = loadedPolygons.current.findIndex((lp) => lp?.id == p.id + '_' + p.type)
          loadedPolygons.current[foundIndex].polygon.setMap(null)
          loadedPolygons.current[foundIndex].label.setMap(null)
          delete loadedPolygons.current[foundIndex]
        }
        loadedPolygons.current.push({
          id: p.id + '_' + p.type,
          label: new google.maps.marker.AdvancedMarkerElement({
            position: { lat: Number(center.lat), lng: Number(center.lng) },
            map,
            content,
          }),
          polygon: new google.maps.Polygon({
            paths: p.path,
            fillColor: p.color,
            map,
            strokeWeight: 0.5,
            clickable: !checkCreating(),
          }),
        })
        loadedPolygons.current[loadedPolygons.current.length - 1].polygon.addListener('click', () => {
          handleGetInfo(p)
        }
        )
      })
    }
  }, [map, zones, sections, blocks, pits, creating.type])

  useEffect(() => {
    if (zones.length > 0 && mapPosition.center.lat < 1) {
      setMapPosition({ zoom: 15, center: getCenter(zones[0]) })
    }
  }, [zones])

  const handleMapType = () => {
    if (mapType == 'satellite') {
      map.setMapTypeId('terrain')
      setMapType('terrain')
    } else {
      map.setMapTypeId('satellite')
      setMapType('satellite')
    }
  }
  const handleCreate = (type: TypeOfAreas) => {
    if (type !== TypeOfAreas.zones) {
      setSelectZone({ open: true })
    }
    setCreating({
      type,
      currentlyCreating: true,
      path: [],
      areaName: '',
      areaSize: null
    })
    setInvalidFields(zonesInputValidation)
    setOptionsOpen(true)
  }

  const handleEnterCoords = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement
    let parsedValue = parseFloat(value)
    validationWithCoordinates(e, setInvalidFieldsCoords)
    if (isNaN(parsedValue)) {
      parsedValue = undefined
    }
    setCurrentlyAdding((prev) => {
      const copy = { ...prev }
      name == 'lat' ? (copy.lat = parsedValue) : (copy.lng = parsedValue)
      return copy
    })
  }

  const handleAddCoords = () => {
    if (
      currentlyAdding.lat &&
      currentlyAdding.lng &&
      typeof currentlyAdding.lat == 'number' &&
      typeof currentlyAdding.lng == 'number' &&
      invalidFieldsCoords.length < 1
    ) {
      setCreating((prev: ICreating) => {
        const copy = structuredClone(prev)
        currentlyAddingMarkers.current.push(
          new google.maps.Marker({
            position: { lat: currentlyAdding.lat, lng: currentlyAdding.lng },
            map,
          }),
        )
        copy.path.push(currentlyAdding)
        if (copy.path.length > 2) {
          currentlyCreatingPolygon.current = new google.maps.Polygon({
            paths: copy.path,
            fillColor: '#fff',
            map,
            strokeWeight: 0.5,
          })
          copy.areaSize = Number(
            computeArea(copy.path.map((i: ILatLong) => new LatLng(i.lat, i.lng, false))).toFixed(4),
          )
        }
        return copy
      })
      setCurrentlyAdding({ lat: null, lng: null })
    } else {
      toast.error('Please enter valid coordinates')
    }
    map.setCenter({ lng: currentlyAdding.lng, lat: currentlyAdding.lat })
    map.setZoom(10)
  }
  const handleDone = async () => {
    if (invalidFields.length > 0) {
      toast.warn('All area inputs must be valid.')
      return
    }
    if (creating.path.length < 3) {
      toast.warn('Area must have at least 3 points')
      return
    } else {
      const resp = await handleSubmit()
      if (resp.meta.requestStatus === 'fulfilled') {
        setCreating({ currentlyCreating: false, path: [], areaName: '', areaSize: null })
        setCurrentlyAdding({ lat: null, lng: null })
        setOptionsOpen(false)
        setMenuOpen(false)
        setInvalidFields(zonesInputValidation)
        currentlyCreatingPolygon.current?.setMap(null)
      }
    }
    google.maps.event.clearListeners(map, 'click')
    currentlyAddingMarkers.current.forEach((m) => m.setMap(null))
  }

  const handleCancel = () => {
    setCreating({ currentlyCreating: false, path: [], areaName: '', areaSize: null })
    setCurrentlyAdding({ lat: null, lng: null })
    currentlyAddingMarkers.current.forEach(m => m.setMap(null))
    currentlyAddingMarkers.current = []

    if (currentlyCreatingPolygon.current) {
      currentlyCreatingPolygon.current.setMap(null)
      currentlyCreatingPolygon.current = null
    }

    google.maps.event.clearListeners(map, 'click')
    setOptionsOpen(false)
    setMenuOpen(false)
  }

  const handleUndo = () => {
    setCreating((prev: ICreating) => {
      const copy = structuredClone(prev)
      copy.path.pop()
      copy.areaSize = Number(
        computeArea(copy.path.map((i: ILatLong) => new LatLng(i.lat, i.lng, false))).toFixed(4),
      )
      currentlyCreatingPolygon.current?.setMap(null)
      currentlyCreatingPolygon.current = new google.maps.Polygon({
        paths: copy.path,
        fillColor: '#fff',
        map,
        strokeWeight: 0.5,
      })
      currentlyAddingMarkers.current[currentlyAddingMarkers.current.length - 1].setMap(null)
      currentlyAddingMarkers.current.pop()
      return copy
    })
  }

  const checkCreating = () => {
    if (creating.type) {
      return true
    }
    return false
  }

  const handleGetInfo = (i: ICreating) => {
    setCreating({ ...i, currentlyCreating: true, updating: true })
    currentlyAddingMarkers.current.forEach(m => m.setMap(null))
    currentlyAddingMarkers.current = i.path.map(i => new google.maps.Marker({
      position: { lat: i.lat, lng: i.lng },
      map,
    }))
    setOptionsOpen(true)
    setInvalidFields([])
  }

  const handleMenuOpen = () => {
    if (creating && creating.type) {
      toast.warn('Area already created, please enter area info.')
    } else {
      setMenuOpen(true)
      map.addListener('click', (e) => {
        setCreating((prev: ICreating) => {
          const copy = structuredClone(prev)
          currentlyAddingMarkers.current.push(
            new google.maps.Marker({
              position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
              map,
            }),
          )
          copy.path.push({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          if (copy.path.length > 2) {
            currentlyCreatingPolygon.current?.setMap(null)
            currentlyCreatingPolygon.current = new google.maps.Polygon({
              paths: copy.path,
              fillColor: '#fff',
              map,
              strokeWeight: 0.5,
            })
            copy.areaSize = Number(
              computeArea(copy.path.map((i: ILatLong) => new LatLng(i.lat, i.lng, false))).toFixed(4),
            )
          }
          return copy
        })
        setCurrentlyAdding({ lat: null, lng: null })
      })
    }
  }

  const AreaInputs = () => {
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      standardFieldValidation(e, setInvalidFields)
      const { value, name, type } = e.target as HTMLInputElement
      setCreating((prev: ICreating) => {
        const copy = structuredClone(prev)
        copy[name] = type === 'number' ? Number(value) : value
        return copy
      })
    }

    const handleSelect = (value: ISelectValue) => {
      validateSelect(value, 'typeOfVegetation', setInvalidFields)
      setCreating((prev: ICreating) => {
        const copy = structuredClone(prev)
        copy.typeOfVegetation = value.value
        return copy
      })
    }

    return (
      <AreaInputsContainer onClick={handleStopPropagation}>
        <ZoneMenuTitle>
          <H2 noUpperCase>Area info</H2>
          <CursorPointer onClick={handleCancel}>
            <CloseIcon />
          </CursorPointer>
        </ZoneMenuTitle>
        <ZonesInputsWrapper>
          <TextDisplayCard
            labelText='Area Size'
            title={
              creating.areaSize
                ? creating.areaSize.toFixed(4) + ' m2'
                : 'Waiting for coordinates...'
            }
            width='100%'
          />
          <Input
            width='100%'
            nomarg
            placeholder='Pit 23'
            type='string'
            labelText='Area name'
            name='areaName'
            onChange={handleChange}
            value={creating.areaName}
            invalid={checkInvalid(invalidFields, 'areaName')}
            noinvalidtext='true'
          />
          <Input
            width='100%'
            type='number'
            placeholder={0}
            labelText='Top Soil Depth'
            nomarg
            onChange={handleChange}
            name='topSoilDepth'
            value={creating.topSoilDepth}
            invalid={checkInvalid(invalidFields, 'topSoilDepth')}
            noinvalidtext='true'
          />
          <Input
            onChange={handleChange}
            placeholder={0}
            nomarg
            width='100%'
            type='number'
            labelText='Overburden'
            name='overburden'
            value={creating.overburden}
            invalid={checkInvalid(invalidFields, 'overburden')}
            noinvalidtext='true'
          />
          <Input
            placeholder={0}
            onChange={handleChange}
            width='100%'
            nomarg
            type='number'
            name='gravelThickness'
            labelText='Gravel Thickness'
            value={creating.gravelThickness}
            invalid={checkInvalid(invalidFields, 'gravelThickness')}
            noinvalidtext='true'
          />
          <Input
            placeholder={0}
            onChange={handleChange}
            width='100%'
            nomarg
            type='number'
            name='averageGrade'
            labelText='Average Grade'
            value={creating.averageGrade}
            invalid={checkInvalid(invalidFields, 'averageGrade')}
            noinvalidtext='true'
          />
          <HonestSelectInput
            labelText='Type of vegetation'
            options={typeOfVegatationList}
            onChange={handleSelect}
            name='typeOfVegetation'
            defaultValue={creating.typeOfVegetation}
            placeholder='Forest'
            absolute
            invalid={invalidFields.includes('typeOfVegetation')}
          />
        </ZonesInputsWrapper>
      </AreaInputsContainer>
    )
  }

  const handleZoneSelect = (value: ISelectValue) => {
    setSelectZone({ open: false })
    setCreating((prev: ICreating) => {
      const copy = structuredClone(prev)
      copy.zoneId = value.value
      return copy
    })
  }

  const getColor = (type: number) => {
    let color = AreaColors.zones
    switch (type) {
      case 2:
        color = AreaColors.sections
        break
      case 3:
        color = AreaColors.blocks
        break
      case 4:
        color = AreaColors.pits
        break
      default:
        color = AreaColors.zones
    }
    return color
  }

  const handleDeleteArea = async () => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await handleDelete(creating.type)
          dispatch(setConfirmModal({ isOpen: false }))
          // const deletedIndex = loadedPolygons.current.findIndex((lp) => lp?.id === creating.id + '_' + creating.type)
          // loadedPolygons.current[deletedIndex].polygon.setMap(null)
          // loadedPolygons.current[deletedIndex].label.setMap(null)
          currentlyAddingMarkers.current.forEach(m => m.setMap(null))
        },
        word: 'delete area',
      }),
    )

    setCreating({ currentlyCreating: false, path: [], areaName: '', areaSize: null })
    setCurrentlyAdding({ lat: null, lng: null })
    setOptionsOpen(false)
    google.maps.event.clearListeners(map, 'click')
    setMenuOpen(false)
  }

  const handleZoneSelectOptions = () => {
    switch (creating.type) {
      case TypeOfAreas.sections:
        return zones.map((z) => ({ label: z.areaName, value: z.id }))

      case TypeOfAreas.blocks:
        return sections.map((s) => ({ label: s.areaName, value: s.id }))

      default:
        return blocks.map((b) => ({ label: b.areaName, value: b.id }))
    }
  }

  return (
    <div className='App-map'>
      <MapHTMLComponentsOverlay>
        {creating?.currentlyCreating && !selectZone.open && AreaInputs()}
        <ZonesSideMenu>
          <ZonesOrangeCircle onClick={handleMapType}>
            <WorldIcon />
          </ZonesOrangeCircle>
          {!optionsOpen ? (
            <ZonesOrangeCircle onClick={handleMenuOpen}>
              {menuOpen && (
                <ZonesMenuContainer onClick={handleStopPropagation}>
                  <ZoneMenuTitle>
                    <H2 noUpperCase>Add New</H2>
                    <CursorPointer onClick={() => setMenuOpen(false)}>
                      <CloseIcon />
                    </CursorPointer>
                  </ZoneMenuTitle>
                  <ZoneMenuItem onClick={() => handleCreate(TypeOfAreas.zones)}>Zone</ZoneMenuItem>
                  {zones.length > 0 && (
                    <ZoneMenuItem onClick={() => handleCreate(TypeOfAreas.sections)}>
                      Section
                    </ZoneMenuItem>
                  )}
                  {sections.length > 0 && (
                    <ZoneMenuItem onClick={() => handleCreate(TypeOfAreas.blocks)}>
                      Block
                    </ZoneMenuItem>
                  )}
                  {blocks.length > 0 && (
                    <ZoneMenuItem onClick={() => handleCreate(TypeOfAreas.pits)}>Pit</ZoneMenuItem>
                  )}
                </ZonesMenuContainer>
              )}
              {PlusIcon}
            </ZonesOrangeCircle>
          ) : (
            <ZonesAddContainer>
              {selectZone.open ? (
                <ZonesSelectContainer>
                  <HonestSelectInput
                    absolute
                    options={handleZoneSelectOptions()}
                    labelText={'Select parent area'}
                    onChange={handleZoneSelect}
                  />
                </ZonesSelectContainer>
              ) : (
                <>
                  <Flex gap='1rem' padding='0 1rem'>
                    {creating.updating && (
                      <ZonesOrangeCircle whiteIcon onClick={handleDeleteArea}>
                        {DeleteIcon}
                      </ZonesOrangeCircle>
                    )}
                    {currentlyCreatingPolygon.current && creating.path.length > 0
                      && <ZonesOrangeCircle onClick={handleUndo}>{RestartIcon}</ZonesOrangeCircle>}
                    <ZonesOrangeCircle onClick={handleCancel}>
                      <CloseIcon white />
                    </ZonesOrangeCircle>
                    <ZonesOrangeCircle onClick={handleDone}>{<Check white />}</ZonesOrangeCircle>
                  </Flex>
                  <LatLongContainer>
                    <Input
                      labelText='Latitude'
                      placeholder='Type here'
                      type='number'
                      name='lat'
                      width='8rem'
                      nomarg
                      value={currentlyAdding.lat}
                      onChange={handleEnterCoords}
                      invalid={checkInvalid(invalidFieldsCoords, 'lat')}
                    />
                    <Input
                      nomarg
                      labelText='Longitude'
                      placeholder='Type here'
                      name='lng'
                      type='number'
                      width='8rem'
                      value={currentlyAdding.lng}
                      onChange={handleEnterCoords}
                      invalid={checkInvalid(invalidFieldsCoords, 'lng')}
                    />
                    <ZonesOrangeCircle onClick={handleAddCoords}>{PlusIcon}</ZonesOrangeCircle>
                  </LatLongContainer>
                </>
              )}
            </ZonesAddContainer>
          )}
        </ZonesSideMenu>
      </MapHTMLComponentsOverlay>
      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_KEY} version='beta' libraries={['marker']}>
        <Map setMap={setMap} />
      </Wrapper>
    </div>
  )
}

const Map = ({ setMap }) => {
  const ref = useRef()
  const mapOptions = {
    zoom: 2,
    mapId: '4504f8b37365c3d0',
    center: {
      lat: 0,
      lng: -0,
    },
    mapTypeId: 'satellite',
    disableDefaultUI: true,
  }

  useEffect(() => {
    setMap(new google.maps.Map(ref.current, mapOptions))
  }, [])

  return <ZonesMapContainer ref={ref} id='map' />
}

