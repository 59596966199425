import React from 'react'
import { useDrop } from 'react-dnd'
// import { useSelector } from 'react-redux'
// // import { RootState } from 'store'
import { IWorkingFunctions } from 'types/tasks.interface'

export const SingleFunctionInput = ({
  input,
  allFunctions,
  setAddedButNotConnected,
  setState
}: ISingleFunctionInput) => {
  // const { tasksLogics } = useSelector((state: RootState) => state.tasks)

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['input', 'funOutput'],
    drop: (item: { id: string; component: number }) => {
      if (item.component == Number(input.id.split('-')[1])) {
        if (item.id.startsWith('inp')) {
          const inputId = Number(item.id.split('inp')[1])
          const inConnectedFunctions = allFunctions.find(f => f.fid == input.fid && !f.notAdded)
          //  do if function is connected to calculated component,
          if (inConnectedFunctions) {
            setState(prev => {
              const copy = structuredClone(prev)
              const cIndex = copy.components.findIndex(f => f.functionId == input.fid)
              copy.components[cIndex].inputParameters.push({ type: 1, value: inputId })
              return copy
            })
            return
          }

          //  do if function is not yet connected to calculated component
          setAddedButNotConnected((prev) => {
            const copy = structuredClone(prev)
            const index = copy.findIndex((f) => f.fid == input.fid)
            const inputIndex = copy[index].input.findIndex((i) => i.component == item.component)
            copy[index].input[inputIndex].tempConnectedInput = {
              componentId: Number(item.id.split('inp')[1]),
            }
            return copy
          })
        } else {
          // from other function
        }
      }

    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }), [allFunctions])
  return (
    <div
      ref={drop}
      id={'funInput_' + input.id}
      style={{ background: isOver ? '#dadada' : '#ffffff' }}
      className='singleFunctionInput'
    >
      {input.name}
    </div>
  )
}

interface ISingleFunctionInput {
  input?: {
    component: number
    fid: number
    id: string
    name: string
    order: number
  }
  state?: any
  setState?: any
  setAddedButNotConnected?: React.Dispatch<React.SetStateAction<IWorkingFunctions[]>>
  allFunctions?: IWorkingFunctions[]
}
