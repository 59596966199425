import React, { useState } from 'react'
import {
  Flex,
  H1WithBack,
  TabsContainer,
  Tabs,
  PageWrapper,
  ContentWrapper,
} from '../../styling/GlobalStyles'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { AddEmployeerWrapper } from './AddEmployee.styled'
import { EmployeeBasicInfoTab } from './EmployeeBasicInfoTab'
import { EmployeesContractsTab } from './EmployeesContractsTab'
import { NavBar } from '../../components/NavBar/NavBar'
import { EmployeesMachineryTab } from './EmployeesMachineryTab'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addUser } from 'features/users(Employees)/usersSlice'
import { AppDispatch } from 'store'
import { toast } from 'react-toastify'
import { EmployeeCapabilitiesTab } from './EmployeeCapabilitiestab'
import { IContract, IEmployee } from 'types/users.interface'
import { employeeBasicInfoTabInputs, employeesInitialState } from './employeesData'

export const AddEmployeer = () => {
  const [active, setActive] = useState('Basic Info')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [invalidFields, setInvalidFields] = useState(employeeBasicInfoTabInputs)
  const [state, setState] = useState<IEmployee>(employeesInitialState)

  const handleSubmit = async () => {
    if (invalidFields.length > 0) {
      toast.warn('All basic info fields must be valid')
      return
    }

    const resp = await dispatch(addUser(state))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/employees')
    }
  }
  const renderTabs = () => {
    if (active === 'Basic Info') {
      return (
        <EmployeeBasicInfoTab
          setState={setState}
          state={state}
          setActive={setActive}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        />
      )
    } else if (active === 'Contract') {
      return <EmployeesContractsTab setState={setState} state={state} setActive={setActive} />
    } else if (active === 'Machines') {
      return <EmployeesMachineryTab setState={setState} state={state} setActive={setActive} />
    } else if (active === 'Capabilities') {
      return (
        <EmployeeCapabilitiesTab
          setState={setState}
          state={state}
          setActive={setActive}
          handleSubmit={handleSubmit}
        />
      )
    }
  }

  const checkContractTab = (name: string) => {
    if (state.contracts.some((c: IContract) => !c.grossSalary || !c.netSalary)) {
      toast.warn('Contracts must be saved first')
    } else {
      setActive(name)
    }
  }

  return (
    <AddEmployeerWrapper>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack arrow title={'Add Employee'} />
          <TabsContainer>
            <Tabs active={active === 'Basic Info'} onClick={() => checkContractTab('Basic Info')}>
              Basic Info
            </Tabs>
            <Tabs active={active === 'Contract'} onClick={() => setActive('Contract')}>
              Contract
            </Tabs>
            <Tabs active={active === 'Machines'} onClick={() => checkContractTab('Machines')}>
              Machines
            </Tabs>
            <Tabs
              active={active === 'Capabilities'}
              onClick={() => checkContractTab('Capabilities')}
            >
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper noFooter>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </AddEmployeerWrapper>
  )
}
