import React from 'react'
import { TabFooter, Button, Flex, Animation } from 'styling/GlobalStyles'
import { Input } from 'components/Input/Input'
import { IDepartmentsState, IDepratmentsTab } from 'types/departments.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const DepartmentBasicInfoTab = ({ state, setState, handleSubmit }: IDepratmentsTab) => {
  useFocusFirstInput()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: IDepartmentsState) => {
      const copy = { ...prev }
      copy.name = e.target.value
      return copy
    })
  }
  return (
    <Animation>
      <Input
        type='text'
        value={state.name}
        onChange={handleChange}
        placeholder='Department name'
        labelText='Department name'
        width={screen.availWidth < 500 ? '100%' : '20rem'}
        invalid={state?.name?.length < 2 ? 'true' : ''}
      />

      <TabFooter>
        <Flex width='100%' end='true'>
          <CancelBtn />
          <Button ml='15' height='40' onClick={handleSubmit} orange>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>
  )
}

