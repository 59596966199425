export const zonesInputValidation = [
  'overburden',
  'topSoilDepth',
  'averageGrade',
  'typeOfVegetation',
  'areaName',
  'gravelThickness',
]

export const zonesCoordsValidation = ['lat', 'lng']
