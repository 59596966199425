import React, { useState } from 'react'
import {
  PageWrapper,
  Button,
  Separator,
  GridItem,
  ItemsGrid,
  Flex,
  MainHeadline,
} from '../../../styling/GlobalStyles'
import { Pagination } from '../../../components/Pagination/Pagination'
import { FieldsModal } from '../../../components/Modal/FieldsModal'
import { Modal } from '../../../components/Modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { clearFieldsList, deleteField, getFields } from '../../../features/fields/fieldsSlice'
import { useNavigate } from 'react-router-dom'
import { NavBar } from '../../../components/NavBar/NavBar'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { setConfirmModal } from 'features/global/globalSlice'
import { AppDispatch, RootState } from 'store'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { getParams, handlePagination } from 'utils/helpers'
import { INameId } from 'constants/globalTypes'
import useQueryPagination from 'hooks/useQueryPagination'

export const ResourcesFields = ({ noTitle }: { noTitle: boolean }) => {
  const [modal, setModal] = useState(false)
  const { fields, noFields, totalPages } = useSelector((state: RootState) => state.fields)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location }))

  useQueryPagination({ fetchFunctions: [getFields], cleanUpFunctions: [clearFieldsList], params })

  const removeField = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: () => {
          dispatch(deleteField(id))
          dispatch(setConfirmModal({ isOpen: false }))
        },
        word: 'delete field',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Fields...' setParams={setParams} />
      <PageWrapper>
        {!noTitle ? (
          <Flex between center>
            <MainHeadline>Fields</MainHeadline>
            <Button orange onClick={() => setModal(true)}>
              Add field
            </Button>
          </Flex>
        ) : (
          <Flex end='true'>
            <Button orange onClick={() => setModal(true)}>
              Add field
            </Button>
          </Flex>
        )}
        <Separator />
        <ItemsGrid columns={3} mt='25px'>
          {fields && !noFields ? (
            fields.map((item: INameId, key: number) => {
              return (
                <GridItem key={key}>
                  <p
                    onClick={() => {
                      navigate('/resources-fields/' + item.id)
                    }}
                  >
                    {item.name}
                  </p>

                  <Flex
                    between
                    center
                    onClick={() => {
                      removeField(item.id)
                    }}
                  >
                    <RemoveCircle />
                  </Flex>
                </GridItem>
              )
            })
          ) : (
            <NoItems title='Fields' />
          )}
        </ItemsGrid>
      </PageWrapper>
      <Flex end='true' width='100%'>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </Flex>
      {modal && (
        <Modal black>
          <FieldsModal close={setModal} />
        </Modal>
      )}
    </>
  )
}
