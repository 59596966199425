import { Flex } from '../../styling/GlobalStyles'
import { Chart } from './Charts'
import { GraphCard } from './GraphCard.styled'

export const GraphCardComponent = ({ data, color, chartColors, title, modifiedData }: IGraphCardComponent) => {
  return (
    <GraphCard>
      <h3>{title}</h3>
      <Flex center between>
        <Chart color={color} data={data} chartColors={chartColors} modifiedData={modifiedData} />
        <Flex column gap='0.3'>
          <strong>{data[0]?.value}</strong>
          {title === 'Active Workers' && (
            <>
              <h4>{data[1]?.name}</h4>
              <p>{data[1]?.value}</p>
            </>
          )}
        </Flex>
      </Flex>
    </GraphCard>
  )
}


interface IGraphCardComponent {
  data: { name: string, value: number }[]
  color?: string
  chartColors: string[]
  modifiedData: { name: string, value: number }[]
  title: string
}
