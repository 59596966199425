import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch } from 'store'
import { NavBar } from '../../components/NavBar/NavBar'
import { clearCompany, getSingleCompany } from '../../features/companies/companiesSlice'
import { ItemsGrid, Separator, H1WithBack, PageWrapper } from '../../styling/GlobalStyles'

export const SingleCompany = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { singleCompany } = useSelector((store: any) => store.companies)

  useEffect(() => {
    dispatch(getSingleCompany(id))

    return () => {
      dispatch(clearCompany())
    }
  }, [])

  const { contact = '', name = '', representative = '', industry = '', taxNumber } = singleCompany

  return (
    <>
      <NavBar />
      <PageWrapper>
        <H1WithBack title={name} arrow />
        <Separator />
        <ItemsGrid columns={2} width='60%'>
          <TextDisplayCard title={name} labelText='Company Name' />
          <TextDisplayCard
            title={representative.position ? representative.position : ''}
            labelText='Representative position'
          />
          <TextDisplayCard title={industry ? industry : ''} labelText='Industry' />
          <TextDisplayCard
            title={contact.address ? contact.address : ''}
            labelText='Company Address'
          />
          <TextDisplayCard
            title={representative.name ? representative.name : ''}
            labelText='Representative Name'
          />

          <TextDisplayCard
            title={contact.phoneNumber ? contact.phoneNumber : ''}
            labelText='Company phone number'
          />
          <TextDisplayCard
            title={representative.phoneNumber ? representative.phoneNumber : ''}
            labelText='Representative phone number'
          />
          <TextDisplayCard title={contact.email ? contact.email : ''} labelText='Company Email' />
          <TextDisplayCard
            title={representative.email ? representative.email : ''}
            labelText='Representative Email'
          />
          <TextDisplayCard title={taxNumber ? taxNumber : ''} labelText='Company VAT' />
        </ItemsGrid>
      </PageWrapper>
    </>
  )
}
