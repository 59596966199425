import React from 'react'
import { SelectWrapper, Label } from '../Input/Input.styled'
import { HonestWeekPicker } from './HonestWeekPicker'

export const HonestWeekPickerInput = (props: IHonestWeekPickerInput) => {
  return (
    <SelectWrapper min={props.min} width={props.width} nomarg={props.nomarg}>
      <Label htmlFor={props.id} labelText={props.labelText}>
        {props.labelText}
      </Label>
      <HonestWeekPicker onChange={props.onChange} />
    </SelectWrapper>
  )
}

interface IHonestWeekPickerInput {
  nomarg?: boolean
  labelText?: string
  id?: number,
  onChange: (firstDay: Date, lastDay: Date) => void,
  reset?: boolean
  width?: string
  min?: boolean
}