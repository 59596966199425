import styled from 'styled-components'

export const AddEmployeerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  display: flex;
  gap: 1.5rem;
`
export const AddProfileImage = styled.div`
  width: 13.4rem;
  position: relative;
`

export const InputContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.5rem;
  @media (max-width: 500px) {
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;

  }
`
export const AddProfileImageClick = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
img{
  pointer-events: none;
  width: 100%;
  border-radius: 100%;
 height: 100%;
 object-fit: cover;

}
  svg{
    pointer-events: none;
  }

`

export const AddProfileImageFilled = styled.div`
  width: 13.4rem;
  height: 13.4rem;
  max-width: 13.4rem;
  max-height: 13.4rem;
  min-width: 13.4rem;
  min-height: 13.4rem;
  border-radius: 50%;
  background-color: red;
`

export const AddProfileImageInput = styled.input`
opacity: 0;
position: absolute;
z-index: -1;
top:0;
left:0
`