import { EditIcon } from 'assets/icons/EditIcon'
import { CategoryModal } from 'components/Modal/CategoryModal'
import { Modal } from 'components/Modal/Modal'
import { NavBar } from 'components/NavBar/NavBar'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Pagination } from 'components/Pagination/Pagination'
import {
  clearInventoryCategoriesList,
  getInventoryCategories,
} from 'features/inventory/invSettingsCategoriesSlice'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  EditContainer,
  Flex,
  GridItem,
  ItemsGrid,
  MainHeadline,
  PageFooter,
  PageWrapper,
  Separator,
} from 'styling/GlobalStyles'
import { getParams, handlePagination } from 'utils/helpers'
import { ModalTypes } from 'types/global.interface'
import { IInvenotryCategoryField, ISingleInventoryCategory } from 'types/inventory.interface'
import useQueryPagination from 'hooks/useQueryPagination'
import { RootState } from 'store'

export const InvSettingsCategories = () => {
  const [modal, setModal] = useState<any>({
    open: false,
    edit: true,
    text: '',
    id: '',
    fields: [],
  })
  const { inventoryCategories, totalPages, noInventoryCategories } = useSelector(
    (state: RootState) => state.inventoryCategories,
  )

  const ToggleEditModal = (text: string, id: number, fields: IInvenotryCategoryField[]) => {
    setModal({ open: true, edit: true, text: text, id, fields })
  }
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location }))


  useQueryPagination({ fetchFunctions: [getInventoryCategories], cleanUpFunctions: [clearInventoryCategoriesList], params })

  return (
    <>
      <PageWrapper>
        <NavBar defaultValue={params.q} input placeholder='Search Inventory Categories...' setParams={setParams} />
        <Flex between>
          <MainHeadline>Inventory Settings Categories</MainHeadline>
          <Button orange onClick={() => setModal({ ...modal, open: true, edit: false })}>
            Add Category
          </Button>
        </Flex>
        <Separator />
        <ItemsGrid columns={3}>
          {noInventoryCategories ? (
            <NoItems title='Inventory categories' />
          ) : (
            inventoryCategories.length > 0 &&
            inventoryCategories.map(({ name, id, fields }: ISingleInventoryCategory) => {

              return (
                <GridItem noRightPadding key={id}>
                  <p onClick={() => navigate('/inventory-categories/' + id.toString())}>{name}</p>

                  <EditContainer
                    noBackground
                    onClick={() => {
                      ToggleEditModal(name, id, fields)
                    }}
                  >
                    <span>{EditIcon}</span>
                  </EditContainer>
                </GridItem>
              )
            })
          )}
        </ItemsGrid>

        {modal.open && (
          <Modal black>
            <CategoryModal
              setParams={{
                type: ModalTypes.Delete,
                f: setParams,
                noItems: inventoryCategories.length < 2,
              }}
              text={modal.text}
              image={modal.image}
              edit={modal.edit}
              id={modal.id}
              invSettings
              close={setModal}
            />
          </Modal>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
