import React from 'react'

export const ArrowLeftColorless = (
  <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'>
    <path
      d='M16.2187 10.0798H4.54395'
      strokeWidth='1.66782'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3813 15.9174L4.54395 10.0801L10.3813 4.24268'
      strokeWidth='1.66782'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
