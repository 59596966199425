import React from 'react'
import { SelectWrapper, Label } from '../Input/Input.styled'
import { HonestTimePicker } from './HonestTimePicker'

export const HonestTimePickerInput = (props: IHonestTimePickerInput) => {
  return (
    <SelectWrapper width={props.width} nomarg={props.nomarg}>
      <Label htmlFor={props.id} labelText={props.labelText}>
        {props.labelText}
      </Label>
      <HonestTimePicker
        calculated={props.calculated}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        reset={props.reset}
      />
    </SelectWrapper>
  )
}


interface IHonestTimePickerInput {
  nomarg?: boolean
  labelText?: string
  id?: number
  onChange: (value: string) => void
  width?: string
  defaultValue?: number
  calculated?: number
  reset?: boolean
}