import React, { useState } from 'react'
import {
  H1WithBack,
  Table,
  Tr,
  Th,
  PageWrapper,
  PageFooter,
  Separator,
} from '../../styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import { Pagination } from '../../components/Pagination/Pagination'
import { NavBar } from '../../components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import {
  cleanClientMachines,
  deleteClientMachinery,
  getClientMachinery,
} from 'features/clientMachinery/clientMachinerySlice'
import { useParams } from 'react-router-dom'
import { Options } from 'components/Options/Options'
import { AppDispatch, RootState } from 'store'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { getParams, handlePagination } from 'utils/helpers'
import { setConfirmModal } from 'features/global/globalSlice'
import { ModalTypes } from 'types/global.interface'
import { clearSingleMachineryCategory } from 'features/categories/machineryCategories'
import useQueryPagination from 'hooks/useQueryPagination'

export const SingleMachineryCategory = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()
  const { totalPages, noMachines, clientMachines } = useSelector(
    (store: RootState) => store.clientMachinery,
  )
  const [params, setParams] = useState<any>(getParams({ location, limit: 5 }))

  useQueryPagination({
    fetchFunctions: [getClientMachinery], cleanUpFunctions: [cleanClientMachines, clearSingleMachineryCategory],
    params, functionParams: { ...params, machineCategoryId: id }
  })

  const handleDelete = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteClientMachinery(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: clientMachines.length < 2,
        },
        word: 'delete machinery',
      }),
    )
  }

  const handleStatus = (status: number) => {
    const statuses = ['Available', 'Busy', 'Broken', 'In Maintenance', 'Paused']
    return statuses[status - 1]
  }

  return (
    <div>
      <PageWrapper>
        <NavBar input placeholder='Search Machines...' setParams={setParams} limit={5} />
        <H1WithBack link='/machinery' title={params?.name} arrow />
        <Separator />
        {noMachines ? (
          <NoItems title='machineries' />
        ) : (
          <Table>
            <Th next mb='0.5rem'>
              <Td w='20%' noTooltip text='Name' />
              <Td w='30%' noTooltip text='Category' />
              <Td w='30%' noTooltip text='Model' />
              <Td w='10%' noTooltip text='Status' />
            </Th>
            {clientMachines.map((item) => {
              return (
                <Tr key={item.id}>
                  <Td w='20%' text={item.name} />
                  <Td w='30%' text={item.machineCategory.name} />
                  <Td w='30%' text={item.machineryModel.name} />
                  <Td w='15%' text={handleStatus(item.status)} />
                  <Td w='5%' noTooltip>
                    <Options
                      handleRemove={() => handleDelete(item.id)}
                      title='Machinery'
                      item={item}
                    />
                  </Td>
                </Tr>
              )
            })}
          </Table>
        )}
        <PageFooter>
          <Pagination
            setPage={(page) => handlePagination(page, setParams, 5)}
            pageCount={Math.ceil(totalPages)}
            forcePage={(params.page ? params.page : 1) - 1}
          />
        </PageFooter>
      </PageWrapper>
    </div>
  )
}
