import { createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { IAddArraySchedule, IAddSchedule } from 'types/workSchedule.interface'
import { baseUrl } from 'utils/axios'
import { areDatesSameDay, formatParams } from 'utils/helpers'

const initialState = {
  isLoading: false,
  workSchedules: [],
  error: false,
  totalCount: 0,
  totalPages: 0,
}

export const getWorkSchedules = createAsyncThunk(
  'workSchedules/getWorkSchedules',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp = await baseUrl.get('work-schedule', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addWorkSchedule = createAsyncThunk(
  'addWorkSchedule/workSchedules',
  async (schedule: IAddSchedule, thunkAPI) => {
    const { id, day } = schedule
    try {
      const resp = await baseUrl.post(
        'work-schedule/user/' + schedule.userId,
        { shiftId: id, date: day },
        thunkAPI,
      )

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const removeWorkSchedule = createAsyncThunk(
  'removeWorkSchedule/workSchedules',
  async (schedule: {userId:number | string, day:any}, thunkAPI) => {
    try {
      await baseUrl.delete(
        'work-schedule/user/' + schedule.userId +'/day/'+schedule.day.toISOString(),
        thunkAPI,
      )
      return schedule
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)


export const addWorkScheduleArray = createAsyncThunk(
  'addWorkScheduleArray/workSchedules',
  async (schedule: IAddArraySchedule, thunkAPI) => {
    const { id, day, userId: users } = schedule
    try {
      const resp = await baseUrl.post(
        'work-schedule/users',
        { shiftId: id, date: day, users },
        thunkAPI,
      )

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const workSchedulesSlice = createSlice({
  name: 'workSchedules',
  initialState,
  reducers: {
    clearWorkSchedule: (state) => {
      state.workSchedules = []
      state.totalPages = 0
      state.totalCount = 0
    },
  },
  extraReducers: {
    [getWorkSchedules.pending.type]: (state) => {
      state.isLoading = true
      state.error = false
    },
    [getWorkSchedules.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.workSchedules = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.error = false
    },
    [getWorkSchedules.rejected.type]: (state) => {
      state.isLoading = false
      state.error = false
    },
    [removeWorkSchedule.pending.type]: (state) => {
      state.isLoading = true
      state.error = false
    },
    [removeWorkSchedule.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const index = state.workSchedules.findIndex(ws=>ws.userData.id == payload.userId)
      const newWS = [...state.workSchedules]
      const shiftId = newWS[index].schedule.find(s=>areDatesSameDay(s.date, payload.day)).shift.id

      // newWS[index].schedule = newWS[index].schedule.filter(s=>(s.shift.id!== shiftId) && !( areDatesSameDay(s.date,payload.day)))
      newWS[index].schedule = newWS[index].schedule.filter(s=>!(s.shift.id === shiftId && areDatesSameDay(s.date, payload.day)))
      state.workSchedules = newWS

      toast.success('Schedule successfully updated.')
      state.error = false
    },
    [removeWorkSchedule.rejected.type]: (state) => {
      state.isLoading = false
      state.error = false
    },
    [addWorkSchedule.fulfilled.type]: (state, { payload }) => {
      state.error = false
      toast.success('Schedule added')
      state.isLoading = false
      const foundIndex = state.workSchedules.findIndex(
        (item) => item.userData.id === payload.userId,
      )
      if (
        state.workSchedules[foundIndex].schedule.some(
          (s) => new Date(s.date).getTime() === new Date(payload.date).getTime(),
        )
      ) {
        const foundScheduleIndex = state.workSchedules[foundIndex].schedule.findIndex(
          (s) => new Date(s.date).getTime() === new Date(payload.date).getTime(),
        )
        state.workSchedules[foundIndex].schedule[foundScheduleIndex] = {
          date: payload.date,
          shift: payload.shift,
        }
      } else {
        state.workSchedules[foundIndex].schedule.push({ date: payload.date, shift: payload.shift })
      }
    },
    [addWorkSchedule.pending.type]: (state) => {
      state.isLoading = true
    },
    [addWorkSchedule.rejected.type]: (state, { payload }) => {
      toast.error(payload.response.data.message.message)
      state.error = true
      state.isLoading = false
    },
    [addWorkScheduleArray.fulfilled.type]: (state, { payload }) => {
      state.error = false
      toast.success('Schedule added')
      state.isLoading = false
      for (const shift of payload) {
        const foundIndex = state.workSchedules.findIndex((s) => s.userData.id === shift.userId)
        const singleShiftIndex = state.workSchedules[foundIndex].schedule.findIndex(
          (s) => new Date(s.date).getTime() === new Date(shift.date).getTime(),
        )
        if (singleShiftIndex < 0) {
          state.workSchedules[foundIndex].schedule.push(shift)
        } else {
          state.workSchedules[foundIndex].schedule[singleShiftIndex] = shift
        }
      }
    },
    [addWorkScheduleArray.pending.type]: (state) => {
      state.isLoading = true
    },
    [addWorkScheduleArray.rejected.type]: (state, { payload }) => {
      toast.error(payload.response.data.message.message)
      state.error = true
      state.isLoading = false
    },
  },
})

export const { clearWorkSchedule } = workSchedulesSlice.actions

export default workSchedulesSlice.reducer
