import styled from 'styled-components'

interface ModalWrapperProps {
  noBack?: boolean
  black?: boolean
  zIndex?: string
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => props.zIndex ?? '9999999 !important'};
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colorGray};
  ${(props) => props.noBack && 'background:none;   backdrop-filter: blur(0px);'}
  ${(props) => props.black && 'background-color:rgba(255,255,255, 0.75);'}
  animation: opacityAnim .2s ease-in;

  @keyframes opacityAnim {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`
interface ModalContainerProps {
  noMaxHeight?: boolean
  maxWidth?: boolean
  width?: string
  height?: string
}
export const ModalContainer = styled.div<ModalContainerProps>`
  max-height: ${(props) => (!props.noMaxHeight ? '85vh' : 'none')};
  min-width: ${(props) => (props.maxWidth ? '' : '36.2rem')};
  max-width: ${(props) => props.maxWidth && '23.7rem'};
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
  height: ${(props) => (props.height ? props.height + 'px' : 'auto')};
  background: #ffffff;
  box-shadow: 0px 0px 1.8rem rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  ${(props) => props.width && 'width:' + props.width}
  
  @media (max-width: 1300px) {
    min-width: unset !important;
    max-width: 90vw;
  }

`

export const ModalTabs = styled.div`
  width: 100%;
`
interface ModalTabProps {
  active?: boolean
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  m?: string
}
export const ModalTab = styled.div<ModalTabProps>`
  border: none;
  width: 180px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? '#fff' : props.theme.colorOrange)};
  border: ${(props) => (props.active ? 'none' : '1px solid' + props.theme.colorOrange)};
  background: ${(props) => (props.active ? props.theme.colorOrange : '#fff')};
  border-radius: 10px;
  padding: 0 1rem;
  font-weight: 500;
  cursor: pointer;
  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  margin-left: ${(props) => props.ml}px;
  margin-right: ${(props) => props.mr}px;
`

export const ModalProgressContainer = styled.div`
  width: 8.75rem;
  height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;

  p {
    font-size: 0.6rem;
    font-weight: 500;
  }
`

export const ModalProgressBar = styled.div`
  width: 7.5rem;
  height: 1rem;
  display: flex;
  background: #c4c4c4;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
`
interface ModalProgressIndicator {
  width?: string
}
export const ModalProgressIndicator = styled.div<ModalProgressIndicator>`
  height: 100%;
  background-color: ${(props) => props.theme.colorOrange};
  width: ${(props) => props.width}%;
  border-radius: 10px;
`

export const ModalButtonsContainer = styled.div`
  bottom: 1.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  position: absolute;
  bottom: 1.25rem;
  left: 0;
`
