import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IGetAllLogicsSingleResponse, IGetAllTasksSingleResponse, ILogicPostRequest, ITasksPatchRequest, 
  ITasksPostRequest, ITasksSinleResponse, IWorkUnitAmountsPostRequest } from 'types/api/tasksApi.interface'
import {
  ITasksSlice,
  ITaskState,
  ITaskStateComponent,
  ITaskWComponent,
  ITaskWorkUnitAmount,
  IToolTypeComponent,
} from 'types/tasks.interface'
import { baseUrl } from 'utils/axios'
import { dataURItoBlob, handleErrors, formatParams } from 'utils/helpers'
import { v4 } from 'uuid'

const initialState:ITasksSlice = {
  isLoading: false,
  tasksLogics: [],
  createdId: null,
  singleTask: null,
  allTasks: [],
  totalPages: 0,
  totalCount: 0,
  noTasks: false,
}

export const getTasksLogics = createAsyncThunk('tasks/getTasksLogics', async (_, thunkAPI) => {
  try {
    const resp:AxiosResponse<IGetAllLogicsSingleResponse[]> = await baseUrl.get('tasks/logics')
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getToolComponents = createAsyncThunk(
  'tasks/getToolComponents',
  async (_, thunkAPI) => {
    try {
      const resp = await baseUrl.get('tasks/logics')
      const newObject = resp.data.map((item) => {
        item.input = item.input.map((i) => ({ ...i, id: v4() }))
        item.output = item.output.map((o) => ({ ...o, id: v4() }))
        return item
      })
      return newObject
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addTask = createAsyncThunk('tasks/addTask', async (task: ITaskState, thunkAPI) => { 
  try {
    const { image } = task
    const forSubmit: ITasksPostRequest = {
      operationTypeParameterValues:task.operationTypeParameterValues.map(p => {
        if (p.parameterId == 1) {
          return p
        }
        return { ...p, desiredValue: p.minimumValue }
      }),
      toolTypeComponents:task.toolTypeComponents.map((t:IToolTypeComponent) => ({
        toolTypeComponentId: t.component.value,
        name: t.name,
        unitId: t.component.label == 'Item' ? t.unit.value : null
      })),
      workerComponentFieldIds:task.workerComponents.map((c: ITaskWComponent) => c.id),
      name:task.name,
      operationTypeId:task.operationTypeId,
      components:task.components,
      shouldUseLocationTracking:task.shouldUseLocationTracking
    }

    const resp = await baseUrl.post('tasks', forSubmit)
    if (image) {
      const blobedImage = dataURItoBlob(image)
      try {
        await axios.put(resp.data.imagePresignedUploadUrl, blobedImage)
        return resp.data
      } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue(error)
      }
    }
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const duplicateTask = createAsyncThunk(
  'tasks/duplicateTask',
  async (nameId: { name: string; taskTemplateId: number }, thunkAPI) => {
    try {
      const resp = await baseUrl.post('tasks/duplicate', nameId)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getTasks = createAsyncThunk('tasks/getTasks', async (params: any, thunkAPI) => {
  try {
    params = formatParams(params)
    const resp: AxiosResponse<IPaginatedResponse<IGetAllTasksSingleResponse>> = await baseUrl.get('tasks/overviews', { params })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const editTask = createAsyncThunk('tasks/editTask', async (task: ITaskState, thunkAPI) => { // TODO Proveriti da li task koji stize je isti kao interface
  try {
    const { id, image } = task
    const forSubmit:ITasksPatchRequest = {
      operationTypeParameterValues:task.operationTypeParameterValues.map(p => {
        if (p.parameterId == 1) {
          return p
        }
        return { ...p, desiredValue: p.minimumValue }
      }),
      toolTypeComponents:task.toolTypeComponents.map((t:IToolTypeComponent) => ({
        toolTypeComponentId: t.component.value,
        name: t.name,
        unitId: t.component.label == 'Item' ? t.unit.value : null
      })),
      workerComponentFieldIds:task.workerComponents.map((c: ITaskWComponent) => c.id),
      name:task.name,
      operationTypeId:task.operationTypeId,
      shouldUseLocationTracking:task.shouldUseLocationTracking,
      components:task.components,
    }
    const resp = await baseUrl.patch('tasks/' + id, forSubmit)
    if (image) {
      const blobedImage = dataURItoBlob(image)
      try {
        await axios.put(resp.data.imagePresignedUploadUrl, blobedImage)
        return resp.data
      } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue(error)
      }
    }
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const addLogic = createAsyncThunk(
  'tasks/addLogic',
  async (logic: { id: string; components: ITaskStateComponent[] }, thunkAPI) => {
    try {
      const { id, components } = logic
      const forDispatch:ILogicPostRequest = {
        componentLogics: components.map((c) => ({
          calculatedTaskComponentId: c.id,
          functionId: c.functionId,
          functionResultIndex: c.functionResultIndex,
          inputParameters: c.inputParameters,
        })),
      }
      const resp = await baseUrl.post('tasks/' + id + '/logic', forDispatch)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addWorkUnitAmounts = createAsyncThunk(
  'tasks/addWorkUnitAmounts',
  async (workUnitsAmounts: IWorkUnitAmountsPostRequest, thunkAPI) => {
    try {
      const resp = await baseUrl.post('tasks/work-unit-amount-per-ranges', workUnitsAmounts)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleTask = createAsyncThunk(
  'tasks/geSingleTask',
  async (id: number, thunkAPI) => {
    try {
      const resp:AxiosResponse<ITasksSinleResponse> = await baseUrl.get('tasks/' + id)

      const opType = resp.data.operationType
      const param = opType.operationTypeParameterValues
      const taskObejct:ITaskState = {
        image: null,
        imageRaw:null,
        logic:[],
        name: resp.data.name,
        operationTypeId: opType.operationTypeId,
        operationTypeParameterValues: param.map((item) => ({
          operationTypeParameterId: item.operationTypeParameterId,
          desiredValue: item.desiredValue,
          minimumValue: item.minimumValue,
          parameterId: item.parameterId,
        })),
        components: resp.data.components,
        workerComponents: resp.data.workerComponents.map((wc) => ({
          id: wc.field.id,
          name: wc.field.name,
        })),
        workUnitAmounts: resp.data.workUnitAmounts.map((item: ITaskWorkUnitAmount, id: number) => ({
          ...item,
          id,
        })),
        selectedOperation: {
          id: opType.operationId,
          name: opType.operationTypeName,
          types: [
            {
              id: opType.operationTypeId,
              name: opType.operationTypeName,
              parameters: param.map((item) => ({
                id: item.operationTypeParameterId,
                name: item.parameterName,
                parameterId: item.parameterId,
                unit: item.unitName,
                unitId: item.unitId,
              })),
            },
          ],
        },
        toolTypeComponents: resp.data.toolTypeComponents.map((i) => ({
          component: {
            label: i.toolTypeComponent.name,
            value: i.toolTypeComponent.id,
          },
          name: i.name,
          unit: i.unit
            ? {
                label: i.unit.name,
                value: i.unit.id,
              }
            : null,
        })),
        shouldUseLocationTracking: resp.data.shouldUseLocationTracking,
      }

      return taskObejct
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteTask = createAsyncThunk('tasks/deleteTask', async (id: number, thunkAPI) => {
  try {
    await baseUrl.delete('tasks/' + id, thunkAPI)
    return id
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getSimpleTasks = createAsyncThunk('tasks/getSimpleTasks', async (_, thunkAPI) => {
  try {
    const resp :AxiosResponse<IPaginatedResponse<IGetAllTasksSingleResponse>> = await baseUrl.get('/tasks/simple', thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    clearTask: (state) => {
      state.createdId = null
      state.singleTask = null
      state.tasksLogics = []
    },
    clearTasksList: (state) => {
      state.allTasks = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getTasksLogics.pending.type]: (state) => {
      state.isLoading = true
    },
    [getTasksLogics.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.tasksLogics = payload
      state.noTasks = state.allTasks.length < 1
    },
    [getTasksLogics.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addTask.pending.type]: (state) => {
      state.isLoading = true
    },
    [addTask.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.createdId = payload.id
      state.noTasks = false
      toast.success('Task successfully created')
    },
    [addTask.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [editTask.pending.type]: (state) => {
      state.isLoading = true
    },
    [editTask.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleTask.components = payload.components
      toast.success('Task updated successfully')
    },
    [editTask.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addLogic.pending.type]: (state) => {
      state.isLoading = true
    },
    [addLogic.fulfilled.type]: (state) => {
      state.isLoading = false
      toast.success('Logic successfully saved')
    },
    [addLogic.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [duplicateTask.pending.type]: (state) => {
      state.isLoading = true
    },
    [duplicateTask.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      toast.success('Task successfully duplicated')
      if (state.allTasks.length < 5) {
        state.allTasks.push(payload)
      }
    },
    [duplicateTask.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addWorkUnitAmounts.pending.type]: (state) => {
      state.isLoading = true
    },

    [addWorkUnitAmounts.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleTask.workUnitAmounts = payload
      toast.success('Work unit amounts successfully saved')
    },
    [addWorkUnitAmounts.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleTask.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleTask.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleTask = payload
    },
    [getSingleTask.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getTasks.pending.type]: (state) => {
      state.isLoading = true
    },
    [getTasks.fulfilled.type]: (state:ITasksSlice, { payload }:{payload:IPaginatedResponse<IGetAllTasksSingleResponse>}) => {
      state.isLoading = false
      state.allTasks = payload.data
      state.noTasks = payload.data.length < 1
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
    },
    [getTasks.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteTask.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteTask.fulfilled.type]: (state: ITasksSlice, { payload }) => {
      state.isLoading = false
      toast.success('Task deleted')
      state.allTasks = state.allTasks.filter((item) => item.id !== payload)
    },
    [deleteTask.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSimpleTasks.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSimpleTasks.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.allTasks = payload
    },
    [getSimpleTasks.rejected.type]: (state) => {
      state.isLoading = false
    },
  },
})

export const { clearTask, clearTasksList } = tasksSlice.actions
export default tasksSlice.reducer
