import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { INameId } from 'constants/globalTypes'
import { Type27Options } from 'pages/ProcessTemplates/processTemplateData'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IProcessTemplatesPostObject, IProcessTemplatesSingleResponse } from 'types/api/processTemplatesApi.interface'
import {
  IProcessTemplatesSliceState,
  IProcessTemplatesState,
} from 'types/processTemplates'
import { handleErrors, formatParams } from 'utils/helpers'
import { baseUrl } from '../../utils/axios'

const initialState: IProcessTemplatesSliceState = {
  isLoading: false,
  processTemplates: [],
  totalCount: 0,
  totalPages: 0,
  singleProcessTemplate: null,
  noProcessTemplates: false,
}

export const getProcessTemplates = createAsyncThunk(
  'processTemplates/getProcessTemplates',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<INameId> = await baseUrl.get('/process/template', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleProcessTemplate = createAsyncThunk(
  'processTemplates/getSingleProcessTemplate',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IProcessTemplatesSingleResponse> = await baseUrl.get('/process/template/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addProcessTemplate = createAsyncThunk(
  'processTemplates/addProcessTemplate',
  async (processTemplate: IProcessTemplatesState, thunkAPI) => {
    try {
      const formatedObject: IProcessTemplatesPostObject = {
        name: processTemplate.name,
        processFunctionId: processTemplate.processFunction.pfid,
        companyIds: processTemplate.companies.map((c) => c.id),
        components: [
          ...processTemplate.processFunction.inputs.map((i) => ({
            name: i.componentGivenName,
            component: i.component,
            componentType: 1,
            index: i.index,
          })),
          ...processTemplate.processFunction.outputs.map((o) => ({
            name: o.componentGivenName,
            component: o.component,
            componentType: 2,
            index: o.index,
          })),
          ...processTemplate.processFunction.displayInputs.map((d) => ({
            name: d.name,
            component: d.component,
            componentType: 3,
            index: d.index,
            value:d.value.value
          })),
        ],
        stepsData: processTemplate.processFunction.steps.map((s) => ({
          id: s.id,
          operationTypeId: s.operationType.value,
        })),
      }

      const response = await baseUrl.post('/process/template', formatedObject)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

// export const editProcessTem = createAsyncThunk(
//   'workflows/editWorkflow',
//   async (workflow: IWorkflow, thunkAPI) => {
//     try {
//       const response = await baseUrl.patch('/workflow/template/' + workflow.id, workflow)
//       return response.data
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error)
//     }
//   },
// )

export const deleteProcessTemplate = createAsyncThunk(
  'processTemplates/deleteProcessTemplate',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('/process/template/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const processTemplatesSlice = createSlice({
  name: 'processTemplates',
  initialState,
  reducers: {
    clearProcesTemplatesList: (state) => {
      state.processTemplates = []
      state.totalCount = 0
      state.totalPages = 0
    },
    clearSingleProcessTemplate: (state) => {
      state.singleProcessTemplate = null
    },
  },
  extraReducers: {
    [getProcessTemplates.pending.type]: (state) => {
      state.isLoading = true
    },
    [getProcessTemplates.fulfilled.type]: (state, { payload }: { payload: IPaginatedResponse<INameId> }) => {
      state.isLoading = false
      state.processTemplates = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noProcessTemplates = payload.data.length < 1
    },
    [getProcessTemplates.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleProcessTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleProcessTemplate.fulfilled.type]: (
      state,
      { payload }: { payload: IProcessTemplatesSingleResponse },
    ) => {
      state.singleProcessTemplate = {
        name: payload.name,
        companies: payload.companies.map((c) => c.company),
        processFunction: {
          ...payload.functionConfiguration,
          inputs:payload.functionConfiguration.inputs.map(i=>({...i, componentGivenName:payload.components.find(c=>c.componentType==1 && c.component == i.component && c.index == i.index)?.name})),
          outputs:payload.functionConfiguration.outputs.map(o=>({...o, componentGivenName:payload.components.find(c=>c.componentType==2 && c.component == o.component)?.name})),
          displayInputs:payload.functionConfiguration.displayInputs.map(d=>({...d, value:Type27Options.find(t=>t.value == payload.components.find(c=>c.componentType==3 && c.component == d.component).value)})),
          steps: payload.stepsData.map((d) => ({
            id: d.id,
            name: d.name,
            operationType: { value: d.operationType.id, label: d.operationType.name },
          })),
        },
      }
      state.isLoading = false
    },
    [getSingleProcessTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addProcessTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [addProcessTemplate.fulfilled.type]: (state, { payload }:{payload:INameId}) => {
      state.isLoading = false
      if (state.processTemplates.length < 30) {
        state.processTemplates.push({ id: payload.id, name: payload.name })
      }
      state.noProcessTemplates = false
      toast.success('Process template successfully added.')
    },
    [addProcessTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    // [editWorkflow.pending.type]: (state) => {
    //   state.isLoading = true
    // },
    // [editWorkflow.fulfilled.type]: (state, { payload }) => {
    //   state.isLoading = false
    //   if (state.workflows.length < 30) {
    //     state.workflows.push({ id: payload.id, name: payload.name })
    //   }
    //   state.noWorkflows = false
    //   toast.success('Workflow successfully updated.')
    // },
    // [editWorkflow.rejected.type]: (state, { payload }) => {
    //   state.isLoading = false
    //   handleErrors(payload)
    // },
    [deleteProcessTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteProcessTemplate.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.processTemplates = state.processTemplates.filter((w) => w.id !== payload)
      state.noProcessTemplates = state.processTemplates.length < 1
      toast.success('Process template successfully deleted.')
    },
    [deleteProcessTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearProcesTemplatesList, clearSingleProcessTemplate } =
  processTemplatesSlice.actions
export default processTemplatesSlice.reducer
