/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react'
import {
  Title,
  Button,
  Animation,
  TabFooter,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
} from '../../../styling/GlobalStyles'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { CancelBtn } from 'components/Button/CancelBtn'
import { getClientMachinery } from 'features/clientMachinery/clientMachinerySlice'
import { CollapseItemMachineryItems } from './CollapseItemMachineryItems'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IMachineModelSimple } from 'types/machineModels.interface'
import { IInventoryItem, IItemsTab } from 'types/inventory.interface'

export const InventoryItemsMachineryTab = ({ state, setState, handleSubmit }: IItemsTab) => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getClientMachinery(null))
  }, [])

  const handleAdd = (item: IMachineModelSimple) => {
    if (!state.machineModels.some((i) => i.id == item.id)) {
      const parsedItem = {
        id: item.id,
        priority: 0,
        rating: 0,
        name: item.name,
      }
      setState((prev: IInventoryItem) => {
        const copy = { ...prev }
        copy.machineModels = [...copy.machineModels, parsedItem]
        return copy
      })
    } else {
      setState((prev: IInventoryItem) => {
        const copy = { ...prev }
        copy.machineModels = copy.machineModels.filter((m) => m.id !== item.id)
        return copy
      })
    }
  }


  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Machines</Title>
          {state.machineModels.map((item) => {
            return <CollapseItemMachineryItems setState={setState} key={item.id} data={item} />
          })}
        </SelectedItemsContainer>
        <ItemsPick
          placeholder='Search machines'
          handleAdd={handleAdd}
          link='machines'
          selectedList={state.machineModels}
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}