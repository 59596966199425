import { baseUrl } from './../../utils/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { handleErrors, formatParams } from 'utils/helpers'
import { ISingleReport } from 'pages/Reports/SingleReport'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IGetAllReportsSingleResponse, IReportsSingleResponse } from 'types/api/reportsApi.interface'

const initialState: IReportsSlice = {
  isLoading: false,
  reports: [],
  noReports: true,
  totalCount: 0,
  totalPages: 0,
  singleReport: null,
}

interface IReportsSlice {
  isLoading: boolean
  reports: { id: number; date: string }[]
  noReports: boolean
  totalCount: number
  totalPages: number
  singleReport: ISingleReport
}

export const getReports = createAsyncThunk('reports/getReports', async (params: any, thunkAPI) => {
  try {
    params = formatParams(params)
    const resp:AxiosResponse<IPaginatedResponse<IGetAllReportsSingleResponse>> = await baseUrl.get('report/daily', { params })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getSingleReport = createAsyncThunk(
  'reports/getSingleReport',
  async (id: number, thunkAPI) => {
    try {
      const resp:AxiosResponse<IReportsSingleResponse> = await baseUrl.get('report/daily/' + id, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearReportsList: (state) => {
      state.reports = []
      state.totalCount = 0
      state.totalPages = 0
    },

    clearSingleReport: (state) => {
      state.singleReport = null
    },
  },
  extraReducers: {
    [getReports.pending.type]: (state) => {
      state.isLoading = true
    },
    [getReports.fulfilled.type]: (state:IReportsSlice, { payload }:{payload:IPaginatedResponse<IGetAllReportsSingleResponse>}) => {
      state.isLoading = false
      state.reports = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noReports = state.reports.length < 1
    },
    [getReports.rejected.type]: (state) => {
      state.isLoading = false
    },

    [getSingleReport.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleReport.fulfilled.type]: (state:IReportsSlice, { payload }:{payload:IReportsSingleResponse}) => {
      state.isLoading = false
      state.singleReport = payload
    },
    [getSingleReport.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default reportsSlice.reducer
export const { clearReportsList, clearSingleReport } = reportsSlice.actions
