import Check from 'assets/icons/Check'
import { CloseIcon } from 'assets/icons/CloseIcon'
import { Flex } from 'styling/GlobalStyles'
import { InputWrapper, Label } from './Input.styled'
import './toggleButton.css'

interface IToggleButton {
  state: any
  handleChange: any
  label?: string
  name?: string
  maxWidth?: boolean
  start?: boolean
}
export const ToggleButton = ({ state, handleChange, label, name, maxWidth, start }: IToggleButton) => {
  let cName = 'toggle-orange'
  if (state) {
    cName += ' toggled'
  }

  return (
    <InputWrapper nomarg end={!start}>
      <Flex width={maxWidth ? '100%' : null} height='100%' column>
        {label && <Label labelText={label} />}
        <div className='toggle-wrapper' onClick={() => handleChange(name ?? !state)}>
          <div className={cName}>
            <div className='toggle-true-icon'>
              <Check valid />
            </div>
            <div className='toggle-false-icon'>
              <CloseIcon />
            </div>
          </div>
        </div>
      </Flex>
    </InputWrapper>
  )
}
