import { AddCircle } from 'assets/icons/AddCircle'
import React from 'react'
import styled from 'styled-components'

export const AddOrangeBtn = ({ onClick }) => {
  return (
    <BtnStyle tabIndex={1} onClick={onClick}>
      <AddCircle white />
    </BtnStyle>
  )
}

const BtnStyle = styled.div`
  background-color: ${({ theme }) => theme.colorOrange};
  height: fit-content;
  width: fit-content;
  padding: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    filter: brightness(115%);
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(3px);
  }
`
