import { useDrop, useDrag } from 'react-dnd'
import { v4 } from 'uuid'
import { IDropItem, ISingleWorkflowTask } from 'types/workflows.interface'
/*eslint-disable*/

export const SingleWorkflowTask = ({ state, setState, task, id, move, linkType }: ISingleWorkflowTask) => {
  const identity = id.split('_')
  const level = parseInt(identity[0].split('-')[1])
  const index = parseInt(identity[1].split('-')[1])
  const handleDrop = (item: IDropItem) => {
    setState((prev) => {
      const copy = structuredClone(prev)
      const levelItems = copy.items.filter((i) => i.level == level + 1).length
      if (!item.taskComponent) {
        copy.items.push({
          taskTemplateId: item.id,
          name: item.name,
          level: level + 1,
          index: levelItems,
          parentItems: [
            {
              level,
              index,
              linkType
            },
          ],
        })
      } else if (item.taskComponent && item.taskComponent.level > level) {
        const itemIndex = copy.items.findIndex(
          (i) => i.index == item.taskComponent.index && i.level == item.taskComponent.level,
        )
        copy.items[itemIndex].parentItems.push({ level, index, linkType })
      }
      return copy
    })
  }

  let [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'item',
      drop: handleDrop,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [state, linkType],
  )

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'item',
    item: { name: task.name, id: task.taskTemplateId, taskComponent: { level, index, id, linkType } },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  let cName = 'wf-task'
  if (task.level === 0) {
    cName += ' wf-main'
  }
  return (
    <div
      ref={level < 1 ? null : drag}
      style={{
        opacity: isDragging ? '0' : '1',
      }}
    >
      <div ref={drop} className={cName} id={id} style={{ marginTop: move * 6 + 'rem' }} key={v4()}>
        {task.name}
        {isOver && <div className='wf-drop-cover'>Drop here</div>}
      </div>
    </div>
  )
}
