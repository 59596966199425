import React from 'react'
import {
  Title,
  TabFooter,
  Button,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
  Animation,
} from 'styling/GlobalStyles'
import { IDepartmentsState, IDepratmentsTab } from 'types/departments.interface'
import { ITask } from 'types/tasks.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { IGetAllTasksSingleResponse } from 'types/api/tasksApi.interface'

export const DepartmentTasksTab = ({ state, setState, handleSubmit }: IDepratmentsTab) => {
  const handleAdd = (item: IGetAllTasksSingleResponse) => {
    setState((prev: IDepartmentsState) => {
      const copy = { ...prev }
      if (copy.tasks.some((t) => t.id == item.id)) {
        copy.tasks = copy.tasks.filter((t) => t.id !== item.id)
      } else {
        copy.tasks = [...copy.tasks, { id: item.id, name: item.name }]
      }
      return copy
    })
  }

  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Tasks</Title>
          {state.tasks.map((item: ITask) => {
            return <SelectedItem setState={setState} key={item.id} data={item} type='tasks' />
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='tasks/overviews'
          selectedList={state.tasks}
          placeholder='Search tasks'
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}

