import { NavBar } from 'components/NavBar/NavBar'
import React, { useEffect, useState } from 'react'
import {
  ContentWrapper,
  Flex,
  H1WithBack,
  PageWrapper,
} from 'styling/GlobalStyles'
import { MapTab } from './MapTab'
import { ICreating } from 'types/zones.interface'
import { useDispatch } from 'react-redux'
import {
  addBlock,
  addPit,
  addSection,
  addZone,
  deleteBlock,
  deletePit,
  deleteSection,
  deleteZone,
  getZones,
  updateBlock,
  updatePit,
  updateSection,
  updateZone,
} from 'features/zones/zonesSlice'
import { AppDispatch } from 'store'
import { TypeOfAreas } from './zoneConstants'

export const Zones: React.FC = () => {
  const [creating, setCreating] = useState<ICreating>({ path: [], areaName: '', areaSize: null })

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getZones())
  }, [])


  const handleSubmit = () => {
    switch (creating.type) {
      case TypeOfAreas.zones:
        return dispatch(creating.updating ? updateZone(creating) : addZone(creating))
      case TypeOfAreas.sections:
        return dispatch(creating.updating ? updateSection(creating) : addSection(creating))
      case TypeOfAreas.blocks:
        return dispatch(creating.updating ? updateBlock(creating) : addBlock(creating))
      default:
        return dispatch(creating.updating ? updatePit(creating) : addPit(creating))
    }
  }
  const handleDelete = async (type: number) => {
    switch (type) {
      case TypeOfAreas.zones:
        return dispatch(deleteZone(creating.id))
      case TypeOfAreas.sections:
        return dispatch(deleteSection(creating.id))
      case TypeOfAreas.blocks:
        return dispatch(deleteBlock(creating.id))
      default:
        return dispatch(deletePit(creating.id))
    }
  }



  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title='Zones' />
        </Flex>
        <ContentWrapper map>
          <MapTab
            creating={creating}
            setCreating={setCreating}
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
          />
        </ContentWrapper>
      </PageWrapper>
    </>
  )
}
