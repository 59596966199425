import React, { useState } from 'react'
import {
    Button,
    Flex,
    MainHeadline,
    PageWrapper,
    Separator,
    PageFooter,
    GridWrapper,
    ItemsGrid,
    GridItem,
} from '../../styling/GlobalStyles'
import { NavBar } from '../../components/NavBar/NavBar'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { Pagination } from 'components/Pagination/Pagination'
import { getParams, handlePagination } from 'utils/helpers'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { setConfirmModal } from 'features/global/globalSlice'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { ModalTypes } from 'types/global.interface'
import { clearCycles, deleteCycle, getCycles } from 'features/cycles/cyclesSlice'
import { INameId } from 'constants/globalTypes'
import useQueryPagination from 'hooks/useQueryPagination'

export const Cycles = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const { cycles, totalPages, noCycles } = useSelector((state: RootState) => state.cycles)
    const [params, setParams] = useState<any>(getParams({ location }))

    useQueryPagination({ fetchFunctions: [getCycles], cleanUpFunctions: [clearCycles], params })

    const handleDeleteCycle = (id: number) => {
        dispatch(
            setConfirmModal({
                isOpen: true,
                onSave: async () => {
                    await dispatch(deleteCycle(id))
                },
                setParams: {
                    type: ModalTypes.Delete,
                    f: setParams,
                    noItems: cycles.length < 2,
                },
                word: 'delete cycle',
            }),
        )
    }

    return (
        <>
            <NavBar defaultValue={params.q} input placeholder='Search Cycles...' setParams={setParams} limit={30} />
            <PageWrapper>
                <Flex between center>
                    <MainHeadline>Cycles</MainHeadline>
                    <Button orange onClick={() => navigate('add')}>
                        Add Cycle
                    </Button>
                </Flex>
                <Separator />
                {false ? (
                    <NoItems title='Workflows' />
                ) : (
                    <GridWrapper>
                        <ItemsGrid columns={3}>
                            {!noCycles ? (
                                cycles.map((c: INameId) => {
                                    return (
                                        <GridItem key={c.id}>
                                            <p onClick={() => navigate('/cycles/' + c.id)}>{c.name}</p>
                                            <Flex center justcenter onClick={() => handleDeleteCycle(c.id)}>
                                                <RemoveCircle />
                                            </Flex>
                                        </GridItem>
                                    )
                                })
                            ) : (
                                <NoItems title='Cycles' />
                            )}
                        </ItemsGrid>
                    </GridWrapper>
                )}
            </PageWrapper>
            <PageFooter>
                <Pagination
                    setPage={(page) => handlePagination(page, setParams, 5)}
                    pageCount={Math.ceil(totalPages)}
                    forcePage={(params.page ? params.page : 1) - 1}
                />
            </PageFooter>
        </>
    )
}
