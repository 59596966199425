import React from 'react'
import {
  Animation,
  Title,
  Button,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
  TabFooter,
} from 'styling/GlobalStyles'
import { IDepartmentsState, IDepratmentsTab } from 'types/departments.interface'
import { ILocation } from 'types/locations.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'

export const DepartmentLocationsTab = ({ state, setState, handleSubmit }: IDepratmentsTab) => {
  const handleAdd = (item: ILocation) => {
    setState((prev: IDepartmentsState) => {
      const copy = { ...prev }
      if (copy.locations.some((l) => l.id === item.id)) {
        copy.locations = copy.locations.filter((l) => l.id !== item.id)
      } else {
        copy.locations = [...copy.locations, { id: item.id, name: item.name }]
      }
      return copy
    })
  }
  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Locations</Title>
          {state.locations.map((item) => {
            return <SelectedItem setState={setState} key={item.id} data={item} type='locations' />
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='locations'
          selectedList={state.locations}
          placeholder='Search locations'
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>

  )
}
