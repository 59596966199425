import React, { SetStateAction } from 'react'
import { Input } from 'components/Input/Input'
import { NavBar } from 'components/NavBar/NavBar'
import { Animation, Button, Flex, ItemsGrid, TabFooter } from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { ISingleIssue } from 'types/issues.interface'

export const IssueInfo = ({ state, setState, handleSubmit }: IIssueInfoTab) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement
    setState({ ...state, [name]: value })
  }
  const handleSelect = (value: ISelectValue) => {
    setState((prev) => {
      const copy = { ...prev }
      copy.industry = { id: value.value, name: value.label }
      return copy
    })
  }

  const parseIndustries = (data: any) => {
    return data.data.map((item: any) => ({ label: item.name, value: item.id }))
  }

  return (
    <>
      <NavBar />
      <Animation>
        <ItemsGrid columns={2} width='50%'>
          <Input
            value={state.name}
            labelText='Issue Title'
            onChange={handleChange}
            name='name'
            placeholder='Enter name'
            invalid={state.name.length < 2}
          />
          <HonestSelectAsyncInput
            link='industries'
            placeholder='Search industries'
            name='industryId'
            labelText='Industry'
            defaultValue={state.industry.name}
            onChange={(type: ISelectValue) => handleSelect(type)}
            parserFunction={parseIndustries}
            invalid={!state.industry.id}
          />
        </ItemsGrid>
        <TabFooter>
          <Flex end='true' gap='1em'>
            <CancelBtn />
            <Button orange type='button' onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        </TabFooter>
      </Animation>
    </>
  )
}

interface IIssueInfoTab {
  state: ISingleIssue
  setState: React.Dispatch<SetStateAction<ISingleIssue>>
  handleSubmit: () => void
}
