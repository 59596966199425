import React, { useState } from 'react'
import { BlackCircle, Collapsable, Flex, ItemsGrid } from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { rating } from 'pages/Employees/dummyList'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { CollapseItemText } from 'components/CollapseItem/CollapseItem.styled'
import Chevron from 'assets/icons/Chevron'
import { ISelectValue } from 'constants/globalTypes'
import { IRole } from 'types/roles.interface'

export const CollapseItemMachine = ({ data, setState, nonModel }: { data: any, setState: any, nonModel?: boolean }) => { //  TODO: komponenta se kristi i kod rola i kod employees, razdvojiti i napisati tipove
  const [expanded, setExpanded] = useState(true)
  const prop = nonModel ? 'machines' : 'machineModels'
  const handleRemove = () => {
    setState((prev: IRole) => {
      const copy = { ...prev }
      copy[prop] = copy[prop].filter((item) => item.id !== data.id)
      return copy
    })
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    setState((prev: IRole) => {
      const copy = structuredClone(prev)
      const objIndex = copy[prop].findIndex((obj) => obj.id === data.id)
      copy[prop][objIndex][name] = Number(value.value)
      return copy
    })
  }

  return (
    <Collapsable expanded={expanded}>
      <Flex mb='20'>
        <CollapseItemText expanded={expanded} onClick={() => setExpanded((prev) => !prev)}>
          {data.name || data.model + ' ' + data.make} <Chevron gray />
        </CollapseItemText>
        <BlackCircle onClick={handleRemove}>
          <RemoveCircle white />
        </BlackCircle>
      </Flex>
      <>
        <ItemsGrid mb='1rem' mt='10px' columns={4} colgap='1em' gap='1em'>
          <HonestSelectInput
            nomarg
            name='rating'
            labelText='Performance'
            defaultValue={String(data.rating)}
            onChange={(value, name) => handleSelect(value as ISelectValue, name)}
            options={rating}
          />
          <HonestSelectInput
            nomarg
            name='priority'
            labelText='Priority'
            defaultValue={String(data.priority)}
            onChange={(value, name) => handleSelect(value as ISelectValue, name)}
            options={rating}
          />
        </ItemsGrid>
      </>
    </Collapsable>
  )
}

