import React from 'react'
import styled from 'styled-components'
import { SeparatorVertical } from '../../styling/GlobalStyles'

export const CardInGraph = styled.div`
  :hover {
    transform: scale(1.05);
  }
  width: 100%;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  box-shadow: 0.5rem 1.25rem 2.8rem rgba(7, 6, 61, 0.05);
  border-radius: 8.3px;
  padding: 0.9rem 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colorGray};
  p {
    font-weight: 700;
    font-size: 0.7rem;
    line-height: 0.9rem;
    text-transform: uppercase;
  }
  span {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 100%;
    color: ${(props) => props.theme.colorOrange};
  }
  @media (max-width: 1300px) {
    h3 {
      font-size: 0.8rem;
    }
    strong {
      font-size: 1.6rem;
    }
    h4 {
      font-size: 0.7rem;
    }
    p {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 1100px) {
    h3 {
      font-size: 0.8rem;
    }
  }
`

export const TotalMachines = ({ num }) => {
  return (
    <CardInGraph>
      <div>
        <p>Total</p>
        <p>Machines</p>
      </div>
      <SeparatorVertical />
      <span>{num}</span>
    </CardInGraph>
  )
}
export const MachineContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-radius: 8.3px;
  row-gap: 2rem;
  column-gap: 1rem;
  margin-bottom: 2rem;
  span {
    font-weight: 700;

    font-size: 2.5rem;
    line-height: 100%;
    color: ${(props) => props.theme.colorGray};
  }
  @media (max-width: 1300px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
`
export const BorderedFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 90%;
  border-left: 1px solid ${(props) => props.theme.colorGray}30;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 25%;
`
