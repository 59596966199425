import React from 'react'
import { Input } from 'components/Input/Input'
import { Animation, Button, Flex, TabFooter } from 'styling/GlobalStyles'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { useNavigate } from 'react-router-dom'
import { ISelectValue } from 'constants/globalTypes'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { hasValue, standardFieldValidation, validateSelect } from 'utils/validationUtils'
import { nextTaskTypes, taskTypes } from './constants'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { ITaskTriggersTab } from 'types/task-triggers.interface'
export const TaskTriggersBasicInfo = ({
  state,
  setState,
  handleSubmit,
  invalidFields,
  setInvalidFields,
}: ITaskTriggersBasicInfo) => {
  const navigate = useNavigate()

  useFocusFirstInput()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'minutes') {
      const { name, value } = e.target
      hasValue(value, name, setInvalidFields)
    } else {
      standardFieldValidation(e, setInvalidFields)
    }
    const { name, value } = e.target as HTMLInputElement
    setState({ ...state, [name]: value })
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    validateSelect(value, name, setInvalidFields)
    setInvalidFields((prev: string[]) => {
      let copy = [...prev]
      if (name === 'nextTaskType' && value.value === 1) {
        copy = [...copy, 'nextTaskTemplate']
        copy = copy.filter((f) => !f.includes('nextWorkflowTemplate'))
      } else if (name === 'nextTaskType' && value.value === 2) {
        copy = [...copy, 'nextWorkflowTemplate']
        copy = copy.filter((f) => !f.includes('nextTaskTemplate'))
      }
      if (name === 'type' && value.value == 2) {
        copy = [...copy, 'minutes']
      } else {
        copy = copy.filter((f) => !f.includes('minutes'))
      }
      return copy
    })

    let obj: any = { id: value.value, name: value.label }
    if (name === 'type') {
      obj = value.value
    }
    setState({ ...state, [name]: obj })
  }

  const parseTasks = (data: any) => {
    return data ? data.data.map((item: any) => ({ label: item.name, value: item.id })) : []
  }

  return (
    <Animation>
      <Flex column width='30%'>
        <Input
          type='text'
          name='name'
          labelText='Trigger title'
          value={state.name}
          onChange={handleChange}
          placeholder='Enter Trigger Title'
          invalid={invalidFields.includes('name') ? 'true' : ''}
        />
        <HonestSelectInput
          defaultValue={state.type}
          name='type'
          options={taskTypes}
          labelText='Task Type'
          onChange={(value: ISelectValue, name: string) => handleSelect(value, name)}
          invalid={invalidFields.includes('type')}
        />
        {state.type == 2 && (
          <Input
            type='number'
            name='minutes'
            labelText='Minutes'
            value={state.minutes}
            onChange={handleChange}
            placeholder='Enter Trigger Minutes'
            invalid={invalidFields.includes('minutes') ? 'true' : ''}
          />
        )}

        <HonestSelectAsyncInput
          defaultValue={state.taskTemplate?.name}
          link='tasks/overviews'
          labelText='Task Event'
          onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
          name='taskTemplate'
          placeholder='Search Task Templates'
          parserFunction={parseTasks}
          invalid={invalidFields.includes('taskTemplate')}
        />
        <HonestSelectInput
          defaultValue={state.nextTaskType?.id ? state.nextTaskType?.id : state.nextTaskType}
          name='nextTaskType'
          options={nextTaskTypes}
          labelText='Next Task Type'
          onChange={(value: ISelectValue, name: string) => handleSelect(value, name)}
          invalid={invalidFields.includes('nextTaskType')}
        />
        {state.nextTaskType?.id == 1 ? (
          <HonestSelectAsyncInput
            defaultValue={state.nextTaskTemplate?.name}
            link='tasks/overviews'
            labelText='Next Task Event'
            onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
            name='nextTaskTemplate'
            placeholder='Search Task Templates'
            parserFunction={parseTasks}
            invalid={invalidFields.includes('nextTaskTemplate')}
            keyProp={1}
          />
        ) : (
          <HonestSelectAsyncInput
            defaultValue={state.nextWorkflowTemplate?.name}
            link='workflow/template'
            labelText='Next Workflow Event'
            onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
            name='nextWorkflowTemplate'
            placeholder='Search Workflow Templates'
            parserFunction={parseTasks}
            invalid={invalidFields.includes('nextWorkflowTemplate')}
            keyProp={2}
          />
        )}
      </Flex>
      <TabFooter>
        <Flex gap='1rem'>
          <Button type='button' onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button type='button' onClick={handleSubmit} orange>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>
  )
}

interface ITaskTriggersBasicInfo extends ITaskTriggersTab {
  invalidFields: string[]
  setInvalidFields: React.Dispatch<React.SetStateAction<string[]>>
}