import React, { useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import { useNavigate } from 'react-router-dom'
import {
  PageWrapper,
  Flex,
  MainHeadline,
  Button,
  Separator,
  ItemsGrid,
  GridItem,
  PageFooter,
} from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { Pagination } from 'components/Pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import {
  clearIndustriesList,
  deleteIndustry,
  getIndustries,
} from 'features/industries/industriesSlice'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { setConfirmModal } from 'features/global/globalSlice'
import { getParams, handlePagination } from 'utils/helpers'
import { ITableItem, ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const Industries = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { industries, noIndustries, totalPages } = useSelector((store: RootState) => store.industries)
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location }))

  useQueryPagination({ fetchFunctions: [getIndustries], cleanUpFunctions: [clearIndustriesList], params })

  const removeIndustry = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteIndustry(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: industries.length < 2,
        },
        word: 'delete industry',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Industry...' setParams={setParams} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Industries</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add Industry
          </Button>
        </Flex>
        <Separator />
        <ItemsGrid columns={3} mt='25px'>
          {!noIndustries ? (
            industries?.map((item: ITableItem) => {
              const { name, id } = item
              return (
                <GridItem key={id}>
                  <p
                    onClick={() => {
                      navigate('/industries/' + id.toString() + '/edit')
                    }}
                  >
                    {name}
                  </p>

                  <Flex between center onClick={() => removeIndustry(id)}>
                    <RemoveCircle />
                  </Flex>
                </GridItem>
              )
            })
          ) : (
            <NoItems title='Industries' />
          )}
        </ItemsGrid>
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
