
import React, { useEffect } from 'react'
import { Animation, Button, ButtonsContainer, Flex, H2, TabFooter } from '../../../styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { ToggleButton } from 'components/Input/ToggleButton'
import { IRolesTab } from 'types/roles.interface'

export const PermissionsRolesTab = ({ state, setState, handleSubmit }: IRolesTab) => {

    const handleEnter = (e: KeyboardEvent) => {
        if (e.keyCode === 13) {
            handleSubmit()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleEnter)

        return () => {
            window.removeEventListener('keydown', handleEnter)
        }
    })

    useFocusFirstInput()

    const handleToggle = (name: string) => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.permissions[name] = !copy.permissions[name]
            return copy
        })
    }

    return (
        <Animation>
            <Flex width={screen.availWidth < 500 ? '100%' : '40%'} column gap="1rem" >
                {state?.permissions && Object.keys(state?.permissions).map((p, i) => <Flex key={i} width='100%' between  >
                    <H2>{p.toUpperCase()}</H2>
                    <ToggleButton
                        state={state.permissions[p] || false}
                        handleChange={handleToggle}
                        name={p}
                    />
                </Flex>)}
            </Flex>
            <TabFooter>
                <ButtonsContainer>
                    <CancelBtn />
                    <Button orange type='button' onClick={handleSubmit}>
                        Save
                    </Button>
                </ButtonsContainer>
            </TabFooter>
        </Animation>

    )
}