import React, { useEffect, useState } from 'react'
import {
    PageWrapper,
    Flex,
    H1WithBack,
    TabsContainer,
    Tabs,
    ContentWrapper,
} from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { ProcessTemplatesSpecificationTab } from './ProcessTemplatesSpecificationTab'
import { ProcessTemplatesLogicTab } from './ProcessTemplatesLogicTab'
import { ProcessTemplatesDataTab } from './ProcessTemplatesDataTab'
import { ProcessTemplatesCompaniesTab } from './ProcessTemplatesCompaniesTab'
import { IProcessTemplatesState } from 'types/processTemplates'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { getSingleProcessTemplate } from 'features/processTemplates/processTemplatesSlice'
import { useParams } from 'react-router-dom'

export const ViewProcessTemplate = () => {
    const [active, setActive] = useState('Specification')
    const [state, setState] = useState<IProcessTemplatesState>(null)
    const dispatch = useDispatch<AppDispatch>()
    const { id } = useParams()
    const { singleProcessTemplate } = useSelector((state: RootState) => state.procesTemplates)

    useEffect(() => {
        dispatch(getSingleProcessTemplate(id))
    }, [])

    useEffect(() => {
        if (singleProcessTemplate) {
            setState(singleProcessTemplate)
        }
    }, [singleProcessTemplate])

    const renderTabs = () => {
        switch (active) {
            case 'Specification':
                return <ProcessTemplatesSpecificationTab state={state} setState={setState} view={true} />
            case 'Logic':
                return (
                    <ProcessTemplatesLogicTab
                        state={state}
                        setState={setState}
                        goToData={() => setActive('Data')}
                        view={true}
                    />
                )
            case 'Data':
                return <ProcessTemplatesDataTab state={state} setState={setState} view={true} />
            default:
                return <ProcessTemplatesCompaniesTab state={state} setState={setState} view={true} />
        }
    }

    return (
        <div>
            <NavBar />
            <PageWrapper noFooter>
                <Flex between main>
                    <H1WithBack mb='1.5' arrow title={state?.name || ''} />
                    <TabsContainer>
                        <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
                            Specification
                        </Tabs>
                        <Tabs active={active === 'Logic'} onClick={() => setActive('Logic')}>
                            Logic
                        </Tabs>
                        <Tabs active={active === 'Data'} onClick={() => setActive('Data')}>
                            Data
                        </Tabs>
                        <Tabs active={active === 'Companies'} onClick={() => setActive('Companies')}>
                            Companies
                        </Tabs>
                    </TabsContainer>
                </Flex>
                <ContentWrapper>{renderTabs()}</ContentWrapper>
            </PageWrapper>
        </div>
    )
}
