import styled from 'styled-components'

export const GraphCard = styled.div`
  width: 100%;
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.colorGray};
  :hover {
    transform: scale(1.05);
  }

  background: #ffffff;
  box-shadow: 8.3px 1.25rem 2.8rem rgba(7, 6, 61, 0.05);
  border-radius: 8.3px;
  padding: 0.9rem 0.9rem;
  h3 {
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  strong {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 100%;
    text-align: right;
  }
  h4 {
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 15px;
    text-align: right;
  }
  p {
    text-align: right;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 0.9rem;
  }

  @media (max-width: 1300px) {
    h3 {
      font-size: 0.8rem;
    }
    strong {
      font-size: 1.6rem;
    }
    h4 {
      font-size: 0.7rem;
    }
    p {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 1100px) {
    h3 {
      font-size: 0.8rem;
    }
  }
`
export const LastCardInGraph = styled(GraphCard)`
  font-weight: 400;
  font-size: 0.6rem;
  line-height: 1.3rem;
  overflow-y: auto;
  max-height: 10rem;


  section {
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem;
    overflow-y: auto;

  }
::-webkit-scrollbar {
  display: none;
}

  strong {
    font-weight: 700;
    font-size: 0.6rem;
    line-height: 1.1rem;
  }
`

interface GraphWrapperProps {
  itemNum?: number
}

export const GraphWrapper = styled.div<GraphWrapperProps>`
  display: grid;
  gap: 1rem;
  margin: 3rem 0;
  /* height: 10rem; */

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  /* grid-template-columns: ${({ itemNum }) =>
    itemNum ? `repeat(${itemNum}, 1fr)` : 'repeat(5, 1fr)'};
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  @media (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  } */
`
