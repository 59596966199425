import React from 'react'
import { CollapseItem } from '../../../components/CollapseItem/CollapseItem'
import {
  Title,
  Button,
  Animation,
  TabFooter,
  SelectedItemsContainer,
  ButtonsContainer,
  ItemsPickContainer,
} from '../../../styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IOperationGlobal, IOperationTypeWithTypesAndParams } from 'types/operations.interface'
import { IInventoryItemsOperationType, IItemsTab } from 'types/inventory.interface'

export const InventoryItemsCapabilitiesTab = ({ handleSubmit, state, setState }: IItemsTab) => {
  const handleAdd = (item: IOperationGlobal<IOperationTypeWithTypesAndParams>) => {
    if (!state.operations.some((i) => i.id == item.id)) {
      const parsedTypes: IInventoryItemsOperationType[] = item.types.map((type) => ({
        rating: 0,
        priority: 0,
        parameters: type.parameters.map(p => ({
          id: p.id,
          name: p.name,
          parameterId: p.parameterId,
          unit: p.unit,
          unitId: p.unitId,
          value: null,
          operationTypeParameterId: p.id
        })),
        operationTypeId: Number(type.id),
        name: type.name,
        id: type.id
      }))

      const parsedItem = { ...item, types: parsedTypes }
      setState((prev) => {
        const copy = { ...prev }
        copy.operations = [...copy.operations, parsedItem]
        return copy
      })
    } else {
      setState((prev) => {
        const copy = { ...prev }
        copy.operations = copy.operations.filter((it) => it.id !== item.id)
        return copy
      })
    }
  }
  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Capabilities</Title>
          {state?.operations.map((item) => {
            return <CollapseItem params setState={setState} key={item.id} data={item} />
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='operations/filled'
          selectedList={state.operations}
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}

