export const absenceList = [
    {
      value: 6,
      label: 'Active',
    },
    {
      value: 1,
      label: 'Day Off',
    },
    {
      value: 2,
      label: 'Vacations',
    },
    {
      value: 3,
      label: 'Maternity leave',
    },
    {
      value: 4,
      label: 'Sick',
    },
  ]
