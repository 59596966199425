import styled from 'styled-components'

export const DashboardModalContainer = styled.div`
  width: max-content;
  height: 100vh;
  position: fixed;
  top: 0;
  animation: slide-right 0.2s ease-in forwards;
  background-color: white;
  border-radius: 10px;
  box-shadow: -10px 0px 40px 0px #00000040;
  pointer-events: all;
  padding: 2rem 1rem;
  @keyframes slide-right {
    from {
      right: -30%;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
`

export const DashboardSectionsContainer = styled.div`

display: flex;
gap:1rem;
height: 50%;

@media (max-width: 500px) {
  flex-direction: column;
  }

`

export const DBCardsContainer = styled.div`

display: flex;
width: 100%;
align-items: center;
justify-content: space-between;
gap: 1rem;

@media (max-width: 500px) {
  flex-direction: column;
  }

`

export const DashboardModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  /* background-color: #6497c866; */
`

export const DashboardModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20%;

  & > div {
    display: flex;
    gap: 1rem;

    svg {
      stroke: red;
    }
  }
`

interface IDashboardModalContent {
  width?: string
}

export const DashboardModalContent = styled.div<IDashboardModalContent>`
  width: ${(props) => props.width ?? '30vw'};
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  max-height: 100%;
  overflow: auto;
  padding-bottom: 2rem;
`

export const DashboardModalArrow = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  svg {
    transform: scale(1.3);
  }
`

interface IEmployeeDetailsUpper {
  columns: number
}
export const EmployeeDetailsUpper = styled.div<IEmployeeDetailsUpper>`
  height: fit-content;
  min-height: 30vh;
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => 'repeat(' + props.columns + ',1fr)'};
  gap: 1.5rem;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    min-height:none !important;
    height: auto !important;
  }

`

export const EmployeDetailsCard = styled.div`
  box-shadow: 0px 0px 25px 0px #0000001a;
  border-radius: 12px;
  background-color: white;
  width: 100%;
  overflow: hidden;
  min-height: 7rem;
  height: 7rem;
  display: flex;
  align-items: center;
  padding: 10px 1rem;

  @media (max-width: 500px) {
    height: max-content;
    flex-direction: column;
    padding: 15px 1rem;
  }

 

`

interface IEDCDetails {
  column?: boolean
}
export const EDCDetails = styled.div<IEDCDetails>`
  width: 74%;
  height: max-content;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  border-left: 0.5px solid ${(props) => props.theme.colorLightGray};
  ${(props) => props.column && 'flex-direction:column;'}

  p {
    font-size: 13px;
  }

  @media (max-width: 500px) {
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: .7rem;
    gap: 5px;
    *{
      padding: 0;
    }
  }

`
export const EDCDetailsIcon = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: scale(2);
    fill: #ff9900;
    stroke-width: 1;
  }
`
export const EmployeeDetailsProfile = styled(EmployeDetailsCard)`
  box-shadow: none;
  max-height: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  overflow: hidden;
  border-radius: none;
  background-color: none;
  img {
    height: 80%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;

    div {
      p {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 500px) {
   height: 20rem;
   overflow: visible;
   min-height: 15rem;
   img{
     width: 50%;
     height: unset;
   }
   div{
     align-items: center;
   }
  }

`
