import { baseUrl } from './../../utils/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { ILocationsSlice, ILocationState } from 'types/locations.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IGetAllLocationsSingleResponse } from 'types/api/locationsApi.interface'

const initialState:ILocationsSlice = {
  isLoading: false,
  locations: [],
  singleLocation: null,
  noLocations: false,
  totalCount: null,
  totalPages: null,
}

export const getLocations = createAsyncThunk(
  'locations/getLocations',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<IGetAllLocationsSingleResponse>> = await baseUrl.get('locations', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleLocation = createAsyncThunk(
  'locations/getSingleLocation',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IGetAllLocationsSingleResponse> = await baseUrl.get('locations/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addLocation = createAsyncThunk(
  'locations/addLocation',
  async (location: ILocationState, thunkAPI) => {
    try {
      const resp = await baseUrl.post('locations', location, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateLocation = createAsyncThunk(
  'locations/updateLocation',
  async (location: ILocationState, thunkAPI) => {
    try {
      const resp = await baseUrl.patch('locations/' + location.id, location, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteLocation = createAsyncThunk(
  'locations/deleteLocation',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('locations/' + id, thunkAPI)

      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    clearLocation: (state) => {
      state.singleLocation = null
    },
    clearLocationsList: (state) => {
      state.locations = []
      state.totalPages = 0
      state.totalCount = 0
    },
  },
  extraReducers: {
    [getLocations.pending.type]: (state) => {
      state.isLoading = true
    },
    [getLocations.fulfilled.type]: (state:ILocationsSlice, { payload }:{payload:IPaginatedResponse<IGetAllLocationsSingleResponse>}) => {
      state.isLoading = false
      state.locations = payload.data
      state.noLocations = state.locations.length < 1
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
    },
    [getLocations.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleLocation.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleLocation.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleLocation = payload
    },
    [getSingleLocation.rejected.type]: (state, { payload }) => {
      handleErrors(payload)

      state.isLoading = false
    },
    [addLocation.pending.type]: (state) => {
      state.isLoading = true
    },
    [addLocation.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      if (state.locations.length < 5) {
        state.locations = [...state.locations, payload]
        state.noLocations = false
      }
      toast.success('Location added')
    },
    [addLocation.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateLocation.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateLocation.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const foundIndex = state.locations.findIndex((item) => item.id == payload.id)
      state.locations[foundIndex] = payload
      toast.success('Location updated')
    },
    [updateLocation.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteLocation.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteLocation.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      toast.success('Location deleted')
      state.locations = state.locations.filter((item) => item.id !== payload)
      state.noLocations = state.locations.length < 1
    },
    [deleteLocation.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default locationsSlice.reducer
export const { clearLocation, clearLocationsList } = locationsSlice.actions
