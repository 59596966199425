import React from 'react'
import { CollapseItemText } from 'components/CollapseItem/CollapseItem.styled'
import { BlackCircle, Flex, Collapsable } from '../../styling/GlobalStyles'
import RemoveCircle from '../../assets/icons/RemoveCircle'

export const SelectedItem = ({ data, setState, type, onClick, onRemove, view, customProp }: ISelectedItem) => {
  const handleRemove = () => {
    setState((prev: any) => {
      const copy = { ...prev }
      if (customProp) {
        copy[type] = copy[type].filter((i: any) => i[customProp] !== data[customProp])
      } else {
        copy[type] = copy[type].filter((i: any) => i.id !== data.id)
      }
      return copy
    })
    onRemove && onRemove()
  }
  const name = data.name ? data.name?.replace(' configuration', '') : data.firstName
  return (
    <Collapsable view={view} expanded={false}>
      <Flex mb='20'>
        <CollapseItemText onClick={onClick} expanded={false}>
          {name}
        </CollapseItemText>
        <BlackCircle onClick={handleRemove}>
          <RemoveCircle white />
        </BlackCircle>
      </Flex>
    </Collapsable>
  )
}


interface ISelectedItem {
  data: any
  setState: React.Dispatch<React.SetStateAction<any>>
  onClick?: () => void
  onRemove?: () => void
  type: string
  view?: boolean
  customProp?: string
}