import React from 'react'

export const arrowsIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.30806 13.3081C3.55214 13.064 3.94786 13.064 4.19194 13.3081L6.25 15.3661L8.30806 13.3081C8.55214 13.064 8.94786 13.064 9.19194 13.3081C9.43602 13.5521 9.43602 13.9479 9.19194 14.1919L6.69194 16.6919C6.44786 16.936 6.05214 16.936 5.80806 16.6919L3.30806 14.1919C3.06398 13.9479 3.06398 13.5521 3.30806 13.3081Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.25 3.125C6.59518 3.125 6.875 3.40482 6.875 3.75V16.25C6.875 16.5952 6.59518 16.875 6.25 16.875C5.90482 16.875 5.625 16.5952 5.625 16.25V3.75C5.625 3.40482 5.90482 3.125 6.25 3.125Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.3081 3.30806C13.5521 3.06398 13.9479 3.06398 14.1919 3.30806L16.6919 5.80806C16.936 6.05214 16.936 6.44786 16.6919 6.69194C16.4479 6.93602 16.0521 6.93602 15.8081 6.69194L13.75 4.63388L11.6919 6.69194C11.4479 6.93602 11.0521 6.93602 10.8081 6.69194C10.564 6.44786 10.564 6.05214 10.8081 5.80806L13.3081 3.30806Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.75 3.125C14.0952 3.125 14.375 3.40482 14.375 3.75V16.25C14.375 16.5952 14.0952 16.875 13.75 16.875C13.4048 16.875 13.125 16.5952 13.125 16.25V3.75C13.125 3.40482 13.4048 3.125 13.75 3.125Z'
      fill='#464255'
    />
  </svg>
)
