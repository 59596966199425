import React from 'react'
import { TabFooter, Button, Flex, Animation } from 'styling/GlobalStyles'
import { Input } from 'components/Input/Input'
import { CancelBtn } from 'components/Button/CancelBtn'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { ICycleState, ICycleTab } from 'types/cycles.interface'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'

export const CycleSpecificationTab = ({ state, setState, handleSubmit, view }: ICycleTab) => {
    useFocusFirstInput()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev: ICycleState) => {
            const copy = { ...prev }
            copy.name = e.target.value
            return copy
        })
    }

    const parseOperations = (data: any) => {
        return data?.data.map(o => ({ label: o.name, value: o.id }))
    }


    const handleSelect = (value: ISelectValue, name: string) => {
        setState(prev => {
            const copy = structuredClone(prev)
            if (name == 'operationTypeId') {
                copy.operationType = { name: value.label, id: value.value }
            } else {
                copy.componentIndexForNumberOfWorkers = value
            }
            return copy
        })
    }

    const getOptions = () => {
        return state?.components.filter(c => c.name && c.component).map(c => ({ label: c.name, value: state.components.findIndex(cmp => cmp.component == c.component) }))
    }

    return (
        <Animation>
            <Flex gap='1rem' column width='15rem' >
                <Input
                    type='text'
                    value={state?.name}
                    onChange={handleChange}
                    placeholder='Enter task cycle name'
                    labelText='Task cycle name'
                    invalid={state?.name?.length < 2 ? 'true' : ''}
                    nomarg
                    view={view}
                />
                <HonestSelectAsyncInput
                    link='operations/users/operation-types'
                    placeholder='Search operation types'
                    name='operationTypeId'
                    labelText='Operation type'
                    defaultValue={state?.operationType?.name}
                    onChange={handleSelect}
                    parserFunction={parseOperations}
                    nomarg
                    view={view}


                // invalid={invalidFields.includes('category')}
                />
                {(view ? getOptions()?.length : true) && <HonestSelectInput
                    name='componentIndexForNumberOfWorkers'
                    onChange={handleSelect}
                    labelText='Component for Number of Workers'
                    defaultValue={state?.componentIndexForNumberOfWorkers?.value}
                    options={getOptions()}
                    nomarg
                    errorWhenEmpty='Components must be selected first.'
                    view={view}
                // invalid={invalidCategoryFields.includes(field.name)}
                />}
            </Flex>

            {handleSubmit && (
                <TabFooter>
                    <Flex width='100%' end='true'>
                        <CancelBtn />
                        <Button ml='15' height='40' onClick={handleSubmit} orange>
                            Save
                        </Button>
                    </Flex>
                </TabFooter>
            )}
        </Animation>
    )
}
