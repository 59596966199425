import React, { useState } from 'react'
import {
  Button,
  Table,
  Th,
  Tr,
  Flex,
  MainHeadline,
  PageWrapper,
  Separator,
  PageFooter,
} from '../../styling/GlobalStyles'
import { NavBar } from '../../components/NavBar/NavBar'
import { InfoIcon } from 'assets/icons/InfoIcon'
import { GreenCheckIcon } from 'assets/icons/CheckCheckIcon'
import { RedXIcon } from 'assets/icons/RedXIcon'
import { useNavigate } from 'react-router-dom'
import { ITask } from 'types/tasks.interface'
import { useDispatch, useSelector } from 'react-redux'
import { clearTasksList, deleteTask, duplicateTask, getTasks } from 'features/tasks/tasksSlice'
import { AppDispatch } from 'store'
import { Options } from 'components/Options/Options'
import { setConfirmModal } from 'features/global/globalSlice'
import { Pagination } from 'components/Pagination/Pagination'
import { getParams, handlePagination } from 'utils/helpers'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Td } from 'components/Td/Td'
import { ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const Tasks = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { allTasks, totalPages, noTasks } = useSelector((store: any) => store.tasks)
  const [params, setParams] = useState<any>(getParams({ location, limit: 10, ap: { sd: 'a', s: 'name' } }))

  useQueryPagination({ fetchFunctions: [getTasks], cleanUpFunctions: [clearTasksList], params })

  const handleDeleteTask = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteTask(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: allTasks.length < 2,
        },
        word: 'delete task',
      }),
    )
  }

  const handleDuplicate = (item: ITask) => {
    dispatch(duplicateTask({ name: item.name + ' copy', taskTemplateId: item.id }))
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Tasks...' setParams={setParams} limit={10} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Tasks Configurations</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add Task
          </Button>
        </Flex>
        <Separator />
        {noTasks ? (
          <NoItems title='Tasks' />
        ) : (
          <Table>
            <Th>
              <Td w='5%'></Td>
              <Td w='27%' noTooltip text='Task' />
              <Td w='17%' noTooltip text='Design' />
              <Td w='17%' noTooltip text='Logic' />
              <Td w='17%'></Td>
              <Td w='5%'></Td>
            </Th>
            {noTasks ? (
              <NoItems title='Tasks' />
            ) : (
              allTasks.map((item) => {

                const hasLogic = item?.isLogicAdded
                const hasDesign = item?.components || Array.isArray(item.components)

                return (
                  <Tr small key={item.id}>
                    <Td w='8%'>{InfoIcon}</Td>
                    <Td w='29%' text={item.name} />
                    <Td w='20%' noTooltip svgColor={hasDesign && '#00a368'} >
                      {hasDesign
                        ? GreenCheckIcon
                        : RedXIcon}
                    </Td>
                    <Td w='40%' noTooltip svgColor={hasLogic && '#00a368'} >
                      {hasLogic ? GreenCheckIcon : RedXIcon}
                    </Td>
                    {/* <Td w='30%'>{item.company ? '' : item.company}</Td> */}
                    <Td w='5%'>
                      <Options
                        item={item}
                        handleRemove={() => handleDeleteTask(item.id)}
                        handleDuplicate={() => handleDuplicate(item)}
                        title='Task'
                      />
                    </Td>
                  </Tr>
                )
              })
            )}
          </Table>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 10)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
