import React from 'react'

export const shiftsIcon = (
  <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.24561 3.75C3.24561 3.05964 3.79543 2.5 4.47368 2.5H16.7544C17.4326 2.5 17.9824 3.05964 17.9824 3.75V16.25C17.9824 16.9404 17.4326 17.5 16.7544 17.5H4.47368C3.79543 17.5 3.24561 16.9404 3.24561 16.25V3.75ZM16.7544 3.75H4.47368V16.25H16.7544V3.75Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.2982 1.25C14.6374 1.25 14.9123 1.52982 14.9123 1.875V4.375C14.9123 4.72018 14.6374 5 14.2982 5C13.9591 5 13.6842 4.72018 13.6842 4.375V1.875C13.6842 1.52982 13.9591 1.25 14.2982 1.25Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.92983 1.25C7.26895 1.25 7.54387 1.52982 7.54387 1.875V4.375C7.54387 4.72018 7.26895 5 6.92983 5C6.59071 5 6.3158 4.72018 6.3158 4.375V1.875C6.3158 1.52982 6.59071 1.25 6.92983 1.25Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.24561 6.875C3.24561 6.52982 3.52052 6.25 3.85964 6.25H17.3684C17.7075 6.25 17.9824 6.52982 17.9824 6.875C17.9824 7.22018 17.7075 7.5 17.3684 7.5H3.85964C3.52052 7.5 3.24561 7.22018 3.24561 6.875Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8241 9.57141C14.0567 9.82264 14.0451 10.2182 13.7983 10.4549L10.2139 13.8924C9.97679 14.1198 9.60653 14.1191 9.37021 13.8909L7.42832 12.0159C7.18229 11.7784 7.17204 11.3828 7.40543 11.1324C7.63882 10.8819 8.02747 10.8715 8.27351 11.1091L9.79424 12.5774L12.9562 9.54511C13.203 9.3084 13.5916 9.32017 13.8241 9.57141Z'
      fill='#464255'
    />
  </svg>
)
