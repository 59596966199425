import styled from 'styled-components'

interface SearchBarProps {
  relative?: boolean
}

export const SearchBarWrapper = styled.div<SearchBarProps>`
overflow: hidden;
  background: #ffffff;
  height: 2.5rem;
  box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  width: 100%;
  position: ${props=>props.relative ? 'relative' : 'absolute'};
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 1rem;
`
