import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../utils/axios'
import { toast } from 'react-toastify'
import { handleErrors } from 'utils/helpers'

const initialState = {
  zones: [],
  blocks: [],
  sections: [],
  pits: [],
  isLoading: false,
  isSingleZoneLoading: false,
  singleZone: null,
}

export const getZones = createAsyncThunk('zones/getZones', async (_, thunkAPI) => {
  try {
    const resp = await baseUrl.get('zones/areas/non-nested')
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const addZone = createAsyncThunk('zones/addZone', async (zone: any, thunkAPI) => {
  try {
    const resp = await baseUrl.post('zones', {...zone,sections:[]}, thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const addSection = createAsyncThunk('zones/addSection', async (section: any, thunkAPI) => {
  try {
    const resp = await baseUrl.post('zones/' + section.zoneId + '/section', {...section, blocks:[]}, thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const updateSection = createAsyncThunk(
  'zones/updateSection',
  async (section: any, thunkAPI) => {
    try {
      const resp = await baseUrl.patch('zones/section/' + section.id, {...section, blocks:[]}, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteSection = createAsyncThunk(
  'zones/deleteSection',
  async (id: number | string, thunkAPI) => {
    try {
      await baseUrl.delete('zones/section/' + id)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addBlock = createAsyncThunk('zones/addBlock', async (block: any, thunkAPI) => {
  try {
    const resp = await baseUrl.post('zones/section/' + block.zoneId + '/block', {...block, pits:[]}, thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const updateBlock = createAsyncThunk('zones/updateBlock', async (block: any, thunkAPI) => {
  try {
    const resp = await baseUrl.patch('zones/section/block/' + block.id, {...block, pits:[]}, thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const deleteBlock = createAsyncThunk(
  'zones/deleteBlock',
  async (id: string | number, thunkAPI) => {
    try {
      await baseUrl.delete('zones/section/block/' + id)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addPit = createAsyncThunk('zones/addPit', async (pit: any, thunkAPI) => {
  try {
    const resp = await baseUrl.post('zones/section/block/' + pit.zoneId + '/pit', pit, thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const updatePit = createAsyncThunk('zones/updatePit', async (pit: any, thunkAPI) => {
  try {
    const resp = await baseUrl.patch('zones/section/block/pit/' + pit.id, pit, thunkAPI)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const deletePit = createAsyncThunk(
  'zones/deletePit',
  async (id: number | string, thunkAPI) => {
    try {
      await baseUrl.delete('zones/section/block/pit/' + id)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteZone = createAsyncThunk(
  'zones/deleteZone',
  async (id: string | number, thunkAPI) => {
    try {
      try {
        await baseUrl.delete('zones/' + id, thunkAPI)
        return id
      } catch (error) {
        return error
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateZone = createAsyncThunk('zones/updateZone', async (zone: any, thunkAPI) => {
  const { id } = zone
  try {
    const resp = await baseUrl.patch(`zones/${id}`, {...zone, sections:[]}, thunkAPI)
    return { ...resp.data, id }
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {},
  extraReducers: {
    [getZones.pending.type]: (state) => {
      state.isLoading = true
    },
    [getZones.fulfilled.type]: (state, { payload }) => {
      if (!payload) {
        toast.error('An error occured')
        return
      }
      state.zones = payload.zones.map((z) => ({ ...z, type: 1 }))
      state.sections = payload.sections.map((s) => ({ ...s, type: 2 }))
      state.blocks = payload.blocks.map((b) => ({ ...b, type: 3 }))
      state.pits = payload.pits.map((p) => ({ ...p, type: 4 }))
      state.isLoading = false
    },
    [getZones.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addZone.pending.type]: (state) => {
      state.isLoading = true
    },
    [addZone.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.zones.push({ ...payload, type: 1 })
      toast.success('Zone added')
    },
    [addZone.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addSection.pending.type]: (state) => {
      state.isLoading = true
    },
    [addSection.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.sections.push({...payload, type:2})
      toast.success('Section added')
    },
    [addSection.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateSection.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateSection.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const sectionIndex = state.sections.findIndex(s=>s.id==payload.id)
      state.sections[sectionIndex] = {...payload,type:2}
      toast.success('Section updated')
    },
    [updateSection.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteSection.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteSection.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const foundBlocks = state.blocks.filter(b=>b.sectionId == payload)
      state.blocks = state.blocks.filter(b=>b.sectionId !== payload)
      state.pits = state.pits.filter(p=>!foundBlocks.some(b=>b.id == p.blockId))
      state.sections = state.sections.filter((s) => s.id !== payload)
      toast.success('Section deleted')
    },
    [deleteSection.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addBlock.pending.type]: (state) => {
      state.isLoading = true
    },
    [addBlock.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.blocks.push({...payload, type:3})
      toast.success('Block added')
    },
    [addBlock.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateBlock.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateBlock.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const blockIndex = state.blocks.findIndex(b=>b.id==payload.id)
      state.blocks[blockIndex] = { ...payload, type: 3 }
      toast.success('Block updated')
    },
    [updateBlock.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteBlock.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteBlock.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.pits = state.pits.filter(p=>p.blockId !== payload)
      state.blocks = state.blocks.filter((b) => b.id !== payload)
      toast.success('Block deleted')
    },
    [deleteBlock.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addPit.pending.type]: (state) => {
      state.isLoading = true
    },
    [addPit.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.pits.push({ ...payload, type: 4 })
      toast.success('Pit added')
    },
    [addPit.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updatePit.pending.type]: (state) => {
      state.isLoading = true
    },
    [updatePit.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const pitIndex = state.pits.findIndex((p) => p.id === payload.id)
      state.pits[pitIndex] = { ...payload, type: 4 }
      toast.success('Pit updated')
    },
    [updatePit.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deletePit.pending.type]: (state) => {
      state.isLoading = true
    },
    [deletePit.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.pits = state.pits.filter((p) => p.id !== payload)
      toast.success('Pit deleted')
    },
    [deletePit.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteZone.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteZone.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const foundSections = state.sections.filter(s=>s.zoneId == payload)
      const foundBlocks = state.blocks.filter(b=>foundSections.some(s=>s.id == b.sectionId))
      state.sections = state.sections.filter(s=>s.zoneId !== payload)
      state.blocks = state.blocks.filter(b=>!foundSections.some(s=>s.id == b.sectionId))
      state.pits = state.pits.filter(p=>!foundBlocks.some(b=>b.id == p.blockId))
      state.zones = state.zones.filter((z) => z.id !== payload)
      toast.success('Zone deleted')
    },
    [deleteZone.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateZone.fulfilled.type]: (state, { payload }) => {
      const zoneIndex = state.zones.findIndex((z) => z.id === payload.id)
      state.zones[zoneIndex] = { ...payload, type: 1 }
      state.isLoading = false
      toast.success('Zone updated')
    },
    [updateZone.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateZone.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default zonesSlice.reducer
