import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { baseUrl } from 'utils/axios'
import { IRole, IStateRoles } from 'types/roles.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { IOperation } from 'types/operations.interface'
import { IMachineModelGlobal } from 'types/machineModels.interface'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { INameId } from 'constants/globalTypes'
import { IClientRolesPatchRequest, IClientRolesPostRequest, ISingleClientRoleGetResponse } from 'types/api/clientRoles.interface'

const initialState: IStateRoles = {
  roles: [],
  isLoading: false,
  singleRole: {
    name: '',
    machineModels: [],
    operations: [],
    permissions: null,
  },
  totalCount: 0,
  totalPages: 0,
  noRoles: false,
}

export const getClientRoles = createAsyncThunk(
  'clientRoles/getRoles',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('/roles', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleClientRole = createAsyncThunk(
  'clientRoles/getSingleRole',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<ISingleClientRoleGetResponse> = await baseUrl.get('roles/' + id)
      const role = resp.data
      role.operations = role.operations?.map((o) => { // TODO: proveriti da li operacija sa bekenda zaista odgvara interfejsu u mapi
        const copy = { ...o }
        copy.types = copy.types.map((c) => {
          const copyC = { ...c }
          return {
            ...copyC,
            id: copyC.operationTypeId,
            parameters: copyC.parameters.map((p) => {
              const copyP = { ...p }
              return { ...copyP, id: copyP.operationTypeParameterId }
            }),
          }
        })
        return copy
      })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addClientRole = createAsyncThunk(
  'clientRoles/addRole',
  async (role: IRole, thunkAPI) => {
    try {
      const forSubmit: IClientRolesPostRequest = {
        name:role.name,
        permissions:role.permissions,
        operationTypes: role.operations
        .map((item:IOperation) => {
          const final = item.types.map((i) => {
            return {
              operationTypeId: Number(i.id),
              parameterValues: i.parameters.map((t) => {
                return { operationTypeParameterId: Number(t.id), value: t.value }
              }),
              rating: i.rating ? i.rating : 0,
              priority: i.priority ? i.priority : 0,
            }
          })
          return final
        })
        .flat(),
        machineModels:role.machineModels.map((m:Omit<IMachineModelGlobal,'name'>) => {
          return {
            rating: m.rating ? m.rating : 0,
            priority: m.priority ? m.priority : 0,
            machineModelId: m.id,
          }
        })
      }

    const resp = await baseUrl.post('/roles', forSubmit, thunkAPI)

      return {id:resp.data.id,name:role.name}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteClientRole = createAsyncThunk(
  'clientRoles/deleteRole',
  async (id: string | number, thunkAPI) => {
    try {
      try {
        await baseUrl.delete('roles/' + id, thunkAPI)
        return id
      } catch (error) {
        return error
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const updateClientRole = createAsyncThunk(
  'clientRoles/updateRole',
  async (role: IRole, thunkAPI) => {
    try {
      const forSubmit:IClientRolesPatchRequest = {
        name:role.name,
        id:role.id,
        permissions:role.permissions,
        operationTypes:role.operations
        .map((item:IOperation) => {
          const final = item.types.map((i) => {
            return {
              operationTypeId: Number(i.id),
              parameterValues: i.parameters.map((t) => {
                return { operationTypeParameterId: Number(t.id), value: t.value ? t.value : 0 }
              }),
              rating: i.rating ? i.rating : 0,
              priority: i.priority ? i.priority : 0,
            }
          })
          return final
        })
        .flat(),
        machineModels:role.machineModels.map((m:Omit<IMachineModelGlobal, 'name'>) => {
          return {
            machineModelId: m.id,
            rating: m.rating ? m.rating : 0,
            priority: m.priority ? m.priority : 0,
          }
        })
      }


      await baseUrl.patch(`roles/${role.id}`, forSubmit, thunkAPI)
      // thunkAPI.dispatch(getRoles())
      return {id:role.id,name:role.name}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const clientRolesSlice = createSlice({
  name: 'clientRoles',
  initialState,
  reducers: {
    clearClientRole: (state) => {
      state.singleRole = {
        name: '',
        machineModels: [],
        operations: [],
        permissions: null,
      }
    },
    clearClientRolesList: (state) => {
      state.roles = []
      state.totalCount = null
      state.totalPages = null
    },
  },
  extraReducers: {
    [getClientRoles.pending.type]: (state) => {
      state.isLoading = true
    },
    [getClientRoles.fulfilled.type]: (state:IStateRoles, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.roles = payload.data
      state.isLoading = false
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages

      state.noRoles = state.roles.length < 1
    },
    [getClientRoles.rejected.type]: (state) => {
      state.isLoading = false
    },
    [deleteClientRole.fulfilled.type]: (state:IStateRoles, { payload }:{payload:number}) => {
      state.isLoading = false
      state.roles = state.roles.filter((role) => role.id !== payload)
      toast.success('Role deleted')
    },
    [addClientRole.pending.type]: (state) => {
      state.isLoading = true
    },
    [addClientRole.fulfilled.type]: (state:IStateRoles, { payload }:{payload:INameId}) => {
      if (state.roles.length < 30) {
        state.roles = [...state.roles, payload]
      }
      toast.success('Role added')
    },
    [addClientRole.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleClientRole.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleClientRole.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleRole = payload
    },
    [getSingleClientRole.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateClientRole.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateClientRole.fulfilled.type]: (state:IStateRoles, { payload }:{payload:INameId}) => {
      state.isLoading = false
      const foundIndex = state.roles.findIndex((item) => item.id === payload.id)
      state.roles[foundIndex] = payload
      toast.success('Role updated')
    },
    [updateClientRole.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearClientRole, clearClientRolesList } = clientRolesSlice.actions

export default clientRolesSlice.reducer
