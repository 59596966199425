import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppDispatch } from 'store'
import { Input } from '../../components/Input/Input'
import { NavBar } from '../../components/NavBar/NavBar'
import { addCompany } from '../../features/companies/companiesSlice'
import {
  Flex,
  ItemsGrid,
  Button,
  Separator,
  H1WithBack,
  PageWrapper,
  PageFooter,
} from '../../styling/GlobalStyles'
import { ICompany } from 'types/companies.interface'
import { getIndustries } from 'features/industries/industriesSlice'
import { CancelBtn } from 'components/Button/CancelBtn'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { companiesInitialState, companyInputs } from './companiesData'
import { minimumCharacters, standardFieldValidation, validateSelect } from 'utils/validationUtils'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { ToggleButton } from 'components/Input/ToggleButton'

export const AddCompany = () => {
  // states
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [invalidFields, setInvalidFields] = useState(companyInputs)
  const [inputValues, setInputValues] = useState<ICompany>(companiesInitialState)

  useFocusFirstInput()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (invalidFields.length > 0) {
      toast.warn('All fields are required')
      return
    }

    const resp = await dispatch(addCompany(inputValues))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/companies')
    }
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    validateSelect(value, name, setInvalidFields)
    setInputValues({ ...inputValues, [name]: value.value })
  }

  useEffect(() => {
    dispatch(getIndustries(null))
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name.split('-')
    const value = e.target.value
    if (name[1] == 'username') {
      minimumCharacters(value, 'representative-username', setInvalidFields, 6)
    } else {
      standardFieldValidation(e, setInvalidFields)
    }
    const copyState = { ...inputValues }
    setInputValues(copyState)

    if (name[0] === 'main') {
      setInputValues((prev) => {
        prev[name[1]] = value
        return prev
      })
    } else if (name[0] === 'contact') {
      setInputValues((prev) => {
        prev.contact[name[1]] = value
        return prev
      })
    } else {
      setInputValues((prev) => {
        prev.representative[name[1]] = value
        return prev
      })
    }
  }

  const parseIndustries = (data: any) => {
    return data.data.map((item: any) => ({ label: item.name, value: item.id }))
  }

  return (
    <>
      <NavBar />
      <PageWrapper>
        <H1WithBack title={'Add Company'} arrow />
        <Separator />
        <ItemsGrid columns={2} width='80%'>
          <Input
            nomarg
            type='text'
            name='main-name'
            labelText='Company name'
            placeholder='Enter company name'
            value={inputValues.name}
            onChange={handleChange}
            invalid={invalidFields.includes('main-name') ? 'true' : ''}
          />
          <Input
            nomarg
            name='representative-position'
            type='text'
            labelText='Representative position'
            placeholder='Enter representative position'
            value={inputValues.representative.position}
            onChange={handleChange}
            invalid={invalidFields.includes('representative-position') ? 'true' : ''}
          />
          <HonestSelectAsyncInput
            nomarg
            link='industries'
            placeholder='Search industries'
            name='industryId'
            labelText='Industry'
            defaultValue={inputValues.industry.name}
            onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
            parserFunction={parseIndustries}
            invalid={invalidFields.includes('industryId') ? 'true' : ''}
          />
          <Input
            nomarg
            name='contact-address'
            type='text'
            labelText='Company address'
            placeholder='Enter company address'
            value={inputValues.contact.address}
            onChange={handleChange}
            invalid={invalidFields.includes('contact-address') ? 'true' : ''}
          />
          <Input
            nomarg
            name='representative-name'
            type='text'
            labelText='Representative'
            placeholder='Enter representative'
            value={inputValues.representative.name}
            onChange={handleChange}
            invalid={invalidFields.includes('representative-name') ? 'true' : ''}
          />
          <Input
            nomarg
            name='contact-phoneNumber'
            type='tel'
            labelText='Company phone number'
            placeholder='Enter company phone number'
            value={inputValues.contact.phoneNumber}
            onChange={handleChange}
            invalid={invalidFields.includes('contact-phoneNumber') ? 'true' : ''}
          />
          <Input
            nomarg
            name='representative-phoneNumber'
            type='tel'
            labelText='Representative phone number'
            placeholder='Enter representative phone number'
            value={inputValues.representative.phoneNumber}
            onChange={handleChange}
            invalid={invalidFields.includes('representative-phoneNumber') ? 'true' : ''}
          />
          <Input
            nomarg
            name='representative-username'
            type='text'
            labelText='Representative username'
            placeholder='Enter username'
            value={inputValues.representative.username}
            onChange={handleChange}
            invalid={invalidFields.includes('representative-username') ? 'true' : ''}
          />
          <Input
            nomarg
            name='contact-email'
            type='email'
            labelText='Company email'
            placeholder='Enter company email'
            value={inputValues.contact.email}
            onChange={handleChange}
            invalid={invalidFields.includes('contact-email') ? 'true' : ''}
          />
          <Input
            nomarg
            name='representative-email'
            type='email'
            labelText='Representative email'
            placeholder='Enter representative email'
            value={inputValues.representative.email}
            onChange={handleChange}
            invalid={invalidFields.includes('representative-email') ? 'true' : ''}
          />
          <Input
            nomarg
            type='number'
            labelText='Company VAT'
            name='main-taxNumber'
            placeholder='Enter company VAT'
            value={inputValues.taxNumber}
            onChange={handleChange}
            invalid={invalidFields.includes('main-taxNumber') ? 'true' : ''}
          />
          <ToggleButton
            state={inputValues.settings?.shouldIncludeTraffic || false}
            handleChange={() =>
              setInputValues((prev: ICompany) => ({
                ...prev,
                settings: {
                  ...prev.settings,
                  shouldIncludeTraffic: !prev.settings.shouldIncludeTraffic
                }

              }))
            }
            maxWidth
            label='Should Include Traffic'
          />
        </ItemsGrid>
      </PageWrapper>
      <PageFooter>
        <Flex gap='1rem'>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </PageFooter>
    </>
  )
}
