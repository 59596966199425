import React, { useEffect, useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import { PageWrapper, H1WithBack, Flex, ContentWrapper, Separator } from 'styling/GlobalStyles'
import { TaskOperationTab } from './TaskOperationTab'
import { TaskLogicTab } from './TaskLogicTab'
import { TaskDesignTab } from './TaskDesignTab'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  addLogic,
  clearTask,
  editTask,
  getSingleTask,
} from 'features/tasks/tasksSlice'
import { toast } from 'react-toastify'
import { AppDispatch, RootState } from 'store'
import { TaskComponentsTab } from './TaskComponentsTab'
import { TaskWorkUnitTab } from './TaskWorkUnitTab'
import { DropDownTabsContainer } from 'components/DropDownTabsContainer/DropDownTabsContainer'
import { ITaskState, ITaskStateComponent } from 'types/tasks.interface'
import { tasksInitialState } from './taskData'

export const EditTask = () => {
  const [active, setActive] = useState('Operation')
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const { singleTask } = useSelector((store: RootState) => store.tasks)
  const [state, setState] = useState<ITaskState>(tasksInitialState)
  const [initialComponents, setInitialCompoents] = useState([])

  useEffect(() => {
    dispatch(getSingleTask(parseInt(id)))

    return () => {
      dispatch(clearTask())
    }
  }, [])

  useEffect(() => {
    if (singleTask) {
      setState(singleTask)
      setInitialCompoents(singleTask.components)
    }
  }, [singleTask, singleTask?.components])

  const handleSubmit = async () => {
    if (!state.operationTypeId) {
      toast.warn('Task capability must be selected.')
      return
    }
    if (state.components.some((c) => !c.componentType)) {
      toast.warn('Components must have set component type.')
      return
    }
    if (state.operationTypeParameterValues.some((t) => !t.minimumValue || (t.parameterId == 1 && !t.desiredValue))) {
      toast.warn('Capabilities must have set value.')
      return
    }
    if (state.toolTypeComponents.some((t) => !t.name)) {
      toast.warn('Tool type components must have set name.')
      return
    }
    const found = state.toolTypeComponents.find(c => c.component.value == 2)

    if (found && !found.unit?.value) {
      toast.warn('Please select item unit.')
      return
    }


    await dispatch(editTask({ ...state, id: Number(id) }))
    window.location.reload()

  }

  const handleLogicSubmit = async () => {
    if ((state.components as ITaskStateComponent[]).some(c => c.componentType == 2 && !c.functionId)) {
      toast.warn('All calculated compoonents must be connected to functions')
      return
    }
    await dispatch(addLogic({ components: state.components.filter(c => c.componentType == 2), id }))
  }

  const renderTabs = () => {
    switch (active) {
      case 'Operation':
        return (
          <TaskOperationTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Logic':
        return (
          <TaskLogicTab
            state={state}
            setState={setState}
            handleLogicSubmit={handleLogicSubmit}
          />
        )
      case 'Design':
        return (
          <TaskDesignTab

            state={state}
            setState={setState}
            handleSubmit={handleSubmit}
          />
        )
      case 'Work Unit Amounts':
        return <TaskWorkUnitTab state={state} setState={setState} handleSubmit={handleSubmit} />
      case 'Components':
        return <TaskComponentsTab state={state} setState={setState} handleSubmit={handleSubmit} />
      default:
        return (
          <TaskOperationTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
    }
  }

  const handleLogicTab = () => {
    if (JSON.stringify(state.components) === JSON.stringify(initialComponents)) {
      setActive('Logic')
    } else {
      toast.warn('Please save the task first')
    }
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between center>
          <H1WithBack arrow title={'Edit ' + (state.name ? state.name : '')} />
          <DropDownTabsContainer
            tabs={['Operation', 'Design', 'Logic', 'Components', 'Work Unit Amounts']}
            setActive={setActive}
            customHandleClick={{ fn: handleLogicTab, tab: 'Logic' }}
            active={active}
          />
        </Flex>
        <Separator />
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
