import React, { useState } from 'react'
import {
  Button,
  Separator,
  GridItem,
  ItemsGrid,
  Flex,
  MainHeadline,
  PageWrapper,
  PageFooter,
} from '../../styling/GlobalStyles'
import { Pagination } from '../../components/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'
import { NavBar } from '../../components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { setConfirmModal } from 'features/global/globalSlice'
import { AppDispatch, RootState } from 'store'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { getParams, handlePagination } from 'utils/helpers'
import { ITableItem, ModalTypes } from 'types/global.interface'
import { clearRoleTemplatesList, deleteRoleTemplate, getRoleTemplates } from 'features/roles/roleTemplatesSlice'
import useQueryPagination from 'hooks/useQueryPagination'

export const RoleTemplates = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { roleTemplates = [], totalPages, noRoleTemplates } = useSelector((store: RootState) => store.roleTemplates)
  const [params, setParams] = useState<any>(getParams({ location }))

  useQueryPagination({ fetchFunctions: [getRoleTemplates], cleanUpFunctions: [clearRoleTemplatesList], params })

  const removeRole = (id: string | number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteRoleTemplate(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: roleTemplates.length < 2,
        },
        word: 'delete role',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Role Templates...' setParams={setParams} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Role Templates</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add role template
          </Button>
        </Flex>
        <Separator />
        <ItemsGrid columns={3} mt='25px'>
          {roleTemplates && !noRoleTemplates ? (
            roleTemplates.map(({ name, id }: ITableItem) => {
              return (
                <GridItem key={id}>
                  <p
                    onClick={() => {
                      navigate('/resources-role-templates/' + id)
                    }}
                  >
                    {name}
                  </p>

                  <Flex between center onClick={() => removeRole(id)}>
                    <RemoveCircle />
                  </Flex>
                </GridItem>
              )
            })
          ) : (
            <NoItems title='Roles' />
          )}
        </ItemsGrid>
      </PageWrapper>

      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
