import React from 'react'


export const machineryIcon = (
  <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5668 6.875L18.6405 9.60712C18.7664 9.92761 19.1237 10.0835 19.4386 9.9553C19.7535 9.8271 19.9066 9.46337 19.7807 9.14288L18.7072 6.41121C18.707 6.41067 18.7068 6.41014 18.7066 6.40961C18.6161 6.17716 18.4588 5.9779 18.2552 5.83791C18.0518 5.69804 17.8118 5.62385 17.5664 5.625H17.568V6.25L17.564 5.62501C17.5648 5.62501 17.5656 5.625 17.5664 5.625'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.4035 11.25C1.4035 10.9048 1.67842 10.625 2.01754 10.625H14.2982C14.6374 10.625 14.9123 10.9048 14.9123 11.25C14.9123 11.5952 14.6374 11.875 14.2982 11.875H2.01754C1.67842 11.875 1.4035 11.5952 1.4035 11.25Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.2193 13.75C14.5411 13.75 13.9913 14.3096 13.9913 15C13.9913 15.6904 14.5411 16.25 15.2193 16.25C15.8976 16.25 16.4474 15.6904 16.4474 15C16.4474 14.3096 15.8976 13.75 15.2193 13.75ZM12.7632 15C12.7632 13.6193 13.8628 12.5 15.2193 12.5C16.5758 12.5 17.6755 13.6193 17.6755 15C17.6755 16.3807 16.5758 17.5 15.2193 17.5C13.8628 17.5 12.7632 16.3807 12.7632 15Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.00881 13.75C5.33057 13.75 4.78074 14.3096 4.78074 15C4.78074 15.6904 5.33057 16.25 6.00881 16.25C6.68706 16.25 7.23688 15.6904 7.23688 15C7.23688 14.3096 6.68706 13.75 6.00881 13.75ZM3.55267 15C3.55267 13.6193 4.65232 12.5 6.00881 12.5C7.3653 12.5 8.46495 13.6193 8.46495 15C8.46495 16.3807 7.3653 17.5 6.00881 17.5C4.65232 17.5 3.55267 16.3807 3.55267 15Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.23688 15C7.23688 14.6548 7.51179 14.375 7.85091 14.375H13.3772C13.7164 14.375 13.9913 14.6548 13.9913 15C13.9913 15.3452 13.7164 15.625 13.3772 15.625H7.85091C7.51179 15.625 7.23688 15.3452 7.23688 15Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.7632 4.74112C1.9935 4.5067 2.30587 4.375 2.63157 4.375H14.2982C14.6374 4.375 14.9123 4.65482 14.9123 5V13.375C14.9123 13.7202 14.6374 14 14.2982 14C13.9591 14 13.6842 13.7202 13.6842 13.375V5.625L2.63157 5.625V14.375H4.16666C4.50578 14.375 4.7807 14.6548 4.7807 15C4.7807 15.3452 4.50578 15.625 4.16666 15.625H2.63157C2.30587 15.625 1.99351 15.4933 1.7632 15.2589C1.53289 15.0245 1.4035 14.7065 1.4035 14.375V5.625C1.4035 5.29348 1.53289 4.97554 1.7632 4.74112Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.6842 9.375C13.6842 9.02982 13.9591 8.75 14.2982 8.75H19.2105C19.5496 8.75 19.8246 9.02982 19.8246 9.375V14.375C19.8246 14.7065 19.6952 15.0245 19.4649 15.2589C19.2346 15.4933 18.9222 15.625 18.5965 15.625H17.0614C16.7223 15.625 16.4474 15.3452 16.4474 15C16.4474 14.6548 16.7223 14.375 17.0614 14.375H18.5965V10H14.2982C13.9591 10 13.6842 9.72018 13.6842 9.375Z'
    />
  </svg>
)
