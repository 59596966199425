import React, { useState, useEffect } from 'react'
import {
  CategoryCard,
  ItemsGrid,
  EditContainer,
  BottomRight,
  TopRight,
  MainHeadline,
  Separator,
  PageWrapper,
  H4WhiteBackgournd,
  Button,
  Flex,
} from '../../../styling/GlobalStyles'
import { useNavigate } from 'react-router-dom'
import { EditIcon } from '../../../assets/icons/EditIcon'
import { Modal } from '../../../components/Modal/Modal'
import { CategoryModal } from '../../../components/Modal/CategoryModal'
import { useDispatch, useSelector } from 'react-redux'
import { clearCategories, getCategories } from '../../../features/categories/categoriesSlice'
import { NavBar } from '../../../components/NavBar/NavBar'
import { AppDispatch, RootState } from 'store'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { IField } from 'types/fields.interface'
import { ISingleCategory } from 'types/categories.interface'

export const ResourcesCategories = ({ noTitle }: { noTitle: boolean }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { categories, noCategories } = useSelector((store: RootState) => store.categories)
  const [modal, setModal] = useState({
    open: false,
    edit: true,
    text: '',
    id: null,
    image: '',
    fields: [],
  })

  const ToggleEditModal = (text: string, id: number, image: string, fields: IField[]) => {
    setModal({ open: true, edit: true, text: text, id, image, fields })
  }

  const ToggleAddModal = () => {
    setModal({ open: true, edit: false, text: '', id: '', image: '', fields: [] })
  }

  useEffect(() => {
    dispatch(getCategories('?s=name&sd=a'))
    return () => {
      dispatch(clearCategories())
    }
  }, [])

  console.log(categories)

  return (
    <>
      <NavBar placeholder='Search Categories...' />
      <PageWrapper>
        {!noTitle && (
          <Flex between center>
            <MainHeadline>Categories</MainHeadline>
            <Button orange onClick={ToggleAddModal}>
              Add category
            </Button>
          </Flex>
        )}

        <Separator />
        {noCategories ? (
          <NoItems title='Categories' />
        ) : (
          <ItemsGrid columns={3} gap='2rem' mt='20px'>
            {categories.map(({ name, id, fields, image }: ISingleCategory) => {
              return (
                <CategoryCard key={id} onClick={() => navigate('/resources-categories/' + id)}>
                  <img src={image} alt='' />
                  <TopRight>
                    <H4WhiteBackgournd>{name && name.toUpperCase()}</H4WhiteBackgournd>
                  </TopRight>
                  <BottomRight>
                    <EditContainer
                      onClick={(e) => {
                        e.stopPropagation()
                        ToggleEditModal(name, id, image, fields)
                      }}
                    >
                      <span>{EditIcon}</span>
                    </EditContainer>
                  </BottomRight>
                </CategoryCard>
              )
            })}
          </ItemsGrid>
        )}
        {modal.open && (
          <Modal black>
            <CategoryModal
              edit={modal.edit}
              close={setModal}
              id={modal.id}
              text={modal.text}
              image={modal.image}
              fields={modal.fields}
            />
          </Modal>
        )}
      </PageWrapper>
      {/* <PageFooter>
        <Pagination
          setPage={(selected) => navigate('?page=' + selected + '&limit=30')}
          pageCount={Math.ceil(totalPages)}
          forcePage={page - 1}
        />
      </PageFooter> */}
    </>
  )
}
