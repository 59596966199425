import React from 'react'
import { useDrop } from 'react-dnd'

/*eslint-disable*/

export const DropMainTaskWorkflow = ({ state, setState }) => {
  const handleDrop = (item) => {
    setState((prev) => {
      const copy = structuredClone(prev)
      const index = copy.items.reduce((count, item) => (item.level === 0 ? count + 1 : count), 0)
      copy.items.push({
        taskTemplateId: item.id,
        name: item.name,
        level: 0,
        index,
        parentItems: [],
      })
      return copy
    })
  }
  let [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'item',
      drop: handleDrop,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [state],
  )
  return (
    <div
      className='wf-main-drop-area'
      style={{ background: isOver ? '#dadada' : '#ffffff' }}
      ref={drop}
    >
      Add task
    </div>
  )
}
