import { Label } from 'components/Input/Input.styled'
import { CircleButton } from 'pages/Shifts/Shifts.styled'
import React from 'react'
import { Flex } from 'styling/GlobalStyles'

export const CheckBox = ({
  state,
  onClick,
  title,
}: {
  state: boolean
  onClick?: () => void
  title: string
}) => {
  return (
    <Flex center gap='1rem' width='100%' height='1.5rem'>
      <CircleButton checked={state} onClick={onClick} />
      <Label nomarg labelText={title} />
    </Flex>
  )
}
