import React from 'react'
import {
  Animation,
  Flex,
  Title,
  TabFooter,
  Button,
  SelectedItemsContainer,
} from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { TriggersCollapseItem } from './IssueTriggersCollapseItem'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IIssueTriggersTab } from 'types/issue-triggers.interface'

export const IssueTriggersCyclesTab = ({ state, setState, handleSubmit }: IIssueTriggersTab) => {
  const handleAdd = (item: any) => {
    setState((prev) => {
      const copy = { ...prev }
      const exists = copy.parentTaskCycleTemplates.find((pt) => pt.id === item.id)
      if (exists) {
        copy.parentTaskCycleTemplates = copy.parentTaskCycleTemplates.filter((i) => i.id !== item.id)
      } else {
        copy.parentTaskCycleTemplates.push(item)
      }
      return copy
    })
  }

  return (
    <Animation>
      <Flex between gap='2rem'>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Task Templates</Title>
          {state.parentTaskCycleTemplates
            ?.filter((pt) => state.parentTaskCycleTemplates.some((i) => pt.id == i.id))
            .map((item) => {
              return (
                <TriggersCollapseItem
                  prop='parentTaskCycleTemplates'
                  setState={setState}
                  key={item.id}
                  data={item}
                />
              )
            })}
        </SelectedItemsContainer>
        <ItemsPick
          selectedList={state.parentTaskCycleTemplates}
          handleAdd={handleAdd}
          link='task/cycle/template'
          placeholder='search task cycles'
        />
      </Flex>
      <TabFooter>
        <Flex gap='1rem'>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </TabFooter>
    </Animation>

  )
}



