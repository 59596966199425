import React, { useState, useEffect } from 'react'
import { Flex, CursorPointer, Separator, Button, H1 } from '../../styling/GlobalStyles'
import { Input } from '../Input/Input'
import { CloseIcon } from '../../assets/icons/CloseIcon'
import { useDispatch } from 'react-redux'
import { editShift } from 'features/shifts/shiftsSlice'
import { HonestTimePickerInput } from 'components/HonestTimePicker/HonestTimePickerInput'
import { AppDispatch } from 'store'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { toast } from 'react-toastify'
import { ISingleShift } from 'types/shifts.interface'

export const ShiftsModal = ({ close, shift }: IShiftsModal) => {
  const [state, setState] = useState<ISingleShift>(shift)
  const dispatch = useDispatch<AppDispatch>()

  useFocusFirstInput()

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => {
      return { ...prev, name: e.target.value }
    })
  }
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        event.preventDefault()
        handleClose()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const handleTimeChange = (time: string, name: string) => {
    const splitted = time.split(':')
    const minutes = parseInt(splitted[0]) * 60 + parseInt(splitted[1])
    if (name == 'shiftStart') {
      setState((prev) => {
        return { ...prev, shiftStart: minutes }
      })
    } else {
      setState((prev) => {
        const copy = { ...prev }
        if (minutes > copy.shiftStart) {
          return { ...copy, minutes: minutes - copy.shiftStart }
        } else {
          return { ...copy, minutes: 1440 - copy.shiftStart + minutes }
        }
      })
    }
  }

  const handleSubmit = async () => {
    const send: any = { ...state }
    if (state.name.length <= 2 || state.info.length <= 5) {
      toast.warn('Name and description must be valid')
      return
    }

    delete send.createdAt
    delete send.updatedAt
    const resp = await dispatch(editShift(send))
    if (resp.meta.requestStatus === 'fulfilled') {
      handleClose()
    }
  }

  const handleClose = () => {
    close((prev) => ({ ...prev, isOpen: false }))
  }

  return (
    <div>
      <Flex column>
        <Flex between width='100%'>
          <H1>Edit {shift.name}</H1>
          <CursorPointer onClick={handleClose}>
            <CloseIcon />
          </CursorPointer>
        </Flex>
        <Separator />

        <Flex between column maxHeight='400px'>
          <Input
            placeholder='Enter shift title'
            type='text'
            onChange={handleName}
            value={state.name}
            labelText='Shift Title'
            invalid={state?.name?.length <= 2}
            customInvalidMessage="Name must be longer than 2 characters"
          />
          <HonestTimePickerInput
            labelText='From'
            onChange={(time) => handleTimeChange(time, 'shiftStart')}
            defaultValue={state ? state.shiftStart : null}
          />
          <HonestTimePickerInput
            labelText='To'
            onChange={(time) => handleTimeChange(time, 'minutes')}
            defaultValue={state ? state.minutes : null}
            calculated={state.shiftStart}
          />
          <Input
            type='textarea'
            minHeight='90px'
            placeholder='Enter shift description'
            value={state.info}
            onChange={(e) => {
              setState((prev) => {
                {
                  return { ...prev, info: e.target.value }
                }
              })
            }}
            labelText='Shift Description*'
            nomarg
            invalid={state?.info?.length <= 5}
            customInvalidMessage="Description must be longer than 5 characters"
          />
        </Flex>
        <Flex mt='5' end='true' width='100%'>
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </Flex>
    </div>
  )
}


interface IShiftsModal {
  close: React.Dispatch<React.SetStateAction<{
    shift: ISingleShift;
    isOpen: boolean;
  }>>
  shift: ISingleShift
}