import { NavBar } from 'components/NavBar/NavBar'
import { IDBMachineUserTask } from 'features/dashboard/types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { Animation, Flex, H1WithBack, PageWrapper, Separator } from 'styling/GlobalStyles'
import { DBTaskStatus, DBTaskTimelineItem, DBTimelineTitle } from '../DashboardTasks/DBTasks.styled'
import { ImagesSlider } from 'components/ImagesSlider/ImagesSlider'
import { getSingleDBMachine } from 'features/dashboard/dbMachinesSlice'
import { DBMachineDetail, DBMachineStatusIcon } from './DBMachines.styled'
import { machineryStatusesData } from '../dashboardData'
import { getTime } from 'utils/helpers'

interface ITimelineItem {
    data: IDBMachineUserTask
}
const TimelineItem = ({ data }: ITimelineItem) => {

    const [open, setOpen] = useState(false)
    return <DBTaskTimelineItem onClick={() => setOpen(prev => !prev)} >
        <DBTimelineTitle status={data.status} >
            <Flex column gap='8px' width='100%' alignStart >
                <Flex width='100%' between center ><h3>{data.taskTemplateName}</h3><p>01.02.2023.</p></Flex>
                {open && <>
                    <Separator />
                    <Flex width='100%' between>
                        <p>Location: </p>
                        <p>{data?.locationName}</p>
                    </Flex>
                    <Flex width='100%' between>
                        <p>Actual Duration: </p>
                        <p>{data.totalDuration ? getTime(data.totalDuration) : '-'}</p>
                    </Flex>
                    <Flex width='100%' between>
                        <p>Employee: </p>
                        <p>{data.employeeFirstName + ' ' + data.employeeLastName}</p>
                    </Flex>
                </>}
            </Flex>
        </DBTimelineTitle>
    </DBTaskTimelineItem>
}

export const DBSingleMachine = () => {

    const dispatch = useDispatch<AppDispatch>()
    const { id } = useParams()
    const [imagesModal, setImagesModal] = useState({ open: false, images: [] })
    const { singleDBMachine } = useSelector((state: RootState) => state.dbMachines)

    useEffect(() => {
        if (id) {
            dispatch(getSingleDBMachine(id))
        }
    }, [id])

    const ownership = singleDBMachine?.machine.ownership
    const usageType = singleDBMachine?.machine.usageType

    const Status = () => {
        const status = machineryStatusesData.find(s => s.id == singleDBMachine?.machine.status)
        return <DBTaskStatus status={singleDBMachine?.machine.status} >
            <DBMachineStatusIcon color={status?.iconColor} >
                {status?.icon}
            </DBMachineStatusIcon>
            <p>{status?.name}</p>
        </DBTaskStatus>
    }

    return <>
        <NavBar />
        <Animation>
            <PageWrapper noFooter>
                <Flex width='100%' between >
                    <H1WithBack arrow title={singleDBMachine?.machine.name} />
                    <Status />
                </Flex>
                <Flex width='100%' gap='2rem' mt='30'>
                    <Flex width='60%' column >
                        <h3 style={{ fontSize: '16px' }} >General Info</h3>
                        <Flex width='100%' gap='2rem' >
                            <DBMachineDetail width='30%' >
                                Machine Name
                            </DBMachineDetail>
                            <DBMachineDetail width='70%' >
                                {singleDBMachine?.machine.name}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem' >
                            <DBMachineDetail width='30%' >
                                Category
                            </DBMachineDetail>
                            <DBMachineDetail width='70%' >
                                {singleDBMachine?.machine.machineCategory.name}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem' >
                            <DBMachineDetail width='30%' >
                                Model
                            </DBMachineDetail>
                            <DBMachineDetail width='70%' >
                                {singleDBMachine?.machine.machineryModel.name}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem' >
                            <DBMachineDetail width='30%' >
                                Task
                            </DBMachineDetail>
                            <DBMachineDetail width='70%' >
                                {singleDBMachine?.machine.taskTemplate?.name || '-'}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem' >
                            <DBMachineDetail width='30%' >
                                Location
                            </DBMachineDetail>
                            <DBMachineDetail width='70%' >
                                {singleDBMachine?.machine.taskLocation?.name || '-'}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem' >
                            <DBMachineDetail width='30%' >
                                {ownership == 1 ? 'Price' : 'Cost Per Hour'}
                            </DBMachineDetail>
                            <DBMachineDetail width='70%' >
                                {ownership == 1 ? !singleDBMachine?.machine.machineCost ? singleDBMachine?.machine.machineCost.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }) : '-' : singleDBMachine?.machine.costPerHour}
                            </DBMachineDetail>
                        </Flex>
                        <Flex width='100%' gap='2rem' >
                            <DBMachineDetail width='30%' >
                                {usageType == 1 ? 'Total Working Hours' : 'Total Working Kilometers'}
                            </DBMachineDetail>
                            <DBMachineDetail width='70%' >
                                {usageType == 1 ? singleDBMachine?.machine.hoursWorked : singleDBMachine?.machine.mileage}
                            </DBMachineDetail>
                        </Flex>
                    </Flex>
                    <Flex width='40%' column>
                        <h3 style={{ fontSize: '16px' }}>Task Timeline</h3>
                        <Flex column mt='20' gap='1rem' >
                            {singleDBMachine?.userTasks.map(ut => <TimelineItem key={ut.id} data={ut} />)}
                        </Flex>
                    </Flex>
                </Flex>
            </PageWrapper>
        </Animation>
        {imagesModal.open && <ImagesSlider images={imagesModal.images} setImagesModal={setImagesModal} />}
    </>
}