export const Type27Options = [{
    label:'Soil',
    value:1
},
{
    label:'Sand',
    value:2
},{
    label:'Yellow Stone',
    value:3
},{
    label:'Other',
    value:4
}]