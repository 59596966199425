import styled from 'styled-components'

interface NavBarWrapperProps {
  sidebar?: boolean
}

export const NavBarWrapper = styled.div<NavBarWrapperProps>`
  position: fixed;
  top: 1rem;
  right: 1rem;
  height: 4rem;
  left: ${({ sidebar }) => (sidebar ? 'calc(15rem + 2rem)' : '6rem')};
  transition: all 0.5s ease-in-out;
  background: #ffffff;
  box-shadow: 0px 0px 1.8rem rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  overflow: hidden;
  form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`

export const NavBarInput = styled.div`
  display: flex;
  align-items: center;
  input {
    height: 1.3rem;
    width: 15.6rem;
    background-color: transparent;
    border: none;
    outline: none;
    text-transform: capitalize;

    font-size: 1rem;
    line-height: 1.3rem;
    color: ${(props) => props.theme.colorGray};
  }
  button {
    color: ${(props) => props.theme.colorGray};
    background-color: transparent;
    border: none;
  }
  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`
export const NavBarUser = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  h3 {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.3rem;
    text-align: right;
    color: ${(props) => props.theme.colorGray};
  }
  span {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.2rem;
    text-align: right;
    color: ${(props) => props.theme.colorGray};
  }
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const UserButtonsContiner = styled.div`
  display: flex;
  gap: 1rem;
  padding: 5px;
  width: 0%;
  overflow: hidden;
  animation: widen 0.8s ease forwards;

  @keyframes widen {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
`

export const NavBarFilterContainer = styled.div`
  display: flex;
`

export const NavBarFilterSelect = styled.select`
  border: none;

  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.colorGray};
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
`

export const NavBarFilterOption = styled.option`
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.colorGray};
  border: none;
  border-radius: 0%;
  margin-top: 0.3rem;
  padding: 0.3rem;
`
interface ProfileProps {
  show?: boolean
}
export const Profile = styled.div<ProfileProps>`
  display: flex;
  align-items: center;
  gap: ${({ show }) => (show ? '1rem' : '0.5rem')};
  transition: all 0.5s ease-in-out;
`
interface ShowLogoutProps {
  show?: boolean
}
export const UserBtn = styled.div<ShowLogoutProps>`
  border-radius: 10px;
  background-color: ${(props) => props.theme.colorGray};
  padding: 10px;
  display: flex;
  width: 70px;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.show ? 'translate(0)' : 'translate(130px)')};
  color: white;
  cursor: pointer;
  transition: all 0.5s ease-in;
`
