import React from 'react'
import {
  Title,
  TabFooter,
  Button,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
  Animation,
} from 'styling/GlobalStyles'
import { IDepartmentsState, IDepratmentsTab } from 'types/departments.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { ITableItem } from 'types/global.interface'

export const DepartmentProcessesTab = ({ state, setState, handleSubmit }: IDepratmentsTab) => {
  const handleAdd = (item: any) => {
    setState((prev: IDepartmentsState) => {
      const copy = { ...prev }
      if (copy.processTemplates.some((p) => p.id == item.id)) {
        copy.processTemplates = copy.processTemplates.filter((t) => t.id !== item.id)
      } else {
        copy.processTemplates = [...copy.processTemplates, item]
      }
      return copy
    })
  }

  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Process Templates</Title>
          {state.processTemplates?.map((item: ITableItem) => {
            return (
              <SelectedItem
                setState={setState}
                key={item.id}
                data={item}
                type='processTemplates'
              />
            )
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='process/template/company'
          selectedList={state.processTemplates}
          placeholder='Search Process Templates'
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}

