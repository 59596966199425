import React, { useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import {
  PageWrapper,
  Flex,
  MainHeadline,
  Button,
  Separator,
  GridWrapper,
  ItemsGrid,
  GridItem,
  PageFooter,
} from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { clearIssueTriggersList, deleteIssueTrigger, getIssueTriggers } from 'features/triggers/issueTriggersSlice'
import { Pagination } from 'components/Pagination/Pagination'
import { setConfirmModal } from 'features/global/globalSlice'
import { getParams, handlePagination } from 'utils/helpers'
import { ITableItem, ModalTypes } from 'types/global.interface'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import useQueryPagination from 'hooks/useQueryPagination'

export const Triggers = () => {
  const navigate = useNavigate()
  const { issueTriggers, totalPages, noItems } = useSelector((store: RootState) => store.issueTriggers)
  const dispatch = useDispatch<AppDispatch>()
  const [params, setParams] = useState<any>(getParams({ location }))

  useQueryPagination({ fetchFunctions: [getIssueTriggers], cleanUpFunctions: [clearIssueTriggersList], params })

  const handleRemove = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteIssueTrigger(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: issueTriggers.length < 2,
        },
        word: 'delete trigger',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Issue Triggers...' setParams={setParams} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Issue Triggers</MainHeadline>
          <Button orange onClick={() => navigate('/triggers-issue/add')}>
            Add Trigger
          </Button>
        </Flex>
        <Separator />
        <GridWrapper>
          {noItems ? <NoItems title='Issue Triggers' /> : <ItemsGrid columns={3}>
            {issueTriggers.map((item: ITableItem) => {
              return (
                <GridItem key={item.id}>
                  <p onClick={() => navigate('/triggers-issue/edit/' + item.id)}>{item.name}</p>
                  <Flex center justcenter onClick={() => handleRemove(item.id)}>
                    <RemoveCircle />
                  </Flex>
                </GridItem>
              )
            })}
          </ItemsGrid>}
        </GridWrapper>
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
