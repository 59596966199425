export const machinerySpecificationTabInputs = [
  'name',
  'machineCategory',
  'machineryModel',
  'usageType',
  'ownership',
  'lifetime',
  'maint-year',
  'maint-q1',
  'maint-q2',
  'maint-q3',
  'maint-q4',
  'status'
]
