import React from 'react'


export const EditIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.5581 2.05806C14.8021 1.81398 15.1979 1.81398 15.4419 2.05806L17.9419 4.55806C18.186 4.80214 18.186 5.19786 17.9419 5.44194L10.4419 12.9419C10.3247 13.0592 10.1658 13.125 10 13.125H7.5C7.15482 13.125 6.875 12.8452 6.875 12.5V10C6.875 9.83424 6.94085 9.67527 7.05806 9.55806L14.5581 2.05806ZM8.125 10.2589V11.875H9.74112L16.6161 5L15 3.38388L8.125 10.2589Z' />
    <path d='M12.6831 3.93306C12.9271 3.68898 13.3229 3.68898 13.5669 3.93306L16.0669 6.43306C16.311 6.67714 16.311 7.07286 16.0669 7.31694C15.8229 7.56102 15.4271 7.56102 15.1831 7.31694L12.6831 4.81694C12.439 4.57286 12.439 4.17714 12.6831 3.93306Z' />
    <path d='M2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5H10.625C10.9702 2.5 11.25 2.77982 11.25 3.125C11.25 3.47018 10.9702 3.75 10.625 3.75L3.75 3.75L3.75 16.25H16.25V9.375C16.25 9.02982 16.5298 8.75 16.875 8.75C17.2202 8.75 17.5 9.02982 17.5 9.375V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612Z' />
  </svg>
)
