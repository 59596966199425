import React from 'react'
import { TabFooter, Button, Flex, Animation } from 'styling/GlobalStyles'
import { Input } from 'components/Input/Input'
import { CancelBtn } from 'components/Button/CancelBtn'
import { IProcessTemplatesState, IProcessTemplateTab } from 'types/processTemplates'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const ProcessTemplatesSpecificationTab = ({ state, setState, handleSubmit, view }: IProcessTemplateTab) => {
    useFocusFirstInput()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev: IProcessTemplatesState) => {
            const copy = { ...prev }
            copy.name = e.target.value
            return copy
        })
    }

    return (
        <Animation>
            <Flex gap='1rem'>
                <Input
                    type='text'
                    value={state?.name}
                    onChange={handleChange}
                    placeholder='Process template name'
                    labelText='Process template name'
                    invalid={state?.name?.length < 2 ? 'true' : ''}
                    width='15rem'
                    view={view}
                />
            </Flex>

            {handleSubmit && (
                <TabFooter>
                    <Flex width='100%' end='true'>
                        <CancelBtn />
                        <Button ml='15' height='40' onClick={handleSubmit} orange>
                            Save
                        </Button>
                    </Flex>
                </TabFooter>
            )}
        </Animation>

    )
}
