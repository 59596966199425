import { IOverviewByDepartments } from 'features/finances/financesSlice'
import { LastCardInGraph } from '../../components/Charts/GraphCard.styled'
import { HR, P } from '../../styling/GlobalStyles'

export const InfoCard = ({ title, data }: IInfoCard) => {
  return (
    <LastCardInGraph>
      <h3>{title}</h3>
      <div>
        {Array.isArray(data) ? (
          data.map((item, index) => {
            const { shift, number, percent } = item
            return (
              <div key={index}>
                <section>
                  <p>{shift}</p>
                  <div>
                    <strong>{number}</strong>
                    <span> ({percent}%)</span>
                  </div>
                </section>
                <HR />
              </div>
            )
          })
        ) : (
          <div>
            <section>
              <p>Number of Tasks</p>
              <div>
                <strong>{data.tasksCount + `${data.tasksCount !== 1 ? ' TASKS' : ' TASK'}`}</strong>
              </div>
            </section>
            <section>
              <P>Estimated Cost</P>
              <div>
                <strong> {data.estimatedCost.toFixed(2)}</strong>
              </div>
            </section>
            <section>
              <P>Actual Cost</P>
              <div>
                <strong> {data.actualCost.toFixed(2)}</strong>
              </div>
            </section>
          </div>
        )}
      </div>
    </LastCardInGraph>
  )
}

interface IInfoCard {
  title: string
  data: IOverviewByDepartments
}