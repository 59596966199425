import React from 'react'
import { CollapseItem } from 'components/CollapseItem/CollapseItem'
import {
  Title,
  Button,
  Animation,
  TabFooter,
  SelectedItemsContainer,
  ButtonsContainer,
  ItemsPickContainer,
} from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IOperation } from 'types/operations.interface'

export const MachineryCapabilitiesTab = ({ handleSubmit, state, setState }) => {
  const handleAdd = (item: IOperation) => {
    if (!state?.operations?.some((i: IOperation) => i.id == item.id)) {
      const parsedTypes = item.types.map((type) => {
        delete type.parameters
        return {
          ...type,
          rating: 0,
          priority: 0,
        }
      })

      const parsedItem = { ...item, types: parsedTypes }
      setState((prev) => {
        const copy = { ...prev }
        copy.operations = [...copy.operations, parsedItem]
        return copy
      })
    } else {
      setState((prev) => {
        const copy = { ...prev }
        copy.operations = copy.operations.filter((it) => it.id !== item.id)
        return copy
      })
    }
  }
  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title fontSize='0.9rem' pad='0 20px'>
            Selected Capabilities
          </Title>
          {state?.operations?.map((item: IOperation) => {
            return <CollapseItem setState={setState} key={item.id} data={item} />
          })}
        </SelectedItemsContainer>
        <ItemsPick
          link='operations/filled'
          selectedList={state.operations}
          handleAdd={handleAdd}
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}


