import { NavBar } from 'components/NavBar/NavBar'
import { clearClientRole, getSingleClientRole, updateClientRole } from 'features/roles/clientRolesSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Flex,
  TabsContainer,
  Tabs,
  H1WithBack,
  PageWrapper,
  ContentWrapper,
} from 'styling/GlobalStyles'
import { CapabilitiesRolesTab } from './CapabilitiesRolesTab'
import { InfoRolesTab } from './InfoRolesTab'
import { MachineryRolesTab } from './MachineryRolesTab'
import { useNavigate, useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { IRole } from 'types/roles.interface'
import { toast } from 'react-toastify'
import { PermissionsRolesTab } from './PermissionsRolesTab'

export const EditRole = () => {
  const [active, setActive] = useState('Info')
  const { singleRole } = useSelector((store: RootState) => store.clientRoles)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { id } = useParams()

  const [state, setState] = useState<IRole>({
    name: '',
    machineModels: [],
    operations: [],
    id: parseInt(id),
    permissions: {
      dashboard: false,
      globalView: false,
      employees: false,
      shifts: false,
      machinery: false,
      inventory: false,
      locations: false,
      departments: false,
      reports: false,
      finances: false,
      zones: false,
      roles: false
    }
  })

  useEffect(() => {
    dispatch(getSingleClientRole(id))
    return () => {
      dispatch(clearClientRole())
    }
  }, [])

  useEffect(() => {
    if (singleRole) {
      setState(singleRole)
    }
  }, [singleRole])

  const handleSubmit = async () => {
    if (!state.name.length) {
      toast.warn('Role name must be filled')
      return
    }
    if (
      state.operations.length &&
      state.operations.some((o) => o.types.some((t) => t.parameters.some((p) => !p.value)))
    ) {
      toast.warn('All operation type values must be filled')
      return
    }

    const resp = await dispatch(updateClientRole(state))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/roles')
    }
  }

  const renderTabs = () => {
    if (active === 'Info') {
      return <InfoRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    } else if (active === 'Machines') {
      return <MachineryRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    } else if (active == 'Permissions') {
      return <PermissionsRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
    return <CapabilitiesRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title={'Edit ' + singleRole ? singleRole.name : ''} />
          <TabsContainer>
            <Tabs active={active === 'Info'} onClick={() => setActive('Info')}>
              Info
            </Tabs>
            <Tabs active={active === 'Machines'} onClick={() => setActive('Machines')}>
              Machines
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
            <Tabs active={active === 'Permissions'} onClick={() => setActive('Permissions')}>
              Permissions
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
