
export const EmployeesIconBlank = (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.25 5.625C4.86929 5.625 3.75 6.74429 3.75 8.125C3.75 9.50571 4.86929 10.625 6.25 10.625C7.63071 10.625 8.75 9.50571 8.75 8.125C8.75 6.74429 7.63071 5.625 6.25 5.625ZM2.5 8.125C2.5 6.05393 4.17893 4.375 6.25 4.375C8.32107 4.375 10 6.05393 10 8.125C10 10.1961 8.32107 11.875 6.25 11.875C4.17893 11.875 2.5 10.1961 2.5 8.125Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.25 6.25C11.25 5.90482 11.5298 5.625 11.875 5.625H19.375C19.7202 5.625 20 5.90482 20 6.25C20 6.59518 19.7202 6.875 19.375 6.875H11.875C11.5298 6.875 11.25 6.59518 11.25 6.25Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.25 10C11.25 9.65482 11.5298 9.375 11.875 9.375H19.375C19.7202 9.375 20 9.65482 20 10C20 10.3452 19.7202 10.625 19.375 10.625H11.875C11.5298 10.625 11.25 10.3452 11.25 10Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.125 13.75C13.125 13.4048 13.4048 13.125 13.75 13.125H19.375C19.7202 13.125 20 13.4048 20 13.75C20 14.0952 19.7202 14.375 19.375 14.375H13.75C13.4048 14.375 13.125 14.0952 13.125 13.75Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.25003 11.8652C5.27859 11.8652 4.33478 12.1885 3.5674 12.7842C2.80001 13.3798 2.2527 14.214 2.01175 15.155C1.92613 15.4894 1.58565 15.6911 1.25125 15.6055C0.916863 15.5199 0.715192 15.1794 0.80081 14.845C1.11061 13.635 1.8143 12.5626 2.80093 11.7967C3.78757 11.0309 5.00104 10.6152 6.25003 10.6152C7.49902 10.6152 8.71248 11.0309 9.69912 11.7967C10.6858 12.5626 11.3894 13.635 11.6992 14.845C11.7849 15.1794 11.5832 15.5199 11.2488 15.6055C10.9144 15.6911 10.5739 15.4894 10.4883 15.155C10.2474 14.214 9.70004 13.3798 8.93266 12.7842C8.16527 12.1885 7.22146 11.8652 6.25003 11.8652Z'
        />
    </svg>
)
