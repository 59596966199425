export const TruckIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.0769 6.87501L18.1697 9.60713C18.2979 9.92762 18.6616 10.0835 18.9821 9.95531C19.3026 9.82712 19.4585 9.46338 19.3303 9.14289L18.2376 6.41122C18.2374 6.41069 18.2372 6.41015 18.237 6.40962C18.1449 6.17718 17.9848 5.97792 17.7776 5.83792C17.5706 5.69806 17.3262 5.62387 17.0765 5.62501H17.0781V6.25001L17.0741 5.62503C17.0749 5.62502 17.0757 5.62502 17.0765 5.62501'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.625 11.25C0.625 10.9048 0.904822 10.625 1.25 10.625H13.75C14.0952 10.625 14.375 10.9048 14.375 11.25C14.375 11.5952 14.0952 11.875 13.75 11.875H1.25C0.904822 11.875 0.625 11.5952 0.625 11.25Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6875 13.75C13.9971 13.75 13.4375 14.3096 13.4375 15C13.4375 15.6904 13.9971 16.25 14.6875 16.25C15.3779 16.25 15.9375 15.6904 15.9375 15C15.9375 14.3096 15.3779 13.75 14.6875 13.75ZM12.1875 15C12.1875 13.6193 13.3068 12.5 14.6875 12.5C16.0682 12.5 17.1875 13.6193 17.1875 15C17.1875 16.3807 16.0682 17.5 14.6875 17.5C13.3068 17.5 12.1875 16.3807 12.1875 15Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.3125 13.75C4.62214 13.75 4.0625 14.3096 4.0625 15C4.0625 15.6904 4.62214 16.25 5.3125 16.25C6.00286 16.25 6.5625 15.6904 6.5625 15C6.5625 14.3096 6.00286 13.75 5.3125 13.75ZM2.8125 15C2.8125 13.6193 3.93179 12.5 5.3125 12.5C6.69321 12.5 7.8125 13.6193 7.8125 15C7.8125 16.3807 6.69321 17.5 5.3125 17.5C3.93179 17.5 2.8125 16.3807 2.8125 15Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5625 15C6.5625 14.6548 6.84232 14.375 7.1875 14.375H12.8125C13.1577 14.375 13.4375 14.6548 13.4375 15C13.4375 15.3452 13.1577 15.625 12.8125 15.625H7.1875C6.84232 15.625 6.5625 15.3452 6.5625 15Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.991116 4.74112C1.22554 4.5067 1.54348 4.375 1.875 4.375H13.75C14.0952 4.375 14.375 4.65482 14.375 5V13.375C14.375 13.7202 14.0952 14 13.75 14C13.4048 14 13.125 13.7202 13.125 13.375V5.625L1.875 5.625V14.375H3.4375C3.78268 14.375 4.0625 14.6548 4.0625 15C4.0625 15.3452 3.78268 15.625 3.4375 15.625H1.875C1.54348 15.625 1.22554 15.4933 0.991116 15.2589C0.756696 15.0245 0.625 14.7065 0.625 14.375V5.625C0.625 5.29348 0.756696 4.97554 0.991116 4.74112Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.125 9.375C13.125 9.02982 13.4048 8.75 13.75 8.75H18.75C19.0952 8.75 19.375 9.02982 19.375 9.375V14.375C19.375 14.7065 19.2433 15.0245 19.0089 15.2589C18.7745 15.4933 18.4565 15.625 18.125 15.625H16.5625C16.2173 15.625 15.9375 15.3452 15.9375 15C15.9375 14.6548 16.2173 14.375 16.5625 14.375H18.125V10H13.75C13.4048 10 13.125 9.72018 13.125 9.375Z'
    />
  </svg>
)
