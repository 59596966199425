import { theme } from 'styling/GlobalStyles'

// lists for ReactSelect value
export const genderList = [
  { value: 'm', label: 'Male' },
  { value: 'f', label: 'Female' },
]
export const accessTypeList = [
  { value: 'manager', label: 'Managment access' },
  { value: 'worker', label: 'Worker access' },
  { value: 'supervisor', label: 'Supervisor access' },
]
export const driverLicense = [
  { value: null, label: 'None' },
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'c', label: 'C' },
  { value: 'd', label: 'D' },
]
export const maritalStatus = [
  { value: 'married', label: 'Married' },
  { value: 'single', label: 'Single' },
]

export const contractIndefiniteList = [
  { value: 'indefinite', label: 'Indefinite' },
  { value: 'definite', label: 'Definite' },
]

// end of lists for ReactSelect value

// chart colors
export const greenChartColors = [`${theme.greenChart}`, `${theme.grayChart}`]
export const brownChartColors = [`${theme.brownChart}`, `${theme.grayChart}`]
export const blueChartColors = [`${theme.blueChart}`, `${theme.grayChart}`]
export const redChartColors = [`${theme.redChart}`, `${theme.grayChart}`]

// funcs
export const modifiedStats = (name, state) => {
  // this data is modified as to create 2 values that would display in graph
  // problem with current lib is that you can't set 100% and compare one value with it

  let userStats
  const values = { ...state }

  switch (name) {
    case 'active':
      userStats = [
        { name: 'Active Workers', value: values.active },
        { name: 'Total Workers', value: values.totalWorkers },
      ]
      userStats = userStats.map((i) => {
        if (i.name === 'Total Workers') {
          const [activeWorkers, totalWorkers] = userStats
          const result = totalWorkers.value - activeWorkers.value

          return { name: 'Total Workers', value: result }
        }
        return i
      })

      return userStats

    case 'vacation':
      userStats = [
        { name: 'Workers On Vacation', value: values.vacation },
        { name: 'Total Workers', value: values.totalWorkers },
      ]
      userStats = userStats.map((i) => {
        if (i.name === 'Total Workers') {
          const [activeWorkers, totalWorkers] = userStats
          const result = totalWorkers.value - activeWorkers.value
          return { name: 'Total Workers', value: result }
        }
        return i
      })
      return userStats

    case 'sick':
      userStats = [
        { name: 'Workers On Sick Relief', value: values.sickLeave },
        { name: 'Total Workers', value: values.totalWorkers },
      ]
      userStats = userStats.map((i) => {
        if (i.name === 'Total Workers') {
          const [activeWorkers, totalWorkers] = userStats
          const result = totalWorkers.value - activeWorkers.value
          return { name: 'Total Workers', value: result }
        }
        return i
      })
      return userStats

    default:
      break
  }
}

export const originStats = (title, values) => {
  const stats = [{ name: 'Total Workers', value: values?.totalWorkers }]

  const string = title.toLowerCase()

  const param = string.includes('active')
    ? 'active'
    : string.includes('sick')
    ? 'sickLeave'
    : string.includes('vacation')
    ? 'vacation'
    : ''

  stats.unshift({ name: title, value: values ? values[param] : null})

  return stats
}
