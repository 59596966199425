import React, { useEffect, useState } from 'react'
import {
  cleanSingleClientMachine,
  getSingleClientMachinery,
  updateClientMachinery,
} from 'features/clientMachinery/clientMachinerySlice'
import { useDispatch, useSelector } from 'react-redux'
import { NavBar } from '../../components/NavBar/NavBar'
import {
  Flex,
  H1WithBack,
  TabsContainer,
  Tabs,
  PageWrapper,
  ContentWrapper,
} from '../../styling/GlobalStyles'
import { MachineryCapabilitiesTab } from './MachineryCapabilitiesTab'
import { MachinerySpecificationTab } from './MachinerySpecificationTab'
import { useParams, useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import { IMachinery } from 'types/machinery.interface'
import { toast } from 'react-toastify'

export const EditMachinery = () => {
  const [active, setActive] = useState('Specification')
  const dispatch = useDispatch<AppDispatch>()
  const { singleClientMachine } = useSelector((store: RootState) => store.clientMachinery)
  const [invalidFields, setInvalidFields] = useState([])
  const { id } = useParams()
  const [state, setState] = useState<IMachinery>(null)

  const navigate = useNavigate()


  useEffect(() => {
    dispatch(getSingleClientMachinery(id))
    return () => {
      dispatch(cleanSingleClientMachine())
    }
  }, [])

  useEffect(() => {
    if (singleClientMachine) {
      setState(structuredClone(singleClientMachine))
    }
  }, [singleClientMachine])

  const handleSubmit = async () => {
    if (invalidFields.length > 0) {
      toast.warning('All specification fields except vehicle are required.')
      return
    }
    const resp = await dispatch(updateClientMachinery({ ...state, oldStatus: singleClientMachine.status }))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/machinery')
    }
  }

  const renderTabs = () => {
    if (active == 'Specification') {
      return (
        <MachinerySpecificationTab
          state={state}
          setState={setState}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          handleSubmit={handleSubmit}
          setActive={setActive}
        />
      )
    } else if (active == 'Capabilities') {
      return (
        <MachineryCapabilitiesTab state={state} setState={setState} handleSubmit={handleSubmit} />
      )
    } else {
      return (
        <MachinerySpecificationTab
          state={state}
          setState={setState}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          handleSubmit={handleSubmit}
          setActive={setActive}
        />
      )
    }
  }

  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack
            title={'Edit ' + (singleClientMachine?.name ? singleClientMachine?.name : '')}
            arrow
          />
          <TabsContainer>
            <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
              Basic Info
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}
