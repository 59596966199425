import React from 'react'
import {
  Title,
  Button,
  Animation,
  TabFooter,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
} from '../../../styling/GlobalStyles'
import { CollapseItemMachine } from './CollapseItemMachine'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IMachineModelGlobal } from 'types/machineModels.interface'
import { IRole, IRolesTab } from 'types/roles.interface'
import { INameId } from 'constants/globalTypes'

export const MachineryRolesTab = ({ state, setState, handleSubmit }: IRolesTab) => {
  const handleAdd = (item: INameId) => {
    if (!state.machineModels.some((i: IMachineModelGlobal) => i.id == item.id)) {
      const parsedItem = {
        id: item.id,
        priority: 0,
        rating: 0,
        name: item.name,
      }
      setState((prev: IRole) => {
        const copy = { ...prev }
        copy.machineModels = [...copy.machineModels, parsedItem]
        return copy
      })
    } else {
      setState((prev: IRole) => {
        const copy = { ...prev }
        copy.machineModels = copy.machineModels.filter((m) => m.id !== item.id)
        return copy
      })
    }
  }

  const parseMachineModels = (data: any) => {
    return data
      ? data.data.map((item: any) => ({
        name: item.machineModel.model,
        id: item.machineModel.id,
      }))
      : []
  }
  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Machines</Title>
          {state.machineModels.map((item: IMachineModelGlobal) => {
            return <CollapseItemMachine setState={setState} key={item.id} data={item} />
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='machine-models'
          selectedList={state.machineModels}
          placeholder='Search machine models'
          parserFunction={parseMachineModels}
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}
