import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
import { baseUrl } from '../../utils/axios'
import { dataURItoBlob, handleErrors, formatParams } from '../../utils/helpers'

const initialState = {
  machineryCategories: [],
  singleMachineryCategory: null,
  isLoading: false,
  isLoadingSingle: false,
  machineryCategoriesForSelect: [],
  noMachineryCategories: false,
  totalMachineryPages: 0,
}

export const getMachineryCategories = createAsyncThunk(
  'categories/getMachineryCategories',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp = await baseUrl.get('machine-categories', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addMachineryCategory = createAsyncThunk(
  'categories/addMachineryCategories',
  async (category: any, thunkAPI) => {
    try {
      const resp = await baseUrl.post(
        'machine-categories',
        { name: category.name, image: category.image ? true : false },
        thunkAPI,
      )

      const url = resp.data.putImagePresignedUrl
      if (category.image && category.image.length > 1) {
        const blobedImage = dataURItoBlob(category.image)
        try {
          resp.data.getImagePresignedUrl = category.image
          await axios.put(url, blobedImage)
          return resp.data
        } catch (error) {
          return error
        }
      }

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateMachineryCategory = createAsyncThunk(
  'categories/updateMachineryCategory',
  async (machineCategory: any, thunkAPI) => {
    const obj = {
      machineCategory: {
        id: machineCategory.id,
        name: machineCategory.name,
        image: machineCategory.image ? true : false,
        fields: [],
      },
      getImagePresignedUrl: machineCategory.localImage
        ? machineCategory.localImage
        : machineCategory.image
        ? machineCategory.image
        : null,
    }

    try {
      const { id } = machineCategory
      const resp = await baseUrl.patch(
        'machine-categories/' + id,
        {
          name: machineCategory.name,
          fields: machineCategory.fields || [],
          image: machineCategory.image ? true : false,
        },
        thunkAPI,
      )

      if (machineCategory.image) {
        const url = resp.data.putImagePresignedUrl
        const blobedImage = dataURItoBlob(machineCategory.image)

        try {
          await axios.put(url, blobedImage)
        } catch (error) {
          return error
        }
      }
      return obj
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleMachineryCategory = createAsyncThunk(
  'categories/getSingleMachineryCategory',
  async (id: string) => {
    try {
      const resp = await baseUrl.get('machine-categories/' + id)

      return resp.data
    } catch (error) {}
  },
)

export const deleteMachineryCategory = createAsyncThunk(
  'categories/deleteMachineryCategory',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('machine-categories/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const machineryCategoriesSlice = createSlice({
  name: 'machineryCategories',
  initialState,
  reducers: {
    clearSingleMachineryCategory: (state: any) => {
      state.singleMachineryCategory = {}
    },

    clearMachineryCategories: (state: any) => {
      state.machineryCategories = []
    },
  },
  extraReducers: {
    [getMachineryCategories.pending.type]: (state) => {
      state.isLoading = true
    },
    [getMachineryCategories.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.machineryCategories = payload.data
      state.totalMachineryPages = payload.totalPages
      state.machineryCategoriesForSelect = payload.data.map((category) => {
        return { label: category.name, value: category.id }
      })
      state.noMachineryCategories = state.machineryCategories.length < 1
    },
    [getMachineryCategories.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleMachineryCategory.pending.type]: (state) => {
      state.isLoadingSingle = true
    },
    [getSingleMachineryCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoadingSingle = false
      state.singleMachineryCategory = payload
    },
    [getSingleMachineryCategory.rejected.type]: (state) => {
      state.isLoadingSingle = true
    },

    [addMachineryCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [addMachineryCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.machineryCategories = [...state.machineryCategories, payload]
      state.noMachineryCategories = false
      toast.success('Category added')
    },
    [addMachineryCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },

    [updateMachineryCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateMachineryCategory.fulfilled.type]: (state, { payload }) => {
      const catIndex = state.machineryCategories.findIndex(
        (category) => category.id == payload.machineCategory.id,
      )
      state.machineryCategories[catIndex] = payload.machineCategory
      state.isLoading = false
      toast.success('Category updated')
    },
    [updateMachineryCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },

    [deleteMachineryCategory.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteMachineryCategory.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.machineryCategories = state.machineryCategories.filter(
        (category) => category.id !== payload,
      )
      toast.success('Category deleted')
    },
    [deleteMachineryCategory.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearSingleMachineryCategory, clearMachineryCategories } =
  machineryCategoriesSlice.actions

export default machineryCategoriesSlice.reducer
