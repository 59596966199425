import styled from 'styled-components'

export const WorkflowLogicContainer = styled.div`
  width: 100%;
  min-height: 500px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 0px 24.9826px rgba(0, 0, 0, 0.1);
  border-radius: 12.4913px;
  padding: 5rem 2rem;
  display: flex;
  gap: 1rem;
  position: relative;

  .toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`
export const WorkflowColumnsContainer = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  gap: 2rem;
  height: max-content;
`

export const WorkflowMainTask = styled.div`
  width: 150px;
  height: 70px;
  background: #ffffff;
  border: 1px solid #ff9900;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  box-shadow: 0px 0px 24.9826px rgba(0, 0, 0, 0.1);
`
export const WorkflowColumn = styled.div`
  padding: 0.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`
export const WorkflowSet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
