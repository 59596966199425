import React from 'react'

export const SuitcaseIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.875 6.25C1.875 5.55964 2.43464 5 3.125 5H16.875C17.5654 5 18.125 5.55964 18.125 6.25V16.25C18.125 16.9404 17.5654 17.5 16.875 17.5H3.125C2.43464 17.5 1.875 16.9404 1.875 16.25V6.25ZM16.875 6.25H3.125V16.25H16.875V6.25Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.79918 3.04917C7.15081 2.69754 7.62772 2.5 8.125 2.5H11.875C12.3723 2.5 12.8492 2.69754 13.2008 3.04917C13.5525 3.40081 13.75 3.87772 13.75 4.375V5.625C13.75 5.97018 13.4702 6.25 13.125 6.25C12.7798 6.25 12.5 5.97018 12.5 5.625V4.375C12.5 4.20924 12.4342 4.05027 12.3169 3.93306C12.1997 3.81585 12.0408 3.75 11.875 3.75H8.125C7.95924 3.75 7.80027 3.81585 7.68306 3.93306C7.56585 4.05027 7.5 4.20924 7.5 4.375V5.625C7.5 5.97018 7.22018 6.25 6.875 6.25C6.52982 6.25 6.25 5.97018 6.25 5.625V4.375C6.25 3.87772 6.44754 3.40081 6.79918 3.04917Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.875 12.5C1.875 12.1548 2.15482 11.875 2.5 11.875H17.5C17.8452 11.875 18.125 12.1548 18.125 12.5C18.125 12.8452 17.8452 13.125 17.5 13.125H2.5C2.15482 13.125 1.875 12.8452 1.875 12.5Z'
    />
  </svg>
)
