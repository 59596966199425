import React, { useState } from 'react'
import {
  Button,
  Table,
  Tr,
  Flex,
  LinkCustom,
  MainHeadline,
  PageWrapper,
  PageFooter,
  Separator,
  Th,
} from '../../styling/GlobalStyles'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearCompaniesList,
  deleteCompany,
  getCompanies,
  handleAddCompany,
} from '../../features/companies/companiesSlice'
import { NavBar } from '../../components/NavBar/NavBar'
import { Pagination } from 'components/Pagination/Pagination'
import { AppDispatch, RootState } from 'store'
import { setConfirmModal } from 'features/global/globalSlice'
import { ICompany } from 'types/companies.interface'
import { Options } from 'components/Options/Options'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { getParams, handlePagination } from 'utils/helpers'
import { Td } from 'components/Td/Td'
import { ModalTypes } from '../../types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const Companies: React.FC = () => {
  const { companies, totalPages, noCompanies } = useSelector((store: RootState) => store.companies)
  const dispatch = useDispatch<AppDispatch>()
  const [params, setParams] = useState<any>(getParams({ location, limit: 5 }))

  useQueryPagination({ fetchFunctions: [getCompanies], cleanUpFunctions: [clearCompaniesList], params })

  const removeCompany = (id: string) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteCompany(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: companies.length < 2,
        },
        word: 'delete company',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Companies...' setParams={setParams} limit={5} />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Companies</MainHeadline>
          <LinkCustom to='/companies/add'>
            <Button orange onClick={() => dispatch(handleAddCompany())}>
              Add company
            </Button>
          </LinkCustom>
        </Flex>
        <Separator />
        {noCompanies ? (
          <NoItems title='Companies' />
        ) : (
          <Table>
            <Th>
              <Td w='16%' noTooltip text={'COMPANY NAME'} />
              <Td w='16%' noTooltip text='INDUSTRY' />
              <Td w='16%' noTooltip text='ADDRESS' />
              <Td w='16%' noTooltip text='PHONE NUM' />
              <Td w='22%' noTooltip text='COMPANY EMAIL' />
              <Td w='10%' noTooltip text='COMPANY VAT' />
              <Td w='5%' />
            </Th>
            {companies &&
              companies.map((item: ICompany) => {
                return (
                  <Tr key={item.id}>
                    <Td w='16%' text={item.name} />
                    <Td w='16%' text={item?.industry?.name} />
                    <Td w='16%' text={item?.contact?.address} />
                    <Td w='16%' text={item?.contact?.phoneNumber} />
                    <Td w='22%' text={item?.contact?.email} />
                    <Td w='10%' text={item?.taxNumber} />
                    <Td w='4%'>
                      <Options item={item} title='Company' removeCompany={removeCompany} />
                    </Td>
                  </Tr>
                )
              })}
          </Table>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 5)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
