import React from 'react'

export const ThreeDots = (
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.2485 11.2641C10.7088 11.2641 11.082 10.891 11.082 10.4307C11.082 9.97033 10.7088 9.59717 10.2485 9.59717C9.7882 9.59717 9.41504 9.97033 9.41504 10.4307C9.41504 10.891 9.7882 11.2641 10.2485 11.2641Z'
      strokeWidth='1.66697'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2485 5.42972C10.7088 5.42972 11.082 5.05656 11.082 4.59624C11.082 4.13592 10.7088 3.76276 10.2485 3.76276C9.7882 3.76276 9.41504 4.13592 9.41504 4.59624C9.41504 5.05656 9.7882 5.42972 10.2485 5.42972Z'
      strokeWidth='1.66697'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2485 17.0985C10.7088 17.0985 11.082 16.7253 11.082 16.265C11.082 15.8047 10.7088 15.4315 10.2485 15.4315C9.7882 15.4315 9.41504 15.8047 9.41504 16.265C9.41504 16.7253 9.7882 17.0985 10.2485 17.0985Z'
      strokeWidth='1.66697'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
