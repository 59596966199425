import React from 'react'

const RemoveCircle = ({ white }: { white?: boolean }) =>
  white ? (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        d='M10.8407 19.8749C15.8301 19.8749 19.8747 15.8302 19.8747 10.8408C19.8747 5.85144 15.8301 1.80676 10.8407 1.80676C5.85132 1.80676 1.80664 5.85144 1.80664 10.8408C1.80664 15.8302 5.85132 19.8749 10.8407 19.8749Z'
        stroke='#F5F5F5'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.22754 10.8408H14.4548'
        stroke='#F5F5F5'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ) : (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        d='M10.8407 19.8749C15.8301 19.8749 19.8747 15.8302 19.8747 10.8408C19.8747 5.85144 15.8301 1.80676 10.8407 1.80676C5.85132 1.80676 1.80664 5.85144 1.80664 10.8408C1.80664 15.8302 5.85132 19.8749 10.8407 19.8749Z'
        stroke='#464255'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.22754 10.8408H14.4548'
        stroke='#464255'
        strokeWidth='1.66782'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )

export default RemoveCircle
