import styled from 'styled-components'

export const ZonesWrapper = styled.div``

export const ZonesSideMenu = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  padding: 2rem 0;
  right: 2rem;
  width: 4rem;
  height: 100%;
  z-index: 99999999;
`

export const ZonesMapContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: relative;
`

interface IZonesOrangeCircleProps {
  whiteIcon?: boolean
}

export const ZonesOrangeCircle = styled.div<IZonesOrangeCircleProps>`
  width: 42.5px;
  height: 42.5px;
  background: ${(props) => props.theme.colorOrange};
  box-shadow: 0px 5.83333px 13.3333px rgba(0, 0, 0, 0.14);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
  :hover {
    background-color: #d17f03;
  }
  ${(props) => props.whiteIcon && 'svg {fill:#fff}'};
`

export const ZonesMenuContainer = styled.div`
  min-width: 250px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 16.6667px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  bottom: 200%;
  right: 0;
  gap: 0.5rem;
  cursor: auto;
`

export const AreaInputsContainer = styled(ZonesMenuContainer)`
  top: 5%;
  left: 2%;
  position: absolute;
  z-index: 900000;
  height: 90%;
  max-width: 300px;
`

export const ZoneMenuItem = styled.div`
  width: 225px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(7, 6, 61, 0.15);
  border-radius: 8.33483px;
  font-weight: 700;
  font-size: 11.6688px;
  line-height: 40px;
  cursor: pointer;
  padding-left: 1rem;
  transition: 0.2s ease;

  :hover {
    background-color: #e0e0e0;
  }
`

export const ZoneMenuTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`

export const ZonesAddContainer = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
`

export const LatLongContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 16.6667px;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
align-items: flex-end;
  width: 400px;
  height: 100px;
`
export const ZonesSelectContainer = styled.div`
  background: #ffffff;
  min-width: 250px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 16.6667px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ZonesInputsWrapper = styled.div`
  height: 100%;
  width: 100%;
`

