import React from 'react'
import {
  Animation,
  Flex,
  Title,
  TabFooter,
  Button,
  SelectedItemsContainer,
} from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { IWorkflowTab } from 'types/workflows.interface'
import { ITableItem } from 'types/global.interface'

export const WorkflowCompaniesTab = ({ state, setState, handleSubmit }: IWorkflowTab) => {
  const handleAdd = (item: any) => {
    setState((prev) => {
      const copy = structuredClone(prev)
      const exists = copy.companies.find((company) => company.id === item.id)
      if (exists) {
        copy.companies = copy.companies.filter((c) => c.id !== item.id)
      } else {
        copy.companies.push(item)
      }
      return copy
    })
  }

  return (
    <Animation>
      <Flex between gap='2rem'>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Companies</Title>
          {state.companies
            .filter((company: ITableItem) => state.companies.some((i) => company.id == i.id))
            .map((item: ITableItem) => {
              return (
                <SelectedItem setState={setState} key={item.id} data={item} type='companies' />
              )
            })}
        </SelectedItemsContainer>
        <ItemsPick
          selectedList={state.companies}
          handleAdd={handleAdd}
          link='companies/simple'
          placeholder='search companies'
        />
      </Flex>
      {handleSubmit && (
        <TabFooter>
          <Flex gap='1rem'>
            <CancelBtn />
            <Button orange onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        </TabFooter>
      )}
    </Animation>
  )
}
