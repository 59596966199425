import React, { useEffect, useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import {
  Flex,
  TabsContainer,
  Tabs,
  H1WithBack,
  PageWrapper,
  ContentWrapper,
} from 'styling/GlobalStyles'
import { IndustryBasicInfoTab } from './IndustryBasicInfoTab'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import {
  clearIndustry,
  getSingleIndustry,
  updateIndustry,
} from 'features/industries/industriesSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const EditIndustry = () => {
  const [active, setActive] = useState('Basic Info')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { id } = useParams()
  const { singleIndustry } = useSelector((store: RootState) => store.industries)
  const [state, setState] = useState({
    name: '',
  })

  useFocusFirstInput()

  const handleSubmit = async () => {

    if (!state?.name?.length) {
      toast.warn('Name must not be empty')
    } else {
      const resp = await dispatch(updateIndustry(state))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/industries')
      }
    }
  }

  const renderTabs = () => {
    if (active === 'Basic Info') {
      return <IndustryBasicInfoTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
  }

  useEffect(() => {
    dispatch(getSingleIndustry(id))

    return () => {
      dispatch(clearIndustry())
    }
  }, [])

  useEffect(() => {
    if (singleIndustry) {
      let obj: any = state
      obj = structuredClone(singleIndustry)

      setState(obj)
    }
  }, [singleIndustry])

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack
            mb='1.5'
            arrow
            title={'Edit ' + (singleIndustry?.name ? singleIndustry.name : '')}
          />
          <TabsContainer>
            <Tabs active={active === 'Basic Info'} onClick={() => setActive('Basic Info')}>
              Basic Info
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
