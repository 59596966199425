import { CloseIcon } from 'assets/icons/CloseIcon'
import { Input } from 'components/Input/Input'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { AreaInputsContainer, ZoneMenuTitle, ZonesInputsWrapper } from 'pages/Zones/Zones.styled'
import { CursorPointer, H2 } from 'styling/GlobalStyles'
import { ICreating } from 'types/zones.interface'
import { handleStopPropagation } from 'utils/helpers'

export const AreaInputs = ({ setCreating, creating, handleCancel }) => {
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { value, name, type } = e.target as HTMLInputElement
        setCreating((prev: ICreating) => {
            const copy = structuredClone(prev)
            copy[name] = type === 'number' ? Number(value) : value
            return copy
        })
    }


    return (
        <AreaInputsContainer onClick={handleStopPropagation}>
            <ZoneMenuTitle>
                <H2 noUpperCase >Area info</H2>
                <CursorPointer onClick={handleCancel}>
                    <CloseIcon />
                </CursorPointer>
            </ZoneMenuTitle>
            <ZonesInputsWrapper>
                <TextDisplayCard
                    labelText='Area Size'
                    title={
                        creating.areaSize
                            ? creating.areaSize.toFixed(4) + ' m2'
                            : 'Waiting for coordinates...'
                    }
                    width='100%'
                />
                <Input
                    width='100%'
                    nomarg
                    placeholder='Pit 23'
                    type='string'
                    labelText='Area name'
                    name='areaName'
                    onChange={handleChange}
                    value={creating.areaName}
                    invalid={creating?.areaName?.length < 2 ? 'true' : ''}
                />
            </ZonesInputsWrapper>
        </AreaInputsContainer>
    )
}
