import React, { useState } from 'react'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { NavBar } from 'components/NavBar/NavBar'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Flex,
  GridItem,
  ItemsGrid,
  MainHeadline,
  PageFooter,
  PageWrapper,
  Separator,
} from 'styling/GlobalStyles'
import { useDispatch, useSelector } from 'react-redux'
import { clearIssuesList, deleteIssue, getIssues } from 'features/issues/issuesSlice'
import { AppDispatch, RootState } from 'store'
import { setConfirmModal } from 'features/global/globalSlice'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Pagination } from 'components/Pagination/Pagination'
import { getParams, handlePagination } from 'utils/helpers'
import { ITableItem, ModalTypes } from 'types/global.interface'
import { Filter } from 'components/Filter/Filter'
import { FilterEnum, LinkEnum } from 'components/Filter/SingleFilter'
import useQueryPagination from 'hooks/useQueryPagination'

export const Issues = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { issues, noIssues, totalPages } = useSelector((store: RootState) => store.issues)
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location }))

  useQueryPagination({ fetchFunctions: [getIssues], cleanUpFunctions: [clearIssuesList], params })

  const removeRole = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteIssue(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: issues.length < 2,
        },
        word: 'delete issue',
      }),
    )
  }

  const filters = [
    {
      type: FilterEnum.SEARCH,
      link: LinkEnum.INDUSTRIES,
      name: 'Industries',
      state: null,
    },
  ]

  return (
    <>
      <NavBar defaultValue={params.q} placeholder='Search Issues...' input setParams={setParams} />
      <PageWrapper>
        <Flex center between>
          <MainHeadline>Issues</MainHeadline>
          <Flex gap='1rem'>
            <Button orange onClick={() => navigate('add')}>
              Add Issue
            </Button>
            <Filter options={filters} setPageParams={setParams} />
          </Flex>
        </Flex>
        <Separator />

        <ItemsGrid columns={3} mt='25px'>
          {!noIssues ? (
            issues.map((item: ITableItem) => {
              return (
                <GridItem key={item.id}>
                  <p onClick={() => navigate(item.id + '/edit')}>{item.name}</p>

                  <Flex between center onClick={() => removeRole(item.id)}>
                    <RemoveCircle />
                  </Flex>
                </GridItem>
              )
            })
          ) : (
            <NoItems title='Issues' />
          )}
        </ItemsGrid>
        <PageFooter>
          <Pagination
            setPage={(page) => handlePagination(page, setParams)}
            pageCount={Math.ceil(totalPages)}
            forcePage={(params.page ? params.page : 1) - 1}
          />
        </PageFooter>
      </PageWrapper>
    </>
  )
}
