import React, { useState } from 'react'
import { NavBar } from '../../../components/NavBar/NavBar'
import {
  Flex,
  TabsContainer,
  Tabs,
  H1WithBack,
  PageWrapper,
  ContentWrapper,
} from '../../../styling/GlobalStyles'
import { ModelsCapabilitiesTab } from './ModelsCapabilitiesTab'
import { ModelsSpecificationTab } from './ModelsSpecificationTab'
import { useDispatch } from 'react-redux'
import { addMachineModel } from '../../../features/machineModels/machineModelsSlice'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from 'store'
import { IMachineModel } from 'types/machineModels.interface'
import { modelSpecificationTabInputs } from './modelValidation'

export const AddModel = () => {
  const navigate = useNavigate()
  const [active, setActive] = useState('Specification')
  const dispatch = useDispatch<AppDispatch>()
  const [invalidFields, setInvalidFields] = useState(modelSpecificationTabInputs)
  const [invalidCategoryFields, setInvalidCategoryFields] = useState<string[]>([])
  const [state, setState] = useState<IMachineModel>({
    make: '',
    model: '',
    fuelType: null,
    fields: [],
    operations: [],
    category: null,
  })


  const handleSubmit = async () => {

    if (state.operations?.some(op => op.types.some(t => t.parameters.some(p => !p.value)))) {
      toast.warn('All operation type parameters must have set values.')
      return
    }


    if (invalidFields.length < 1 && invalidCategoryFields.length < 1) {

      const resp = await dispatch(addMachineModel(state))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/resources-models')
      }
    } else {
      toast.warn('All fields must be valid')
    }
  }
  const renderTabs = () => {
    if (active === 'Specification') {
      return (
        <ModelsSpecificationTab
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          invalidCategoryFields={invalidCategoryFields}
          setInvalidCategoryFields={setInvalidCategoryFields}
        />
      )
    } else if (active === 'Capabilities') {
      return <ModelsCapabilitiesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title='Add Machinery model' />
          <TabsContainer>
            <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
              Specification
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
