import useAuth from 'hooks/useAuth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'styling/GlobalStyles'

export const CancelBtn = ({ onClick }: { onClick?: any }) => {
  const navigate = useNavigate()
  const exactRoute = '/' + location.pathname.split('/')[1]
  const { isAdmin } = useAuth()

  const checkValidRoute = () => {
    if (isAdmin && exactRoute.split('/')[1] === 'employees') {
      return '/operations'
    }
    return exactRoute
  }

  return <Button onClick={() => onClick ? onClick() : navigate(checkValidRoute())}>Cancel</Button>
}
