export const employeeBasicInfoTabInputs = [
    'firstName',
    'lastName',
    'nationalId',
    'contact-phoneNumber',
    'contact-email',
    'role',
    'dateOfBirth',
    'nationality',
    'accessType',
    'gender',
    'maritalStatus',
    'drivingLicense',
    'drivingLicense',
  ]
  
  export const employeeContractInputs = ['netSalary', 'grossSalary', 'endDate']
  
  export const employeesInitialState = {
    contracts: [],
    firstName: '',
    nationality: '',
    dateOfBirth: null,
    lastName: '',
    nationalId: '',
    role: {
      name: '',
      id: null
    },
    gender: '',
    maritalStatus: '',
    contact: {
      email: '',
      phoneNumber: '+',
    },
    drivingLicense: {
      isActive: false,
      drivingCategories: [],
      validUntil: null,
    },
    machines: [],
    operations: [],
    profileImage: false,
    image: '',
    accessType: '',
    isNone: false,
  }