import styled from 'styled-components'

export const StatusCircle = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 0.6rem;
  background: ${(props) => props.color};
  border-radius: 50%;
`

export const ImageModalFrame = styled.div`
  max-height: 30vh;
  max-width: 40vw;
  height: 30vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    object-fit: contain;
  }
`

export const ContractInputWrapper = styled.div`
  
  display: flex;
  flex-direction:column;
  gap:1rem;
  width:50%;
  
  @media (max-width: 500px) {
  
    width:100%;
  }

`