import React, { useState } from 'react'
import {
  Button,
  GridItem,
  GridWrapper,
  ItemsGrid,
  Separator,
  PageWrapper,
  Flex,
  MainHeadline,
  PageFooter,
} from '../../styling/GlobalStyles'
import { Pagination } from '../../components/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'
import {
  clearOperationsList,
  deleteOperation,
  getOperations,
} from '../../features/operations/operationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { NavBar } from '../../components/NavBar/NavBar'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { setConfirmModal } from 'features/global/globalSlice'
import { AppDispatch, RootState } from 'store'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { getParams, handlePagination } from 'utils/helpers'
import { ITableItem, ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const Operations = () => {
  const { noOperations, operations, totalPages } = useSelector(
    (store: RootState) => store.operations,
  )
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location, ap: { sd: 'a', s: 'name' } }))

  useQueryPagination({ fetchFunctions: [getOperations], cleanUpFunctions: [clearOperationsList], params })


  const handleRemove = (id: number | string) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteOperation(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: operations.length < 2,
        },
        word: 'delete operation',
      }),
    )
  }

  return (
    <>
      <NavBar
        input
        defaultValue={params.q}
        placeholder='Search Operations...'
        setParams={setParams}
        searchFields={['name']}
      />
      <PageWrapper>
        <Flex between center>
          <MainHeadline>Operations</MainHeadline>
          <Button orange onClick={() => navigate('/operations/add')}>
            Add operation
          </Button>
        </Flex>
        <Separator />
        <GridWrapper>
          <ItemsGrid columns={3}>
            {operations && !noOperations ? (
              operations.map((item: ITableItem, key: number) => {
                return (
                  <GridItem key={key}>
                    <p onClick={() => navigate('/operations/' + item.id)}>{item.name}</p>
                    <Flex center justcenter onClick={() => handleRemove(item.id)}>
                      <RemoveCircle />
                    </Flex>
                  </GridItem>
                )
              })
            ) : (
              <NoItems title='Operations' />
            )}
          </ItemsGrid>
        </GridWrapper>
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
