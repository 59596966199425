import React, { useState } from 'react'
import {
    PageWrapper,
    Flex,
    H1WithBack,
    TabsContainer,
    Tabs,
    ContentWrapper,
} from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CycleCompaniesTab } from './CycleCompaniesTab'
import { ICycleState } from 'types/cycles.interface'
import { CycleSpecificationTab } from './CycleSpecificationTab'
import { CycleComponentsTab } from './CycleComponentsTab'
import { CycleStepsTab } from './CycleStepsTab'
import { addCycle } from 'features/cycles/cyclesSlice'

export const AddCycle = () => {
    const [active, setActive] = useState('Specification')
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [state, setState] = useState<ICycleState>({
        name: '',
        companies: [],
        steps: [{
            name: '',
            componentIndexForLocation: null,
            isStepForFinish: false,
        }],
        operationType: null,
        componentIndexForNumberOfWorkers: null,
        components: [{
            component: null,
            name: ''
        }]
    })


    const handleSubmit = async () => {
        if (state.name.length < 2) {
            toast.error('Task cycle template name must be provided')
            return
        }
        if (!state.companies.length) {
            toast.error('At least one company must be assigned')
            return
        }
        if (!state.steps.length) {
            toast.error('At least one step must be added ')
            return
        }

        if (state.components.length < 2) {
            toast.error('At least two components must be added')
            return
        }

        const resp = await dispatch(addCycle(state))
        if (resp.meta.requestStatus === 'fulfilled') {
            navigate('/cycles')
        }
    }

    const renderTabs = () => {
        switch (active) {
            case 'Specification':
                return (
                    <CycleSpecificationTab state={state} setState={setState} handleSubmit={handleSubmit} />
                )
            case 'Components':
                return <CycleComponentsTab state={state} setState={setState} handleSubmit={handleSubmit} />
            case 'Steps':
                return <CycleStepsTab state={state} setState={setState} handleSubmit={handleSubmit} />
            case 'Companies':
                return <CycleCompaniesTab state={state} setState={setState} handleSubmit={handleSubmit} />
            default:
                return (
                    <CycleSpecificationTab state={state} setState={setState} handleSubmit={handleSubmit} />
                )
        }
    }

    return (
        <div>
            <NavBar />
            <PageWrapper noFooter>
                <Flex between main>
                    <H1WithBack mb='1.5' arrow title='Add Task Cycle' />
                    <TabsContainer>
                        <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
                            Specification
                        </Tabs>
                        <Tabs active={active === 'Components'} onClick={() => setActive('Components')}>
                            Components
                        </Tabs>
                        <Tabs active={active === 'Steps'} onClick={() => setActive('Steps')}>
                            Steps
                        </Tabs>
                        <Tabs active={active === 'Companies'} onClick={() => setActive('Companies')}>
                            Companies
                        </Tabs>
                    </TabsContainer>
                </Flex>
                <ContentWrapper>{renderTabs()}</ContentWrapper>
            </PageWrapper>
        </div>
    )
}
