import styled from 'styled-components'

interface CollapseItemProps {
  white?:boolean
  expanded?:boolean
  mb?:string
}

export const CollapseItemText = styled.div<CollapseItemProps>`
  height: 40px;
  font-weight: ${(props) => (props.white ? 'normal' : 'bold')};
  color: ${(props) => (props.white ? props.theme.colorGray : 'white')};
  font-size: 13px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: ${(props) => (props.white ? 'white' : props.theme.colorOrange)};
  outline: ${(props) => props.white && '1px solid ' + props.theme.colorOrange};
  box-shadow: 8.3px 1.2rem 2.8rem rgba(7, 6, 61, 0.05);
  border-radius: 8.3px;
  width: 100%;
  padding: 0 1rem;
  cursor: pointer;
  ${(props) => props.mb && 'margin-bottom:' + props.mb};

  svg {
    transition: 0.3s ease;
    ${(props) => props.expanded && !props.white && 'transform: rotate(180deg)'};
  }
`
