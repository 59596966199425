import React, { useState } from 'react'
import {
  ContentWrapper,
  Flex,
  H1WithBack,
  PageWrapper,
  Tabs,
  TabsContainer,
} from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { TaskTriggersBasicInfo } from './TaskTriggersBasicInfo'
import { addTaskTrigger } from 'features/triggers/tasksTriggersSlice'
import { useNavigate } from 'react-router-dom'
import { taskTriggersBasicInfoInputs } from './taskTriggersValidation'
import { toast } from 'react-toastify'
import { ISingleTaskTrigger } from 'types/task-triggers.interface'
import { taskTriggersInitialState } from './taskTriggersData'
import { TaskTriggersCompaniesTab } from './TaskTriggersCompaniesTab'

export const AddTaskTrigger = () => {
  const [active, setActive] = useState('Basic Info')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [invalidFields, setInvalidFields] = useState(taskTriggersBasicInfoInputs)
  const [state, setState] = useState<ISingleTaskTrigger>(taskTriggersInitialState)

  const handleSubmit = async () => {
    if (invalidFields.length > 0) {
      toast.warn('All basic info fields must be valid.')
      return
    }

    const resp = await dispatch(addTaskTrigger(state))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/triggers-task')
    }
  }
  const renderTabs = () => {
    if (active === 'Basic Info') {
      return (
        <TaskTriggersBasicInfo
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        />
      )
    } else if (active === 'Companies') {
      return <TaskTriggersCompaniesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
  }

  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title='Add Task Trigger' />
          <TabsContainer>
            <Tabs active={active === 'Basic Info'} onClick={() => setActive('Basic Info')}>
              Basic Info
            </Tabs>
            <Tabs active={active === 'Companies'} onClick={() => setActive('Companies')}>
              Companies
            </Tabs>
          </TabsContainer>
        </Flex>
        <Flex between></Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}
