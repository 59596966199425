import { NavBar } from 'components/NavBar/NavBar'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Flex,
  TabsContainer,
  Tabs,
  H1WithBack,
  PageWrapper,
  ContentWrapper,
} from 'styling/GlobalStyles'
import { InfoRolesTab } from 'pages/ResourcesAdmin/Roles/InfoRolesTab'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from 'store'
import { IRole } from 'types/roles.interface'
import { toast } from 'react-toastify'
import { PermissionsRolesTab } from 'pages/ResourcesAdmin/Roles/PermissionsRolesTab'
import { addRoleTemplate } from 'features/roles/roleTemplatesSlice'

export const AddRoleTemplate = () => {
  const [active, setActive] = useState('Info')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const [state, setState] = useState<IRole>({
    name: '',
    permissions: {
      dashboard: false,
      globalView: false,
      employees: false,
      shifts: false,
      machinery: false,
      inventory: false,
      locations: false,
      departments: false,
      reports: false,
      finances: false,
      zones: false,
      roles: false
    }
  })

  const handleSubmit = async () => {
    if (state.name.length < 1) {
      toast.warn('Role template name must be filled')
      return
    }

    const resp = await dispatch(addRoleTemplate(state))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/resources-role-templates')
    }
  }
  const renderTabs = () => {
    if (active == 'Permissions') {
      return <PermissionsRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
    return <InfoRolesTab state={state} setState={setState} handleSubmit={handleSubmit} />
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title='Add Role Template' />
          <TabsContainer>
            <Tabs active={active === 'Info'} onClick={() => setActive('Info')}>
              Info
            </Tabs>
            <Tabs active={active === 'Permissions'} onClick={() => setActive('Permissions')}>
              Permissions
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
