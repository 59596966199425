import React from 'react'
import {
  H2,
  OrangeCircle,
  Button,
  TabFooter,
  Flex,
  Animation,
  SpecificationWrapper,
  ButtonsContainer,
} from '../../styling/GlobalStyles'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import nationalities from './nationalities.json'
import {
  AddProfileImage,
  InputContainer,
  AddProfileImageClick,
  AddProfileImageInput,
} from './AddEmployee.styled'
import { Input } from '../../components/Input/Input'
import { addImagePlus } from '../../assets/icons/AddImagePlus'
import { getBase64, onlyLetters, onlyNumbers } from '../../utils/helpers'
import { HonestSelectInput } from '../../components/HonestSelect/HonestSelectInput'
import { accessTypeList, driverLicense, genderList, maritalStatus } from './constants'
import Avatar from '../../assets/images/profileAvatar.png'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ISelectValue } from 'constants/globalTypes'
import { validateSelect, standardFieldValidation, validateDate } from 'utils/validationUtils'
import { toast } from 'react-toastify'
import { CancelBtn } from 'components/Button/CancelBtn'
import { DatePickerInput } from 'components/DatePicker/DatePicker'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { IEmployee, IEmployeeBasicInfoTab } from 'types/users.interface'

export const EmployeeBasicInfoTab = ({
  setActive,
  state,
  setState,
  invalidFields,
  setInvalidFields,
}: IEmployeeBasicInfoTab) => {
  const isEditing = location.pathname.includes('edit')
  const nationalitiesList = nationalities.sort((a, b) => a.label.localeCompare(b.label))

  useFocusFirstInput()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name.split('-')
    const { value } = e.target as HTMLInputElement
    standardFieldValidation(e, setInvalidFields)
    if (name[0] === 'contact') {
      setState((prev: IEmployee) => {
        if (prev.contact) {
          const copy = structuredClone(prev)
          copy.contact[name[1]] = value
          return copy
        }
      })
    } else {
      setState({ ...state, [(e.target as HTMLInputElement).name]: value })
    }
  }

  const handleDateChange = (value: Date, name: string) => {
    validateDate(name, setInvalidFields)
    setState({ ...state, [name]: value })
  }
  const handleValidUntilChange = (date) => {
    setState((prev: IEmployee) => {
      const copy = { ...prev }
      if (prev.drivingLicense) {
        copy.drivingLicense.validUntil = date
        if (copy.drivingLicense.drivingCategories.length > 0 && !copy.drivingLicense.validUntil) {
          setInvalidFields([...invalidFields, 'validUntil'])
        }
        setInvalidFields((prev: string[]) => prev.filter((i) => i !== 'validUntil'))
        return copy
      }
      return prev
    })
  }

  const handleReactSelect = (value, name: string) => {

    validateSelect(value, name, setInvalidFields)
    setState((prev: IEmployee) => {
      const copy = { ...prev }

      copy.isNone = value.label == 'multiNone'

      if (name === 'drivingLicense' && state.drivingLicense) {
        setInvalidFields((p: string[]) => {
          let c = [...p]
          c = value.length > 0 ? [...c, 'validUntil'] : c.filter((f) => !f.includes('validUntil'))
          return c
        })
        copy.drivingLicense.drivingCategories =
          value.label === 'multiNone'
            ? [] && setInvalidFields((prev: string[]) => prev.filter((i) => i !== 'validUntil'))
            : value.map((item: ISelectValue) => item.value)

        if (copy.drivingLicense?.drivingCategories?.length) {
          copy.drivingLicense.isActive = true
        } else {
          copy.drivingLicense.isActive = false
          copy.drivingLicense.drivingCategories = []
        }
      } else if (name === 'role') {
        copy.role = { id: value.value, name: value.label }
      } else {
        copy[name] = value.value
      }
      return copy
    })
  }

  const handleImage = (e) => {
    e.target.parentElement.children[1].click()
  }

  const parseRoles = (data: any) => {
    return data.data.map((item: any) => ({ label: item.name, value: item.id }))
  }

  const handleNextTab = () => {
    if (invalidFields.length < 1) {
      setActive('Contract')
    } else {
      toast.warn('All fields must be valid')
    }
  }

  const getDobYears = () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() - 18)
    return date
  }

  console.log(state.isNone)

  return (
    <Animation>
      <SpecificationWrapper>
        <Flex gap='1rem' mediaQ>
          <AddProfileImage>
            {state?.image ? (
              <>
                <AddProfileImageClick onClick={handleImage}>
                  <OrangeCircle>
                    <img
                      src={state.image}
                      alt='profile-image'
                      onError={({ currentTarget }) => (currentTarget.src = Avatar)}
                    />
                  </OrangeCircle>
                  <H2 noUpperCase>Your Profile Image</H2>
                </AddProfileImageClick>
                <AddProfileImageInput
                  type='file'
                  name='imageupload'
                  accept='image/*'
                  onChange={(e) => {
                    getBase64(e.target.files[0]).then((res: string) =>
                      setState((prev) => {
                        const copy = { ...prev }
                        copy.image = res
                        return copy
                      }),
                    )
                  }}
                />
              </>
            ) : (
              <>
                <AddProfileImageClick onClick={handleImage}>
                  <OrangeCircle>{addImagePlus}</OrangeCircle>
                  <H2 noUpperCase>Add Profile Image</H2>
                </AddProfileImageClick>
                <AddProfileImageInput
                  type='file'
                  name='imageupload'
                  accept='image/*'
                  onChange={(e) => {
                    getBase64(e.target.files[0]).then((res: string) =>
                      setState((prev) => {
                        const copy = { ...prev }
                        copy.image = res
                        copy.profileImage = true
                        return copy
                      }),
                    )
                  }}
                />
              </>
            )}
          </AddProfileImage>
          <InputContainer>
            <Input
              type='text'
              labelText='First Name'
              name='firstName'
              onChange={handleChange}
              value={state?.firstName}
              placeholder='Enter first name'
              invalid={invalidFields.includes('firstName') ? 'true' : ''}
              onKeyDown={onlyLetters}
              keyProp={1}
            />
            <Input
              type='text'
              labelText='Last Name'
              name='lastName'
              onChange={handleChange}
              value={state?.lastName}
              placeholder='Enter last name'
              invalid={invalidFields.includes('lastName') ? 'true' : ''}
              onKeyDown={onlyLetters}
              keyProp={2}
            />
            {!isEditing && <Input
              type='text'
              labelText='Username'
              name='username'
              onChange={handleChange}
              value={state?.username}
              placeholder='Enter username'
              invalid={invalidFields.includes('username') ? 'true' : ''}
              keyProp={55}
            />}
            <Input
              onKeyDown={onlyNumbers}
              type='number'
              labelText='Personal ID number'
              name='nationalId'
              value={state?.nationalId}
              onChange={handleChange}
              placeholder='Enter Personal ID number'
              invalid={invalidFields.includes('nationalId') ? 'true' : ''}
              keyProp={3}
            />
            <Input
              type='tel'
              name='contact-phoneNumber'
              labelText='Phone number'
              value={state?.contact?.phoneNumber}
              onChange={handleChange}
              disabled={isEditing ? true : false}
              placeholder='Enter phone number'
              invalid={invalidFields.includes('contact-phoneNumber') ? 'true' : ''}
              keyProp={4}
            />
            <Input
              type='email'
              name='contact-email'
              labelText='Email'
              value={state?.contact?.email}
              onChange={handleChange}
              placeholder='Enter email'
              invalid={invalidFields.includes('contact-email') ? 'true' : ''}
              keyProp={5}
            />
            <HonestSelectInput
              onChange={(value: ISelectValue, name: string) => {
                handleReactSelect(value, name)
              }}
              defaultValue={state?.accessType}
              options={accessTypeList}
              labelText='Access Type'
              name='accessType'
              invalid={invalidFields.includes('accessType')}
            />
            <HonestSelectAsyncInput
              nomarg
              link='roles'
              placeholder='Search role'
              name='role'
              labelText='Role'
              defaultValue={state?.role?.name}
              onChange={(type: ISelectValue, name: string) => handleReactSelect(type, name)}
              parserFunction={parseRoles}
              invalid={invalidFields.includes('role')}
            />

            {/* <HonestDateInput
              labelText='Date of Birth'
              name='dateOfBirth'
              dateValue={state.dateOfBirth ? moment(state.dateOfBirth) : state.dateOfBirth}
              onChange={(dateValue, name) => handleDateChange(dateValue, name)}
              invalid={invalidFields.includes('dateOfBirth') ? 'true' : ''}
              noFuture
            /> */}
            <DatePickerInput
              label='Date of Birth'
              date={state?.dateOfBirth ? new Date(state.dateOfBirth) : null}
              onChange={handleDateChange}
              name={'dateOfBirth'}
              maxDate={getDobYears()}
            />
            <HonestSelectAsyncInput
              name='nationality'
              onChange={(value: ISelectValue, name: string) => {
                handleReactSelect(value, name)
              }}
              defaultValue={state?.nationality}
              fixedList={nationalitiesList}
              link='Nationality'
              labelText='Nationality'
              invalid={invalidFields.includes('nationality')}
            />
            {/* <HonestSelectInput
              name='nationality'
              onChange={(value: ISelectValue, name: string) => {
                handleReactSelect(value, name)
              }}
              defaultValue={state.nationality}
              options={nationalitiesList}
              labelText='Nationality'
              invalid={invalidFields.includes('nationality')}
            /> */}
            <HonestSelectInput
              name='gender'
              onChange={(value: ISelectValue, param: string) => {
                handleReactSelect(value, param)
              }}
              defaultValue={state?.gender}
              options={genderList}
              labelText='Gender'
              invalid={invalidFields.includes('gender')}
            />
            <HonestSelectInput
              name='maritalStatus'
              onChange={(value: ISelectValue, name: string) => {
                handleReactSelect(value, name)
              }}
              defaultValue={state?.maritalStatus}
              options={maritalStatus}
              labelText='Marital Status'
              invalid={invalidFields.includes('maritalStatus')}
            />
            <HonestSelectInput
              isNone={state?.isNone}
              options={driverLicense}
              labelText='Driving License'
              name='drivingLicense'
              defaultValue={state?.drivingLicense ? state.drivingLicense.drivingCategories : []}
              multi
              onChange={(value: ISelectValue[], name: string) => {
                handleReactSelect(value, name)
              }}
              editingMultiNone={isEditing && state?.drivingLicense?.drivingCategories.length > 0}
              invalid={invalidFields.includes('drivingLicense')}
            />
            {state?.drivingLicense?.drivingCategories?.length ? (
              <DatePickerInput
                label='Valid until'
                date={
                  state?.drivingLicense.validUntil ? new Date(state.drivingLicense.validUntil) : null
                }
                minDate={new Date()}
                onChange={handleValidUntilChange}
                name={'validUntil'}
              />
            ) : null}
          </InputContainer>
        </Flex>
      </SpecificationWrapper>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button type='button' onClick={handleNextTab} orange>
            Next
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}
