import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { INameId } from 'constants/globalTypes'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IIssueTriggersPostPatchRequest } from 'types/api/issueTriggersApi.interface'
import { IIssueTriggersSlice, IIssueTriggerState } from 'types/issue-triggers.interface'
import { baseUrl } from 'utils/axios'
import { handleErrors, formatParams } from 'utils/helpers'

const initialState:IIssueTriggersSlice = {
  isLoading: false,
  issueTriggers: [],
  singleIssueTrigger: null,
  totalPages: null,
  noItems: false,
}

export const getIssueTriggers = createAsyncThunk(
  'triggers/getIssueTriggers',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('issue-triggers', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addIssueTrigger = createAsyncThunk(
  'triggers/addIssueTrigger',
  async (trigger: IIssueTriggerState, thunkAPI) => {
    try {

      const forSubmit:IIssueTriggersPostPatchRequest = { 
        taskTemplateId : trigger.taskTemplate.id,
        issueId : trigger.issue.id,
        companies : trigger.companies.length > 0 ? trigger?.companies?.map((item:INameId) => item.id) : [],
        parentTaskTemplateIds :trigger.parentTaskTemplates.length ? trigger?.parentTaskTemplates?.map((item:INameId) => item.id) : [],
        parentProcessTemplateIds : trigger.parentProcessTemplates.length ? trigger?.parentProcessTemplates?.map((item:INameId) => item.id) : [],
        parentTaskCycleTemplateIds : trigger.parentTaskCycleTemplates.length ? trigger?.parentTaskCycleTemplates?.map((item:INameId) => item.id) : [],
        name:trigger.name
      }

      const resp = await baseUrl.post('issue-triggers', forSubmit, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleIssueTrigger = createAsyncThunk(
  'triggers/getSingleIssueTrigger',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IIssueTriggerState> = await baseUrl.get('issue-triggers/' + id, thunkAPI)
      return resp.data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateIssueTrigger = createAsyncThunk(
  'triggers/updateIssueTrigger',
  async (trigger: IIssueTriggerState, thunkAPI) => {
    try {
      const forSubmit:IIssueTriggersPostPatchRequest = { 
        taskTemplateId : trigger.taskTemplate.id,
        issueId : trigger.issue.id,
        companies : trigger.companies.length > 0 ? trigger?.companies?.map((item:INameId) => item.id) : [],
        parentTaskTemplateIds :trigger.parentTaskTemplates.length ? trigger?.parentTaskTemplates?.map((item:INameId) => item.id) : [],
        parentProcessTemplateIds : trigger.parentProcessTemplates.length ? trigger?.parentProcessTemplates?.map((item:INameId) => item.id) : [],
        parentTaskCycleTemplateIds : trigger.parentTaskCycleTemplates.length ? trigger?.parentTaskCycleTemplates?.map((item:INameId) => item.id) : [],
        name:trigger.name
      }
    const resp = await baseUrl.patch('issue-triggers/' + trigger.id, forSubmit, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteIssueTrigger = createAsyncThunk(
  'triggers/deleteIssueTrigger',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('issue-triggers/' + id, thunkAPI)

      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const issueTriggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    clearIssueTrigger: (state) => {
      state.singleIssueTrigger = null
    },
    clearIssueTriggersList: (state) => {
      state.issueTriggers = []
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getIssueTriggers.pending.type]: (state) => {
      state.isLoading = true
    },
    [getIssueTriggers.fulfilled.type]: (state, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.isLoading = false
      state.issueTriggers = payload.data
      state.noItems = payload.data.length < 1
      state.totalPages = payload.totalPages
    },
    [getIssueTriggers.rejected.type]: (state) => {
      state.isLoading = false
    },
    [addIssueTrigger.pending.type]: (state) => {
      state.isLoading = true
    },
    [addIssueTrigger.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.issueTriggers = [...state.issueTriggers, payload]
    },
    [addIssueTrigger.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleIssueTrigger.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleIssueTrigger.fulfilled.type]: (state, { payload }:{payload:IIssueTriggerState}) => {
      state.isLoading = false
      state.singleIssueTrigger = payload
    },
    [getSingleIssueTrigger.rejected.type]: (state) => {
      state.isLoading = false
    },
    [addIssueTrigger.pending.type]: (state) => {
      state.isLoading = true
    },
    [addIssueTrigger.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.issueTriggers.push(payload)
      toast.success('Issue Trigger added')
    },
    [addIssueTrigger.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateIssueTrigger.pending.type]: (state) => {
      state.isLoading = true
    },

    [updateIssueTrigger.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleIssueTrigger = payload
      toast.success('Issue Trigger updated')
    },
    [updateIssueTrigger.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteIssueTrigger.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteIssueTrigger.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.issueTriggers = state.issueTriggers.filter((item) => item.id !== payload)
      state.noItems = state.issueTriggers.length < 1
      toast.success('Issue Trigger deleted')
    },
    [deleteIssueTrigger.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearIssueTriggersList, clearIssueTrigger } = issueTriggersSlice.actions

export default issueTriggersSlice.reducer
