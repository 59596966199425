import React from 'react'

export const SearchIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'>
    <path
      d='M7.01214 11.424C9.57909 11.424 11.66 9.28958 11.66 6.65665C11.66 4.02372 9.57909 1.88931 7.01214 1.88931C4.44518 1.88931 2.36426 4.02372 2.36426 6.65665C2.36426 9.28958 4.44518 11.424 7.01214 11.424Z'
      stroke='#464255'
      strokeWidth='1.10015'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8222 12.6158L10.2949 10.0236'
      stroke='#464255'
      strokeWidth='1.10015'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
