import { baseUrl } from './../../utils/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IIndustry, IIndustrySlice } from 'types/industries.interface'
import { toast } from 'react-toastify'
import { handleErrors, formatParams } from 'utils/helpers'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { INameId } from 'constants/globalTypes'

const initialState:IIndustrySlice = {
  isLoading: false,
  industries: [],
  singleIndustry: null,
  noIndustries: false,
  totalCount: 0,
  totalPages: 0,
}

export const getIndustries = createAsyncThunk(
  'industries/getIndustries',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('industries', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addIndustry = createAsyncThunk(
  'industries/addIndustries',
  async (industry: {name:string}, thunkAPI) => {
    try {
      const resp = await baseUrl.post('/industries', industry, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleIndustry = createAsyncThunk(
  'industries/getSingleIndustry',
  async (id: string, thunkAPI) => {
    try {
      const resp = await baseUrl.get('industries/' + id)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateIndustry = createAsyncThunk(
  'issues/updateIndustry',
  async (industry: IIndustry, thunkAPI) => {
    try {
      const resp = await baseUrl.patch('industries/' + industry.id, industry, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteIndustry = createAsyncThunk(
  'industries/deleteIndustry',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('industries/' + id)

      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const industriesSlice = createSlice({
  name: 'industries',
  initialState,
  reducers: {
    clearIndustry: (state) => {
      state.singleIndustry = null
    },
    clearIndustriesList: (state) => {
      state.industries = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getIndustries.pending.type]: (state) => {
      state.isLoading = true
    },
    [getIndustries.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.industries = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noIndustries = state.industries.length < 1
    },
    [getIndustries.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addIndustry.pending.type]: (state) => {
      state.isLoading = true
    },
    [addIndustry.fulfilled.type]: (state, { payload }) => {
      state.isLoading = true
      toast.success('Industry added')
      if (state.industries.length < 30) {
        state.industries = [...state.industries, payload]
      }
    },
    [addIndustry.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleIndustry.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleIndustry.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleIndustry = payload
    },
    [getSingleIndustry.rejected.type]: (state, { payload }) => {
      state.isLoading = true
      handleErrors(payload)
    },
    [updateIndustry.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateIndustry.fulfilled.type]: (state) => {
      state.isLoading = true
      toast.success('Industry updated')
    },
    [updateIndustry.rejected.type]: (state, { payload }) => {
      state.isLoading = true
      handleErrors(payload)
    },
    [deleteIndustry.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteIndustry.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      toast.success('Industry deleted')
      state.industries = state.industries.filter((item) => item.id !== payload)
    },
    [deleteIndustry.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default industriesSlice.reducer
export const { clearIndustry, clearIndustriesList } = industriesSlice.actions
