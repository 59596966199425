import styled from 'styled-components'
import Chevron from '../../assets/icons/Chevron'
import { Flex } from '../../styling/GlobalStyles'
import React, { useEffect } from 'react'
import { convertMinsToHrsMins } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { addWorkSchedule, addWorkScheduleArray, removeWorkSchedule } from 'features/work-schedules/workScheduleSlice'
import { AppDispatch } from 'store'
import { IShiftInput } from 'types/shifts.interface'
import { setConfirmModal } from 'features/global/globalSlice'

// checked, onCheck
export const CircleButton = ({
  orange,
  checked,
  onClick,
}: {
  orange?: boolean
  checked: boolean
  onClick?: () => void
}) => {
  return (
    <Circle orange={orange} onClick={onClick}>
      <CheckBox checked={checked} />
    </Circle>
  )
}


interface CheckBoxProps {
  checked?: boolean
}
export const CheckBox = styled.div<CheckBoxProps>`
  background: ${(props) => {
    return props.checked ? `${props.theme.colorOrange}` : 'none'
  }};

  transition: all 0.2s linear;

  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
`

interface CircleProps {
  orange?: boolean
}
export const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 1rem;
  min-height: 1rem;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.orange ? props.theme.colorOrange : props.theme.colorGray)};
`

export const ShiftInput = ({
  list = [],
  position,
  day,
  defaultShift,
  userId,
  resetChecked,
  up,
}: IShiftInput) => {
  const [shift, setShift] = React.useState<any>({})
  const [dropDown, setDropDown] = React.useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const [emptyOutDated, setEmptyOutDated] = React.useState(false)

  const toggle = () => {
    setDropDown(!dropDown)
  }
  useEffect(() => {
    const today = new Date()
    // 00:00am
    today.setHours(0, 0, 0, 0)
    const emptyOutDated = today.getTime() >= day.getTime()

    setEmptyOutDated(emptyOutDated)
  }, [shift, day])
  useEffect(() => {
    defaultShift ? setShift(defaultShift.shift) : setShift({})
  }, [defaultShift])

  let top = position ? position.top : null
  if (list && top + 40 * list.length + 10 > document.documentElement.scrollHeight) {
    top = top - 40 * list.length - 5
  } else {
    top = top + 45
  }

  const handleWorkSchedule = (item, id) => {
    if (!item) {
      dispatch(
        setConfirmModal({
          isOpen: true,
          onSave: async () => {
            await dispatch(removeWorkSchedule({ userId, day }))
          },
          word: 'unassign shift',
        }),
      )

      return
    }
    resetChecked([])

    setShift(item),
      typeof userId === 'number'
        ? dispatch(addWorkSchedule({ userId, day, id }))
        : dispatch(addWorkScheduleArray({ userId, day, id }))
  }
  return (
    <InputWrapper
      tabIndex={0}
      onBlur={() => setDropDown(false)}
      onClick={toggle}
      shift
      active={shift.minutes && !emptyOutDated}
      outDated={shift.minutes && emptyOutDated}
      emptyOutDated={emptyOutDated && !shift.minutes}
      absence={shift.absenceType ? true : false}
    >
      <Flex between column>
        <strong>{shift.name ? shift.name : 'Select shift'}</strong>
        <Flex between>
          <p>
            {shift.minutes
              ? convertMinsToHrsMins(shift.shiftStart) +
              '-' +
              convertMinsToHrsMins(shift.minutes, shift.shiftStart)
              : shift.absence
                ? '----'
                : '00:00-00:00'}
          </p>
          {emptyOutDated ? null : <Chevron />}
        </Flex>
      </Flex>
      {emptyOutDated ? null : (
        <Dropdown show={dropDown} up={up}>
          <div
            onClick={() => {
              handleWorkSchedule(null, 0)
            }}
            style={{ top }}
          >
            {(shift.minutes || shift.absenceType) ? <span className='drop-name red'>Clear selection</span> : null}
          </div>
          {list &&
            list.map((item) => {
              const { id } = item

              return (
                <div
                  key={id}
                  onClick={() => {
                    handleWorkSchedule(item, id)
                  }}
                  style={{ top }}
                >
                  <span className='drop-name'>{item.name}</span>
                </div>
              )
            })}
        </Dropdown>
      )}
    </InputWrapper>
  )
}

interface DropdownProps {
  show?: boolean
  left?: boolean
  up?: boolean
}
export const Dropdown = styled.div<DropdownProps>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  overflow-y: auto;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 99;
  position: absolute;
  max-height: 8rem;
  overflow: auto;
  width: 100%;
  ${(props) => (props.left ? 'right:110%;top:0;width:10rem' : 'left:0;top:3.4rem;width:100%')};
  ${(props) => props.up && 'top:-270%'};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  > div {
    cursor: pointer;
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
    ${(props) => props.left && 'padding:0.6rem'};
  }
  > div:hover {
    color: ${(prop) => prop.theme.colorOrange};
    svg {
      fill: ${(prop) => prop.theme.colorOrange} !important;
    }
  }
  svg {
    fill: ${(prop) => prop.theme.colorGray} !important;
  }
`

interface InputWrapperProps {
  outDated?: boolean
  emptyOutDated?: boolean
  active?: boolean
  shift?: boolean
  absence?: boolean
}

const InputWrapper = styled.div<InputWrapperProps>`
  cursor: ${({ outDated, emptyOutDated }) =>
    outDated || emptyOutDated ? 'not-allowed' : 'pointer'};
  position: relative;
  background: ${({ active, theme, outDated, absence }) =>
    active && !absence
      ? theme.colorOrange
      : outDated && !absence
        ? theme.colorOrange
        : absence
          ? 'red'
          : '#ffffff'};
  height: 3.1rem;
  ${(props) => (props.shift ? '' : 'box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);')}
  border-radius: 0.6rem;
  width: 100%;
  padding: 0.3rem;
  text-transform: capitalize;
  transition: 0.3s;
  opacity: ${({ emptyOutDated, outDated }) => (outDated || emptyOutDated ? '0.7' : '1')};


  /* .close{
    position: absolute;
    aspect-ratio: 1/1;
    top: 5px;
    right: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    transition: .2s ease;
    color: white;
    :hover{
      background-color: #ffffff;
      color: #ff9900;
    
    }

    
  } */

  strong,
  p {
    color: ${({ active, theme, outDated, absence }) =>
    active || outDated || absence ? '#ffffff' : theme.colorGray};
  }
  .drop-name {
    color: ${({ theme }) => theme.colorGray};
  }
  .drop-name:hover {
    color: ${({ theme }) => theme.colorOrange};
  }

  .red{
    font-style: italic;
    color: red;
    transition: .1s ease-in;
    padding: 3px;
    border-radius: 3px;
  }

  .red:hover{
    background-color: red;
    color: white;
  }
  span,
  label {
    font-size: 0.75rem;
    text-align: center;
    color: ${({ active, theme, emptyOutDated }) =>
    active || emptyOutDated ? '#ffffff' : theme.colorGray};
  }
  @media (max-width: 1460px) {
    span,
    p {
      font-size: 0.6rem;
    }
    strong {
      font-size: 0.7rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }
`



export const AddShiftInputs = styled.div`

  display: flex;
  gap: 1rem;
  width: 70%;


  @media (max-width: 500px) {
  
    flex-direction: column;
    width: 100%;
  
  }

`