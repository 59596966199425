export const nextTaskTypes = [
  {
    label: 'Task',
    value: 1,
  },
  {
    label: 'Workflow',
    value: 2,
  },
]

export const taskTypes = [
  {
    label: 'On Task Start',
    value: 1,
  },
  {
    label: 'On Task End',
    value: 2,
  },
]
