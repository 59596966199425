import { CancelBtn } from 'components/Button/CancelBtn'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { ToggleButton } from 'components/Input/ToggleButton'
import { ISelectValue } from 'constants/globalTypes'
import { getIndustries } from 'features/industries/industriesSlice'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppDispatch } from 'store'
import { ICompany } from 'types/companies.interface'
import { isEmpty } from 'utils/helpers'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { standardFieldValidation, validateSelect } from 'utils/validationUtils'
import { Input } from '../../components/Input/Input'
import { NavBar } from '../../components/NavBar/NavBar'
import {
  clearCompany,
  getSingleCompany,
  updateCompany,
} from '../../features/companies/companiesSlice'
import {
  Flex,
  ItemsGrid,
  Button,
  Separator,
  H1WithBack,
  PageWrapper,
  PageFooter,
} from '../../styling/GlobalStyles'

export const EditCompany = () => {
  const { singleCompany } = useSelector((store: any) => store.companies)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { id } = useParams()
  const [invalidFields, setInvalidFields] = useState([])
  const [inputValues, setInputValues] = useState<ICompany>(singleCompany)

  useFocusFirstInput()

  const handleSubmit = async () => {
    if (invalidFields.length > 0) {
      toast.warn('All fields are required')
      return
    }
    const resp = await dispatch(updateCompany(inputValues))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/companies')
    }
  }


  useEffect(() => {
    dispatch(getSingleCompany(id))
    dispatch(getIndustries(null))
    return () => {
      dispatch(clearCompany())
    }
  }, [])

  useEffect(() => {
    if (singleCompany && !isEmpty(singleCompany)) {
      setInputValues(structuredClone(singleCompany))
    }
  }, [singleCompany])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name.split('-')
    const value = e.target.value
    const copy = { ...inputValues }

    standardFieldValidation(e, setInvalidFields)
    setInputValues(copy)

    if (name[0] === 'main') {
      setInputValues((prev) => {
        prev[name[1]] = value
        return prev
      })
    } else if (name[0] === 'contact') {
      setInputValues((prev) => {
        prev.contact[name[1]] = value
        return prev
      })
    } else {
      setInputValues((prev) => {
        prev.representative[name[1]] = value
        return prev
      })
    }
  }

  const handleSelect = (value: ISelectValue, name: string) => {
    validateSelect(value, name, setInvalidFields)
    setInputValues((prev) => {
      const copy = { ...prev }
      copy.industry = { id: value.value, name: value.label }
      return copy
    })
  }

  const parseIndustries = (data: any) => {
    return data.data.map((item: any) => ({ label: item.name, value: item.id }))
  }

  return (
    <>
      <NavBar />
      <PageWrapper>
        <H1WithBack title={'Edit ' + (singleCompany?.name ? singleCompany?.name : '')} arrow />
        <Separator />
        <ItemsGrid columns={2} width='80%'>
          <Input
            nomarg
            type='text'
            name='main-name'
            labelText='Company name'
            placeholder='Enter company name'
            value={inputValues?.name ? inputValues.name : ' '}
            onChange={handleChange}
            invalid={invalidFields.includes('main-name') ? 'true' : ''}
          />
          <Input
            nomarg
            type='text'
            name='representative-position'
            labelText='Representative Position'
            placeholder='Enter Representative Position'
            value={inputValues?.representative ? inputValues?.representative?.position : ''}
            onChange={handleChange}
            invalid={invalidFields.includes('representative-position') ? 'true' : ''}
          />

          <HonestSelectAsyncInput
            nomarg
            link='industries'
            placeholder='Search industries'
            name='industryId'
            labelText='Industry'
            defaultValue={inputValues?.industry?.name}
            onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
            parserFunction={parseIndustries}
            invalid={invalidFields.includes('industryId') ? 'true' : ''}
          />
          <Input
            nomarg
            name='contact-address'
            type='text'
            labelText='Company address'
            placeholder='Enter company address'
            value={
              inputValues?.contact && inputValues?.contact.address ? inputValues.contact.address : ''
            }
            onChange={handleChange}
            invalid={invalidFields.includes('contact-address') ? 'true' : ''}
          />
          <Input
            nomarg
            name='representative-name'
            type='text'
            labelText='Representative'
            placeholder='Enter representative'
            value={
              inputValues?.representative && inputValues?.representative.name
                ? inputValues?.representative?.name
                : ''
            }
            onChange={handleChange}
            invalid={invalidFields.includes('representative-name') ? 'true' : ''}
          />
          <Input
            nomarg
            name='contact-phoneNumber'
            type='tel'
            labelText='Company phone number'
            placeholder='Enter company phone number'
            value={
              inputValues?.contact && inputValues?.contact?.phoneNumber
                ? inputValues?.contact?.phoneNumber
                : ''
            }
            onChange={handleChange}
            invalid={invalidFields.includes('contact-phoneNumber') ? 'true' : ''}
          />
          <Input
            nomarg
            name='representative-phoneNumber'
            type='tel'
            labelText='Representative phone number'
            placeholder='Enter representative phone number'
            value={
              inputValues?.representative && inputValues?.representative?.phoneNumber
                ? inputValues?.representative?.phoneNumber
                : ''
            }
            onChange={handleChange}
            invalid={invalidFields.includes('representative-phoneNumber') ? 'true' : ''}
          />
          <Input
            nomarg
            name='contact-email'
            type='email'
            labelText='Company email'
            placeholder='Enter company email'
            value={
              inputValues?.contact && inputValues?.contact.email ? inputValues?.contact?.email : ''
            }
            onChange={handleChange}
            invalid={invalidFields.includes('contact-email') ? 'true' : ''}
          />
          <Input
            nomarg
            name='representative-email'
            type='email'
            labelText='Representative email'
            placeholder='Enter representative email'
            value={
              inputValues?.representative && inputValues?.representative?.email
                ? inputValues.representative.email
                : ''
            }
            onChange={handleChange}
            invalid={invalidFields.includes('representative-email') ? 'true' : ''}
          />

          <Input
            nomarg
            type='number'
            name='main-taxNumber'
            labelText='Company VAT'
            placeholder='Enter company VAT'
            value={inputValues?.taxNumber ? inputValues.taxNumber : ''}
            onChange={handleChange}
            invalid={invalidFields.includes('main-taxNumber') ? 'true' : ''}
          />
          <ToggleButton
            start
            state={inputValues?.settings?.shouldIncludeTraffic || false}
            handleChange={() =>
              setInputValues((prev: ICompany) => ({
                ...prev,
                settings: {
                  ...prev.settings,
                  shouldIncludeTraffic: !prev.settings?.shouldIncludeTraffic
                }

              }))
            }
            label='Should Include Traffic'
          />
        </ItemsGrid>
      </PageWrapper>
      <PageFooter>
        <Flex gap='1rem'>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </PageFooter>
    </>
  )
}
