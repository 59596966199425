import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { RootState } from 'store'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IAllEmployeesSingleResponse, IEmployeesStatsResponse, ISingleEmployeeGetResponse, ISingleEmployeePatchRequest, ISingleEmployeePostRequest } from 'types/api/usersApi.interface'
import { IOperation } from 'types/operations.interface'
import { IContract, IEmployee, IEmployeeSlice } from 'types/users.interface'
import { baseUrl } from 'utils/axios'
import { dataURItoBlob, handleErrors, formatParams } from 'utils/helpers'

const initialState:IEmployeeSlice = {
  isLoading: false,
  users: [],
  singleUser: null,
  noUsers: false,
  totalCount: null,
  totalPages: null,
  stats: null,
  infoShifts: [],
}

export const getUsers = createAsyncThunk('users/getUsers', async (params: any, thunkAPI) => {
  try {
    params = formatParams(params)
    const resp:AxiosResponse<IPaginatedResponse<IAllEmployeesSingleResponse>> = await baseUrl.get('users', { params })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const deleteUser = createAsyncThunk('users/deleteUsers', async (id: any, thunkAPI) => {
  try {
    await baseUrl.delete('users/' + id)
    return id
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue(error)
  }
})

export const getSingleUser = createAsyncThunk(
  'users/getSingleUser',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<ISingleEmployeeGetResponse> = await baseUrl.get('users/' + id)
      const contracts = await baseUrl.get('users/' + id + '/contracts')
      resp.data.contracts = contracts.data // TODO: Proveriti da li ovo moze da se brise jer vec ima contractaq u useru
      delete resp.data.sub
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const addUser = createAsyncThunk('users/addUser', async (user: IEmployee, thunkAPI) => {
  try {
    const { image } = user
    delete user.image
    const forSubmit:ISingleEmployeePostRequest = {
      firstName:user.firstName,
      lastName:user.lastName,
      nationality:user.nationality,
      nationalId:user.nationalId,
      accessType:user.accessType,
      contact:user.contact,
      contracts:user.contracts.map((i) => {
        delete i.contractIndefinite
        return i
      }),
      roleId:user.role.id,
      operationTypes:user.operations
      .map((item:IOperation) => {
        const final = item.types.map((i) => {
          return {
            operationTypeId: Number(i.id),
            parameterValues: i.parameters.map((t) => {
              return { operationTypeParameterId: Number(t.id), value: t.value }
            }),
            rating: i.rating ? i.rating : 0,
            priority: i.priority ? i.priority : 0,
          }
        })
        return final
      })
      .flat(),
      maritalStatus:user.maritalStatus,
      machines:user.machines,
      profileImage:user.profileImage,
      dateOfBirth:user.dateOfBirth,
      drivingLicense:user.drivingLicense,
      gender:user.gender,
      username:user.username
    }

    const resp = await baseUrl.post('/users', forSubmit, thunkAPI)

    if (image.length > 1) {
      const blobedImage = dataURItoBlob(image)

      try {
        const url = resp.data.putImagePresignedUrl
        await axios.put(url, blobedImage)

        return resp.data
      } catch (error) {
        return thunkAPI.rejectWithValue(error)
      }
    }

    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (user: IEmployee, thunkAPI) => {
    try {
      const { image } = user
      delete user.image
      delete user.profileImage

      if (image && !image.startsWith('http')) {
        user.profileImage = true
      }
      const forSubmit:ISingleEmployeePatchRequest={
        accessType:user.accessType,
        firstName:user.firstName,
        lastName:user.lastName,
        gender:user.gender,
        roleId:user.role.id,
        operationTypes:user.operations
        .map((item) => {
          const final = item.types.map((i) => {
            return {
              operationTypeId: Number(i.id),
              parameterValues: i.parameters.map((t) => {
                return { operationTypeParameterId: Number(t.id), value: t.value }
              }),
              rating: i.rating ? i.rating : 0,
              priority: i.priority ? i.priority : 0,
            }
          })
          return final
        })
        .flat(),
        contracts:user.contracts.map((c: IContract) => {
          if (typeof c.id === 'string') {
            delete c.id
          }
          delete c.name
          delete c.contractIndefinite
          return c
        }),
        dateOfBirth:user.dateOfBirth,
        nationalId:user.nationalId,
        nationality:user.nationality,
        profileImage:user.profileImage,
        maritalStatus:user.maritalStatus,
        machines:user.machines,
        drivingLicense:user.drivingLicense,
        contact:user.contact
      }

      const resp = await baseUrl.patch('/users/' + user.id, forSubmit, thunkAPI)
      const contracts = (thunkAPI.getState() as RootState).users.singleUser.contracts
      
      if (
        JSON.stringify(
          contracts.map((c) => {
            const copy = structuredClone(c)
            delete copy.contractIndefinite
            return copy
          }),
        ) !== JSON.stringify(user.contracts)
      ) {
        await baseUrl.patch('/users/' + user.id + '/contracts', { contracts: user.contracts })
      }

      if (!contracts.every((c: IContract) => user.contracts.some((ct) => ct.id === c.id))) {
        const deletedContracts = contracts
          .filter((c: IContract) => !user.contracts.some((ct) => ct.id === c.id))
          .map((c: IContract) => c.id)
        for (const cId of deletedContracts) {
          await baseUrl.delete('/contracts/' + cId)
        }
      }
      if (image && image.length > 1 && !image.startsWith('http')) {
        const blobedImage = dataURItoBlob(image)
        try {
          const url = resp.data.putImagePresignedUrl
          await axios.put(url, blobedImage)
          return resp.data
        } catch (error) {
          console.log(error)
          return thunkAPI.rejectWithValue(error)
        }
      }

      return resp.data
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getUserStats = createAsyncThunk('users/getUserStats', async (_, thunkAPI) => {
  try {
    const resp:AxiosResponse<IEmployeesStatsResponse> = await baseUrl.get('/users-availability')
    return resp.data
  } catch (error) {
    console.log(error)
    return thunkAPI.rejectWithValue(error)
  }
})
const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearEmployee: (state) => {
      state.singleUser = null
    },
    clearEmployeesList: (state) => {
      state.users = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getUsers.pending.type]: (state) => {
      state.isLoading = true
    },
    [getUsers.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false

      state.users = payload.data
      state.noUsers = state.users.length < 1
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
    },
    [getUsers.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteUser.fulfilled.type]: (state, { payload }) => {
      try {
        state.isLoading = false
        state.users = state.users.filter((user) => user.id !== payload)
        state.noUsers = state.users.length < 1
        toast.success('Employee successfully deleted.')
      } catch (error) {
        console.log(error)
      }
     
    },
    [deleteUser.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleUser.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const obj = payload
      obj.contracts = obj.contracts.map((item: IContract) => ({
        ...item,
        contractIndefinite: item.endDate ? false : true,
      }))
      obj.roleId = obj.role?.value
      obj.image = obj.getImagePresignedUrl
      obj.isNone = obj.drivingLicense?.isActive ? false : true
      delete obj.getImagePresignedUrl

      state.singleUser = obj
    },
    [getSingleUser.rejected.type]: (state) => {
      state.isLoading = false
    },
    [addUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [addUser.fulfilled.type]: (state, { payload }) => {
      toast.success('Employee added')
      payload.role = payload.role.name
      if (state.users.length < 5) {
        state.users.push(payload)
      }
    },
    [addUser.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateUser.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleUser = payload
      toast.success('User updated')
    },
    [updateUser.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getUserStats.pending.type]: (state) => {
      state.isLoading = true
    },
    [getUserStats.fulfilled.type]: (state, { payload }:{payload:IEmployeesStatsResponse}) => {
      state.isLoading = false
      state.infoShifts = payload?.shifts
        .filter((i) => {
          return i.numberOfUsers > 0
        })
        .map((i) => {
          return {
            shift: i.name,
            number: i.numberOfUsers,
            percent: ((i.numberOfUsers * 100) / payload.totalWorkers).toFixed(1),
          }
        })
        delete payload.shifts
        state.stats = payload

    },
    [getUserStats.rejected.type]: (state) => {
      state.isLoading = false
    },
  },
})

export const { clearEmployee, clearEmployeesList } = userSlice.actions

export default userSlice.reducer
