import { ThreeDots } from 'assets/icons/ThreeDots'
import { NavBar } from 'components/NavBar/NavBar'
import { AlertCard, ClientDashboardContainer, DashboardCard, DashboardCardHeadline, DCContent, DCTaskCount, Flex, MainDashboardCard, MainHeadline, PageWrapper, SquareIcon, ChartTooltip, DCLineItem, MachineryCardCount, DCBarTooltip, DCFinancesCard, AreaTooltip } from 'styling/GlobalStyles'
import { FileIcon } from 'assets/icons/FileIcon'
import { machineryIcon } from 'assets/icons/MachineryIcon'
import { employeesIcon } from 'assets/icons/EmployeesIcon'
import { inventoryIcon } from 'assets/icons/InventoryIcon'
import { financesIcon } from 'assets/icons/financesIcon'
import { AlertIcon } from 'assets/icons/AlertIcon'
import { ArrowLeft } from 'assets/icons/ArrowLeft'
import { GreenCheckIcon } from 'assets/icons/CheckCheckIcon'
import { WarningCircle } from 'assets/icons/WarningCircle'
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CustomLabel } from 'components/Charts/Charts'
import { months, setState } from 'utils/helpers'
import { AlertsModal } from './AlertsModal'
import { useEffect, useState } from 'react'
import { dashboardAlertCards, machineryStatusesData, taskStatusesData } from './dashboardData'
import { InventoryModal } from './InventoryModal'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { getDashboardData } from 'features/dashboard/dashboardSlice'
import { useNavigate } from 'react-router-dom'
import { CycleIcon } from 'assets/icons/CycleIcon'
import { DashboardSectionsContainer } from './Dashboard.styled'

interface ITitle {
    mainIcon: JSX.Element,
    title: string,
    subtitle?: string
    color?: string
    rightSide?: JSX.Element
    height?: string
    center?: boolean
    onClick?: () => void
    arrow?: boolean
}

export const Title = ({ mainIcon, title, subtitle, color, rightSide, height, center, onClick, arrow }: ITitle) => {
    return <DashboardCardHeadline height={height} center={center}>
        <div>
            <SquareIcon color={color} >
                {mainIcon}
            </SquareIcon>
            <div>
                <p className='bold' >{title}</p>
                {subtitle && <p style={{ fontSize: '.7rem' }} >{subtitle}</p>}
            </div>
        </div>
        {rightSide ?? <span style={{ cursor: 'pointer' }} onClick={() => onClick && onClick()}>{arrow ? ArrowLeft : ThreeDots}</span>}
    </DashboardCardHeadline>
}

interface IAlertCardComponent {
    count: number
    title: string
    warning?: boolean
    onClick: () => void
}

const AlertCardComponent = ({ count, title, onClick }: IAlertCardComponent) => {
    return <AlertCard onClick={onClick}>
        <Flex column between height='100%'>
            <h1>{count}</h1>
            <p>{title}</p>
        </Flex>
        <span>{ArrowLeft}</span>
    </AlertCard>
}
interface ILineItem {
    title: string
    count: number
    warning?: boolean
    noBottom?: boolean
    borderTop?: boolean
    numberOnly?: boolean
}
const LineItem = ({ title, count, warning, noBottom, borderTop, numberOnly }: ILineItem) => {
    return <DCLineItem noBottom={noBottom} warning={warning} borderTop={borderTop}>
        <p>{title}</p>
        <div>
            <p className='bold' style={numberOnly && { color: '#FF5B5B' }}>{count}</p>
            {!numberOnly && (!warning ? GreenCheckIcon : WarningCircle)}
        </div>
    </DCLineItem>
}

const colors = ['#00a368', '#ff9900', '#ff5b5b', '#b46f33', '#30b6d1', '#7f0788', '#3c8f05333']

export const ClientDashboard = () => {

    const [alertsModal, setAlertsModal] = useState({ modalOpen: false, alertId: null, total: null })
    const [inventory, setInventory] = useState({ modalOpen: false, selectedItems: [] })
    const [selectedItems, setSelectedItems] = useState([])
    const navigate = useNavigate()
    const getDate = () => {
        const date = new Date()
        return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + '.'
    }
    const dispatch = useDispatch<AppDispatch>()

    const { finances, machinery, shifts, inventoryItems, taskStatuses, alerts, taskCycleStatuses } = useSelector((state: RootState) => state.dashboard)

    useEffect(() => {
        dispatch(getDashboardData())
    }, [])

    useEffect(() => {
        if (inventoryItems?.length > 0) {
            setSelectedItems(inventoryItems.slice(0, 5))
        }
    }, [inventoryItems])

    const getAmPm = (date: Date) => {
        let hours = date.getHours();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        return hours + ' ' + ampm;
    }

    const groputItems = (group) => {
        const groupedItems = {};
        if (
            group
        ) {
            for (const item of group) {
                let { name } = item;
                const { count } = item
                if (!name) {
                    name = taskStatusesData.find(ts => ts.id == item.status).name
                }

                if (!groupedItems[name]) {
                    groupedItems[name] = 0;
                }

                groupedItems[name] += count;
            }
        }

        return Object.entries(groupedItems).map(([name, value]) => ({ name, value }));
    }

    const getTaskStatus = (name: string, cycle?: boolean): number => {

        const obj = cycle ? cyclesData : tasksData
        return Number(obj?.find(td => td.name == name)?.value) || 0

    }

    const financesData = finances?.hourlyFinances.map(f => ({ name: getAmPm(new Date(f.hour)), actualCost: f.hourlyActualCost, estimatedCost: f.hourlyEstimatedCost }))
    const machineryData = machinery?.machineStatuses.length > 0 ? machineryStatusesData.map(m => {
        if (machinery?.machineStatuses.some(s => s.status == m.id)) {
            const foundStatus = machinery?.machineStatuses.find(ms => ms.status == m.id)
            return { name: machineryStatusesData.find(msd => msd.id == foundStatus.status).name, value: foundStatus.count }
        }
        return { name: m.name, value: 0.1 }
    }) : machineryStatusesData.map(msd => ({ name: msd.name, value: 0 }))

    const shiftsData = groputItems(shifts?.shifts)
    const tasksData = groputItems(taskStatuses)
    const cyclesData = groputItems(taskCycleStatuses)

    return <>
        <NavBar />
        <PageWrapper noFooter >
            <Flex center>
                <MainHeadline flex w='100%' >
                    Dashboard
                </MainHeadline>
                <p style={{ width: '15rem', textAlign: 'end' }} >{getDate()}</p>
            </Flex>

            <ClientDashboardContainer style={{ paddingBottom: '1rem' }}>
                <DashboardSectionsContainer>
                    <MainDashboardCard>
                        <Title mainIcon={AlertIcon} title='Alerts' subtitle='All statuses' color='#FF5B5B' />
                        <DCContent grid>
                            {dashboardAlertCards.map((c, i) => <AlertCardComponent
                                key={i}
                                title={c.name}
                                count={alerts && alerts[c.prop] ? alerts[c.prop] : 0}
                                onClick={() => !alertsModal.modalOpen && setAlertsModal({ modalOpen: true, alertId: c.name, total: alerts && alerts[c.prop] ? alerts[c.prop] : 0 })}
                            />)}
                        </DCContent>
                    </MainDashboardCard>
                    <MainDashboardCard>
                        <Flex height='100%' width='100%' gap='1rem' >
                            <DashboardCard width='100%' >
                                <Title mainIcon={FileIcon} title='Tasks' subtitle='All statuses' arrow onClick={() => navigate('/dashboard/tasks')} />
                                <DCContent height='100%' >
                                    <Flex gap='1rem' width='100%' >
                                        <DCTaskCount flex>
                                            <p>Not Started:</p>
                                            <p className='bold' >{getTaskStatus('Not Started')}</p>
                                        </DCTaskCount>
                                        <DCTaskCount flex>
                                            <p>Completed:</p>
                                            <p className='bold'>{getTaskStatus('Completed')}</p>
                                        </DCTaskCount>
                                    </Flex>
                                    <Flex gap='1rem' width='100%' >
                                        <DCTaskCount flex>
                                            <p>In Progress:</p>
                                            <p className='bold'>{getTaskStatus('In Progress')}</p>
                                        </DCTaskCount>
                                        <DCTaskCount flex>
                                            <p>Idle:</p>
                                            <p className='bold'>{getTaskStatus('Idle')}</p>
                                        </DCTaskCount>
                                    </Flex>
                                    <Flex gap='1rem' width='100%' >
                                        <DCTaskCount flex>
                                            <p>Paused:</p>
                                            <p className='bold'>{getTaskStatus('Paused')}</p>
                                        </DCTaskCount>

                                        <DCTaskCount width='100%' flex>
                                            <p>Stopped:</p>
                                            <p className='bold'>{getTaskStatus('Stopped')}</p>
                                        </DCTaskCount>
                                    </Flex>

                                </DCContent>
                            </DashboardCard>
                            <DashboardCard width='100%' >
                                <Title mainIcon={CycleIcon} title='Cycles' subtitle='All statuses' arrow onClick={() => navigate('/dashboard/cycles')} />
                                <DCContent height='100%' >
                                    <Flex gap='1rem' width='100%' >
                                        <DCTaskCount flex>
                                            <p>Not Started:</p>
                                            <p className='bold' >{getTaskStatus('Not Started', true)}</p>
                                        </DCTaskCount>
                                        <DCTaskCount flex>
                                            <p>Completed:</p>
                                            <p className='bold'>{getTaskStatus('Completed', true)}</p>
                                        </DCTaskCount>
                                    </Flex>
                                    <Flex gap='1rem' width='100%' >
                                        <DCTaskCount flex>
                                            <p>In Progress:</p>
                                            <p className='bold'>{getTaskStatus('In Progress', true)}</p>
                                        </DCTaskCount>
                                        <DCTaskCount flex>
                                            <p>Idle:</p>
                                            <p className='bold'>{getTaskStatus('Idle', true)}</p>
                                        </DCTaskCount>
                                    </Flex>
                                    <Flex gap='1rem' width='100%' >
                                        <DCTaskCount flex>
                                            <p>Paused:</p>
                                            <p className='bold'>{getTaskStatus('Paused', true)}</p>
                                        </DCTaskCount>

                                        <DCTaskCount width='100%' flex>
                                            <p>Stopped:</p>
                                            <p className='bold'>{getTaskStatus('Stopped', true)}</p>
                                        </DCTaskCount>
                                    </Flex>

                                </DCContent>
                            </DashboardCard>
                        </Flex>
                        {/* <DCContent gap='.4rem' >
                            <Flex between width='100%' height='20%' >
                                <DCFinancesCard>
                                    <p>Total Estimated Cost:</p>
                                    <h3>${finances?.totalEstimatedCost.toFixed(2)}</h3>
                                </DCFinancesCard>
                                <DCFinancesCard color='#1CBBFF'>
                                    <p>Total Actual Cost:</p>
                                    <h3>${finances?.totalActualCost.toFixed(2)}</h3>
                                </DCFinancesCard>
                                <DCFinancesCard color='#FF4F47;' >
                                    <p>Total Difference:</p>
                                    <h3>${finances?.totalDifference.toFixed(2)}</h3>
                                </DCFinancesCard>
                            </Flex>
                            <ResponsiveContainer width="100%" height="80%">
                                <AreaChart
                                    width={500}
                                    height={400}
                                    data={financesData}
                                    margin={{
                                        top: 10,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <defs>
                                        <linearGradient id="actual" x1="229.5" y1="-18.3341" x2="229.5" y2="206.125" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.5" stopColor="#1CBBFF" />
                                            <stop offset="1" stopColor="#1CBBFF" stopOpacity="0.1" />
                                        </linearGradient>
                                        <linearGradient id="estimated" x1="229.5" y1="-18.3341" x2="229.5" y2="206.125" gradientUnits="userSpaceOnUse">
                                            <stop offset="0.5" stopColor="#ff9900" />
                                            <stop offset="1" stopColor="#ff9900" stopOpacity="0.1" />
                                        </linearGradient>
                                    </defs>

                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" fontSize='.7rem' />
                                    <YAxis fontSize='.7rem' />
                                    <Tooltip wrapperStyle={{ outline: 'none' }} content={props => {
                                        const actualCost = props.payload?.find(p => p.name == 'actualCost')
                                        const estimated = props.payload?.find(p => p.name == 'estimatedCost')
                                        return <AreaTooltip>
                                            <div className='time' >
                                                <p>{props?.label}</p>
                                            </div>
                                            <Flex column >
                                                <p>Estimated Cost:</p>
                                                <span style={{ color: '#ff9900' }} >${estimated?.value}</span>
                                            </Flex>
                                            <Flex column >
                                                <p>Actual Cost:</p>
                                                <span style={{ color: '#1CBBFF' }}>${actualCost?.value}</span>
                                            </Flex>
                                        </AreaTooltip>
                                    }} />

                                    <Area type="monotone" dataKey="estimatedCost"
                                        stroke="#fadcafcf"
                                        strokeWidth={2}
                                        strokeOpacity={0.9}
                                        fill='url(#estimated)'
                                        fillOpacity='1'
                                    />
                                    <Area type="monotone" dataKey="actualCost"
                                        stroke="#53c3f373"
                                        strokeWidth={2}
                                        strokeOpacity={0.9}
                                        fill='url(#actual)'
                                        fillOpacity='.8'
                                    />

                                </AreaChart>
                            </ResponsiveContainer>
                        </DCContent> */}
                    </MainDashboardCard>
                </DashboardSectionsContainer>
                <DashboardSectionsContainer>
                    <DashboardCard>
                        <Title mainIcon={financesIcon} title='Finances' subtitle='Financial Overview' arrow onClick={() => navigate('/dashboard/tasks')} />
                        <Flex width='100%' >
                            <DCFinancesCard plain color='#1CBBFF'>
                                <p>Total cost:</p>
                                <h3>${finances?.totalActualCost.toFixed(2)}</h3>
                            </DCFinancesCard>
                        </Flex>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={600}
                                height={400}
                                data={financesData}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    left: -20,
                                    bottom: -10,
                                }}
                            >
                                <defs>
                                    <linearGradient id="actual" x1="229.5" y1="-18.3341" x2="229.5" y2="206.125" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.5" stopColor="#1CBBFF" />
                                        <stop offset="1" stopColor="#1CBBFF" stopOpacity="0.1" />
                                    </linearGradient>
                                    <linearGradient id="estimated" x1="229.5" y1="-18.3341" x2="229.5" y2="206.125" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.5" stopColor="#ff9900" />
                                        <stop offset="1" stopColor="#ff9900" stopOpacity="0.1" />
                                    </linearGradient>
                                </defs>

                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" fontSize='.7rem' />
                                <YAxis fontSize='.7rem' />
                                <Tooltip wrapperStyle={{ outline: 'none' }} content={props => {
                                    const actualCost = props.payload?.find(p => p.name == 'actualCost')
                                    // const estimated = props.payload?.find(p => p.name == 'estimatedCost')
                                    return <AreaTooltip>
                                        <div className='time' >
                                            <p>{props?.label}</p>
                                        </div>
                                        {/* <Flex column >
                                            <p>Estimated Cost:</p>
                                            <span style={{ color: '#ff9900' }} >${estimated?.value}</span>
                                        </Flex> */}
                                        <Flex column m='2rem 0' >
                                            <p>Actual Cost:</p>
                                            <span style={{ color: '#1CBBFF' }}>${actualCost?.value}</span>
                                        </Flex>
                                    </AreaTooltip>
                                }} />

                                {/* <Area type="monotone" dataKey="estimatedCost"
                                    stroke="#fadcafcf"
                                    strokeWidth={2}
                                    strokeOpacity={0.9}
                                    fill='url(#estimated)'
                                    fillOpacity='1'
                                /> */}
                                <Area type="monotone" dataKey="actualCost"
                                    stroke="#53c3f373"
                                    strokeWidth={2}
                                    strokeOpacity={0.9}
                                    fill='url(#actual)'
                                    fillOpacity='.8'
                                />

                            </AreaChart>
                        </ResponsiveContainer>
                    </DashboardCard>
                    <DashboardCard>
                        <Title mainIcon={machineryIcon} title='Machinery' subtitle='All statuses' arrow onClick={() => navigate('/dashboard/machines')} />
                        <DCContent>
                            <MachineryCardCount>
                                <p>Total Machinery</p>
                                <h3>{machinery?.totalCount}</h3>
                            </MachineryCardCount>
                            <ResponsiveContainer width='100%' height='80%'>
                                <BarChart data={machineryData} barSize={20} >
                                    <Tooltip wrapperStyle={{ outline: 'none' }} content={(props) => {
                                        return props.payload && <DCBarTooltip>
                                            <p>{props?.payload[0]?.payload?.name}</p>
                                            <p>{props?.payload[0]?.value >= 1 ? props?.payload[0]?.value : 0}</p>
                                        </DCBarTooltip>
                                    }} />
                                    <CartesianGrid strokeOpacity={.7} strokeDasharray='3 3' />
                                    <Bar dataKey="value" radius={5} >
                                        {machineryData?.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colors[index]} />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </DCContent>
                    </DashboardCard>
                    <DashboardCard>
                        <Title mainIcon={employeesIcon} title='Employees' subtitle='All shifts' arrow onClick={() => navigate('/dashboard/employees')} />
                        <DCContent>
                            <ResponsiveContainer width='100%' height='60%'>
                                <PieChart>
                                    <Tooltip wrapperStyle={{ outline: 'none' }} itemStyle={{ outline: 'none' }} content={(props) => {
                                        return <ChartTooltip>
                                            <p>{props.payload[0]?.name}</p>
                                            <div>
                                                <h3 style={{ color: props.payload[0]?.payload?.fill }} >{props.payload[0]?.value}</h3>
                                                I
                                                <p> {((Number(props.payload[0]?.value) / Number(shifts?.totalCount)) * 100).toFixed(2)}%</p>
                                            </div>
                                        </ChartTooltip>
                                    }} />
                                    <Pie data={shiftsData} dataKey='value' innerRadius={30} outerRadius={50}>
                                        {shiftsData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colors[index]} />
                                        ))}
                                        <Label position='center' content={<CustomLabel value={String(shifts?.totalCount)} />} />
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            <LineItem borderTop title='Low Rated Workers' count={shifts?.lowRatedCount} noBottom numberOnly />

                        </DCContent>
                    </DashboardCard>
                    <DashboardCard onClick={() => setState(setInventory, 'modalOpen', !inventory.modalOpen,)} >
                        <Title mainIcon={inventoryIcon} title='Inventory' subtitle='Items' onClick={() => setState(setInventory, 'modalOpen', true)} />
                        <DCContent noGap={selectedItems.length < 5} >
                            {selectedItems?.map((i, n) => <LineItem key={n} borderTop={n == 5} title={i.name} count={i.quantity} warning={i.isLowStock} />)}
                        </DCContent>
                    </DashboardCard>
                </DashboardSectionsContainer>
            </ClientDashboardContainer >
        </PageWrapper >
        {alertsModal.modalOpen && <AlertsModal modal={alertsModal} setModal={setAlertsModal} />}
        {inventory.modalOpen && <InventoryModal selectedItems={selectedItems} list={inventoryItems} setSelectedItems={setSelectedItems} modal={inventory} setModal={setInventory} />}
    </>

}