import React from 'react'
import { Modal } from 'components/Modal/Modal'
import { Button, Flex, H2, IconInTheBackground } from 'styling/GlobalStyles'
import { QuestionIcon } from 'assets/icons/QuestionIcon'
import { setConfirmModal } from 'features/global/globalSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { ModalTypes } from 'types/global.interface'

interface IConfirmModal {
  word?: string
  sentence?: string
  onSave: any
  onCancel: () => void
  setParams?: {
    type: ModalTypes
    f: React.Dispatch<any>
    noItems: boolean
  }
}

export const ConfirmModal: React.FC<IConfirmModal> = ({ word, onSave, onCancel, setParams, sentence }) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleSave = async () => {
    await onSave()
    setParams?.type == ModalTypes.Delete &&
      setParams.f((prev) => {
        const copy = { ...prev }
        if (copy.page > 1 && setParams.noItems) {
          copy.page = copy.page - 1
        }
        return copy
      })
    dispatch(setConfirmModal({ isOpen: false }))
  }
  return (
    <Modal maxWidth noBack>
      <IconInTheBackground>
        <QuestionIcon />
      </IconInTheBackground>
      <Flex column>
        <Flex mb='30' mt='30' gap='1rem' justcenter width='100%'>
          <H2 center>{word ? 'Are you sure you want to ' + word + '?' : sentence}</H2>
        </Flex>
        <Flex between gap='1rem' width='100%'>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={handleSave} orange>
            Yes
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}
