import { NavBar } from 'components/NavBar/NavBar'
import { addIssue } from 'features/issues/issuesSlice'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AppDispatch } from 'store'
import {
  Flex,
  TabsContainer,
  Tabs,
  H1WithBack,
  PageWrapper,
  ContentWrapper,
} from 'styling/GlobalStyles'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { IssueInfo } from './IssueInfoTab'

export const AddIssue = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [active, setActive] = useState('BasicInfo')
  const [state, setState] = useState({
    name: '',
    industry: { id: null, name: '' },
  })

  useFocusFirstInput()

  const handleSubmit = async () => {

    if (state.industry.id && state.name.length >= 2) {
      const resp = await dispatch(addIssue(state))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/issues')
      }
    } else {
      toast.warn('Both fields must be filled and valid')
    }
  }
  const renderTabs = () => {
    if (active === 'BasicInfo') {
      return <IssueInfo state={state} setState={setState} handleSubmit={handleSubmit} />
    } else if (active === 'Trigers') {
    }
  }
  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title={'Add Issue'} />
          <TabsContainer>
            <Tabs active={active === 'BasicInfo'} onClick={() => setActive('BasicInfo')}>
              Basic Info
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
