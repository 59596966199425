import React, { useState } from 'react'
import { BlackCircle, Collapsable, Flex } from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { CollapseItemText } from 'components/CollapseItem/CollapseItem.styled'
import Chevron from 'assets/icons/Chevron'
import { taskComponentTypes } from './taskData'
import { ICollapseItemDesignTask } from 'types/tasks.interface'
import { ISelectValue } from 'constants/globalTypes'

export const CollapseItemDesignTask = ({
  data,
  setState,
}: ICollapseItemDesignTask) => {
  const [expanded, setExpanded] = useState(true)
  const handleRemove = () => {
    setState((prev) => {
      const copy = { ...prev }
      copy.components = copy.components.filter((item) => item.component !== data.component)
      return copy
    })
  }

  const handleSelect = (value: ISelectValue) => {
    setState((prev) => {
      const copy = structuredClone(prev)
      const objIndex = prev.components.findIndex((obj) => obj.component == data.component)
      copy.components[objIndex].componentType = value.value
      return copy
    })
  }

  return (
    <Collapsable expanded={expanded}>
      <Flex mb='20'>
        <CollapseItemText expanded={expanded} onClick={() => setExpanded((prev) => !prev)}>
          {data.name} <Chevron gray />
        </CollapseItemText>
        <BlackCircle onClick={handleRemove}>
          <RemoveCircle white />
        </BlackCircle>
      </Flex>
      <HonestSelectInput
        width='100%'
        labelText='Field Type'
        options={taskComponentTypes}
        defaultValue={data.componentType}
        name='componentType'
        placeholder='Select here'
        onChange={handleSelect}
      />
    </Collapsable>
  )
}

