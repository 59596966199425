import React, { useState, useEffect, useRef } from 'react'
import { Flex, GridItem, H2, Button, CursorPointer } from 'styling/GlobalStyles'
import Check from 'assets/icons/Check'
import { IOperation, IOperationType } from 'types/operations.interface'
import { CloseIcon } from 'assets/icons/CloseIcon'

export const TaskOperationModal = ({ operation, closeModal, handleAdd }: ITaskOperationModal) => {
  const [selectedType, setSelectedType] = useState<number>(null)
  const modalRef = useRef(null)


  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 27) {
        event.preventDefault()
        closeModal()
      }
    }
    const outSideClick = (e) => {
      if (!modalRef.current.contains(e.target)) {
        closeModal()
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('mousedown', outSideClick)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('mousedown', outSideClick)
    }
  }, [])

  const handleAddType = () => {
    if (selectedType !== null) {
      handleAdd(operation, selectedType)
    }
  }

  return (
    <Flex column gap='3rem' maxHeight='60vh' ref={modalRef}>
      <Flex width='100%' between>
        <H2>Select Operation Type</H2>
        <CursorPointer onClick={() => closeModal()}>
          <CloseIcon />
        </CursorPointer>
      </Flex>
      <Flex column gap='1rem' maxHeight='100%' padding='.5rem' overflow>
        {operation?.types?.map((item: IOperationType) => {
          const valid = selectedType == item.id
          return (
            <GridItem
              key={item.id}
              modalItem
              valid={valid}
              onClick={() => setSelectedType(Number(item.id))}
            >
              {item.name}
              <Check valid={valid} />
            </GridItem>
          )
        })}
      </Flex>
      <Flex end='true' gap='1rem'>
        <Button onClick={() => closeModal()}>Cancel</Button>
        <Button disabled={selectedType === null} orange onClick={handleAddType}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}



interface ITaskOperationModal {
  operation: IOperation //  TODO: napisati tip
  closeModal: () => void
  handleAdd: (item: IOperation, typeId: number) => void
}