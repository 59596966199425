import React from 'react'


export const FileIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M3.49112 2.24112C3.72554 2.0067 4.04348 1.875 4.375 1.875H11.875C12.0408 1.875 12.1997 1.94085 12.3169 2.05806L16.6919 6.43306C16.8092 6.55027 16.875 6.70924 16.875 6.875V16.875C16.875 17.2065 16.7433 17.5245 16.5089 17.7589C16.2745 17.9933 15.9565 18.125 15.625 18.125H4.375C4.04348 18.125 3.72554 17.9933 3.49112 17.7589C3.2567 17.5245 3.125 17.2065 3.125 16.875V3.125C3.125 2.79348 3.2567 2.47554 3.49112 2.24112ZM11.6161 3.125L4.375 3.125L4.375 16.875H15.625V7.13388L11.6161 3.125Z' />
    <path d='M11.875 1.875C12.2202 1.875 12.5 2.15482 12.5 2.5V6.25H16.25C16.5952 6.25 16.875 6.52982 16.875 6.875C16.875 7.22018 16.5952 7.5 16.25 7.5H11.875C11.5298 7.5 11.25 7.22018 11.25 6.875V2.5C11.25 2.15482 11.5298 1.875 11.875 1.875Z' />
    <path d='M6.875 10.625C6.875 10.2798 7.15482 10 7.5 10H12.5C12.8452 10 13.125 10.2798 13.125 10.625C13.125 10.9702 12.8452 11.25 12.5 11.25H7.5C7.15482 11.25 6.875 10.9702 6.875 10.625Z' />
    <path d='M6.875 13.125C6.875 12.7798 7.15482 12.5 7.5 12.5H12.5C12.8452 12.5 13.125 12.7798 13.125 13.125C13.125 13.4702 12.8452 13.75 12.5 13.75H7.5C7.15482 13.75 6.875 13.4702 6.875 13.125Z' />
  </svg>
)
