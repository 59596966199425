import { useEffect, useRef } from 'react'

const useFocusFirstInput = () => {
  const inputRef = useRef(null)

  useEffect(() => {
    const input = document.querySelector('input[type="text"]')
    if (input) {
      inputRef.current = input
      inputRef.current.focus()
    }
  }, [])
}

export default useFocusFirstInput
