import React, { useEffect } from 'react'
import { Input } from '../../components/Input/Input'
import { Animation, Button, ButtonsContainer, Flex, ItemsGrid, TabFooter } from '../../styling/GlobalStyles'
import { useDispatch, useSelector } from 'react-redux'
import { HonestSelectInput } from '../../components/HonestSelect/HonestSelectInput'
import { CancelBtn } from 'components/Button/CancelBtn'
import { validateSelect, standardFieldValidation, validateDate } from 'utils/validationUtils'
import { ISelectValue } from 'constants/globalTypes'
import { HonestSelectAsyncInput } from 'components/HonestAsyncSelect/HonestAsyncSelectInput'
import { AppDispatch, RootState } from 'store'
import { clearModel, getSingleMachineModel } from 'features/machineModels/machineModelsSlice'
import { DatePickerInput } from 'components/DatePicker/DatePicker'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { IMachineryTab } from 'types/machinery.interface'

export const MachinerySpecificationTab = ({
  state,
  setState,
  setActive,
  invalidFields,
  setInvalidFields,
  isAdding
}: IMachinerySpecificationTab) => {
  const dispatch = useDispatch<AppDispatch>()
  const ownershipList = [
    { label: 'Company', value: 1 },
    { label: 'Rented', value: 2 },
  ]
  const usageTypeOptions = [
    { label: 'Hours worked', value: 1 },
    { label: 'Mileage', value: 2 },
  ]
  const statusOptions = [
    { label: 'Free', value: 1 },
    { label: 'Broken', value: 3 },
    { label: 'In Maintenance', value: 4 },
  ].filter(s => isAdding ? s.label !== 'Free' : true)

  const { singleModel } = useSelector((store: RootState) => store.machineModels)

  useFocusFirstInput()


  useEffect(() => {
    if (singleModel) {
      setState((prev) => ({
        ...prev,
        operations: singleModel?.operations.map(o => ({ ...o, types: o.types.map(t => ({ ...t, id: t.operationTypeId })) })),
      }))
    }


  }, [state?.machineryModel, singleModel])

  useEffect(() => {
    return () => {
      dispatch(clearModel())
    }
  }, [])

  const handleChange = (e: React.FormEvent<HTMLInputElement>, index: number) => {
    const { name, value, type } = e.target as HTMLInputElement
    standardFieldValidation(e, setInvalidFields)
    setState((prev) => {
      const copy = { ...prev }
      if (name.startsWith('maint')) {
        const newName = name.split('-')
        copy.maintenanceCosts.map((item, i) => {
          if (index === i) {
            item[newName[1]] = value
          }
          return item
        })
        return copy
      } else {
        copy[name] = type == 'number' ? Number(value) : value
      }
      return copy
    })
  }


  const handleSelect = (value: ISelectValue, name: string) => {
    if (name !== 'vehicle') {
      if (!(isAdding && name == 'status')) {
        validateSelect(value, name, setInvalidFields)
      }

    }
    const copy = structuredClone(state)

    switch (name) {
      case 'machineCategory':
        copy.machineCategory = { id: value.value, name: value.label }
        break
      case 'vehicle':
        copy.vehicle = { id: value.value, name: value.label }
        break
      case 'machineryModel':
        value.value && dispatch(getSingleMachineModel(value.value))
        copy.machineryModel = { id: value.value, name: value.label }
        break
      case 'ownership':
        setInvalidFields((p: string[]) => {
          let c = [...p]
          if (value.value == 2) {
            c = c.filter((f) => !f.includes('machineCost') && !f.includes('purchaseDate'))
            c = [...c, 'costPerHour']
          } else {
            c = c.filter((f) => !f.includes('costPerHour'))
            c = [...c, 'machineCost', 'purchaseDate']
          }
          return c
        })
        copy.ownership = value.value
        break
      case 'usageType':
        setInvalidFields((p: string[]) => {
          let c = [...p]
          if (value.value == 1) {
            c = c.filter((f) => !f.includes('mileage'))
            c = [...c, 'hoursWorked']
          } else {
            c = c.filter((f) => !f.includes('hoursWorked'))
            c = [...c, 'mileage']
          }
          return c
        })
        copy.usageType = value.value
        break
      default:
        copy[name] = value.value
        break
    }

    setState(copy)
  }

  const handleDateChange = (dt: Date) => {
    setState((prev) => {
      const copy = { ...prev }
      copy.purchaseDate = new Date(dt).toDateString()
      validateDate('purchaseDate', setInvalidFields)
      return copy
    })
  }

  const parseCategories = (data: any) => {
    return data ? data.data.map((item: any) => ({ label: item.name, value: item.id })) : []
  }

  const parseModels = (data: any) => {
    return data
      ? data.data.map((item: any) => ({
        label: item.machineModel.make + ' ' + item.machineModel.model,
        value: item.machineModel.id,
      }))
      : []
  }

  const parseVehicles = (data: any) => {
    return data
      ? data.map((item: any) => {
        const type = item.type == 2 ? 'almaksVehicle' : 'frotcomVehicle'
        return {
          label: item[type][item.type == 2 ? 'name' : 'licensePlate'],
          value: item[type].id
        }
      })
      : []
  }

  return (
    <Animation>
      <ItemsGrid columns={3}>
        <Input
          labelText='Machine Name'
          type='text'
          name='name'
          placeholder='Enter name'
          value={state?.name}
          onChange={handleChange}
          invalid={invalidFields.includes('name') ? 'true' : ''}
        />
        <HonestSelectAsyncInput
          link='machine-categories'
          placeholder='Search categories'
          name='machineCategory'
          labelText='Category'
          defaultValue={state?.machineCategory?.name}
          onChange={(value: ISelectValue) => handleSelect(value, 'machineCategory')}
          parserFunction={parseCategories}
          invalid={invalidFields.includes('machineCategory')}
        />
        <HonestSelectAsyncInput
          link='machine-models'
          placeholder='Search machine models'
          name='machineryModel'
          labelText='Machine model'
          defaultValue={state?.machineryModel?.name}
          onChange={(value: ISelectValue) => handleSelect(value, 'machineryModel')}
          parserFunction={parseModels}
          invalid={invalidFields.includes('machineryModel')}
        />

        <HonestSelectAsyncInput
          link='machines/vehicles/list'
          placeholder='Search vehicles'
          name='vehicleId'
          labelText='Vehicle'
          defaultValue={state?.vehicle?.name}
          onChange={(value: ISelectValue) => handleSelect(value, 'vehicle')}
          parserFunction={parseVehicles}
          noPagination
        />

        <HonestSelectInput
          name='status'
          labelText='Status'
          options={statusOptions}
          defaultValue={state?.status}
          onChange={(value: ISelectValue, param: string) => {
            handleSelect(value, param)
          }}
          invalid={!isAdding && invalidFields.includes('status')}
        />

        <HonestSelectInput
          name='ownership'
          labelText='Ownership'
          options={ownershipList}
          defaultValue={state?.ownership}
          onChange={(value: ISelectValue, param: string) => {
            handleSelect(value, param)
          }}
          invalid={invalidFields.includes('ownership')}
        />
        {state?.ownership &&
          (state?.ownership == 2 ? (
            <Input
              type='number'
              name='costPerHour'
              labelText='Cost per hour'
              placeholder='Enter cost per hour'
              value={state.costPerHour}
              onChange={handleChange}
              invalid={invalidFields.includes('costPerHour')}
            />
          ) : (
            <>
              <Input
                type='number'
                name='machineCost'
                labelText='Machine cost'
                placeholder='Machine cost'
                value={state?.machineCost}
                onChange={handleChange}
                invalid={invalidFields.includes('machineCost') ? 'true' : ''}
              />

              <DatePickerInput
                date={state.purchaseDate ? new Date(state?.purchaseDate) : null}
                name='purchaseDate'
                maxDate={new Date()}
                label='Purchase Date'
                onChange={handleDateChange}
              />
            </>
          ))}
        <HonestSelectInput
          name='usageType'
          labelText='Usage Type'
          options={usageTypeOptions}
          defaultValue={state?.usageType}
          onChange={(value: ISelectValue, param: string) => {
            handleSelect(value, param)
          }}
          invalid={invalidFields.includes('usageType')}
        />
        {state?.usageType &&
          (state?.usageType == 1 ? (
            <Input
              type='number'
              name='hoursWorked'
              labelText='Hours worked'
              placeholder='Enter hours worked'
              value={state?.hoursWorked}
              onChange={handleChange}
              invalid={invalidFields.includes('hoursWorked')}
            />
          ) : (
            <Input
              type='number'
              name='mileage'
              labelText='Mileage'
              placeholder='Enter mileage'
              value={state?.mileage}
              onChange={handleChange}
              invalid={invalidFields.includes('mileage')}
            />
          ))}
        <Input
          name='lifetime'
          type='number'
          value={state?.lifetime}
          labelText='Lifetime'
          placeholder='Enter lifetime'
          invalid={invalidFields.includes('lifetime') ? 'true' : ''}
          onChange={handleChange}
        />
        <Flex column>
          <Flex gap='0.5rem'>
            {state?.maintenanceCosts?.map((item, index) => {
              return (
                <div key={index}>
                  <Input
                    name='maint-year'
                    labelText='Estimated Maintenance Cost per year'
                    placeholder='Enter maintenance cost'
                    type='number'
                    value={item.year}
                    invalid={invalidFields.includes('maint-year') ? 'true' : ''}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <Flex gap='1rem'>
                    <Input
                      name='maint-q1'
                      onChange={(e) => handleChange(e, index)}
                      placeholder='q1'
                      type='number'
                      w='25%'
                      className='smallInput'
                      invalid={invalidFields.includes('maint-q1') ? 'true' : ''}
                      value={item.q1}
                      noinvalidtext='true'
                    />
                    <Input
                      value={item.q2}
                      name='maint-q2'
                      placeholder='q2'
                      onChange={(e) => handleChange(e, index)}
                      type='number'
                      w='25%'
                      invalid={invalidFields.includes('maint-q2') ? 'true' : ''}
                      noinvalidtext='true'
                    />
                    <Input
                      value={item.q3}
                      name='maint-q3'
                      placeholder='q3'
                      onChange={(e) => handleChange(e, index)}
                      type='number'
                      invalid={invalidFields.includes('maint-q3') ? 'true' : ''}
                      w='25%'
                      noinvalidtext='true'
                    />
                    <Input
                      value={item.q4}
                      name='maint-q4'
                      placeholder='q4'
                      invalid={invalidFields.includes('maint-q4') ? 'true' : ''}
                      onChange={(e) => handleChange(e, index)}
                      noinvalidtext='true'
                      type='number'
                      w='25%'
                    />
                  </Flex>
                </div>
              )
            })}
          </Flex>
        </Flex>
      </ItemsGrid>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={() => setActive('Capabilities')} type='button'>
            Next
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}


interface IMachinerySpecificationTab extends IMachineryTab {
  setActive: React.Dispatch<React.SetStateAction<string>>
  invalidFields: string[]
  setInvalidFields: React.Dispatch<React.SetStateAction<string[]>>
  isAdding?: boolean
}
