export const rolesInitialState = {
    name: '',
    machineModels: [],
    operations: [],
    permissions: {
      dashboard: false,
      globalView: false,
      employees: false,
      shifts: false,
      machinery: false,
      inventory: false,
      locations: false,
      departments: false,
      reports: false,
      finances: false,
      zones: false,
      roles: false
    }
  }