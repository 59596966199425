import styled from 'styled-components'

interface GlobalViewToggleProsp {
  active: boolean
}

export const GlobalViewToggle = styled.div<GlobalViewToggleProsp>`
  min-width: 60px;
  height: 60px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: ${(props) =>
      props.active ? props.theme.colorOrange : props.theme.colorLightGray} !important;
    height: 35px;
    width: 35px;
  }
`

export const GlobalViewControls = styled.div`

  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 500px) {
    display: none;
  }
  

`

export const GlobalViewMobileMenu = styled.div`

  display: none;
  position: relative;
  min-width: 60px;
  width: 60px;
  height: 60px;
  margin-left: 1rem;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12.5px;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${(props) =>
      props.theme.black} !important;
    height: 35px;
    width: 35px;
  }

  @media (max-width: 500px) {
   display: flex;
  }

`

export const GlobalViewMobileOptions = styled.div<{open?:boolean}>`

position: absolute;
top: 125%;
right: 0;
width: fit-content;
height: max-content;
display: flex;
flex-direction: column;
transition: .2s ease;
padding: 1rem;
${({open})=>open?'min-height:11rem; height:20rem' : 'height:0; padding:0'};
overflow: hidden;
background-color: white;
z-index: 999;
border-radius: 10px;
gap:1rem;
*{
  z-index: 9999;
}

`

export const GlobalViewMapContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  height: calc(100% - 5rem);
  border-radius: 10px;
`

export const GlobalViewSelect = styled.div`
  font-weight: 600;
  font-size: 11.6688px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12.5px;
  min-width: 140px;
  height: 60px;
  position: relative;
  cursor: pointer;
`

export const GlobalViewOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 9;
`

export const GlobalViewSelectOption = styled.div`
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12.5px;
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 20px;

  &:hover {
    color: ${(props) => props.theme.colorOrange};
  }
`
