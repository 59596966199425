import { ArrowLeftColorless } from 'assets/icons/ArrowLeftColorless'
import { TruckIcon } from 'assets/icons/TruckIcon'
import { Filter } from 'components/Filter/Filter'
import { FilterEnum, LinkEnum } from 'components/Filter/SingleFilter'
import { NavBar } from 'components/NavBar/NavBar'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Pagination } from 'components/Pagination/Pagination'
import { Status } from 'components/Status/Status'
import { Td } from 'components/Td/Td'
import { endOfWeek } from 'date-fns/esm'
import { clearDBTMachinesList, getDBMachines } from 'features/dashboard/dbMachinesSlice'
import useQueryPagination from 'hooks/useQueryPagination'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'store'
import { EditContainer, Flex, H1WithBack, PageFooter, PageWrapper, Table, Th, Tr } from 'styling/GlobalStyles'
import { getParams, handlePagination } from 'utils/helpers'
import { DBCardsContainer } from '../Dashboard.styled'
import { machineryStatusesData } from '../dashboardData'
import { DBMachinesCard } from './DBMachines.styled'

export const DBMachines = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const [params, setParams] = useState<any>(getParams({
        location, ap: {
            dateFrom: new Date(searchParams.get('dateFrom') ?? new Date()),
            dateTo: endOfWeek(new Date(searchParams.get('dateTo') ?? new Date()), { weekStartsOn: 1 }),
        }
    }))
    const navigate = useNavigate()
    const { machines, totalPages, noMachines, dashboard } = useSelector((state: RootState) => state.dbMachines)

    useQueryPagination({ fetchFunctions: [getDBMachines], cleanUpFunctions: [clearDBTMachinesList], params })

    const getMachineSum = (status: number) => {
        const foundStatus = dashboard?.machineStatuses.find(s => s.status == status)
        return foundStatus ? foundStatus.count : 0
    }

    const filters = [

        {
            type: FilterEnum.ASYNC_SELECT,
            name: 'Category',
            link: LinkEnum.MACHINE_CATEGORIES,
            propName: 'categoryId',
            single: true
        },
        {
            type: FilterEnum.SELECT,
            name: 'Status',
            options: machineryStatusesData.map(s => ({ label: s.name, value: s.id })),
            propName: 'status'
        },
    ]
    return <>
        <NavBar />
        <PageWrapper>
            <Flex center between>
                <H1WithBack title='Machines' arrow link='/dashboard' />
                <Filter options={filters} setPageParams={setParams} />
            </Flex>
            <DBCardsContainer style={{ marginTop: 20 }} >
                <DBMachinesCard total >
                    <div className='svg' >
                        {TruckIcon}
                    </div>
                    <div className='details' >
                        <h2>Total<br />Machines:</h2>
                        <h1>{dashboard?.totalCount}</h1>
                    </div>
                </DBMachinesCard>
                <DBMachinesCard>
                    <div className='details' >
                        <h2>Total<br />Active:</h2>
                        <h1>{getMachineSum(2)}</h1>
                    </div></DBMachinesCard>
                <DBMachinesCard>
                    <div className='details' >
                        <h2>Total<br /> Inactive:</h2>
                        <h1>{getMachineSum(1)}</h1>
                    </div></DBMachinesCard>
                <DBMachinesCard>
                    <div className='details' >
                        <h2>Total<br />Maintenance:</h2>
                        <h1>{getMachineSum(4)}</h1>
                    </div>
                </DBMachinesCard>
                <DBMachinesCard>
                    <div className='details' >
                        <h2>Total<br />Broken:</h2>
                        <h1>{getMachineSum(3)}</h1>
                    </div>
                </DBMachinesCard>
                <DBMachinesCard>
                    <div className='details' >
                        <h2>Total<br />Paused:</h2>
                        <h1>{getMachineSum(5)}</h1>
                    </div>
                </DBMachinesCard>
            </DBCardsContainer>

            {noMachines ? <NoItems marginTop='40px' title='Machines' /> : <Table style={{ marginTop: '40px' }} >
                <Th next >
                    <Td w='18%' noTooltip text='Name' />
                    <Td w='22%' noTooltip text='Category' />
                    <Td w='22%' noTooltip text='Model' />
                    <Td w='19%' noTooltip text='Task' />
                    <Td w='10%' noTooltip text='Status' />
                </Th>

                {machines.map((item) => {
                    const status = machineryStatusesData.find(s => s.id == item.status)
                    return (
                        <Tr key={item.id}>
                            <Td w='15%' text={item.name} />
                            <Td w='18%' text={item.categoryName} />
                            <Td w='18%' text={item.modelName} />
                            <Td w='15%' text={item.taskTemplateName} />
                            <Td w='10%' >
                                <Status objectColor={status.color} status={status.name} />
                            </Td>
                            <Td noTooltip>
                                <EditContainer onClick={() => navigate('/dashboard/machines/' + item.id)} >
                                    <Flex style={{ transform: 'rotate(180deg)' }} className='arrow-table' >
                                        {ArrowLeftColorless}
                                    </Flex>
                                </EditContainer>
                            </Td>
                        </Tr>
                    )
                })}
            </Table>}
        </PageWrapper>
        <PageFooter>
            <Pagination
                setPage={(page) => handlePagination(page, setParams, 30)}
                pageCount={Math.ceil(totalPages)}
                forcePage={(params.page ? params.page : 1) - 1}
            />
        </PageFooter>
    </>
}