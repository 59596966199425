import React, { useState } from 'react'
import {
    PageWrapper,
    Button,
    Separator,
    GridItem,
    ItemsGrid,
    Flex,
    MainHeadline,
} from 'styling/GlobalStyles'
import { Pagination } from 'components/Pagination/Pagination'
import { FieldsModal } from 'components/Modal/FieldsModal'
import { Modal } from 'components/Modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NavBar } from 'components/NavBar/NavBar'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { setConfirmModal } from 'features/global/globalSlice'
import { AppDispatch, RootState } from 'store'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { getParams, handlePagination } from 'utils/helpers'
import { ITableItem } from 'types/global.interface'
import { clearWorkerComponentsList, deleteWorkerComponent, getWorkerComponents } from 'features/workerComponents/workerComponentsSlice'
import useQueryPagination from 'hooks/useQueryPagination'

export const WorkerComponents = ({ noTitle }: { noTitle: boolean }) => {
    const [modal, setModal] = useState(false)
    const { workerComponents, noWorkerComponents, totalPages } = useSelector((state: RootState) => state.workerComponents)
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [params, setParams] = useState<any>(getParams({ location }))

    useQueryPagination({ fetchFunctions: [getWorkerComponents], cleanUpFunctions: [clearWorkerComponentsList], params })

    const removeField = (id: number) => {
        dispatch(
            setConfirmModal({
                isOpen: true,
                onSave: () => {
                    dispatch(deleteWorkerComponent(id))
                    dispatch(setConfirmModal({ isOpen: false }))
                },
                word: 'delete worker component',
            }),
        )
    }

    return (
        <>
            <NavBar />
            <PageWrapper>
                {!noTitle ? (
                    <Flex between center>
                        <MainHeadline>Worker Components</MainHeadline>
                        <Button orange onClick={() => setModal(true)}>
                            Add Worker Component
                        </Button>
                    </Flex>
                ) : (
                    <Flex end='true'>
                        <Button orange onClick={() => setModal(true)}>
                            Add Worker Components
                        </Button>
                    </Flex>
                )}
                <Separator />
                <ItemsGrid columns={3} mt='25px' placeholder='components' >
                    {!noWorkerComponents ? (
                        workerComponents.map((item: ITableItem, key: number) => {
                            return (
                                <GridItem key={key}>
                                    <p
                                        onClick={() => {
                                            navigate('/tasks-worker-components/' + item.id)
                                        }}
                                    >
                                        {item.name}
                                    </p>

                                    <Flex
                                        between
                                        center
                                        onClick={() => {
                                            removeField(item.id)
                                        }}
                                    >
                                        <RemoveCircle />
                                    </Flex>
                                </GridItem>
                            )
                        })
                    ) : (
                        <NoItems title='Worker Components' />
                    )}
                </ItemsGrid>
            </PageWrapper>
            <Flex end='true' width='100%'>
                <Pagination
                    setPage={(page) => handlePagination(page, setParams)}
                    pageCount={Math.ceil(totalPages)}
                    forcePage={(params.page ? params.page : 1) - 1}
                />
            </Flex>
            {modal && (
                <Modal black>
                    <FieldsModal workerComponents close={setModal} />
                </Modal>
            )}
        </>
    )
}


