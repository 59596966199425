import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  MainHeadline,
  theme,
  Button,
  Flex,
  PageWrapper,
  Separator,
  ItemsGrid,
  GridItem,
  EditContainer,
  PageFooter,
  H2,
  PageControls,
} from '../../styling/GlobalStyles'
import { GraphCardComponent } from '../../components/Charts/GraphCard'
import { GraphWrapper } from '../../components/Charts/GraphCard.styled'
import { CategoryModal } from '../../components/Modal/CategoryModal'
import { TotalMachines } from './Machinery.styled'
import { Modal } from '../../components/Modal/Modal'
import { NavBar } from '../../components/NavBar/NavBar'
import { useSelector } from 'react-redux'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { greenChartColors, redChartColors } from 'pages/Employees/constants'
import { EditIcon } from 'assets/icons/EditIcon'
import { RootState } from 'store'
import {
  cleanSingleClientMachine,
  getMachineryStatuses,
} from 'features/clientMachinery/clientMachinerySlice'
import { Pagination } from 'components/Pagination/Pagination'
import { getParams, handlePagination } from 'utils/helpers'
import { getMachineryCategories } from 'features/categories/machineryCategories'
import { ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const orangeChartColors = [`${theme.colorOrange}`, `${theme.grayChart}`]

export const machine01 = [
  { name: 'Active Machines', value: 35 },
  { name: 'Total Machines', value: 50 },
]

export const machine02 = [
  { name: 'Inactive Machines', value: 10 },
  { name: 'Total Machines', value: 50 },
]

export const machine03 = [
  { name: 'Broken Machines', value: 5 },
  { name: 'Total Machines', value: 50 },
]

export const Machinery = () => {
  const { machineryCategories, noMachineryCategories, totalMachineryPages } = useSelector(
    (store: RootState) => store.machineryCategories,
  )
  const { machineryStatuses } = useSelector((state: RootState) => state.clientMachinery)
  const navigate = useNavigate()
  const [params, setParams] = useState<any>(getParams({ location }))

  const [modal, setModal] = useState<any>({
    open: false,
    edit: true,
    text: '',
    id: '',
    image: '',
    fields: [],
  })

  const ToggleEditModal = (text, id, image) => {
    setModal({ open: true, edit: true, text, id, image })
  }

  const ToggleAddModal = () => {
    setModal({ open: true, edit: false, text: '', id: '' })
  }

  useQueryPagination({ fetchFunctions: [getMachineryCategories], cleanUpFunctions: [cleanSingleClientMachine], fetchFunctionsNoParams: [getMachineryStatuses], params })

  const RenderCharts = () => {
    const getData = (name: string, value: number, forPercentDisplay?: boolean) => {
      const ob = {
        name: 'Total Machines',
        value: forPercentDisplay ? machineryStatuses.total : machineryStatuses.total - value,
      }
      return [{ name, value }, ob]
    }

    return (
      <GraphWrapper>
        <TotalMachines num={machineryStatuses.total} />
        <GraphCardComponent
          data={getData('Active Machines', machineryStatuses.busy, true)}
          chartColors={greenChartColors}
          modifiedData={getData('Active Machines', machineryStatuses.busy)}
          title='Active Machines'
        />

        <GraphCardComponent
          data={getData('Inactive Machines', machineryStatuses.free, true)}
          chartColors={orangeChartColors}
          modifiedData={getData('Inactive Machines', machineryStatuses.free)}
          title='Inactive Machines'
        />

        <GraphCardComponent
          data={getData('Broken Machines', machineryStatuses.broken, true)}
          chartColors={redChartColors}
          modifiedData={getData('Broken Machines', machineryStatuses.broken)}
          title='Broken Machines'
        />
      </GraphWrapper>
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Machineries...' setParams={setParams} />
      <PageWrapper>
        <PageControls>
          <MainHeadline>Machinery</MainHeadline>
          <Flex gap='1rem'>
            <Button orange onClick={() => navigate('/machinery/add')}>
              Add Machinery
            </Button>
            <Button orange onClick={ToggleAddModal}>
              Add Category
            </Button>
          </Flex>
        </PageControls>
        <Separator />

        <RenderCharts />
        {noMachineryCategories ? (
          <NoItems title='Machinery' />
        ) : (
          <>
            <H2 style={{ marginBottom: '1rem' }}>Machinery Categories</H2>
            <ItemsGrid columns={3}>
              {machineryCategories &&
                machineryCategories.map((category) => {
                  const { name, id } = category

                  return (
                    <GridItem noRightPadding key={id}>
                      <p onClick={() => navigate('/machinery/' + id.toString() + '?name=' + name)}>{name}</p>

                      <EditContainer
                        noBackground
                        onClick={() => {
                          ToggleEditModal(name, id, category?.getImagePresignedUrl)
                        }}
                      >
                        <span>{EditIcon}</span>
                      </EditContainer>
                    </GridItem>
                  )
                })}
            </ItemsGrid>
          </>
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams)}
          pageCount={Math.ceil(totalMachineryPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>

      {modal.open && (
        <Modal black>
          <CategoryModal
            machinery
            text={modal.text}
            edit={modal.edit}
            id={modal.id}
            setParams={{
              type: ModalTypes.Delete,
              f: setParams,
              noItems: machineryCategories.length < 2,
            }}
            close={setModal}
          />
        </Modal>
      )}
    </>
  )
}
