import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {  IOperationGlobal, IOperationsPatchRequest, IOperationsPostRequest, IOperationsSlice, IOperationTypeWithTypesAndParams} from 'types/operations.interface'
import { toast } from 'react-toastify'
import { baseUrl } from '../../utils/axios'
import { handleErrors, formatParams } from 'utils/helpers'
import { INameId } from 'constants/globalTypes'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'

const initialState:IOperationsSlice = {
  isLoading: false,
  operations:[],
  totalCount:0,
  totalPages: 0,
  singleOperation: null,
  noOperations: false,
}

export const getOperations = createAsyncThunk(
  'operations/getOperations',
  async (params: any, thunkAPI) => {
    params = formatParams(params)
    try {
      const resp: AxiosResponse<INameId> = await baseUrl.get('/operations', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleOperation = createAsyncThunk(
  'singleOperation/getSingleOperation',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IOperationGlobal<IOperationTypeWithTypesAndParams>> = await baseUrl.get(`operations/${id}`)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addOperation = createAsyncThunk(
  'postOperation/addOperation',
  async (operation: IOperationsPostRequest, thunkAPI) => {
    try {
      const resp = await baseUrl.post('operations', operation, thunkAPI)
      return {name:resp.data.name, id:resp.data.id}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteOperation = createAsyncThunk(
  'deleteOperation/deleteOperation',
  async (id: string | number, thunkAPI) => {
    try {
      await baseUrl.delete('operations/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateOperation = createAsyncThunk(
  'postOperation/updateOperation',
  async (operation: IOperationsPatchRequest, thunkAPI) => {
    try {
      const { id } = operation
      delete operation.id
      await baseUrl.put('operations/' + id, operation, thunkAPI)
      return operation
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    clearOperation: (state) => {
      state.singleOperation = null
    },
    clearOperationsList: (state) => {
      state.singleOperation = null
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getOperations.pending.type]: (state) => {
      state.isLoading = true
    },
    [getOperations.fulfilled.type]: (state, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.isLoading = false
      state.operations = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noOperations = payload.totalCount < 1
    },
    [getOperations.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleOperation.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleOperation.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleOperation = payload
    },
    [getSingleOperation.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [addOperation.pending.type]: (state) => {
      state.isLoading = true
    },
    [addOperation.fulfilled.type]: (state, { payload }:{payload:INameId}) => {
      state.isLoading = false
      state.operations = [...state.operations, payload] 
      toast.success('Operation added')
    },
    [addOperation.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateOperation.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateOperation.fulfilled.type]: (state) => {
      state.isLoading = false
      toast.success('Operation updated')
    },
    [updateOperation.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteOperation.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteOperation.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      toast.success('Operation deleted')
      state.operations = state.operations.filter((item) => item.id !== payload)
    },
    [deleteOperation.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },

  },
})

export const { clearOperation, clearOperationsList } = operationsSlice.actions
export default operationsSlice.reducer
