import styled from 'styled-components'
export const PaginationWrapper = styled.div`
  /* position: fixed;
  bottom: 1rem;
  right: 1rem;
  left: calc(15rem + 2rem);
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: flex-end; */
  /* background: #ffffff; */
  /* box-shadow: 0px 0px 1.8rem rgba(0, 0, 0, 0.1); */
  /* border-radius: 15px; */
  outline: none;
  /* margin: 0 1rem 1rem 1rem; */
  /* padding: 1.5rem; */

  /* z-index: 1; */
  .pagination {
    display: flex;
    gap: 0.5rem;
  }
  .disabled {
    color: white;
    cursor: auto;
    svg {
      path[stroke] {
        stroke: #d3d3d3;
      }
    }
  }

  .pagination > * {
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }
  .pagination:nth-last-child(2) {
  }
  .pagination:nth-child(2) {
    border-radius: 0 15% 15% 0;
  }

  .page-item {
    list-style: none;
    flex: none;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    border-radius: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    color: #5a5a5a;
    background-color: #f5f5f5;
    transition: all 0.2s ease;
    outline: none;
  }

  .page-item-active {
    color: white;
    border-radius: 15%;
    outline: none;
    background-color: ${(props) => props.theme.colorOrange};
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    &:focus {
      outline: none !important;
    }
  }
  .previousClassName {
    background-color: grey;
  }
  .nextClassName {
    background-color: grey;
  }
  .page-link {
    width: 3rem;
    height: 3rem;
    border-radius: 12.5rem;
    &:focus {
      outline: none !important;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .arrow-page {
    list-style: none;
    flex: none;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 2.75rem;
    text-align: center;
    color: #5a5a5a;
    border-radius: 15%;
    margin: 0 0.6rem 0 0.6rem;
    transition: all 0.2s ease;
  }
  @media (max-width: 500px) {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    *{
      scale: .85;
    }
  }
`
