import React from 'react'
import styled from 'styled-components'
import { StarOrange } from '../../assets/icons/StarOrange'

export const Rating = ({ rating }: { rating: number | string }) => {
  return (
    <Wrapper>
      {rating ? <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }} > <h3>{rating}</h3><StarOrange /></div> : 'Not rated yet'}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    padding: 0.1rem 0.2rem;
  }
`
