import styled from 'styled-components'

interface IDBMachinesCard {
  total?: boolean
}
export const DBMachinesCard = styled.div<IDBMachinesCard>`
  height: 130px;
  width: 100%;
  ${(props) => props.total && 'min-width:20%;'}

  border-radius: 12.5px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;

  .svg {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      fill: ${(props) => props.theme.colorOrange};
      scale: 2;
    }
  }

  .details {
    height: 100%;
    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${(props) =>
      props.total &&
      'border-left:0.5px solid ' + props.theme.colorLightGray + '; padding-left:1rem;'}

    h2 {
      font-size: 16px;
      color: ${(props) => props.theme.colorGray};
    }

    h1 {
      font-size: 32px;
      color: ${(props) => props.theme.colorOrange};
    }
  }
`

interface IDBMachineDetail {
  width: string
}
export const DBMachineDetail = styled.p<IDBMachineDetail>`
  width: ${(props) => props.width};
  font-size: 13px;
  color: ${(props) => props.theme.colorGray};
  border-bottom: 1px solid ${(props) => props.theme.colorLightGray};
  padding: 1rem 0;
`

interface IDBMachineStatusIcon {
  color: string
}
export const DBMachineStatusIcon = styled.div<IDBMachineStatusIcon>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  filter: drop-shadow(0px 0px 25.00447654724121px rgba(0, 0, 0, 0.1));

  svg {
    fill: ${(props) => props.color};
  }
`
