import React, { useEffect, useState } from 'react'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { Input } from 'components/Input/Input'
import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'store'
import {
  Animation,
  Button,
  Flex,
  H1WithBack,
  ItemsGrid,
  PageFooter,
  PageWrapper,
  Separator,
} from 'styling/GlobalStyles'
import { clearLocation, getSingleLocation, updateLocation } from 'features/locations/locationsSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { ILocationState } from 'types/locations.interface'
import { onlyNumbers } from 'utils/helpers'
import { CancelBtn } from 'components/Button/CancelBtn'
import {
  standardFieldValidation,
  validateSelect,
  validationWithCoordinates,
} from 'utils/validationUtils'
import { ISelectValue } from 'constants/globalTypes'
import { toast } from 'react-toastify'
import { getMachineryCategories } from 'features/categories/machineryCategories'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const EditLocation = () => {
  const { machineryCategoriesForSelect } = useSelector((store: any) => store.machineryCategories)
  const { singleLocation } = useSelector((store: any) => store.locations)
  const [invalidFields, setInvalidFields] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()
  const [state, setState] = useState<ILocationState>({
    name: '',
    type: '',
    coordinates: {
      latitude: null,
      longitude: null,
    },
    areaSize: null,
    machineryCategory: null,
    machinery: null,
  })

  useFocusFirstInput()

  useEffect(() => {
    dispatch(getMachineryCategories(null))
    dispatch(getSingleLocation(id))

    return () => {
      dispatch(clearLocation())
    }
  }, [])
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = (e.target as HTMLInputElement).name.split('-')
    const { type, value } = e.target as HTMLInputElement

    if (name[0] === 'coordinates') {
      validationWithCoordinates(e, setInvalidFields)
      setState((prev) => {
        const copy = { ...prev }
        copy.coordinates[name[1]] = parseFloat(value)
        return copy
      })
      return
    }
    standardFieldValidation(e, setInvalidFields)
    setState({
      ...state,
      [(e.target as HTMLInputElement).name]: type === 'text' ? value : Number(value),
    })
  }

  useEffect(() => {
    if (singleLocation) {
      const obj = structuredClone(singleLocation)
      if (!(obj.type == 'static')) {
        setInvalidFields((prev: string[]) => {
          let copy = [...prev]
          copy = [...copy, 'coordinates-latitude', 'machinery']
          return copy
        })
      }
      setState(obj)
    }
    setInvalidFields([])
  }, [singleLocation])

  const handleSelect = (value: ISelectValue, name: string) => {
    if (name === 'type') {
      setInvalidFields((prev: string[]) => {
        let copy = [...prev]
        if (value.label === 'static') {
          copy = copy.filter((f: string) => !f.includes('machinery'))
          copy = [...copy, 'coordinates-longitude', 'coordinates-latitude']
        } else {
          copy = copy.filter((f: string) => !f.includes('coord'))
          copy = [...copy, 'machinery']
        }
        return copy
      })
    }
    validateSelect(value, name, setInvalidFields)
    setState({ ...state, [name]: value.value })
  }
  const locationType = [
    {
      value: 'static',
      label: 'static',
    },
    // { value: 'mobile', label: 'mobile' },
  ]

  const handleSubmit = async () => {
    if (invalidFields.length < 1) {
      if (state.type === 'static') {
        delete state.machinery
        delete state.machineryCategory
      }
      const resp = await dispatch(updateLocation(state))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/locations')
      }
    } else {
      toast.warn('All fields must be valid.')
    }
  }

  const machinesOptions = [{ value: 2, label: 'Machine 1' }]
  return (
    <>
      <NavBar />
      <Animation>
        <PageWrapper>
          <H1WithBack arrow title={'Edit ' + (singleLocation?.name ? singleLocation.name : '')} />

          <Separator />
          <ItemsGrid columns={2} width='80%'>
            <Input
              placeholder='Enter name of location'
              labelText='Location Name'
              name='name'
              value={state.name}
              invalid={invalidFields.includes('name') ? 'true' : ''}
              onChange={handleChange}
            />
            <HonestSelectInput
              options={locationType}
              labelText='Location Type'
              name='type'
              invalid={invalidFields.includes('type') ? 'true' : ''}
              defaultValue={state.type}
              onChange={handleSelect}
            />
            {state.type === 'static' ? (
              <>
                <Input
                  placeholder='Enter latitude'
                  labelText='Latitude'
                  type='number'
                  name='coordinates-latitude'
                  value={state.coordinates.latitude}
                  invalid={invalidFields.includes('coordinates-latitude') ? 'true' : ''}
                  onChange={handleChange}
                />
                <Input
                  placeholder='Enter longitude'
                  labelText='Longitude'
                  type='number'
                  name='coordinates-longitude'
                  value={state.coordinates.longitude}
                  invalid={invalidFields.includes('coordinates-longitude') ? 'true' : ''}
                  onChange={handleChange}
                />
                <Input
                  placeholder='Enter location area size'
                  labelText='Location Area Size'
                  name='areaSize'
                  invalid={invalidFields.includes('areaSize') ? 'true' : ''}
                  type='number'
                  onKeyDown={onlyNumbers}
                  value={state.areaSize}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <HonestSelectInput
                  labelText='Machinery Categories'
                  options={machineryCategoriesForSelect}
                  defaultValue={state.machineryCategory}
                  name='machineryCategory'
                  onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
                />
                <HonestSelectInput
                  labelText='Machinery'
                  options={machinesOptions}
                  defaultValue={state.machinery}
                  name='machinery'
                  onChange={(type: ISelectValue, name: string) => handleSelect(type, name)}
                />
              </>
            )}
          </ItemsGrid>
        </PageWrapper>
        <PageFooter>
          <Flex gap='1rem'>
            <CancelBtn />
            <Button orange onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        </PageFooter>
      </Animation>
    </>
  )
}
