import React, { useEffect, useState, useRef } from 'react'
import { addZero, convertMinsToHrsMins } from 'utils/helpers'
import './style.css'
import { v4 } from 'uuid'

export const HonestTimePicker = ({ onChange, defaultValue, calculated, reset }: IHonestTimePicker) => {
  const hours = []
  const minutes = []
  const [state, setState] = useState({ hours: '--', minutes: '--' })
  const [displayOptions, setDisplayOptions] = useState(false)
  const [defaultSet, setDefaultSet] = useState(false)
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      setState({ hours: '--', minutes: '--' })
      defaultValue = null
      return
    } else {
      didMount.current = true
    }
  }, [reset])

  const handleTime = (type, value) => {
    setState((prev) => {
      const newObj = { ...prev }
      if (type == 'hours' && state.minutes == '--') {
        newObj.minutes = '00'
        newObj.hours = value
      } else if (type == 'minutes' && state.hours == '--') {
        newObj.hours = '00'
        newObj.minutes = value
      } else {
        newObj[type] = value
      }
      return newObj
    })
  }

  useEffect(() => {
    onChange && state.hours !== '--' && onChange(state.hours + ':' + state.minutes)
  }, [state])

  useEffect(() => {
    if (!defaultSet)
      if (!isNaN(defaultValue) && !calculated) {
        const splitted = convertMinsToHrsMins(defaultValue).split(':')
        setState({ hours: splitted[0], minutes: splitted[1] })
        setDefaultSet(true)
      } else if (!isNaN(defaultValue) && calculated) {
        const splitted = convertMinsToHrsMins(defaultValue, calculated).split(':')
        setState({ hours: splitted[0], minutes: splitted[1] })
        setDefaultSet(true)
      }
  }, [defaultValue])

  for (let i = 0; i <= 23; i++) {
    hours.push(
      <div
        key={v4()}
        className={
          addZero(i) === parseInt(state.hours) ? 'time-item time-active' : 'time-item'
        }
        onClick={() => handleTime('hours', addZero(i))}
      >
        {addZero(i)}
      </div>,
    )
  }
  for (let i = 0; i <= 59; i++) {
    minutes.push(
      <div
        key={v4()}
        className={
          addZero(i) === parseInt(state.minutes) ? 'time-item time-active' : 'time-item'
        }
        onClick={() => handleTime('minutes', addZero(i))}
      >
        {addZero(i)}
      </div>,
    )
  }

  const cName =
    state.hours == '--' || state.minutes == '--'
      ? 'time-picker-display'
      : 'time-picker-display display-active'

  return (
    <div
      className={cName}
      tabIndex={-1}
      onBlur={() => setDisplayOptions(false)}
      onClick={() => setDisplayOptions((prev) => !prev)}
    >
      {state.hours + ' : ' + state.minutes}
      {displayOptions && (
        <div className='time-picker-options'>
          <div className='picker-container'>{hours}</div>
          <div className='picker-container'>
            {minutes}
          </div>
        </div>
      )}
    </div>
  )
}


interface IHonestTimePicker {
  onChange: (value: string) => void
  defaultValue: number
  calculated: number
  reset?: boolean
}