import React, { useEffect } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Flex,
  H1WithBack,
  H2,
  ItemsGrid,
  PageWrapper,
  Separator,
} from 'styling/GlobalStyles'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { AppDispatch, RootState } from 'store'
import { clearRoleTemplate, getSingleRoleTemplate } from 'features/roles/roleTemplatesSlice'

export const SingleRoleTemplate = () => {
  const navigate = useNavigate()
  const { singleRoleTemplate } = useSelector((store: RootState) => store.roleTemplates)
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getSingleRoleTemplate(id))

    return () => {
      dispatch(clearRoleTemplate())
    }
  }, [])

  return (
    <>
      <NavBar />
      <PageWrapper>
        <Flex between>
          <H1WithBack title={singleRoleTemplate.name} arrow />
          <Button orange onClick={() => navigate('edit')}>
            Edit Role Template
          </Button>
        </Flex>
        <Separator />

        <H2 mb='1'>Role Template Name</H2>
        <TextDisplayCard width='30%' title={singleRoleTemplate?.name} />

        <ItemsGrid columns={3}>
          <Flex column gap='1rem'>
            <H2 mt='2'>Permissions:</H2>
            {
              Object.keys(singleRoleTemplate?.permissions ?? []).filter(p => singleRoleTemplate?.permissions[p] ?? []).map((p, k) => <p key={k} >{p.toLocaleLowerCase()}</p>)
            }
          </Flex>
        </ItemsGrid>
      </PageWrapper>
    </>
  )
}
