import React from 'react'

export const CompaniesIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.625 16.875C0.625 16.5298 0.904822 16.25 1.25 16.25H18.75C19.0952 16.25 19.375 16.5298 19.375 16.875C19.375 17.2202 19.0952 17.5 18.75 17.5H1.25C0.904822 17.5 0.625 17.2202 0.625 16.875Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.24112 2.24112C2.47554 2.0067 2.79348 1.875 3.125 1.875H10.625C10.9565 1.875 11.2745 2.0067 11.5089 2.24112C11.7433 2.47554 11.875 2.79348 11.875 3.125V16.875C11.875 17.2202 11.5952 17.5 11.25 17.5C10.9048 17.5 10.625 17.2202 10.625 16.875L10.625 3.125H3.125L3.125 16.875C3.125 17.2202 2.84518 17.5 2.5 17.5C2.15482 17.5 1.875 17.2202 1.875 16.875V3.125C1.875 2.79348 2.0067 2.47554 2.24112 2.24112Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.625 7.5C10.625 7.15482 10.9048 6.875 11.25 6.875H16.875C17.2065 6.875 17.5245 7.0067 17.7589 7.24112C17.9933 7.47554 18.125 7.79348 18.125 8.125V16.875C18.125 17.2202 17.8452 17.5 17.5 17.5C17.1548 17.5 16.875 17.2202 16.875 16.875L16.875 8.125L11.25 8.125C10.9048 8.125 10.625 7.84518 10.625 7.5Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.375 5.625C4.375 5.27982 4.65482 5 5 5H7.5C7.84518 5 8.125 5.27982 8.125 5.625C8.125 5.97018 7.84518 6.25 7.5 6.25H5C4.65482 6.25 4.375 5.97018 4.375 5.625Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.625 10.625C5.625 10.2798 5.90482 10 6.25 10H8.75C9.09518 10 9.375 10.2798 9.375 10.625C9.375 10.9702 9.09518 11.25 8.75 11.25H6.25C5.90482 11.25 5.625 10.9702 5.625 10.625Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.375 13.75C4.375 13.4048 4.65482 13.125 5 13.125H7.5C7.84518 13.125 8.125 13.4048 8.125 13.75C8.125 14.0952 7.84518 14.375 7.5 14.375H5C4.65482 14.375 4.375 14.0952 4.375 13.75Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.125 13.75C13.125 13.4048 13.4048 13.125 13.75 13.125H15C15.3452 13.125 15.625 13.4048 15.625 13.75C15.625 14.0952 15.3452 14.375 15 14.375H13.75C13.4048 14.375 13.125 14.0952 13.125 13.75Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.125 10.625C13.125 10.2798 13.4048 10 13.75 10H15C15.3452 10 15.625 10.2798 15.625 10.625C15.625 10.9702 15.3452 11.25 15 11.25H13.75C13.4048 11.25 13.125 10.9702 13.125 10.625Z'
      fill='#464255'
    />
  </svg>
)
