import { ISelectValue } from 'constants/globalTypes'
import { ITaskComponent, ITaskState } from 'types/tasks.interface'

export const components: ITaskComponent[] = [
  { name: 'Area', component: 1, componentType: 1 },
  { name: 'Size', component: 2, componentType: 1 },
  { name: 'Machinery', component: 3, componentType: 1 },
  { name: 'Operators', component: 4, componentType: 1 },
  { name: 'Workers', component: 5, componentType: 1 },
  { name: 'Time', component: 6, componentType: 1 },
  { name: 'Location', component: 7, componentType: 1 },
  { name: 'Distance', component: 8, componentType: 1 },
  { name: 'Pickup', component: 9, componentType: 1 },
  { name: 'Delivery', component: 10, componentType: 1 },
  { name: 'Item', component: 11, componentType: 1 },
  { name: 'Cargo', component: 12, componentType: 1 },
  { name: 'Amount', component: 13, componentType: 1 },
  { name: 'Volume', component: 14, componentType: 1 },
  { name: 'Capacity', component: 15, componentType: 1 },
  { name: 'Height', component: 16, componentType: 1 },
  { name: 'Speed', component: 17, componentType: 1 },
  { name: 'Width', component: 18, componentType: 1 },
  { name: 'Length', component: 19, componentType: 1 },
  { name: 'Depth', component: 20, componentType: 1 },
  { name: 'Weight', component: 21, componentType: 1 },
  { name: 'Type', component: 22, componentType: 1 },
  { name: 'Coordinates', component: 23, componentType: 1 },
  { name: 'Quantity', component: 24, componentType: 1 },
  { name: 'Duration', component: 25, componentType: 1 },
]

export const taskComponentTypes: ISelectValue[] = [
  { label: 'Input', value: 1 },
  { label: 'Calculated', value: 2 },
  { label: 'Display', value: 3 },
]

// export const workerComponents:ITaskWComponent[] = [
//   { name: 'Number', component: 1 },
//   { name: 'Text', component: 2 },
//   { name: 'List of numbers', component: 3 },
// ]


export const tasksInitialState:ITaskState ={
  name: '',
  logic: [],
  operationTypeId: null,
  operationTypeParameterValues: [],
  components: [],
  selectedOperation: null,
  workerComponents: [],
  image: null,
  toolTypeComponents: [],
  shouldUseLocationTracking: false,
  imageRaw: null,
  workUnitAmounts: [] 
}