import React from 'react'
import {
    Animation,
    Flex,
    Title,
    TabFooter,
    Button,
    SelectedItemsContainer,
} from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { INameId } from 'constants/globalTypes'
import { TriggersCollapseItem } from '../IssueTriggers/IssueTriggersCollapseItem'
import { ITaskTriggersTab } from 'types/task-triggers.interface'

export const TaskTriggersCompaniesTab = ({ state, setState, handleSubmit }: ITaskTriggersTab) => {
    const handleAdd = (item: any) => {
        setState((prev) => {
            const copy = { ...prev }
            const exists = copy.companies.find((company) => company.id === item.id)
            if (exists) {
                copy.companies = copy.companies.filter((i) => i.id !== item.id)
            } else {
                copy.companies.push(item)
            }
            return copy
        })
    }

    return (
        <Animation>
            <Flex between gap='2rem'>
                <SelectedItemsContainer>
                    <Title pad='0 20px'>Selected Companies</Title>
                    {state.companies
                        ?.filter((company: INameId) => state.companies.some((i) => company.id == i.id))
                        .map((item: INameId) => {
                            return (
                                <TriggersCollapseItem
                                    prop='companies'
                                    setState={setState}
                                    key={item.id}
                                    data={item}
                                />
                            )
                        })}
                </SelectedItemsContainer>
                <ItemsPick
                    selectedList={state.companies}
                    handleAdd={handleAdd}
                    link='companies/simple'
                    placeholder='search companies'
                />
            </Flex>
            <TabFooter>
                <Flex gap='1rem'>
                    <CancelBtn />
                    <Button orange onClick={handleSubmit}>
                        Save
                    </Button>
                </Flex>
            </TabFooter>
        </Animation>

    )
}

