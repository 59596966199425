import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { AddCircleWhite } from 'assets/icons/AddCircle'
import { EditIcon } from 'assets/icons/EditIcon'
import { ThreeDots } from 'assets/icons/ThreeDots'
import { Input } from 'components/Input/Input'
import {
  Table,
  Tr,
  BlackCircle,
  EditContainer,
  Flex,
  Th,
  P1,
  Animation,
  PageWrapper,
  H1WithBack,
  Dropdown,
  PageFooter,
} from 'styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import {
  convertAbsence,
  convertMinsToHrsMins,
  getParams,
  handleOptionBtn,
  handlePagination,
} from 'utils/helpers'
import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { addShift, clearShiftsList, deleteShift, getShifts } from 'features/shifts/shiftsSlice'
import { Modal } from 'components/Modal/Modal'
import { ShiftsModal } from 'components/Modal/ShiftsModal'
import { DeleteIcon } from 'assets/icons/DeleteIcon'
import { setConfirmModal } from 'features/global/globalSlice'
import { HonestTimePickerInput } from 'components/HonestTimePicker/HonestTimePickerInput'
import { AppDispatch, RootState } from 'store'
import { ISingleShift } from 'types/shifts.interface'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { Pagination } from 'components/Pagination/Pagination'
import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { AddShiftInputs } from './Shifts.styled'
import { absenceList } from './shiftsData'
import { ISelectValue } from 'constants/globalTypes'
import useQueryPagination from 'hooks/useQueryPagination'

export const AddShift = () => {
  const { shifts, noShifts, totalPages } = useSelector((store: RootState) => store.shifts)
  const [name, setName] = useState('')
  const [info, setInfo] = useState('')
  const [reset, setReset] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const [showEditMenu, setShowEditMenu] = useState(false)
  const [editID, setEditID] = useState(null)
  const [range, setRange] = useState({ shiftStart: 0, minutes: 0, startSelected: false, minutesSelected: false })
  const [modal, setModal] = useState({ shift: null, isOpen: false })
  const [absenceType, setAbsenceType] = useState<ISelectValue>(null)
  const [params, setParams] = useState<any>(getParams({ location, limit: 5 }))

  useFocusFirstInput()

  useQueryPagination({ fetchFunctions: [getShifts], cleanUpFunctions: [clearShiftsList], params })

  const submitItem = (e?: React.FormEvent) => {
    e.preventDefault()
    if (!name || !info || !absenceType?.value || (absenceType.value == 6 && (!range.minutesSelected || !range.startSelected))) {
      toast.error('All fields are required')
      return
    }

    setName('')
    setReset(prev => !prev)
    setAbsenceType(null)
    setInfo('')
    setRange({
      shiftStart: 0,
      minutes: 0,
      minutesSelected: false,
      startSelected: false
    })
    const { shiftStart, minutes } = range

    const obj = { name, info, shiftStart, minutes, absenceType: absenceType?.value < 6 ? absenceType.value : null }

    if (obj.absenceType) {
      obj.shiftStart = null
      obj.minutes = null
    }

    dispatch(addShift(obj))
  }

  // // handleEnter
  // const handleEnterSubmit = (e) => {
  //   if (e.key == 'Enter') {
  //     submitItem()
  //   }
  // }
  const handleSelect = (type: ISelectValue) => {
    setAbsenceType(type)
  }

  // end of handleEnter
  const handleTimeChange = (time: string, name: string) => {
    const splitted = time.split(':')
    const minutes = parseInt(splitted[0]) * 60 + parseInt(splitted[1])
    if (name == 'shiftStart') {
      setRange((prev) => {
        const copy = { ...prev }
        return { ...copy, shiftStart: minutes, startSelected: true }
      })
    } else {
      setRange((prev) => {
        const copy = { ...prev }
        if (minutes > copy.shiftStart) {
          return { ...copy, minutes: minutes - copy.shiftStart, minutesSelected: true }
        } else {
          return { ...copy, minutes: 1440 - copy.shiftStart + minutes, minutesSelected: true }
        }
      })
    }
  }

  const removeShift = (id: number): void => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: () => {
          dispatch(deleteShift(id))
          dispatch(setConfirmModal({ isOpen: false }))
        },
        word: 'delete shift',
      }),
    )
  }
  return (
    <>
      <NavBar />
      <PageWrapper>
        <Animation>
          <H1WithBack arrow title='Add Shift' link='/shifts' />
          <Flex gap='1rem' m='3rem 0' column>
            <AddShiftInputs>
              <Input
                type='text'
                labelText='Shift Title'
                placeholder='Title'
                width='100%'
                value={name}
                nomarg
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              />
              <HonestSelectInput
                options={absenceList}
                labelText='Type'
                nomarg
                onChange={handleSelect}
                reset={reset}
              />
              {absenceType?.value > 5 && (
                <>
                  <HonestTimePickerInput
                    labelText='From'
                    onChange={(time) => handleTimeChange(time, 'shiftStart')}
                    reset={reset}
                  />
                  <HonestTimePickerInput
                    labelText='To'
                    onChange={(time) => handleTimeChange(time, 'minutes')}
                    reset={reset}
                  />
                </>
              )}
              <Flex width='100%' >
                <Input
                  type='textarea'
                  minHeight='80px'
                  width='150px'
                  placeholder='Enter shift description'
                  value={info}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setInfo(e.target.value)
                  }}
                  labelText='Shift Description'
                  nomarg
                />
                <BlackCircle orange onClick={submitItem}>
                  {AddCircleWhite}
                </BlackCircle>
              </Flex>

            </AddShiftInputs>
          </Flex>
          {noShifts ? (
            <NoItems title='Shifts' />
          ) : (
            <Table>
              <Th next >
                <Td w='25%' noTooltip text='Shift Title' />
                <Td w='15%' noTooltip text='From' />
                <Td w='15%' noTooltip text='To' />
                <Td w='15%' noTooltip text='Description' />
                <Td w='5%'></Td>
              </Th>
              {Array.isArray(shifts) &&
                shifts.map((item: ISingleShift) => {
                  return (
                    <Tr key={item.id}>
                      <Td w='25%' text={item.name} />
                      <Td w='15%' noTooltip>
                        <P1 red={item.absenceType ? true : false}>
                          {item.absenceType
                            ? convertAbsence(item.absenceType)
                            : convertMinsToHrsMins(item.shiftStart)}
                        </P1>
                      </Td>
                      <Td w='15%' noTooltip>
                        <P1 red={item.absenceType ? true : false}>
                          {item.absenceType
                            ? convertAbsence(item.absenceType)
                            : convertMinsToHrsMins(item.minutes, item.shiftStart, true)}
                        </P1>
                      </Td>
                      <Td toolTipText={item.info} w='40%'>
                        <p>{item.info}</p>
                      </Td>
                      <Td noTooltip w='5%'>
                        <EditContainer
                          tabIndex={0}
                          onBlur={() => setShowEditMenu(false)}
                          onClick={() => {
                            handleOptionBtn(item, setEditID, setShowEditMenu, editID)
                          }}
                        >
                          <span>{ThreeDots}</span>
                          {editID === item.id ? (
                            <Dropdown show={showEditMenu} left>
                              <Flex
                                gap='10px'
                                onClick={() => setModal({ shift: item, isOpen: true })}
                              >
                                {EditIcon} Edit Shift
                              </Flex>
                              <Flex
                                gap='10px'
                                onClick={() => {
                                  removeShift(item.id)
                                }}
                              >
                                {DeleteIcon}Delete Shift
                              </Flex>
                            </Dropdown>
                          ) : null}
                        </EditContainer>
                      </Td>
                    </Tr>
                  )
                })}
            </Table>
          )}

        </Animation>
      </PageWrapper>
      <PageFooter>
        {totalPages > 1 && (
          <Pagination
            setPage={(page) => handlePagination(page, setParams, 5)}
            pageCount={Math.ceil(totalPages)}
            forcePage={(params.page ? params.page : 1) - 1}
          />
        )}
      </PageFooter>
      {modal.isOpen && (
        <Modal black>
          <ShiftsModal shift={modal.shift} close={setModal} />
        </Modal>
      )}
    </>
  )
}
