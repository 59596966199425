import React, { useEffect, useState } from 'react'
import {
  CardsContainer,
  Flex,
  MainHeadline,
  PageWrapper,
  Separator,
  H2,
  ItemsGrid,
  PageControls,
} from '../../../styling/GlobalStyles'
import { NavBar } from '../../../components/NavBar/NavBar'
import { InfoCard } from 'components/InfoCard/InfoCard'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { getOverview } from 'features/finances/financesSlice'
import { DatePickerInput } from 'components/DatePicker/DatePicker'
import { endOfWeek, startOfWeek } from 'date-fns'

export const Costs = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { overview } = useSelector((state: RootState) => state.finances)
  const [startDate, setStartDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [endDate, setEndDate] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }))

  useEffect(() => {
    dispatch(
      getOverview({
        startDate: startDate?.toISOString().substr(0, 10),
        endDate: endDate?.toISOString().substr(0, 10),
      }),
    )
  }, [startDate, endDate])

  return (
    <>
      <NavBar />
      <PageWrapper>
        <PageControls>
          <MainHeadline>Costs Overview</MainHeadline>
          <Flex gap='1rem'>
            <DatePickerInput
              maxDate={endDate}
              label='Start date'
              date={startDate}
              setDate={setStartDate}
            />
            <DatePickerInput
              minDate={startDate}
              label='End date'
              date={endDate}
              setDate={setEndDate}
            />
          </Flex>
        </PageControls>
        <Separator />
        <CardsContainer>
          <div>
            <h3>Task Count</h3>
            <section>
              <span>{overview?.overview.tasksCount || 0}</span>
            </section>
          </div>

          <div>
            <h3>Estimated Cost</h3>
            <section>
              <span>{overview?.overview?.estimatedCost?.toFixed(2) || 0}</span>
            </section>
          </div>

          <div>
            <h3>Actual Cost</h3>
            <section>
              <span>{overview?.overview?.actualCost?.toFixed(2) || 0}</span>
            </section>
          </div>
        </CardsContainer>
        <Separator />
        <H2 mb='40px'>Departments</H2>
        <ItemsGrid columns={3}>
          {overview?.overviewByDepartments &&
            overview.overviewByDepartments.map((item, i) => {
              return <InfoCard title={item.departmentName} data={item} key={i} />
            })}
        </ItemsGrid>
      </PageWrapper>
    </>
  )
}
