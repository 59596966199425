import React, { useState } from 'react'
import Chevron from '../../assets/icons/Chevron'
import { CollapseItemText } from './CollapseItem.styled'
import { BlackCircle, Flex, Collapsable, ItemsGrid } from '../../styling/GlobalStyles'
import RemoveCircle from '../../assets/icons/RemoveCircle'
import { rating } from '../../pages/Employees/dummyList'
import { Input } from '../Input/Input'
import { HonestSelectInput } from '../HonestSelect/HonestSelectInput'
import { IOperation, IOperationParameter, IOperationType } from 'types/operations.interface'
import { ISelectValue } from 'constants/globalTypes'

export const CollapseItem = ({ data, setState, fuel, params }: CollapseItem) => {
  const [expanded, setExpanded] = useState(true)

  const handleRemove = () => {
    setState((prev) => {
      const copy = structuredClone(prev)
      copy.operations = copy.operations.filter((item: IOperation) => item.id !== data.id)
      return copy
    })
  }

  const handleRemoveType = (id: number | string) => {
    setState((prev) => {
      const copy = structuredClone(prev)
      const objIndex = copy.operations.findIndex((obj: IOperation) => obj.id === data.id)
      let { types } = data
      types = types.filter((item: IOperationType) => item.id !== id)
      copy.operations[objIndex] = { ...data, types }
      return copy
    })
  }

  const handleSelect = (value: ISelectValue, name: string, itemId: number) => {
    setState((prev) => {
      const copy = structuredClone(prev)
      const objIndex = copy.operations.findIndex((obj: IOperation) => obj.id === data.id)

      const { types } = data
      const paramIndex = types.findIndex((obj: IOperationType) => obj.id === itemId)
      types[paramIndex][name] = parseInt(value.label)
      data = { ...data, types }

      copy.operations[objIndex] = data
      return copy
    })
  }

  const handleInput = (value: string, itemId: number, paramId: number) => {
    setState((prev) => {
      const copy = structuredClone(prev)
      const objIndex = copy.operations.findIndex((obj: IOperation) => obj.id === data.id)
      const { types } = structuredClone(data)
      const itemIndex = types.findIndex((obj: IOperationType) => obj.id === itemId)
      const paramIndex = types[itemIndex].parameters.findIndex(
        (obj: IOperationParameter) => obj.id === paramId,
      )
      types[itemIndex].parameters[paramIndex].value = value

      data = { ...data, types }

      copy.operations[objIndex] = data
      return copy
    })
  }

  const handleFuel = (value: string, name: string, itemId: number) => {
    setState((prev) => {
      const copy = structuredClone(prev)
      const objIndex = copy.operations.findIndex((obj: IOperation) => obj.id === data.id)
      const { types } = data
      const paramIndex = types.findIndex((obj: IOperationType) => obj.id === itemId)
      types[paramIndex][name] = parseInt(value)
      data = { ...data, types }
      copy.operations[objIndex] = data
      return copy
    })
  }

  return (
    <Collapsable expanded={expanded}>
      <Flex mb='20'>
        <CollapseItemText expanded={expanded} onClick={() => setExpanded((prev) => !prev)}>
          {data?.name} <Chevron gray />
        </CollapseItemText>
        <BlackCircle onClick={handleRemove}>
          <RemoveCircle white />
        </BlackCircle>
      </Flex>
      {data?.types.map((item: any, index: number) => {
        return (
          <div key={index}>
            <CollapseItemText expanded={expanded} key={item.id} white>
              {item.name}
              <Flex center onClick={() => handleRemoveType(item.id)}>
                <RemoveCircle />
              </Flex>
            </CollapseItemText>
            <ItemsGrid mb='1rem' mt='10px' columns={4} repeat>
              {params && item?.parameters?.map((parameter: IOperationParameter) => {
                if (parameter.unit)
                  return (
                    <Input
                      type='number'
                      key={parameter.id}
                      placeholder={parameter.unit}
                      value={parameter.value}
                      labelText={parameter.name}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        handleInput(
                          (e.target as HTMLInputElement).value,
                          item.id,
                          Number(parameter.id),
                        )
                      }}
                      nomarg
                    />
                  )
              })}

              {fuel && (
                <Input
                  type='number'
                  key={item.id}
                  placeholder={'Fuel'}
                  value={item.fuelCost}
                  labelText='Fuel'
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    handleFuel((e.target as HTMLInputElement).value, 'fuelCost', item.id)
                  }
                  nomarg
                />
              )}

              <HonestSelectInput
                nomarg
                name='rating'
                labelText='Performance'
                defaultValue={String(item.rating)}
                onChange={(value: ISelectValue, name: string) =>
                  handleSelect(value, name, item.id)
                }
                options={rating}
              />
              <HonestSelectInput
                nomarg
                name='priority'
                labelText='Priority'
                defaultValue={String(item.priority)}
                onChange={(value: ISelectValue, name: string) =>
                  handleSelect(value, name, item.id)
                }
                options={rating}
              />
            </ItemsGrid>
          </div>
        )
      })}
    </Collapsable>
  )
}



interface CollapseItem {
  data: any
  setState: React.Dispatch<React.SetStateAction<any>>
  fuel?: boolean
  params?: boolean
}