import { Button } from 'styling/GlobalStyles'

export const ClearButton = ({ onClick, text }) => {
  return <Button onClick={onClick} white >{text}</Button>
}

// const BtnStyle = styled.button`
//   background-color: ${({ theme }) => theme.colorWhite};
//   border: 1px solid ${({ theme }) => theme.colorGray};
//   border-radius: 10px;
//   width: 11.25rem;
//   z-index: 999999;
//   height: 2.5rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: ${({ theme }) => theme.colorGray};
//   text-transform: capitalize;
//   user-select: none;
//   padding: 0 1rem;
//   font-weight: 500;
//   font-size: 0.8rem;
//   line-height: 1.3rem;
//   cursor: pointer;
//   transition: 0.2s ease;

//   &:hover {
//     filter: brightness(115%);
//     transform: translateY(-3px);
//   }

//   &:active {
//     transform: translateY(3px);
//   }
// `
