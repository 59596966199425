import React, { useEffect, useState } from 'react'
import { NavBar } from '../../../components/NavBar/NavBar'
import {
  PageWrapper,
  Flex,
  Button,
  MainHeadline,
  GridItem,
  GridWrapper,
  Separator,
  ItemsGrid,
  H1WithBack,
} from '../../../styling/GlobalStyles'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  clearCategory,
  getSingleCategory,
  updateCategory,
} from '../../../features/categories/categoriesSlice'
import RemoveCircle from '../../../assets/icons/RemoveCircle'
import { AppDispatch, RootState } from 'store'
import { NoItems } from 'components/NoItems-fallback/NoItems'

export const SingleResourcesCategory = () => {
  const { category, noCategoryFields, isLoadingSingle } = useSelector(
    (state: RootState) => state.categories,
  )
  const [fields, setFields] = useState(category ? category.fields : null)
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()

  useEffect(() => {
    dispatch(getSingleCategory(Number(id)))
    return () => {
      dispatch(clearCategory())
    }
  }, [])


  useEffect(() => {
    category ? setFields(category.fields) : null
  }, [category])


  const handleRemove = (itemId: number) => {
    const modified = fields.filter((item: any) => item.id !== itemId)
    const array = modified.map((item: any) => ({ name: item.name, id: item.id }))
    setFields(modified)
    dispatch(updateCategory({ id, fields: array, name: category.name }))
  }

  return (
    <>
      <NavBar placeholder='Search Fields...' />
      <PageWrapper>
        <Flex between>
          <MainHeadline>{category && category.name}</MainHeadline>
          <Button orange onClick={() => navigate('add')}>
            Add fields
          </Button>
        </Flex>
        <Separator />
        <H1WithBack arrow title='All Fields' />
        <GridWrapper>
          {noCategoryFields && !isLoadingSingle ? (
            <NoItems title='Fields' />
          ) : (
            <ItemsGrid columns={3}>
              {fields?.map(({ id, name }) => {
                return (
                  <GridItem key={id}>
                    {name}
                    <Flex center justcenter onClick={() => handleRemove(Number(id))}>
                      <RemoveCircle />
                    </Flex>
                  </GridItem>
                )
              })}
            </ItemsGrid>
          )}
        </GridWrapper>
      </PageWrapper>
      {/* {Math.ceil(operations.totalCount / 30) > 1 && (
        <Pagination
          setPage={(selected) => navigate('?page=' + selected + '&limit=30')}
          pageCount={Math.ceil(operations.totalCount / 30)}
          forcePage={page - 1}
        />
      )} */}
    </>
  )
}
