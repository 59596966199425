import React, { useEffect, useState } from 'react'
import {
  PageWrapper,
  Flex,
  H1WithBack,
  TabsContainer,
  Tabs,
  ContentWrapper,
} from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { WorkflowSpecificationTab } from './WorkflowSpecificationTab'
import { WorkflowLocicTab } from './WorkflowLogicTab'
import { WorkflowCompaniesTab } from './WorkflowCompaniesTab'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import {
  clearSingleWorkflow,
  editWorkflow,
  getSingleWorkflow,
} from 'features/workflows/workflowsSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { IWorkflowAddPage } from 'types/workflows.interface'
import { toast } from 'react-toastify'

export const EditWorkflow = () => {
  const [active, setActive] = useState('Specification')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { id } = useParams()
  const [state, setState] = useState<IWorkflowAddPage>({
    name: '',
    companies: [],
    items: [],
  })
  const { singleWorkflow } = useSelector((state: RootState) => state.workflows)

  useEffect(() => {
    dispatch(getSingleWorkflow(Number(id)))
    return () => {
      dispatch(clearSingleWorkflow())
    }
  }, [])

  useEffect(() => {
    singleWorkflow && setState(singleWorkflow)
  }, [singleWorkflow])

  const handleSubmit = async () => {
    if (state.name.length < 2) {
      toast.error('Workflow name must be provided')
      return
    }
    if (state.companies.length < 1) {
      toast.error('At least one company must be assigned to workflow')
      return
    }
    if (state.items.length < 2) {
      toast.error('At least one company must be assigned to workflow')
      return
    }
    if (state.items.filter((i) => i.level == 1).length < 1) {
      toast.error('Workflow logic must have at least 2 levels')
      return
    }
    const obj = {
      name: state.name,
      companyIds: state.companies.map((c) => c.id),
      items: structuredClone(state).items.map((i) => {
        delete i.name
        return i
      }),
    }
    const resp = await dispatch(editWorkflow({ ...obj, id: Number(id) }))
    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/workflows')
    }
  }

  const renderTabs = () => {
    switch (active) {
      case 'Specification':
        return (
          <WorkflowSpecificationTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
      case 'Logic':
        return <WorkflowLocicTab state={state} setState={setState} handleSubmit={handleSubmit} />
      default:
        return (
          <WorkflowCompaniesTab state={state} setState={setState} handleSubmit={handleSubmit} />
        )
    }
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack
            mb='1.5'
            arrow
            title={(singleWorkflow ? singleWorkflow.name : '') + ' preview'}
          />
          <TabsContainer>
            <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
              Specification
            </Tabs>
            <Tabs active={active === 'Logic'} onClick={() => setActive('Logic')}>
              Logic
            </Tabs>
            <Tabs active={active === 'Companies'} onClick={() => setActive('Companies')}>
              Companies
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
