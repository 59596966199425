import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { authUrl } from '../../utils/axios'
//  import { toast } from 'react-toastify'

const initialState = {
  isLoading: false,
  user: null,
}

export const getMe = createAsyncThunk('user/getMe', async (_, thunkAPI) => {
  try {
    const resp = await authUrl.get('auth/user')
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [getMe.pending.type]: (state) => {
      state.isLoading = true
    },
    [getMe.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false
      state.user = payload
    },
    [getMe.rejected.type]: (state) => {
      state.isLoading = false
    },
  },
})

export default userSlice.reducer
