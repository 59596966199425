import React from 'react'

export const zonesIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.75 18.125C3.75 17.7798 4.02982 17.5 4.375 17.5H15.625C15.9702 17.5 16.25 17.7798 16.25 18.125C16.25 18.4702 15.9702 18.75 15.625 18.75H4.375C4.02982 18.75 3.75 18.4702 3.75 18.125Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 6.25C8.96447 6.25 8.125 7.08947 8.125 8.125C8.125 9.16053 8.96447 10 10 10C11.0355 10 11.875 9.16053 11.875 8.125C11.875 7.08947 11.0355 6.25 10 6.25ZM6.875 8.125C6.875 6.39911 8.27411 5 10 5C11.7259 5 13.125 6.39911 13.125 8.125C13.125 9.85089 11.7259 11.25 10 11.25C8.27411 11.25 6.875 9.85089 6.875 8.125Z'
      fill='#464255'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 2.5C8.50816 2.5 7.07742 3.09263 6.02252 4.14752C4.96763 5.20242 4.375 6.63316 4.375 8.125C4.375 10.7125 5.8199 13.0685 7.34663 14.8243C8.10236 15.6933 8.85969 16.3941 9.42822 16.8773C9.65306 17.0684 9.84765 17.2249 10 17.3437C10.1523 17.2249 10.3469 17.0684 10.5718 16.8773C11.1403 16.3941 11.8976 15.6933 12.6534 14.8243C14.1801 13.0685 15.625 10.7125 15.625 8.125C15.625 6.63316 15.0324 5.20242 13.9775 4.14752C12.9226 3.09263 11.4918 2.5 10 2.5ZM10 18.125C9.64159 18.637 9.64141 18.6369 9.64121 18.6368L9.64069 18.6364L9.63923 18.6354L9.63461 18.6321L9.61872 18.6208C9.60521 18.6112 9.58595 18.5973 9.5613 18.5793C9.51202 18.5433 9.4412 18.4908 9.35192 18.4226C9.17342 18.2862 8.92077 18.0865 8.61866 17.8297C8.01531 17.3169 7.21014 16.5723 6.40337 15.6445C4.8051 13.8065 3.125 11.1625 3.125 8.125C3.125 6.30164 3.84933 4.55295 5.13864 3.26364C6.42795 1.97433 8.17664 1.25 10 1.25C11.8234 1.25 13.572 1.97433 14.8614 3.26364C16.1507 4.55295 16.875 6.30164 16.875 8.125C16.875 11.1625 15.1949 13.8065 13.5966 15.6445C12.7899 16.5723 11.9847 17.3169 11.3813 17.8297C11.0792 18.0865 10.8266 18.2862 10.6481 18.4226C10.5588 18.4908 10.488 18.5433 10.4387 18.5793C10.4141 18.5973 10.3948 18.6112 10.3813 18.6208L10.3654 18.6321L10.3608 18.6354L10.3593 18.6364L10.3588 18.6368C10.3586 18.6369 10.3584 18.637 10 18.125ZM10 18.125L10.3584 18.637C10.1432 18.7877 9.85679 18.7877 9.64159 18.637L10 18.125Z'
      fill='#464255'
    />
  </svg>
)
