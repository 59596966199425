import React, { useState } from 'react'
import {
    PageWrapper,
    Flex,
    H1WithBack,
    TabsContainer,
    Tabs,
    ContentWrapper,
} from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ProcessTemplatesSpecificationTab } from './ProcessTemplatesSpecificationTab'
import { ProcessTemplatesLogicTab } from './ProcessTemplatesLogicTab'
import { ProcessTemplatesDataTab } from './ProcessTemplatesDataTab'
import { ProcessTemplatesCompaniesTab } from './ProcessTemplatesCompaniesTab'
import { IProcessTemplatesState } from 'types/processTemplates'
import { addProcessTemplate } from 'features/processTemplates/processTemplatesSlice'

export const AddProcessTemplate = () => {
    const [active, setActive] = useState('Specification')
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [state, setState] = useState<IProcessTemplatesState>({
        name: '',
        companies: [],
        processFunction: null,
    })


    const handleSubmit = async () => {
        if (state.name.length < 2) {
            toast.error('Process Template name must be provided.')
            return
        }
        if (state.companies.length < 1) {
            toast.error('At least one company must be assigned to process template.')
            return
        }
        if (!state.processFunction) {
            toast.error('Process logic must be selected.')
            return
        }
        if (state.processFunction.inputs.some(i => !i.componentGivenName) || state.processFunction.outputs.some(i => !i.componentGivenName)) {
            toast.error('All inputs must be given a name.')
            return
        }
        if (state.processFunction.steps.some(s => !s.operationType?.value)) {
            toast.error('All process function steps must have an operation type assigned.')
            return
        }
        if (state.processFunction.displayInputs.some(d => !d?.value)) {
            toast.error('All display inputs must have set values.')
            return
        }
        const resp = await dispatch(addProcessTemplate(state))
        if (resp.meta.requestStatus === 'fulfilled') {
            navigate('/process-templates')
        }
    }


    const renderTabs = () => {
        switch (active) {
            case 'Specification':
                return (
                    <ProcessTemplatesSpecificationTab
                        state={state}
                        setState={setState}
                        handleSubmit={handleSubmit}
                    />
                )
            case 'Logic':
                return (
                    <ProcessTemplatesLogicTab state={state} setState={setState} goToData={() => setActive('Data')} />
                )
            case 'Data':
                return (
                    <ProcessTemplatesDataTab
                        state={state}
                        setState={setState}
                        handleSubmit={handleSubmit}
                    />
                )
            default:
                return (
                    <ProcessTemplatesCompaniesTab
                        state={state}
                        setState={setState}
                        handleSubmit={handleSubmit}
                    />
                )
        }
    }

    return (
        <div>
            <NavBar />
            <PageWrapper noFooter>
                <Flex between main>
                    <H1WithBack mb='1.5' arrow title='Add Process Template' />
                    <TabsContainer>
                        <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
                            Specification
                        </Tabs>
                        <Tabs active={active === 'Logic'} onClick={() => setActive('Logic')}>
                            Logic
                        </Tabs>
                        <Tabs active={active === 'Data'} onClick={() => setActive('Data')}>
                            Data
                        </Tabs>
                        <Tabs active={active === 'Companies'} onClick={() => setActive('Companies')}>
                            Companies
                        </Tabs>
                    </TabsContainer>
                </Flex>
                <ContentWrapper>{renderTabs()}</ContentWrapper>
            </PageWrapper>
        </div>
    )
}
