import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../utils/axios'
import { IParametersState } from 'types/parameters.interface'
import { handleErrors } from 'utils/helpers'

const initialState: IParametersState = {
  isLoading: false,
  parameters: [],
}

export const getParameters = createAsyncThunk('parameters/getParameters', async (_, thunkAPI) => {
  try {
    const response = await baseUrl.get('/parameters')
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {},
  extraReducers: {
    [getParameters.pending.type]: (state) => {
      state.isLoading = true
    },
    [getParameters.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.parameters = payload.map((parameter) => ({
        ...parameter,
        value: parameter.id,
        label: parameter.name,
      }))
    },
    [getParameters.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export default parametersSlice.reducer
