import React, { useCallback, useRef, useState } from 'react'
import { SelectListContainer, ItemsGrid, GridItem } from 'styling/GlobalStyles'
import { SearchBar } from 'components/SearchBar/SearchBar'
import Check from 'assets/icons/Check'
import usePaginate from 'hooks/usePaginate'
import { DraggableItem } from './DraggableItem'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { formatParams } from 'utils/helpers'
import { useDebounce } from 'hooks/useDebounde'
import { Spinner } from 'components/Loading/Loading'
/*eslint-disable*/
export const ItemsPick = ({
  fixedList,
  selectedList = [],
  handleAdd,
  placeholder,
  link,
  parserFunction,
  extraParams,
  width,
  oneColumn,
  drag,
  customSelectedProp,
  noScroll,
  leaveDuplicates,
  view
}: IItemsPick) => {
  const [list, setList] = useState<IPickItem[]>(fixedList ? fixedList : null)
  const [params, setParams] = useState<any>({ page: 1, limit: 35, ...extraParams, q: '' })
  const debouncedParams = useDebounce<string>(params, 200)
  const observer: any = useRef()
  const [loading, setLoading] = useState(false)

  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setParams((prev: any) => {
            const copy = structuredClone(prev)
            copy.page = copy.page + 1
            return copy
          });
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading]
  );


  const handleQuery = (e: React.FormEvent<HTMLInputElement>) => {
    setList([])
    setParams((prev: any) => {
      const copy = { ...prev }
      copy.q = (e.target as HTMLInputElement).value
      copy.page = 1
      return copy
    })

  }

  usePaginate(link, formatParams(params), fixedList, parserFunction, setList, null, null, null, leaveDuplicates, customSelectedProp, setLoading)

  return (
    <SelectListContainer view={view} width={width} >
      <Spinner inElement={true} loading={loading} />
      {!fixedList && <SearchBar onChange={handleQuery} placeholder={placeholder} />}
      <ItemsGrid columns={oneColumn ? 1 : 2}>
        {(list && list.length < 1) ? <NoItems title='data' /> :
          list?.map((item: any, key: number) => {
            const valid = selectedList.some((it) => customSelectedProp ? it[customSelectedProp] === item[customSelectedProp]
              : it?.id === item.id)
            return !drag ? (
              <GridItem ref={list.length === key + 1 ? lastPostElementRef : null} valid={valid} onClick={() => handleAdd(item)} key={key}>
                {item.name || item.firstName}
                <Check valid={valid} />
              </GridItem>
            ) : (
              <DraggableItem data={{ name: item.name, id: item.id }} keyNum={key} valid={valid} />
            )
          })
        }
      </ItemsGrid>
    </SelectListContainer>
  )
}

interface IItemsPick {
  fixedList?: IPickItem[]
  selectedList: IPickItem[]
  handleAdd?: any
  placeholder?: string
  link?: string
  parserFunction?: any
  extraParams?: any
  width?: string
  oneColumn?: boolean
  drag?: boolean
  noScroll?: boolean
  customSelectedProp?: string
  leaveDuplicates?: boolean
  view?: boolean
}

interface IPickItem {
  name: string
  id?: number | string
  pfid?: number
}
