import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Status } from '../../../components/Status/Status'
import {
  Button,
  MainHeadline,
  Tr,
  Th,
  Table,
  Divider,
  PageWrapper,
  Separator,
  PageFooter,
  PageControls,
} from '../../../styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import { NavBar } from '../../../components/NavBar/NavBar'
import { Options } from 'components/Options/Options'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearInventorySuppliersList,
  deleteSupplier,
  getSuppliers,
} from 'features/supliers/suppliersSlice'
import { AppDispatch, RootState } from 'store'
import { Pagination } from 'components/Pagination/Pagination'
import { ISupplier } from 'types/suppliers.interface'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { setConfirmModal } from 'features/global/globalSlice'
import { getParams, handlePagination } from 'utils/helpers'
import { ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const InvetorySuppliers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { suppliers, totalPages, isLoading } = useSelector((state: RootState) => state.suppliers)
  const [params, setParams] = useState<any>(getParams({ location, limit: 5 }))

  useQueryPagination({ fetchFunctions: [getSuppliers], cleanUpFunctions: [clearInventorySuppliersList], params })

  const statuses = ['Active', 'Inactive']

  const handleRemove = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteSupplier(id))
        },
        word: 'delete supplier',
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: suppliers.length < 2,
        },
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Suppliers...' setParams={setParams} limit={5} />
      <PageWrapper>
        <PageControls>
          <MainHeadline>Suppliers</MainHeadline>
          <Button onClick={() => navigate('add')} orange>
            Add Supplier
          </Button>
        </PageControls>
        <Separator />

        <Divider m='3rem' />

        {suppliers.length > 0 ? (
          <Table>
            <Th>
              <Td w='10%' noTooltip text='Name' />
              <Td w='15%' noTooltip text='Phone Number' />
              <Td w='20%' noTooltip text='Address' />
              <Td w='10%' noTooltip text='VAT ID' />
              <Td w='25%' noTooltip text='Email' />
              <Td w='15%' noTooltip text='Status' />
              <Td w='5%' />
            </Th>

            {suppliers.map((item: ISupplier) => {
              return (
                <Tr key={item.id}>
                  <Td w='10%' text={item.name} />
                  <Td w='15%' text={item.phoneNumber} />
                  <Td w='20%' text={item.address} />
                  <Td w='10%' text={item.vatId} />
                  <Td w='25%' text={item.email} />
                  <Td w='15%' noTooltip>
                    <Status status={statuses[item.status - 1]} />
                  </Td>
                  <Td w='5%' noTooltip>
                    <Options
                      item={item}
                      handleRemove={() => handleRemove(item.id)}
                      title='Supplier'
                    />
                  </Td>
                </Tr>
              )
            })}
          </Table>
        ) : isLoading ? (
          <></>
        ) : (
          <NoItems title='suppliers' />
        )}
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 5)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
