import React from 'react'
import { useDrag } from 'react-dnd'

export const SingleFunctionOutput = ({ output }: ISingleFunctionOutput) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'funOutput',
    item: {
      id: 'funOutput_' + output.id,
      component: output.component,
      functionId: output.fid,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return (
    <div
      ref={drag}
      id={'funOutput_' + output.id}
      style={{
        opacity: isDragging ? '0' : '1',
      }}
      className='singleFunctionOutput'
    >
      {output.name}
    </div>
  )
}

interface ISingleFunctionOutput {
  output: {
    id: string
    name: string
    component: number
    fid: number
    order: number
  }
}