import React, { useState } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import {
  PageWrapper,
  Flex,
  MainHeadline,
  Button,
  Separator,
  GridWrapper,
  ItemsGrid,
  GridItem,
  PageFooter,
  PageControls,
} from 'styling/GlobalStyles'
import RemoveCircle from 'assets/icons/RemoveCircle'
import { useNavigate } from 'react-router-dom'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import {
  clearDepartmentsList,
  deleteDepartment,
  getDepartments,
} from 'features/departments/departmentsSlice'
import { Pagination } from 'components/Pagination/Pagination'
import { setConfirmModal } from 'features/global/globalSlice'
import { getParams, handlePagination, } from 'utils/helpers'
import { ITableItem, ModalTypes } from 'types/global.interface'
import useQueryPagination from 'hooks/useQueryPagination'

export const Departments = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { departments, noDepartments, totalPages } = useSelector((state: RootState) => state.departments)
  const [params, setParams] = useState<any>(getParams({ location }))

  useQueryPagination({ fetchFunctions: [getDepartments], cleanUpFunctions: [clearDepartmentsList], params })

  const handleRemove = (id: number) => {
    dispatch(
      setConfirmModal({
        isOpen: true,
        onSave: async () => {
          await dispatch(deleteDepartment(id))
        },
        setParams: {
          type: ModalTypes.Delete,
          f: setParams,
          noItems: departments.length < 2,
        },
        word: 'delete department',
      }),
    )
  }

  return (
    <>
      <NavBar defaultValue={params.q} input placeholder='Search Departments...' setParams={setParams} limit={30} />
      <PageWrapper>
        <PageControls>
          <MainHeadline>Departments</MainHeadline>
          <Button orange onClick={() => navigate('/departments/add')}>
            Add Department
          </Button>
        </PageControls>
        <Separator />
        <GridWrapper>
          <ItemsGrid columns={3}>
            {!noDepartments ? (
              departments.map((item: ITableItem, key: number) => {
                return (
                  <GridItem key={key}>
                    <p onClick={() => navigate('/departments/' + item.id)}>{item.name}</p>
                    <Flex center justcenter onClick={() => handleRemove(item.id)}>
                      <RemoveCircle />
                    </Flex>
                  </GridItem>
                )
              })
            ) : (
              <NoItems title='Departments' />
            )}
          </ItemsGrid>
        </GridWrapper>
      </PageWrapper>
      <PageFooter>
        <Pagination
          setPage={(page) => handlePagination(page, setParams, 30)}
          pageCount={Math.ceil(totalPages)}
          forcePage={(params.page ? params.page : 1) - 1}
        />
      </PageFooter>
    </>
  )
}
