import React, { useEffect } from 'react'
import {
  Title,
  TabFooter,
  Button,
  SelectedItemsContainer,
  ItemsPickContainer,
  ButtonsContainer,
  Animation,
} from 'styling/GlobalStyles'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store'
import { getClientRoles } from 'features/roles/clientRolesSlice'
import { IDepartmentsState, IDepratmentsTab } from 'types/departments.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { INameId } from 'constants/globalTypes'

export const DepartmentRoleAccessTab = ({ state, setState, handleSubmit }: IDepratmentsTab) => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getClientRoles(null))
  }, [])

  const handleAdd = (item: INameId) => {
    setState((prev: IDepartmentsState) => {
      const copy = { ...prev }
      if (copy.roles.some((r) => r.id == item.id)) {
        copy.roles = copy.roles.filter((r) => r.id !== item.id)
      } else {
        copy.roles = [...copy.roles, item]
      }
      return copy
    })
  }
  return (
    <Animation>
      <ItemsPickContainer>
        <SelectedItemsContainer>
          <Title pad='0 20px'>Selected Roles</Title>
          {state.roles.map((item) => {
            return <SelectedItem setState={setState} key={item.id} data={item} type='roles' />
          })}
        </SelectedItemsContainer>
        <ItemsPick
          handleAdd={handleAdd}
          link='roles'
          selectedList={state.roles}
          placeholder='Search roles'
        />
      </ItemsPickContainer>
      <TabFooter>
        <ButtonsContainer>
          <CancelBtn />
          <Button orange onClick={handleSubmit}>
            Save
          </Button>
        </ButtonsContainer>
      </TabFooter>
    </Animation>
  )
}
