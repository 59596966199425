import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../utils/axios'
import { toast } from 'react-toastify'
import { IInventoryItem, IInventoryItemssSlice } from 'types/inventory.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { IOperation } from 'types/operations.interface'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IGetAllInventoryItemsSingleResponse, IInvenotryItemsGetSingleResponse, IInventoryItemPatchRequest, IInventoryItemPostRequest, IInventoryItemsCountResponse } from 'types/api/inventoryItems.interface'
import { AxiosResponse } from 'axios'

const initialState:IInventoryItemssSlice = {
  isLoading: false,
  inventoryItems: [],
  singleInventoryItem: null,
  totalCount: 0,
  totalPages: 0,
  inventoryItemsInfo:null,
  noItems: false,
}

export const getInventoryItems = createAsyncThunk(
  'inventoryItems/getInventoryItems',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<IGetAllInventoryItemsSingleResponse>> = await baseUrl.get('inventory-items', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addInventoryItem = createAsyncThunk(
  'inventoryItems/addInventoryItem',
  async (item: IInventoryItem, thunkAPI) => {
    try {
      const forSubmit:IInventoryItemPostRequest = {
        sku:item.sku,
        name:item.name,
        model:item.model,
        machineModels:item.machineModels.map((item) => {
          return {
            id: item.id,
            rating: Number(item.rating),
            priority: Number(item.priority),
          }
        }),
        locationId: item.location.id,
        categoryId: item.category.id,
        maxLimit: Number(item.maxLimit),
        minLimit: Number(item.minLimit),
        quantity: Number(item.quantity),
        price: Number(item.price),
        fieldValues :item.fieldValues.map((item) => ({
          fieldId: item.id,
          value: item.value,
        })),
        operationTypes:item.operations
        .map((item:IOperation) => {
          const final = item.types.map((i) => {
            return {
              operationTypeId: Number(i.id),
              parameterValues: i.parameters.map((t) => {
                return { operationTypeParameterId: Number(t.id), value: Number(t.value) }
              }),
              rating: Number(i.rating),
              priority: Number(i.priority),
            }
          })
          return final
        })
        .flat()
      }
  
      const resp = await baseUrl.post('inventory-items', forSubmit, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleInventoryItem = createAsyncThunk(
  'inventoryItems/getSingleInventoryItem',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<IPaginatedResponse<IInvenotryItemsGetSingleResponse>> = await baseUrl.get('inventory-items/' + id, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateInventoryItem = createAsyncThunk(
  'inventoryItems/updateInventoryItem',
  async (item: IInventoryItem, thunkAPI) => {
    console.log(item)
    try {
      const forSubmit:IInventoryItemPatchRequest = {
        id:item.id,
        name:item.name,
        sku:item.name,
        model:item.model,
        machineModels:item.machineModels.map((item) => {
          return {
            id: item.id,
            rating: Number(item.rating),
            priority: Number(item.priority),
          }
        }),
        maxLimit : Number(item.maxLimit),
        minLimit : Number(item.minLimit),
        quantity : Number(item.quantity),
        price : Number(item.price),
        operationTypes:item.operations
        .map((item) => {
          const final = item.types.map((i) => {
            return {
              operationTypeId: Number(i.operationTypeId),
              parameterValues: i.parameters.map((t) => {
                return { operationTypeParameterId: t.id, value: t.value }
              }),
              rating: Number(i.rating),
              priority: Number(i.priority),
            }
          })
          return final
        })
        .flat(),
        locationId : item.location.id,
        categoryId : item.category.id,
        fieldValues : item.fieldValues.map((f) => ({
        fieldId: f.id,
        value: typeof f.value === 'object' ? f.value.id : f.value,
      }))
      }

      const resp = await baseUrl.patch('inventory-items/' + item.id, forSubmit, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getInfoIntentoryItems = createAsyncThunk(
  'inventoryItems/getInfoIntentoryItems',
  async (_, thunkAPI) => {
    try {
      const resp:AxiosResponse<IPaginatedResponse<IInventoryItemsCountResponse>> = await baseUrl.get('inventory-items/count', thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteInventoryItem = createAsyncThunk(
  'inventoryItems/deleteInventoryItem',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('inventory-items/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const inventoryItemsSlice = createSlice({
  name: 'inventoryItems',
  initialState,
  reducers: {
    clearInventoryItem: (state) => {
      state.singleInventoryItem = null
    },
    clearInventoryItemsList: (state) => {
      state.inventoryItems = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [addInventoryItem.pending.type]: (state) => {
      state.isLoading = true
    },
    [addInventoryItem.fulfilled.type]: (state:IInventoryItemssSlice, { payload }:{payload:IGetAllInventoryItemsSingleResponse}) => {
      state.isLoading = false
      state.noItems = false
      if (state.inventoryItems.length < 5) {
        state.inventoryItems = [...state.inventoryItems, payload]
      }
      toast.success('Inventory Item added')
    },
    [addInventoryItem.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      // check if msg path is good
      handleErrors(payload)
    },
    [getInventoryItems.pending.type]: (state) => {
      state.isLoading = true
    },
    [getInventoryItems.fulfilled.type]: (state:IInventoryItemssSlice, { payload }:{payload:IPaginatedResponse<IGetAllInventoryItemsSingleResponse>}) => {
      state.isLoading = false
      state.inventoryItems = payload.data
      state.totalPages = payload.totalPages
      state.totalCount = payload.totalCount
      state.noItems = payload.data.length < 1
    },
    [getInventoryItems.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleInventoryItem.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleInventoryItem.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleInventoryItem = {
        ...payload,
        fieldValues: payload.fieldValues.map((f) => ({ ...f, id: f.fieldId })),
      }
    },
    [getSingleInventoryItem.rejected.type]: (state, { payload }) => {
      handleErrors(payload)
      state.isLoading = false
    },
    [updateInventoryItem.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateInventoryItem.fulfilled.type]: (state:IInventoryItemssSlice, { payload }) => {
      const foundIndex = state.inventoryItems.findIndex((item) => item.id === payload.id)
      state.inventoryItems[foundIndex] = payload
      state.isLoading = false
      toast.success('Item updated')
    },
    [updateInventoryItem.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getInfoIntentoryItems.pending.type]: (state) => {
      state.isLoading = true
    },
    [getInfoIntentoryItems.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.inventoryItemsInfo = payload
    },
    [getInfoIntentoryItems.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteInventoryItem.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteInventoryItem.fulfilled.type]: (state: IInventoryItemssSlice, { payload }) => {
      state.isLoading = false
      state.inventoryItems = state.inventoryItems.filter((item) => item.id !== payload)
      toast.success('Item deleted')
    },
    [deleteInventoryItem.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearInventoryItem, clearInventoryItemsList } = inventoryItemsSlice.actions

export default inventoryItemsSlice.reducer
