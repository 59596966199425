import React from 'react'
import { useDrag } from 'react-dnd'

export const SingleInput = ({ id, name, component, noDrag }: ISingleInput) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'input',
    item: { id, component },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))

  return (
    <div
      id={String(id)}
      style={{
        opacity: isDragging ? '0' : '1',
        textAlign: 'center'
      }}
      ref={noDrag ? null : drag}
      className='single-input'
    >
      {name}
    </div>
  )
}

interface ISingleInput {
  id: string | number
  name: string
  component: number
  noDrag?: boolean
}