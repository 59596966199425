/*eslint-disable*/

import { CloseIcon } from "assets/icons/CloseIcon"
import { useEffect, useRef } from "react"
import { CursorPointer, Flex, H2, P } from "styling/GlobalStyles"
import { IInventoryItemLocation } from "types/inventory.interface"


export const InventoryLocationsModal = ({ locations, setModal, itemName }: IInventoryLocationsModal) => {
    const closeModal = () => setModal({ isOpen: false, locations: [], itemName: null })
    const modalRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.keyCode === 27) {
                event.preventDefault()
                closeModal()
            }
        }
        const outSideClick = (e) => {
            if (!modalRef.current.contains(e.target)) {
                closeModal()
            }
        }

        document.addEventListener('keydown', keyDownHandler)
        document.addEventListener('mousedown', outSideClick)

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
            document.removeEventListener('mousedown', outSideClick)
        }
    }, [])

    return <div ref={modalRef}>
        <Flex between width="100%" >
            <H2>Locations for: {itemName}</H2>
            <CursorPointer onClick={closeModal}>
                <CloseIcon />
            </CursorPointer>
        </Flex>
        <div style={{ marginTop: '2rem' }}  >
            <Flex column width="100%">
                <Flex width="100%" between padding="0 1rem" mb="10" style={{ borderBottom: '1px solid gray' }} >
                    <P>Location</P>
                    <P>Quantity</P>
                </Flex>
                <Flex width="100%" column style={{ maxHeight: '400px', overflow: 'auto' }} >
                    {
                        locations.map(l => <Flex mb='5' width="100%" between padding="0 1rem" >
                            <P>{l.name}</P>
                            <P>{l.quantity}</P>
                        </Flex>)
                    }
                </Flex>
            </Flex>
        </div>
    </div>
}

interface IInventoryLocationsModal {
    locations: IInventoryItemLocation[]
    setModal: any
    itemName: string
}