import { Title } from './Dashboard'
import { DashboardModalContainer, DashboardModalContent, DashboardModalWrapper } from './Dashboard.styled'
import { AlertIcon } from 'assets/icons/AlertIcon'
import { Table, Th, Tr, Flex, Button } from 'styling/GlobalStyles'
import { Td } from 'components/Td/Td'
import { CircleButton } from 'pages/Shifts/Shifts.styled'
import { setState } from 'utils/helpers'
import { CancelBtn } from 'components/Button/CancelBtn'
import { useState } from 'react'
import { IInventoryDBItem } from 'types/dashboard.interface'

export const InventoryModal = ({ setModal, modal, setSelectedItems, selectedItems, list }: IInventoryModal) => {
    const [localSelected, setLocalSelected] = useState<IInventoryDBItem[]>([...selectedItems])

    const checkSelected = (i: IInventoryDBItem) => localSelected.some(it => it.id == i.id)
    const handleCheck = (i: IInventoryDBItem) => {
        setLocalSelected(prev => {
            if (checkSelected(i)) {
                prev = prev.filter(itm => itm.id !== i.id)
            } else {
                prev = [...prev, i]
            }
            return prev
        })
    }

    const handleSave = () => {
        setState(setModal, 'modalOpen', !modal.modalOpen)
        setSelectedItems(localSelected)
    }

    return <DashboardModalWrapper>
        <DashboardModalContainer>
            <Title center mainIcon={AlertIcon} height={'fit-content'} title='Inventory' rightSide={<></>} />
            <DashboardModalContent width={screen.availWidth < 500 ? '100%' : '60vw'}>
                <Table height='100%' width='100%' modal={true}>
                    <Th minWidth={0}>
                        <Td w='17%' noTooltip text={''} />
                        <Td w='20%' noTooltip text={'SKU'} />
                        <Td w='30%' noTooltip text={'ITEM NAME'} />
                        <Td w='30%' noTooltip text={'MODEL'} />
                        <Td w='20%' noTooltip text={'QTY'} />
                    </Th>
                    {list.map((i: IInventoryDBItem) => <Tr minWidth={0} key={i.id} selected={checkSelected(i)} >
                        <Td w='18%' noTooltip>
                            <CircleButton checked={checkSelected(i)} onClick={() => handleCheck(i)} />
                        </Td>
                        <Td w='20%' noTooltip text={i.sku} />
                        <Td w='30%' noTooltip text={i.name} />
                        <Td w='30%' noTooltip text={i.model} />
                        <Td w='20%' noTooltip text={i.quantity} />
                    </Tr>)}
                </Table>
                <Flex width='100%' end='true'>
                    <CancelBtn onClick={() => setState(setModal, 'modalOpen', !modal.modalOpen)} />
                    <Button ml='15' height='40' orange onClick={handleSave}>
                        Save
                    </Button>
                </Flex>
            </DashboardModalContent>
        </DashboardModalContainer>
    </DashboardModalWrapper>
}

interface IInventoryModal {
    setModal: React.Dispatch<React.SetStateAction<{
        modalOpen: boolean;
        selectedItems: any[];
    }>>
    modal: {
        modalOpen: boolean;
        selectedItems: any[];
    }
    setSelectedItems: React.Dispatch<React.SetStateAction<IInventoryDBItem[]>>
    selectedItems: IInventoryDBItem[]
    list: IInventoryDBItem[]

}