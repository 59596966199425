import React from 'react'

export const financesIcon = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.625 5.625C0.625 4.93464 1.18464 4.375 1.875 4.375H18.125C18.8154 4.375 19.375 4.93464 19.375 5.625V14.375C19.375 15.0654 18.8154 15.625 18.125 15.625H1.875C1.18464 15.625 0.625 15.0654 0.625 14.375V5.625ZM18.125 5.625H1.875V14.375H18.125V5.625Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 8.125C8.96447 8.125 8.125 8.96447 8.125 10C8.125 11.0355 8.96447 11.875 10 11.875C11.0355 11.875 11.875 11.0355 11.875 10C11.875 8.96447 11.0355 8.125 10 8.125ZM6.875 10C6.875 8.27411 8.27411 6.875 10 6.875C11.7259 6.875 13.125 8.27411 13.125 10C13.125 11.7259 11.7259 13.125 10 13.125C8.27411 13.125 6.875 11.7259 6.875 10Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.2796 4.58844C13.5069 4.32867 13.9018 4.30235 14.1616 4.52965L19.1616 8.90465C19.4213 9.13195 19.4477 9.5268 19.2204 9.78657C18.9931 10.0463 18.5982 10.0727 18.3384 9.84537L13.3384 5.47037C13.0787 5.24307 13.0523 4.84822 13.2796 4.58844Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.2204 10.2134C19.4477 10.4732 19.4213 10.8681 19.1616 11.0954L14.1616 15.4704C13.9018 15.6977 13.5069 15.6713 13.2796 15.4116C13.0523 15.1518 13.0787 14.7569 13.3384 14.5296L18.3384 10.1546C18.5982 9.92735 18.9931 9.95367 19.2204 10.2134Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.72037 4.58844C6.94767 4.84822 6.92135 5.24307 6.66157 5.47037L1.66157 9.84537C1.4018 10.0727 1.00695 10.0463 0.779648 9.78657C0.552347 9.5268 0.57867 9.13195 0.838443 8.90465L5.83844 4.52965C6.09822 4.30235 6.49307 4.32867 6.72037 4.58844Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.779648 10.2134C1.00695 9.95367 1.4018 9.92735 1.66157 10.1546L6.66157 14.5296C6.92135 14.7569 6.94767 15.1518 6.72037 15.4116C6.49307 15.6713 6.09822 15.6977 5.83844 15.4704L0.838443 11.0954C0.57867 10.8681 0.552347 10.4732 0.779648 10.2134Z'
    />
  </svg>
)
