import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { INameId } from 'constants/globalTypes'
import { toast } from 'react-toastify'
import { IFieldsPatchRequest, IFieldsPostRequest } from 'types/api/fieldsApi.interface'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IField } from 'types/fields.interface'
import { IInventoryFieldsSlice } from 'types/inventory.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { baseUrl } from '../../utils/axios'
// import { toast } from 'react-toastify'

const initialState:IInventoryFieldsSlice = {
  isLoading: false,
  isSingleLoading: false,
  singleField: null,
  totalCount: 0,
  totalPages: 0,
  fields: [],
  noFields: false,
  noSubfields: false,
}

export const getInvSettingsFields = createAsyncThunk(
  'invSettingsFields/getInvSettingsFields',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('inventory-fields', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addInvField = createAsyncThunk(
  'invSettingsFields/addField',
  async (field: IField, thunkAPI) => {
    const forSubmit:IFieldsPostRequest={...field}
    if (field.dropDownItems.length < 1) {
      delete field.dropDownItems
    }
    try {
      const resp = await baseUrl.post('inventory-fields', forSubmit, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const editInvField = createAsyncThunk(
  'invSettingsFields/editField',
  async (field: IField, thunkAPI) => {
    const forSubmit:IFieldsPatchRequest={...field}
    if (field.dropDownItems.length < 1 || field.dataType != 3) {
      delete field.dropDownOptions
    }
    try {
      const resp = await baseUrl.put('inventory-fields/' + field.id, forSubmit, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleInvField = createAsyncThunk(
  'invSettingsFields/getSingleField',
  async (id: string, thunkAPI) => {
    try {
      const resp = await baseUrl.get('inventory-fields/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteInvField = createAsyncThunk(
  'invSettingsFields/deleteField',
  async (id: number | string, thunkAPI) => {
    try {
      await baseUrl.delete('inventory-fields/' + id)

      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const inventorySettingsFieldsSlice = createSlice({
  name: 'inventoryItems',
  initialState,
  reducers: {
    clearInventoryField: (state) => {
      state.singleField = null
    },
    clearInventoryFieldsList: (state) => {
      state.totalCount = 0
      state.totalPages = 0
      state.fields = []
    },
  },
  extraReducers: {
    [getInvSettingsFields.pending.type]: (state) => {
      state.isLoading = true
    },
    [getInvSettingsFields.fulfilled.type]: (state:IInventoryFieldsSlice, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.isLoading = false
      state.noFields = payload.data.length < 1
      state.fields = payload.data
      state.totalPages = payload.totalPages
      state.totalCount = payload.totalCount
    },
    [getInvSettingsFields.rejected.type]: (state) => {
      state.isLoading = false
    },
    [getSingleInvField.pending.type]: (state) => {
      state.isSingleLoading = true
    },
    [getSingleInvField.fulfilled.type]: (state, { payload }) => {
      state.isSingleLoading = false
      state.noSubfields = payload.dropDownOptions.length < 1
      state.singleField = payload
    },
    [getSingleInvField.rejected.type]: (state) => {
      state.isSingleLoading = false
    },
    [addInvField.pending.type]: (state) => {
      state.isLoading = true
    },
    [addInvField.fulfilled.type]: (state: IInventoryFieldsSlice, { payload }) => {
      state.isLoading = false
      state.fields = [...state.fields, payload]
      state.noFields = false
      toast.success('Field added')
    },
    [addInvField.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [editInvField.pending.type]: (state) => {
      state.isLoading = true
    },
    [editInvField.fulfilled.type]: (state, { payload }) => {
      state.singleField = { ...payload, dropDownOptions: payload.dropDownOptions }
      state.noSubfields = payload.dropDownItems?.length < 1 || payload.dataType !== 3
      state.isLoading = false
      toast.success('Field edited')
    },
    [editInvField.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteInvField.fulfilled.type]: (state: any, { payload }) => {
      state.fields = state.fields.filter((item) => item.id !== payload)
      state.noFields = state.fields.length < 1
      toast.success('Field deleted')
      state.isLoading = false
    },
    [deleteInvField.pending.type]: (state: any) => {
      state.isLoading = true
    },
    [deleteInvField.rejected.type]: (state: any, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearInventoryField, clearInventoryFieldsList } =
  inventorySettingsFieldsSlice.actions

export default inventorySettingsFieldsSlice.reducer
