import styled from 'styled-components'

export const TasksTabsContainer = styled.div`
  margin: 0 1rem;
  max-width: 50%;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 999999;
  margin-right: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  @media (max-width: 1370px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`

export const TaskTabsArrow = styled.div`
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9999999999999900;
  svg {
    transform: rotate(-90deg) scale(2);
  }
`

interface ITaskTabsProps {
  active: boolean
}

export const TasksTabs = styled.div<ITaskTabsProps>`
  background: #fff;
  min-width: 10rem;
  cursor: pointer;
  font-size: 0.8rem;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  border-radius: 0.5rem;
  color: ${(props) => (props.active ? props.theme.colorOrange : props.theme.colorGray)};
  font-weight: ${(props) => (props.active ? 'bold' : '400')};
  height: 2.5rem;
  border: ${(props) => (props.active ? '1px solid #ff9900' : '1px solid' + props.theme.colorGray)};
  transition: all 0.2s;
  opacity: ${(props) => (props.active ? '1' : '0.5')};
  z-index: 99999999999999;
  box-shadow: ${(props) => (props.active ? 'none' : '0px -4px 20px rgba(0, 0, 0, 0.10)')};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #ff9900;
    border: 1px solid #ff9900;
    opacity: 1;
  }
`
