import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { INameId } from 'constants/globalTypes'
import { nextTaskTypes } from 'pages/Triggers/TasksTriggers/constants'
import { toast } from 'react-toastify'
import { IPaginatedResponse } from 'types/api/general.interface'
import { ITaskTriggersPostPatchRequest, ITaskTriggersSingleResponse } from 'types/api/taskTriggersApi.interface'
import { ISingleTaskTrigger, ITaskTriggersSlice } from 'types/task-triggers.interface'
import { baseUrl } from 'utils/axios'
import { handleErrors, formatParams } from 'utils/helpers'

const initialState:ITaskTriggersSlice = {
  isLoading: false,
  taskTriggers: [],
  singleTaskTrigger: null,
  totalPages: 0,
  noItems: false,
}

export const getTasksTriggers = createAsyncThunk(
  'taskTriggers/getTriggers',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('task-triggers', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addTaskTrigger = createAsyncThunk(
  'taskTriggers/addTrigger',
  async (taskTrigger: ISingleTaskTrigger, thunkAPI) => {
    try {

      const forSubmit:ITaskTriggersPostPatchRequest = {
        nextTaskTemplateId:taskTrigger.taskTemplate.id,
        companies:taskTrigger.companies?.map((item: { name: string; id: number }) => item.id),
        minutes:taskTrigger.minutes,
        taskTrigger : taskTrigger.nextTaskType.id,
        name:taskTrigger.name,
        type:taskTrigger.type,
        nextTaskType:taskTrigger.nextTaskType.id,
        taskTemplateId:taskTrigger.taskTemplate.id
      }
  
      if (taskTrigger.type == 1) {
        delete forSubmit.minutes
      }

      if (taskTrigger.nextTaskType.id === 2) {
        forSubmit.nextWorkflowTemplateId = taskTrigger.nextWorkflowTemplate.id
        delete forSubmit.nextTaskTemplateId
      } else {
        forSubmit.nextTaskTemplateId = taskTrigger.nextTaskTemplate.id
        delete forSubmit.nextWorkflowTemplateId
      }

      const resp = await baseUrl.post('task-triggers', forSubmit, thunkAPI)

      return {id:resp.data.id,name:resp.data.name}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleTaskTrigger = createAsyncThunk(
  'taskTriggers/getsingleTaskTrigger',
  async (id: string, thunkAPI) => {
    try {
      const {data}:{data:ITaskTriggersSingleResponse} = await baseUrl.get('task-triggers/' + id, thunkAPI)
      const foundType = nextTaskTypes.find((t) => t.value === data.nextTaskType)
      const obj:ISingleTaskTrigger={
        nextTaskType : { id: foundType?.value, name: foundType?.label },
        nextWorkflowTemplate:data.nextWorkflowTemplate,
        taskTemplate:data.taskTemplate,
        nextTaskTemplate:data.nextTaskTemplate,
        type:data.type,
        id:data.id,
        companies:data.companies,
        name:data.name,
        minutes:data.minutes
      }
      return obj
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateTaskTrigger = createAsyncThunk(
  'taskTriggers/updateTaskrigger',
  async (taskTrigger: ISingleTaskTrigger, thunkAPI) => {
    try {
      const forSubmit:ITaskTriggersPostPatchRequest = {
        nextTaskTemplateId:taskTrigger.taskTemplate.id,
        companies:taskTrigger.companies?.map((item: { name: string; id: number }) => item.id),
        minutes:taskTrigger.minutes,
        taskTrigger : taskTrigger.nextTaskType.id,
        name:taskTrigger.name,
        type:taskTrigger.type,
        nextTaskType:taskTrigger.nextTaskType.id,
        taskTemplateId:taskTrigger.taskTemplate.id
      }
  

      if (taskTrigger.type == 1) {
        delete forSubmit.minutes
      }

      if (taskTrigger.nextTaskType.id === 2) {
        forSubmit.nextWorkflowTemplateId = taskTrigger.nextWorkflowTemplate.id
        delete forSubmit.nextTaskTemplateId
      } else {
        forSubmit.nextTaskTemplateId = taskTrigger.nextTaskTemplate.id
        delete forSubmit.nextWorkflowTemplateId
      }

      const resp = await baseUrl.patch('task-triggers/' + taskTrigger.id, forSubmit, thunkAPI)

      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteTaskTrigger = createAsyncThunk(
  'taskTriggers/deleteTaskTrigger',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('task-triggers/' + id, thunkAPI)

      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const tasksTriggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    clearTaskTrigger: (state) => {
      state.singleTaskTrigger = null
    },
    clearTaskTriggersList: (state) => {
      state.taskTriggers = []
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getTasksTriggers.pending.type]: (state) => {
      state.isLoading = true
    },
    [getTasksTriggers.fulfilled.type]: (state, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.isLoading = false
      state.taskTriggers = payload.data
      state.totalPages = payload.totalPages
      state.noItems = payload.data.length < 1
    },
    [getTasksTriggers.rejected.type]: (state) => {
      state.isLoading = false
    },
    [addTaskTrigger.pending.type]: (state) => {
      state.isLoading = true
    },
    [addTaskTrigger.fulfilled.type]: (state, { payload }:{payload:INameId}) => {
      state.isLoading = false
      state.taskTriggers = [...state.taskTriggers, payload]
    },
    [addTaskTrigger.rejected.type]: (state) => {
      state.isLoading = false
    },
    [getSingleTaskTrigger.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleTaskTrigger.fulfilled.type]: (state, { payload }:{payload:ISingleTaskTrigger}) => {
      state.isLoading = false
      state.singleTaskTrigger = payload
    },
    [getSingleTaskTrigger.rejected.type]: (state) => {
      state.isLoading = false
    },
    [addTaskTrigger.pending.type]: (state) => {
      state.isLoading = true
    },
    [addTaskTrigger.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.taskTriggers.push(payload)
      toast.success('Task Trigger added')
    },
    [addTaskTrigger.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateTaskTrigger.pending.type]: (state) => {
      state.isLoading = true
    },

    [updateTaskTrigger.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleTaskTrigger = payload
      toast.success('Task Trigger updated')
    },
    [updateTaskTrigger.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteTaskTrigger.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteTaskTrigger.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.taskTriggers = state.taskTriggers.filter((item) => item.id !== payload)
      state.noItems = state.taskTriggers.length < 1
      toast.success('Task Trigger deleted')
    },
    [deleteTaskTrigger.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
  },
})

export const { clearTaskTrigger, clearTaskTriggersList } = tasksTriggersSlice.actions

export default tasksTriggersSlice.reducer
