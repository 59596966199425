import React from 'react'


export const NutIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.38564 1.50399C9.5726 1.39671 9.78441 1.34024 10 1.34024C10.2156 1.34024 10.4274 1.39671 10.6144 1.50399L17.1758 5.21434C17.3681 5.32355 17.5283 5.48155 17.64 5.67239C17.7518 5.86323 17.8113 6.08017 17.8125 6.30134L17.8125 6.30469L17.8125 13.6953H17.1875L17.8125 13.6987C17.8113 13.9198 17.7518 14.1368 17.64 14.3276C17.5283 14.5185 17.3681 14.6765 17.1758 14.7857L10.6144 18.496C10.4274 18.6033 10.2156 18.6598 10 18.6598C9.78439 18.6598 9.57257 18.6033 9.3856 18.496L2.82517 14.7862L2.82418 14.7857C2.63186 14.6765 2.47174 14.5185 2.35996 14.3276C2.24818 14.1368 2.1887 13.9198 2.18751 13.6987L2.1875 13.6953V6.30134C2.18869 6.08017 2.24818 5.86323 2.35996 5.67239C2.47174 5.48155 2.63186 5.32355 2.82418 5.21434L2.82517 5.21378L9.38564 1.50399ZM16.5625 13.6923V6.30771C16.5624 6.30646 16.5621 6.30523 16.5614 6.30414C16.561 6.30345 16.5605 6.30282 16.5599 6.30229C16.5595 6.30193 16.5591 6.30162 16.5586 6.30135C16.5586 6.30133 16.5587 6.30137 16.5586 6.30135L10 2.59259L3.44145 6.3013C3.44141 6.30132 3.44148 6.30128 3.44145 6.3013C3.44029 6.30198 3.43924 6.30299 3.43856 6.30414C3.43792 6.30523 3.43756 6.30646 3.4375 6.30771V13.6923C3.43756 13.6936 3.43792 13.6948 3.43856 13.6959C3.43924 13.697 3.44019 13.698 3.44134 13.6987C3.44137 13.6987 3.4413 13.6986 3.44134 13.6987L10 17.4074L16.5586 13.6987C16.5586 13.6987 16.5585 13.6987 16.5586 13.6987C16.5597 13.698 16.5608 13.697 16.5614 13.6959C16.5621 13.6948 16.5624 13.6936 16.5625 13.6923Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 7.8125C8.79188 7.8125 7.8125 8.79188 7.8125 10C7.8125 11.2081 8.79188 12.1875 10 12.1875C11.2081 12.1875 12.1875 11.2081 12.1875 10C12.1875 8.79188 11.2081 7.8125 10 7.8125ZM6.5625 10C6.5625 8.10152 8.10152 6.5625 10 6.5625C11.8985 6.5625 13.4375 8.10152 13.4375 10C13.4375 11.8985 11.8985 13.4375 10 13.4375C8.10152 13.4375 6.5625 11.8985 6.5625 10Z'
      fill='white'
    />
  </svg>
)
