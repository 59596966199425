import React, { useEffect } from 'react'
import { NavBar } from 'components/NavBar/NavBar'
import { clearClientRole, getSingleClientRole } from 'features/roles/clientRolesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Flex,
  H1WithBack,
  H2,
  ItemsGrid,
  P1,
  PageWrapper,
  Separator,
} from 'styling/GlobalStyles'
import { TextDisplayCard } from 'components/TextDisplayCard/TextDisplayCard'
import { AppDispatch, RootState } from 'store'

export const SingleRole = () => {
  const navigate = useNavigate()
  const { singleRole } = useSelector((store: RootState) => store.clientRoles)
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getSingleClientRole(id))

    return () => {
      dispatch(clearClientRole())
    }
  }, [])

  return (
    <>
      <NavBar />
      <PageWrapper>
        <Flex between>
          <H1WithBack title={singleRole.name} arrow />
          <Button orange onClick={() => navigate('edit')}>
            Edit Role
          </Button>
        </Flex>
        <Separator />

        <H2 mb='1'>Role Name</H2>
        <TextDisplayCard width='30%' title={singleRole?.name} />

        <H2 mb='1' mt='1'>
          Role Machine Models
        </H2>

        <Flex column gap='1rem'>
          {singleRole?.machineModels?.length ? (
            singleRole.machineModels.map((item) => {
              return (
                <Flex width='100%' gap='1rem' key={item.id}>
                  <TextDisplayCard
                    width='15rem'
                    title={item.model + ' ' + item.make}
                    labelText='Machine name'
                  />
                  <TextDisplayCard title={item.priority} labelText='Priority' />
                  <TextDisplayCard title={item.rating} labelText='Performance' />
                </Flex>
              )
            })
          ) : (
            <P1>No Machine models to display</P1>
          )}
        </Flex>
        <ItemsGrid columns={3}>
          <Flex column gap='1rem'>
            <H2 mt='2'>Role capabilities</H2>
            {singleRole?.operations?.length ? (
              singleRole.operations.map((item) => {
                return (
                  <Flex gap='1rem' key={item.id} column>
                    <TextDisplayCard title={item.name} width='17rem' labelText='Capability title' />

                    {item.types.map((i) => {
                      return (
                        <Flex width='80%' key={i.id} gap='1rem' column>
                          <Flex gap='1rem'>
                            <TextDisplayCard
                              title={i.name}
                              labelText='Capability name'
                              width='10rem'
                            />
                            <TextDisplayCard title={i.priority} labelText='Priority' />
                            <TextDisplayCard title={i.rating} labelText='Performance' />
                          </Flex>
                          {i?.parameters.map((i) => {
                            return (
                              <Flex key={i.value} width='fit-content' gap='0.5rem'>
                                <TextDisplayCard title={i.name} labelText='Parameter' />
                                <TextDisplayCard title={i.unit} labelText='Unit' />
                                <TextDisplayCard title={i.value} labelText='Value' />
                              </Flex>
                            )
                          })}

                          <Flex column gap='1rem'>
                            {/* {i.parameters &&
                            i.parameters.map((param, key) => {
                        return (
                          <Flex key={key} width='fit-content' gap='1rem'>
                            <TextDisplayCard
                              width='10rem'
                              title={param.name}
                              labelText='Parameter name'
                            />
                            <TextDisplayCard title={param.unit} labelText='Parameter unit' />
                          </Flex>
                        )
                      })} */}
                          </Flex>
                        </Flex>
                      )
                    })}

                  </Flex>
                )
              })
            ) : (
              <P1>No Capabilities to display</P1>
            )}
            <H2 mt='2'>Permissions:</H2>
            {
              Object.keys(singleRole?.permissions ?? []).filter(p => singleRole?.permissions[p] ?? []).map((p, k) => <p key={k} >{p.toLocaleLowerCase()}</p>)
            }
          </Flex>
        </ItemsGrid>
      </PageWrapper>
    </>
  )
}
