import React from 'react'
import styled from 'styled-components'
import { theme } from '../../styling/GlobalStyles'

export const Status = ({ status, objectColor }: { status: string, objectColor?: string }) => {
  return (
    <StatusWrapper status={status} objectColor={objectColor}>
      <section>
        <div></div>
        {status}
      </section>
    </StatusWrapper>
  )
}

interface StatusWrapperProps {
  objectColor?: string
  status?: string
}

export const StatusWrapper = styled.div<StatusWrapperProps>`
  width: 85%;
  section {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    font-weight: 400;
    font-size: 0.75rem;
  }
  div {
    min-height: 0.5rem;
    height: 0.5rem;
   width: 0.5rem;
   min-width: 0.5rem;
    border-radius: 50%;
    background-color: ${({ status, objectColor }) =>
    objectColor ??
    ((status === 'Active' && `${theme.greenChart}`) ||
      (status === 'Pending order' && `${theme.greenChart}`) ||
      (status === 'In stock' && `${theme.blueChart}`) ||
      (status === 'On Vacation' && `${theme.blueChart}`) ||
      (status === 'Sick Relief' && `${theme.brownChart}`) ||
      (status === 'Not Working' && `${theme.redChart}`) ||
      (status === 'Inactive' && `${theme.redChart}`) ||
      (status === 'Out of Stock' && `${theme.redChart}`) ||
      (status === 'In Use' && `${theme.lightBlue}`))}
  }
`
