import React from 'react'
import {
    Title,
    TabFooter,
    Button,
    SelectedItemsContainer,
    ItemsPickContainer,
    ButtonsContainer,
    Animation,
} from 'styling/GlobalStyles'
import { IDepartmentsState, IDepratmentsTab } from 'types/departments.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { SelectedItem } from 'components/ItemsPick/SelectedItem'
import { INameId } from 'constants/globalTypes'

export const DepartmentCyclesTab = ({ state, setState, handleSubmit }: IDepratmentsTab) => {
    const handleAdd = (item: any) => {
        setState((prev: IDepartmentsState) => {
            const copy = { ...prev }
            if (copy.taskCycleTemplates.some((c) => c.id == item.id)) {
                copy.taskCycleTemplates = copy.taskCycleTemplates.filter((c) => c.id !== item.id)
            } else {
                copy.taskCycleTemplates = [...copy.taskCycleTemplates, item]
            }
            return copy
        })
    }

    return (
        <Animation>
            <ItemsPickContainer>
                <SelectedItemsContainer>
                    <Title pad='0 20px'>Selected Workflows</Title>
                    {state.taskCycleTemplates?.map((item: INameId) => {
                        return (
                            <SelectedItem
                                setState={setState}
                                key={item.id}
                                data={item}
                                type='taskCycleTemplates'
                            />
                        )
                    })}
                </SelectedItemsContainer>
                <ItemsPick
                    handleAdd={handleAdd}
                    link='task/cycle/template'
                    selectedList={state.taskCycleTemplates}
                    placeholder='Search Task Cycles'
                />
            </ItemsPickContainer>
            <TabFooter>
                <ButtonsContainer>
                    <CancelBtn />
                    <Button orange onClick={handleSubmit}>
                        Save
                    </Button>
                </ButtonsContainer>
            </TabFooter>
        </Animation>
    )
}

