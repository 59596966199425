/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import { Input } from '../../components/Input/Input'
import {
  Flex,
  Button,
  BlackCircle,
  AddButton,
  Separator,
  CursorPointer,
  WhiteBackground,
  PageWrapper,
  H1WithBack,
  PageFooter,
  Duplicate,
} from '../../styling/GlobalStyles'
import RemoveCircle from '../../assets/icons/RemoveCircle'
import { AddCircle } from '../../assets/icons/AddCircle'
import { useDispatch, useSelector } from 'react-redux'
import { addOperation } from '../../features/operations/operationsSlice'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'
import { NavBar } from '../../components/NavBar/NavBar'
import { HonestSelectInput } from '../../components/HonestSelect/HonestSelectInput'
import { DuplicateIcon } from 'assets/icons/DuplicateIcon'
import { AppDispatch, RootState } from 'store'
import { getParameters } from 'features/parameters/parametersSlice'
import { IOperationParameter, IOperationType } from 'types/operations.interface'
import { ISelectValue } from 'constants/globalTypes'
import { IParameters } from 'types/parameters.interface'
import { CancelBtn } from 'components/Button/CancelBtn'
import useFocusFirstInput from 'utils/useFocusFirstInput'

export const AddOperation = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [name, setName] = useState('')
  const [nameInvalid, setNameInvalid] = useState(false)
  const [descInvalid, setDescInvalid] = useState(false)
  const [description, setDescription] = useState('')
  const [operationTypes, setOperationTypes] = useState<IOperationType[]>([
    {
      id: 1,
      name: '',
      parameters: [{ id: 0, name: '', unit: null, parameterId: null, unitId: null }],
    },
  ])
  const navigate = useNavigate()
  const fetchedParameters = useSelector((state: RootState) => state.parameters.parameters)

  useEffect(() => {
    dispatch(getParameters())
  }, [])

  useFocusFirstInput()

  const handleSubmit = async () => {
    if (
      name.length < 1 ||
      description.length < 1 ||
      operationTypes.some((item: IOperationType) => item.invalid === true || !item.name)
    ) {
      toast.error('Fields must not be empty')
    } else if (
      operationTypes.some((item: IOperationType) =>
        item.parameters.some((p: IOperationParameter) => !p.parameterId || !p.unitId),
      )
    ) {
      toast.error('All operation parameters and units must be filled')
    } else {
      const objectForSubmit: any = {
        types: operationTypes.map((type) => ({
          name: type.name,
          parameters: type.parameters.map((param) => ({
            parameterId: param.parameterId,
            unitId: param.unitId,
          })),
        })),
        name,
        description,
      }
      delete objectForSubmit.id
      const resp = await dispatch(addOperation(objectForSubmit))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate(-1)
      }
    }
  }

  const handleRemoveParameters = (itemId: string | number, paramId: string | number) => {
    const newAr = [...operationTypes]
    const objIndex = newAr.findIndex((obj) => obj.id === itemId)
    newAr[objIndex].parameters = newAr[objIndex].parameters.filter(
      (parameter) => parameter.id !== paramId,
    )
    setOperationTypes(newAr)
  }

  const checkTypeValid = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOperationTypes((prev) => {
      const newAr = [...prev]
      const objIndex = newAr.findIndex((obj) => obj.id == e.target.name)
      newAr[objIndex].invalid = e.target.value.length < 1
      return newAr
    })
  }

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOperationTypes((prev) => {
      const newAr = [...prev]
      const objIndex = newAr.findIndex((obj) => obj.id == e.target.name)
      if (objIndex > -1) {
        newAr[objIndex].name = e.target.value
      } else {
        newAr.push({
          id: v4(),
          name: e.target.value,
          invalid: false,
          parameters: [{ id: v4(), name: '', unit: null, parameterId: null, unitId: null }],
        })
      }
      return newAr
    })
  }

  const handleAddTypes = (id: number | string) => {
    setOperationTypes((prev) => {
      const newAr = [...prev]
      const objIndex = newAr.findIndex((obj) => obj.id === id)

      newAr[objIndex].parameters.push({
        id: v4(),
        invalid: false,
        name: '',
        unit: null,
        parameterId: null,
        unitId: null,
      })
      return newAr
    })
  }

  const handleParamUnit = (
    value: ISelectValue,
    itemId: number | string,
    paramId: number | string,
    name: string,
  ) => {
    setOperationTypes((prev) => {
      const copy = [...prev]
      const objIndex = copy.findIndex((item: IOperationType) => item.id === itemId)
      const paramIndex = copy[objIndex].parameters.findIndex(
        (item: IOperationParameter) => item.id == paramId,
      )
      copy[objIndex].parameters[paramIndex][name == 'parameter' ? 'parameterId' : 'unitId'] =
        value.value
      copy[objIndex].parameters[paramIndex][name == 'parameter' ? 'name' : 'unit'] = value.label
      if (name == 'parameter') {
        copy[objIndex].parameters[paramIndex].unitId = null
        copy[objIndex].parameters[paramIndex].unit = null
      }
      return copy
    })
  }

  const handleSelectList = (itemId: number | string, paramId: number | string) => {
    const typesIndex = operationTypes.findIndex((item) => item.id === itemId)
    const parameterIndex = operationTypes[typesIndex].parameters.findIndex(
      (item) => item.id == paramId,
    )
    const name = operationTypes[typesIndex].parameters[parameterIndex].name
    const foundValues = fetchedParameters.find((item) => item.name == name)
    // @ts-ignore 
    return foundValues ? foundValues.units.map((item: IParameters) => ({ label: item.name, value: item.id }))
      : []
  }

  const handleDuplicate = (item: IOperationType) => {
    const newItem = {
      ...item,
      id: v4(),
      parameters: item.parameters.map((param) => ({ ...param, id: v4() })),
    }
    setOperationTypes((prev) => [...prev, newItem])
  }
  const parametersForSelect = (item?: IOperationType) => {
    const selectedParameters = item.parameters.map((p) => p.parameterId)
    return fetchedParameters.filter((fp) => !selectedParameters.some((sp) => sp == fp.id))
  }

  return (
    <>
      <NavBar />
      <PageWrapper>
        <H1WithBack arrow title='Add Operation' />
        <Separator />
        <form>
          <Flex width='100%' between>
            <WhiteBackground animate height='fit-content' width='30%'>
              <Flex width='100%' column>
                <Input
                  type='text'
                  placeholder='Enter the name of operation'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setName(e.target.value)
                    e.target.value.length < 1 ? setNameInvalid(true) : setNameInvalid(false)
                  }}
                  labelText='Operation Title*'
                  value={name}
                  invalid={nameInvalid ? 'true' : null}
                />
                <Input
                  type='textarea'
                  minHeight='270px'
                  placeholder='Enter description'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDescription(e.target.value)
                    e.target.value.length < 1 ? setDescInvalid(true) : setDescInvalid(false)
                  }}
                  labelText='Operation Description*'
                  nomarg
                  value={description}
                  invalid={descInvalid ? 'true' : null}
                />
              </Flex>
            </WhiteBackground>

            <div style={{ width: '65%' }}>
              {operationTypes.map((item) => {
                return (
                  <WhiteBackground key={item.id} animate mb='25px'>
                    <Flex>
                      <Input
                        placeholder='Enter name of type'
                        labelText={'Operation type*'}
                        type='text'
                        width='42%'
                        nomarg
                        name={item.id}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          inputChange(e)
                          checkTypeValid(e)
                        }}
                        value={item.name}
                        invalid={item.invalid ? 'true' : null}
                      />
                      <Flex width='40%' column>
                        {item.parameters.map((param) => {
                          return (
                            <Flex m='0 1rem' mb='16' gap='1rem' width='fit-content' key={param.id}>
                              <HonestSelectInput
                                width='10em'
                                labelText={item.parameters.indexOf(param) < 1 ? 'Parameter' : ''}
                                nomarg
                                defaultValue={param.parameterId}
                                options={parametersForSelect(item)}
                                onChange={(value: ISelectValue) =>
                                  handleParamUnit(value, item.id, param.id, 'parameter')
                                }
                                name='parameter'
                                optionsForDefault={fetchedParameters}
                              />

                              <HonestSelectInput
                                width='7em'
                                labelText={item.parameters.indexOf(param) < 1 ? 'Unit' : ''}
                                nomarg
                                defaultValue={param.unitId}
                                onChange={(value: ISelectValue) =>
                                  handleParamUnit(value, item.id, param.id, 'unit')
                                }
                                options={handleSelectList(item.id, param.id)}
                                name='unit'
                              />

                              <CursorPointer
                                start='true'
                                mt={item.parameters.indexOf(param) < 1 && '15px'}
                                onClick={() => handleRemoveParameters(item.id, param.id)}
                                invisible={!(item.parameters.length > 1)}
                              >
                                <RemoveCircle />
                              </CursorPointer>
                            </Flex>
                          )
                        })}

                        <CursorPointer
                          start='true'
                          onClick={() => handleAddTypes(item.id)}
                          ml='1rem'
                          mt='5px'
                        >
                          <AddCircle />
                        </CursorPointer>
                      </Flex>

                      {operationTypes.length > 1 && (
                        <BlackCircle
                          absolute
                          top='10px'
                          right='10px'
                          onClick={() => {
                            setOperationTypes((prev) => prev.filter((i) => i.id !== item.id))
                          }}
                        >
                          <RemoveCircle white />
                        </BlackCircle>
                      )}
                    </Flex>
                    <Duplicate onClick={() => handleDuplicate(item)}>{DuplicateIcon}</Duplicate>
                  </WhiteBackground>
                )
              })}
              <Flex width='100%'>
                <AddButton
                  width='42%'
                  height='40px'
                  orange
                  onClick={() => {
                    setOperationTypes((prev) => [
                      ...prev,
                      {
                        id: v4(),
                        name: '',
                        parameters: [
                          {
                            id: v4(),
                            invalid: false,
                            name: '',
                            unit: null,
                            parameterId: null,
                            unitId: null,
                          },
                        ],
                      },
                    ])
                  }}
                >
                  <AddCircle white />
                  Add new type
                </AddButton>
              </Flex>
            </div>
          </Flex>
        </form>
      </PageWrapper>
      <PageFooter>
        <Flex width='100%' end='true'>
          <CancelBtn />
          <Button ml='15' height='40' orange onClick={handleSubmit}>
            Save
          </Button>
        </Flex>
      </PageFooter>
    </>
  )
}
