import React from 'react'
import styled from 'styled-components'
import 'react-phone-number-input/style.css'

interface IInputWrapper {
  width?: boolean
  nomarg?: boolean
  w?: string
  value?: boolean
  invalid?: boolean
  disabled?: boolean
  m?: string
  minHeight?: boolean
  maxHeight?: boolean
  height?: boolean
  children: React.ReactNode
  blured: boolean
  end?: boolean
}

export const InputWrapper = styled.div<Pick<IInputWrapper, any>>`
  width: ${(props) => (props.width ? props.width : '100%')};
  margin-bottom: ${(props) => (props.nomarg ? '0' : '1.6rem')};
  ${props => props.end && 'display:flex;justify-content:flex-end;'}
  article {
    position: absolute;
  }
  /* label {
    font-weight: 700;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: ${(props) => props.theme.colorGray};
  } */
  .jHa-DtC input {
    background: ${(props) => (props.value ? `${props.theme.backgrounWhite}` : 'none')};
    box-shadow: ${(props) => (props.value ? `${props.theme.shadowInputActive}` : 'none')};
    border: ${(props) => (props.value ? `${props.theme.borderInputActive}` : '1px solid #c3c0cc')};
  }
  input::placeholder {
    color: #777777;
  }
  input,
  textarea {
    ${(props) => {
    return props.w && `width:${props.m}`
  }}
    background: ${(props) => (props.value ? `${props.theme.backgrounWhite}` : 'none')};
    box-shadow: ${(props) => (props.value ? `${props.theme.shadowInputActive}` : 'none')};
    border: ${(props) => (props.value ? '1px solid transparent' : '1px solid #c3c0cc')};
    /* background: '#FFFFFF';
border: '1px solid #EFEFEF';
box-shadow: '0px 0px 30px rgba(0, 0, 0, 0.1)'; */
    padding: 1rem;
    ${(props) => props.invalid && props.blured && 'border: 1px solid red;'}
    transition: 0.5s;
    outline: none;
    height: 2.5rem;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : '')};
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 0.87rem;
    resize: none;
    color: ${(props) => props.theme.colorGray};
    ${(props) => props.minHeight && 'min-height:' + props.minHeight}
    ${(props) => props.maxHeight && 'max-height:' + props.maxHeight}
    ${(props) => props.height && 'height:' + props.height}
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    width: 100%;
  }
  input[type='time'] {
    background: ${(props) => (props.time ? `${props.theme.backgrounWhite}` : 'none')};
    box-shadow: ${(props) => (props.time ? `${props.theme.shadowInputActive}` : 'none')};
    border: ${(props) => (props.time ? `${props.theme.borderInputActive}` : '1px solid #c3c0cc')};
    color: orange;
  }

  input[type='time']::-webkit-datetime-edit-ampm-field {
    background-color: ${(props) => props.theme.colorOrange};
    border-radius: 15%;
    color: #fff;
    padding: 19px 13px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  // AMPLIFY LOGIN PHONE NUNMBER STYLE CLASSES

  .amplify-input:focus {
    box-shadow: none !important;
  }

  .amplify-select:focus {
    box-shadow: none !important;
    border: none !important;
  }

  .amplify-select {
    display: flex;
    align-items: center;
    /* border-right: 1px solid ${({ theme }) => theme.colorGray} !important; */
  }

  .custom-phonenumberfield-class {
    --amplify-components-fieldcontrol-border-color: #ffffff;
    --amplify-components-fieldcontrol-border-radius: 8px;
  }

  .custom-phonenumberfield-class .amplify-input,
  .amplify-select {
    border: none;
  }
  .custom-phonenumberfield-class label {
    display: none;
  }
  .phoneNumber-field {
    border-radius: 8px;
  }
  .phoneNumber-field-empty {
    border-radius: 8px;
    transition: all 0.3s ease-in;
  }
  .phoneNumber-field-error {
    border-radius: 8px;
    border: 1px solid red;
  }
  .phoneNumber-field-filled {
    border-radius: 8px;
    border: 1px solid gray;
    background-color: ${({ theme }) => theme.colorWhite};
    border: none;
    transition: all 0.3s ease-in;
    border: ${(props) => props.theme.borderInputActive};
    box-shadow: ${(props) => props.theme.shadowInputActive};
  }

  //  END OF AMPLIFY LOGIN PHONE NUNMBER STYLE CLASSES

  /* .smallInput {
    position: relative;
    display: inline-block;
  }
  .smallInput:after {
    content: '$/h';
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translateY(-50%);
  } */
`

interface ISelectContainer {
  invalid?: string
}

export const SelectContainer = styled.div<ISelectContainer>`
  width: 100%;
  color: ${(props) => props.theme.colorGray};
  outline: none;
  height: 2.5rem;
  border: 1px solid ${(props) => (props.invalid ? 'crimson' : '#c3c0cc')};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`

interface ISelectWrapper {
  width?: string
  nomarg?: boolean
  m?: string
  labelText?: string
  min?: boolean
  invalid?: string
  view?: boolean
}

export const SelectWrapper = styled.div<ISelectWrapper>`
  width: 100%;
  width: ${(props) => props.width} !important;
  margin-bottom: ${(props) => (props.nomarg ? '0' : '1.6rem')};
  margin: ${(props) => props.m};
  ${(props) => props.min && 'min-width:200px;'}
  ${(props) => props.view && 'user-select:none; pointer-events:none;'}
  position: relative;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0 1em;
  }

  /* select:hover {
    background: ${(props) => props.theme.backgrounWhite};
    box-shadow: ${(props) => props.theme.shadowInputActive};
    border: ${(props) => props.theme.borderInputActive};
  } */
  select option {
    background: ${(props) => props.theme.backgrounWhite};
    box-shadow: ${(props) => props.theme.shadowInputActive};
    border: ${(props) => props.theme.borderInputActive};
  }

  select:focus {
    background: ${(props) => props.theme.backgrounWhite};
    box-shadow: ${(props) => props.theme.shadowInputActive};
    border: ${(props) => props.theme.borderInputActive};
  }
`
interface ILabel {
  labelText?: string
  children: React.ReactNode
}

export const Label = ({ labelText, nomarg }: Pick<ILabel, any>) => {
  return (
    <LabelWrapper htmlFor='' labelText={labelText} nomarg={nomarg}>
      {labelText}
    </LabelWrapper>
  )
}

interface ILabelWrapper {
  w?: string
  labelText?: string
  htmlFor?: string
  nomarg?: boolean
}

export const LabelWrapper = styled.label<ILabelWrapper>`
  line-height: 1.3rem;
  font-weight: 700;
  font-size: 0.75rem;
  color: ${(props) => props.theme.colorGray};
  width: ${(props) => props.w};

  margin-bottom: ${(props) => (props.nomarg ? '' : '0.5rem')};
`

interface ICollapsableAbsolute {
  expanded?: any
  right?: boolean
  height?: boolean
}

export const CollapsableAbsolute = styled.div<ICollapsableAbsolute>`
  visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.expanded ? '1' : '0')};
  max-height: ${(props) => (props.expanded ? `${props.height}` : '0')};
  transition: all 500ms;
  margin-bottom: ${(props) => (props.expanded ? '1.25rem' : '0')};
  /* position: absolute;
  ${(props) => (props.right ? 'right: 0;' : 'left: 0;')} */
  position: absolute;
  top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const DateInputWrapper = styled.div`
  position: relative;
  * {
    margin: 0;
    padding: 0;
    user-select: none;
  }

  .calendar {
    user-select: none;
    border: 1px solid #c3c0cc;
    max-width: 20rem;
    display: flex;
    height: fit-content;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5%;
    left: 20%;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colorWhite};
    box-shadow: '0px 0px 30px rgba(0, 0, 0, 0.5)';
    padding: 0.3rem;
  }

  .day {
    position: relative;
    width: calc(100% / 7);
    height: 44px;
    display: inline-block;
    background-color: transparent;
    padding: 0;
    font-size: 0.8rem;
    width: 44px;
    margin: 0;
    box-sizing: border-box;
    z-index: 1;
    text-align: center;
  }
  .days {
    width: 40rem;
    position: relative;
    display: flex;
    width: calc(100% / 7);
    height: 44px;
    gap: 1.6rem;
    align-items: center;
    justify-content: center;
  }
  .name-day {
    width: 44px;
    border-bottom: 1px solid gainsboro;
    padding: 0.7rem;
    font-size: 0.75rem;
  }
  .selected {
    color: ${(props) => props.theme.colorWhite};
    background-color: ${(props) => props.theme.colorOrange};
  }
  .before {
    /* ds */
  }
  .disabled {
    background-color: #c3c0cc80;
    cursor: not-allowed;
    color: white;
  }
  .today {
    /* border: 1px solid black; */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gainsboro;

    padding: 0.8rem;
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
  .header p {
    font-size: 1.2rem;
    color: white;
    text-align: center;
    cursor: pointer;
    width: 90%;
    color: ${(props) => props.theme.colorGray};
  }
  .arrow {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .numbers-container {
    width: 100%;
    border: none;
  }
  .single-day {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .single-day:hover {
    background-color: ${(props) => props.theme.colorOrange};
    color: ${(props) => props.theme.colorWhite};
    cursor: pointer;
  }
  .disabled:hover {
    cursor: not-allowed;
    background-color: #c3c0cc;
  }
`

interface IDateInputPlaceholder {
  filled?: any
  open?: boolean
  invalid?: string
  blured?: string
}

export const DateInputPlaceholder = styled.div<IDateInputPlaceholder>`
  width: 100%;
  background: ${(props) => (props.filled ? '#ffffff' : 'none')};
  border: ${(props) => (props.filled ? '' : '1px solid #c3c0cc')};
  box-shadow: ${(props) => (props.filled ? `${props.theme.shadowInputActive}` : '')};
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  font-size: 0.87rem;
  cursor: ${(props) => (props.open ? 'not-allowed' : 'default')};
  ${(props) => props.blured && props.invalid && 'border:1px solid red;'}
`

export const LoginPasswordField = styled.div`
display: flex;
justify-content: space-between;
gap:1rem;

input{
  aspect-ratio: 1/1;
  border-radius: 10px;
  border:1px solid #c3c0cc;
  text-align: center;
  font-size: 1.2rem;
  padding: 0;
}
`