import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { baseUrl } from 'utils/axios'
import {  IRoleTemplateSlice } from 'types/roles.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { INameId } from 'constants/globalTypes'
import { ISingleRoleTemplateGetResponse, ISingleRoleTemplatePostRequest } from 'types/api/roleTemplates.interface'

const initialState: IRoleTemplateSlice = {
  roleTemplates: [],
  isLoading: false,
  singleRoleTemplate: {
    name: '',
    permissions: null,
  },
  totalCount: 0,
  totalPages: 0,
  noRoleTemplates: false,
}

export const getRoleTemplates = createAsyncThunk(
  'adminRoles/getRoles',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp: AxiosResponse<IPaginatedResponse<INameId>> = await baseUrl.get('/roles/template/all', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleRoleTemplate = createAsyncThunk(
  'adminRoles/getSingleRole',
  async (id: string, thunkAPI) => {
    try {
      const resp:AxiosResponse<ISingleRoleTemplateGetResponse> = await baseUrl.get('roles/template/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addRoleTemplate = createAsyncThunk(
  'adminRoles/addRole',
  async (role: ISingleRoleTemplatePostRequest, thunkAPI) => {
    try {
      const resp = await baseUrl.post('/roles/template', role, thunkAPI)
      return {id:resp.data.id, name:role.name}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteRoleTemplate = createAsyncThunk(
  'adminRoles/deleteRole',
  async (id: string | number, thunkAPI) => {
    try {
      try {
        await baseUrl.delete('roles/template/' + id, thunkAPI)
        return id
      } catch (error) {
        return error
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const updateRoleTemplate = createAsyncThunk(
  'adminRoles/updateRole',
  async (role: ISingleRoleTemplateGetResponse, thunkAPI) => {
    try {
      await baseUrl.patch(`roles/template/${role.id}`, role, thunkAPI)

      // thunkAPI.dispatch(getRoles())
      return {id:role.id,name:role.name}
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const roleTemplatesSlice = createSlice({
  name: 'roleTemplates',
  initialState,
  reducers: {
    clearRoleTemplate: (state) => {
      state.singleRoleTemplate = {
        name: '',
        permissions: null,
      }
    },
    clearRoleTemplatesList: (state) => {
      state.roleTemplates = []
      state.totalCount = null
      state.totalPages = null
    },
  },
  extraReducers: {
    [getRoleTemplates.pending.type]: (state) => {
      state.isLoading = true
    },
    [getRoleTemplates.fulfilled.type]: (state:IRoleTemplateSlice, { payload }:{payload:IPaginatedResponse<INameId>}) => {
      state.roleTemplates = payload.data
      state.isLoading = false
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noRoleTemplates = state.roleTemplates.length < 1
    },
    [getRoleTemplates.rejected.type]: (state) => {
      state.isLoading = false
    },
    [deleteRoleTemplate.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.roleTemplates = state.roleTemplates.filter((role) => role.id !== payload)
      toast.success('Role template deleted')
    },
    [addRoleTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [addRoleTemplate.fulfilled.type]: (state:IRoleTemplateSlice, { payload }:{payload:INameId}) => {
      if (state.roleTemplates.length < 30) {
        state.roleTemplates = [...state.roleTemplates, payload]
      }
      toast.success('Role template added')
    },
    [addRoleTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleRoleTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleRoleTemplate.fulfilled.type]: (state:IRoleTemplateSlice, { payload }:{payload:ISingleRoleTemplateGetResponse}) => {
      state.isLoading = false
      state.singleRoleTemplate = payload
    },
    [getSingleRoleTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateRoleTemplate.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateRoleTemplate.fulfilled.type]: (state:IRoleTemplateSlice, { payload }:{payload:INameId}) => {
      state.isLoading = false
      const foundIndex = state.roleTemplates.findIndex((item) => item.id === payload.id)
      state.roleTemplates[foundIndex] = payload
      toast.success('Role template updated')
    },
    [updateRoleTemplate.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleRoleTemplate = payload
      handleErrors(payload)
    },
  },
})

export const { clearRoleTemplate, clearRoleTemplatesList } = roleTemplatesSlice.actions

export default roleTemplatesSlice.reducer
