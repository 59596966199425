import React, { useEffect, useState } from 'react'
import {
    PageWrapper,
    Flex,
    H1WithBack,
    TabsContainer,
    Tabs,
    ContentWrapper,
} from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store'
import { useParams } from 'react-router-dom'
import { CycleCompaniesTab } from './CycleCompaniesTab'
import { ICycleState } from 'types/cycles.interface'
import { CycleSpecificationTab } from './CycleSpecificationTab'
import { CycleComponentsTab } from './CycleComponentsTab'
import { CycleStepsTab } from './CycleStepsTab'
import { clearSingleCycle, getSingleCycle } from 'features/cycles/cyclesSlice'

export const ViewCycle = () => {
    const [active, setActive] = useState('Specification')
    const dispatch = useDispatch<AppDispatch>()
    const [state, setState] = useState<ICycleState>(null)
    const { id } = useParams()
    const { singleCycle } = useSelector((state: RootState) => state.cycles)

    useEffect(() => {
        dispatch(getSingleCycle(id))

        return (() => {
            dispatch(clearSingleCycle())
        })
    }, [id])

    useEffect(() => {
        singleCycle && setState(singleCycle)
    }, [singleCycle])

    const renderTabs = () => {
        switch (active) {
            case 'Specification':
                return (
                    <CycleSpecificationTab state={state} setState={setState} view />
                )
            case 'Components':
                return <CycleComponentsTab state={state} setState={setState} view />
            case 'Steps':
                return <CycleStepsTab state={state} setState={setState} view />
            case 'Companies':
                return <CycleCompaniesTab state={state} setState={setState} view />
            default:
                return (
                    <CycleSpecificationTab state={state} setState={setState} view />
                )
        }
    }

    return (
        <div>
            <NavBar />
            <PageWrapper noFooter>
                <Flex between main>
                    <H1WithBack mb='1.5' arrow title='View Task Cycle' />
                    <TabsContainer>
                        <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
                            Specification
                        </Tabs>
                        <Tabs active={active === 'Components'} onClick={() => setActive('Components')}>
                            Components
                        </Tabs>
                        <Tabs active={active === 'Steps'} onClick={() => setActive('Steps')}>
                            Steps
                        </Tabs>
                        <Tabs active={active === 'Companies'} onClick={() => setActive('Companies')}>
                            Companies
                        </Tabs>
                    </TabsContainer>
                </Flex>
                <ContentWrapper>{renderTabs()}</ContentWrapper>
            </PageWrapper>
        </div>
    )
}
