import styled from 'styled-components'

export const FilterIconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  cursor: pointer;
  position: relative;
  box-shadow: ${(props) => props.theme.shadowMedium};
  background-color: white;

  svg {
    fill: #ff9900;
  }
`

export const FiltersContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: fit-content;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.shadowMedium};
  border-radius: 5px;
  z-index: 999999;
  background-color: white;
  color: ${(props) => props.theme.colorGray};
`

export const FiltersContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: auto;
  padding: 10px;

  p {
    cursor: pointer;
    transition: 0.1s ease-in;
    &:hover {
      color: #ff9900;
    }
  }
`

export const SingleFilterTitle = styled.div<ISingleFilterContainer>`
  width: 100%;
  padding: 5px;
  display: flex;
  user-select: none;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s ease;
  &:hover {
    background-color: #f9f9f9;
  }
  svg {
    fill: none;
    stroke: ${(props) => props.theme.colorGrayHover} !important;
    transform: rotate(${(props) => (props.open ? '0' : '-90')}deg);
    transition: 0.2s ease-in;
    width: 15px;
    margin-right: 5px;
  }
`

interface ISingleFilterContent {
  open: boolean
}

export const SingleFilterContent = styled.div<ISingleFilterContent>`
  transition: 0.1s ease-in;
  overflow: hidden;
  width: 100%;
  height: max-content;
  max-height: ${(props) => (props.open ? 'max-content' : '0px')};
  background-color: #f9f9f9;
  cursor: auto;
`

interface ISingleFilterContainer {
  open: boolean
}
export const SingleFilterContainer = styled.div<ISingleFilterContainer>`
  display: flex;
  justify-content: start;
  align-items: center;
  transition: 0.2s ease-in;
  flex-direction: column;
`
export const SearchBox = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colorGrayHover};
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;

  input {
    width: 90%;
    height: 100%;
    padding: 5px;
    border: none;
    outline: none;
    color: ${(props) => props.theme.colorGrayHover};
    background-color: transparent;
    ::placeholder {
      color: #e4e4e4cc;
    }
  }

  svg {
    fill: none;
    transform: scale(1.2);
  }
`

export const ClearFilterButton = styled.p`
  font-size: 0.65rem;
  background-color: ${(props) => props.theme.colorLightGray};
  padding: 2px;
  border-radius: 5px;
  transition: 0.1s ease;
  &:hover {
    background: none;
    outline: 1px solid ${(props) => props.theme.colorGray};
  }
`

export const SelectedOption = styled.div`
  padding: 2px;
  font-size: 12px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 1rem;
  position: relative;
  svg {
    scale: 0.8;
  }
  border-radius: 5px;

  ::after {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Remove';
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: ${(props) => props.theme.colorLightGray};
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }

  &:hover::after {
    opacity: 0.9;
  }
`

export const SelectedOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px;
`
