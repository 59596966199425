import { DatePickerInput } from 'components/DatePicker/DatePicker'
import { NavBar } from 'components/NavBar/NavBar'
import { Pagination } from 'components/Pagination/Pagination'
import { Td } from 'components/Td/Td'
import { endOfWeek } from 'date-fns'
import { useState } from 'react'
import { Animation, Flex, H1, H1WithBack, H2, PageControls, PageFooter, PageWrapper, Separator, SubsContainer, Table, Th, Tr, TrChevronContainer, TrContentContainer } from 'styling/GlobalStyles'
import { formatDateForDisplay, getParams, getTime, getTimeFromDate, setState } from 'utils/helpers'
import { EDCDetails, EDCDetailsIcon, EmployeDetailsCard, EmployeeDetailsProfile, EmployeeDetailsUpper } from '../Dashboard.styled'
import Avatar from '../../../assets/images/profileAvatar.png'
import { Status } from 'components/Status/Status'
import { StarColorless } from 'assets/icons/StarColorless'
import { TrophyIcon } from 'assets/icons/TrophyIcon'
import { TimerIcon } from 'assets/icons/TimerIcon'
import { FileIcon } from 'assets/icons/FileIcon'
import { WalletIcon } from 'assets/icons/WalletIcon'
// import { Filter } from 'components/Filter/Filter'
// import { FilterEnum } from 'components/Filter/SingleFilter'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { useNavigate, useParams } from 'react-router-dom'
import { clearSingleDBEmployee, getSingleDBEmployee } from 'features/dashboard/dbEmployeesSlice'
import { taskStatusesData } from '../dashboardData'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import { ISDBETask } from 'features/dashboard/types'
import Chevron from 'assets/icons/Chevron'
import useQueryPagination from 'hooks/useQueryPagination'

export const DBSingleEmployee = () => {
    const { id } = useParams()
    const searchParams = new URLSearchParams(window.location.search);
    const [params, setParams] = useState<any>(getParams({
        location, limit: 5, ap: {
            dateFrom: new Date(searchParams.get('dateFrom') ?? new Date()),
            dateTo: endOfWeek(new Date(searchParams.get('dateTo') ?? new Date()), { weekStartsOn: 1 }),
            userId: id
        }
    }))
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const { singleEmployee } = useSelector((state: RootState) => state.dbEmployees)


    useQueryPagination({ fetchFunctions: [getSingleDBEmployee], cleanUpFunctions: [clearSingleDBEmployee], params })

    // const filters = [
    //     {
    //         type: FilterEnum.FIXED_LIST,
    //         name: 'Difference',
    //         state: null,
    //         propName: 's',
    //         sd: true,
    //         single: true,
    //         filterOptions: [
    //             {
    //                 name: 'Cost - From Low To High',
    //                 id: 'costDifference-a'
    //             },
    //             {
    //                 name: 'Cost - From High To Low',
    //                 id: 'costDifference-d'
    //             },
    //             {
    //                 name: 'Duration - From Low To High',
    //                 id: 'durationDifference-a'
    //             },
    //             {
    //                 name: 'Duration - From High To Low',
    //                 id: 'durationDifference-d'
    //             },
    //         ]
    //     }
    // ]

    const getPage = (arr: any[]) => {
        const startIndex = (page - 1) * 5;
        const endIndex = startIndex + 5;

        return arr?.slice(startIndex, endIndex);
    }

    const timePlus = singleEmployee?.data.totalActualDurationInMinutes <= singleEmployee?.data.totalEstimatedDurationInMinutes
    const costPlus = singleEmployee?.data.totalActualCost <= singleEmployee?.data.totalEstimatedCost
    const lastStartedShift = singleEmployee?.data.lastLoginShiftInfo?.createdAt ? formatDateForDisplay(singleEmployee?.data.lastLoginShiftInfo.createdAt) + ' ' + getTimeFromDate(singleEmployee?.data.lastLoginShiftInfo.createdAt) : '/'
    const lastFinishedShift = singleEmployee?.data.lastLogoutShiftInfo?.createdAt ? formatDateForDisplay(singleEmployee?.data.lastLogoutShiftInfo.createdAt) + ' ' + getTimeFromDate(singleEmployee?.data.lastLogoutShiftInfo.createdAt) : '/'
    const employeeName = singleEmployee?.data.firstName ? singleEmployee?.data.firstName + ' ' + singleEmployee?.data.lastName : ''

    const TaskRow = ({ task, nested, sub }: { task: ISDBETask, nested?: boolean, sub?: boolean }) => {

        const cycle = task.type == 2
        const status = cycle ? task.taskCycle.status : task.userTask.status
        const [open, setOpen] = useState(false)
        const actual = cycle ? getTime(task.taskCycle?.totalDuration, 1) + ' / $' + (task.taskCycle?.actualCost?.toFixed(2) || 0) : getTime(task.userTask?.totalDuration, 1) + ' / $' + (task.userTask?.actualCost?.toFixed(2) || 0)

        return < Tr sub={sub} hasSubs={cycle} open={open} overflowHidden={!open} pointer onClick={cycle ? () => setOpen(p => !p) : () => navigate('/dashboard/tasks/' + (cycle ? task.taskCycle.id : task.userTask.taskId))} key={cycle ? task.taskCycle.id : task.userTask.taskId} >
            <TrContentContainer>
                <Td w='15%' text={nested ? task.userTask?.cycleInfo?.templateStep.name : cycle ? task?.taskCycle?.template?.name : task?.userTask?.taskTemplate?.name} />
                <Td w='15%' text={(cycle || nested) ? '' : getTime(task.userTask.estimatedDuration, 1) + ' / $' + (task.userTask.estimatedCost.toFixed(2))} />
                <Td w='15%' text={actual} />
                <Td w='15%'
                    text={(cycle || nested) ? '' : getTime(Number(Math.abs(task.userTask.estimatedDuration - task.userTask.totalDuration).toFixed(2))) + ' / $' + Math.abs(task.userTask.actualCost - task.userTask.estimatedCost).toFixed(2)}
                >
                    {(!cycle) ? '' : (nested && !cycle) && <p style={{ color: task.userTask?.estimatedDuration >= task.userTask?.totalDuration ? '#00B04C' : '#F00' }} >
                        {getTime(Number(Math.abs(task.userTask?.estimatedDuration - task.userTask?.totalDuration).toFixed(2))) + ' / $' + Math.abs(task.userTask?.actualCost - task.userTask?.estimatedCost).toFixed(2)}
                    </p>}
                </Td>
                <Td w='10%' text={(cycle) ? task.taskCycle.grade : task.userTask.grade} />
                <Td w='15%' noTooltip text={(cycle ? task.taskCycle.rewardPoints : task.userTask.rewardPoints) || 'No Reward Points'} />
                <Td w='10%'  >
                    <p style={(status == 4 || status == 3 || status == 7) ? { color: status == 3 ? '#00B04C' : '#F00' } : {}}>
                        {taskStatusesData.find(s => s.id == status).name}</p>
                </Td>
                <Td w='5%' >
                    <TrChevronContainer open={open}>
                        {!sub && !nested && cycle && < Chevron />}
                    </TrChevronContainer>
                </Td>
            </TrContentContainer>
            {cycle && <SubsContainer>
                {task.taskCycle.userTasks.map((t, i) => <TaskRow sub={true} key={i} task={{ type: 1, taskCycle: null, userTask: t }} nested />)}
            </SubsContainer>}
        </Tr >
    }


    return <>
        <NavBar />
        <Animation>
            <PageWrapper noFooter={singleEmployee?.totalPages < 1}>
                <PageControls style={{ gap: '1rem' }} >
                    <H1WithBack title={employeeName} arrow link='/dashboard/employees' />
                    <Flex gap='1rem'>
                        <DatePickerInput
                            maxDate={new Date(params.dateTo)}
                            label='Start date'
                            date={new Date(params.dateFrom)}
                            setDate={(v) => setState(setParams, 'dateFrom', v)}
                        />
                        <DatePickerInput
                            minDate={new Date(params.dateFrom)}
                            label='End date'
                            date={new Date(params.dateTo)}
                            setDate={(v) => setState(setParams, 'dateTo', v)}
                        />
                    </Flex>
                </PageControls>
                <Flex column >
                    <EmployeeDetailsUpper columns={3} >
                        <EmployeeDetailsProfile>
                            <img src={singleEmployee?.data.presignedImageUrl || Avatar}
                                alt='profile-image'
                                onError={({ currentTarget }) => (currentTarget.src = Avatar)} />
                            <Flex column gap='10px' >
                                <Flex column>
                                    <H2 noUpperCase>{singleEmployee?.data.roleName}</H2>
                                    <Status status={singleEmployee?.data.isActive ? 'Active' : 'Inactive'} />
                                </Flex>
                                <Flex column >
                                    <p>Last Shift Started: {lastStartedShift} </p>
                                    <p>Last Shift Finished: {lastFinishedShift}</p>
                                </Flex>
                            </Flex>
                        </EmployeeDetailsProfile>
                        <EmployeDetailsCard>
                            <EDCDetailsIcon>
                                <StarColorless />
                            </EDCDetailsIcon>
                            <EDCDetails>
                                <Flex width='100%' between >
                                    <H2 noUpperCase ml='1rem' >TOTAL<br /> RATING:</H2>
                                    <H1 style={{ color: '#ff9900', fontSize: '32px' }}  >{singleEmployee?.data.rating || 0}</H1>
                                </Flex>
                            </EDCDetails>
                        </EmployeDetailsCard>
                        <EmployeDetailsCard >
                            <EDCDetailsIcon>
                                <TrophyIcon />
                            </EDCDetailsIcon>
                            <EDCDetails>
                                <Flex width='100%' between >
                                    <H2 noUpperCase ml='1rem' >TOTAL REWARD<br /> POINTS:</H2>
                                    <H1 style={{ color: '#ff9900', fontSize: '32px' }}  >{singleEmployee?.data.totalRewardPoints}</H1>
                                </Flex>
                            </EDCDetails>
                        </EmployeDetailsCard>
                        <EmployeDetailsCard>
                            <EDCDetailsIcon>
                                <TimerIcon />
                            </EDCDetailsIcon>
                            <EDCDetails column >
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p><b>Total Estimated Time:</b></p>
                                    <p style={{ color: '#ff9900' }} ><b>{getTime(singleEmployee?.data.totalEstimatedDurationInMinutes)}</b></p>
                                </Flex>
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p><b>Total Actual Time:</b></p>
                                    <p style={{ color: '#ff9900' }}><b>{getTime(singleEmployee?.data.totalActualDurationInMinutes)}</b></p>
                                </Flex>
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p><b>Total Difference Time: </b></p>
                                    <p style={{ color: timePlus ? '#00B04C' : '#f00' }}><b>{getTime(singleEmployee?.data.totalDifferenceInMinutes)}</b></p>
                                </Flex>
                            </EDCDetails>
                        </EmployeDetailsCard>
                        <EmployeDetailsCard>
                            <EDCDetailsIcon>
                                {FileIcon}
                            </EDCDetailsIcon>
                            <EDCDetails column >
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p><b>Total Completed Tasks:</b></p>
                                    <p style={{ color: '#00B04C' }}><b>{singleEmployee?.data.totalCompletedTasks}</b></p>
                                </Flex>
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p><b>Total In Progress: </b></p>
                                    <p style={{ color: '#ff9900' }}><b>{singleEmployee?.data.totalInProgressTasks}</b></p>
                                </Flex>
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p><b>Total Completed Cycles:</b></p>
                                    <p style={{ color: '#00B04C' }}><b>{singleEmployee?.data.totalCyclesCompleted}</b></p>
                                </Flex>
                                {/* <Flex paddingLeft='1rem' width='100%' between >
                                    <p><b>Total Reported: </b></p>
                                    <p style={{ color: '#f00' }}><b>{singleEmployee?.data.totalReportedTasks}</b></p>
                                </Flex> */}
                            </EDCDetails>
                        </EmployeDetailsCard>
                        <EmployeDetailsCard>
                            <EDCDetailsIcon>
                                <WalletIcon />
                            </EDCDetailsIcon>
                            <EDCDetails column >
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p ><b>Total Estimated Cost:</b></p>
                                    <p style={{ color: '#ff9900' }}><b>$  {singleEmployee?.data.totalEstimatedCost.toFixed(2)}</b></p>
                                </Flex>
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p ><b>Total Actual Cost: </b></p>
                                    <p style={{ color: '#ff9900' }}><b>$ {singleEmployee?.data.totalActualCost.toFixed(2)}</b></p>
                                </Flex>
                                <Flex paddingLeft='1rem' width='100%' between >
                                    <p ><b>Total Difference Cost:</b></p>
                                    <p style={{ color: costPlus ? '#00B04C' : '#f00' }}><b>$ {singleEmployee?.data.totalCostDifference.toFixed(2)}</b></p>
                                </Flex>
                            </EDCDetails>
                        </EmployeDetailsCard>
                    </EmployeeDetailsUpper>

                    {singleEmployee?.data.tasks.length < 1 ? <NoItems title={'Tasks'} marginTop='20px' /> : <div>
                        <Flex mt='20' width='100%' between center >
                            <H2>Tasks</H2>
                            {/* <Filter options={filters} setPageParams={setParams} /> */}
                        </Flex>
                        <Separator /><Table>
                            <Th next >
                                <Td w='15%' noTooltip text='Task Name' />
                                <Td w='15%' noTooltip text='Estimated Task' />
                                <Td w='15%' noTooltip text='Actual Task' />
                                <Td w='15%' noTooltip text='Difference' />
                                <Td w='10%' noTooltip text='Performance' />
                                <Td w='15%' noTooltip text='R. Points' />
                                <Td w='5%' noTooltip text='Status' />
                                <Td w='5%' noTooltip text='' />
                            </Th>
                            {getPage(singleEmployee?.data.tasks)?.map((item, i) => <TaskRow key={i} task={item} />)}
                        </Table>
                    </div>}
                </Flex>
            </PageWrapper>
            {Math.ceil(singleEmployee?.data.tasks.length / 5) >= 2 && <PageFooter>
                <Pagination
                    setPage={(page) => setPage(page)}
                    pageCount={singleEmployee.data.tasks.length / 5}
                    forcePage={(page ? page : 1) - 1}
                />
            </PageFooter>}

        </Animation>
    </>
}