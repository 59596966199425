export interface ITableItem {
  id?: number
  name: string
}

export interface IRoute {
  path: string
  Component: () => JSX.Element
}

export enum ModalTypes {
  'Delete' = 1,
  'BeforeSabe' = 2
}
