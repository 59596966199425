import styled from 'styled-components'

interface SidebarWrapperProps {
  sidebar: boolean
}

export const SidebarWrapper = styled.aside<SidebarWrapperProps>`
  .icon {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 1rem;
    right: 0;
    transform: ${(props) => (props.sidebar ? 'translate(0)' : 'translate(-4rem)')};
    transition: 0.5s;
    opacity: 0.8;
    :hover {
      transform: scaleX(1.1);
    }
  }

  user-select: none;
  background: #ffffff;
  box-shadow: 0px 0px 1.8rem rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
  width: 15rem;
  padding: 0 1rem;
  position: fixed;
  top: 1rem;
  left: ${(props) => (!props.sidebar ? '-15rem' : '1rem')};
  transition: all 0.5s ease-in-out;
  bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
    height: none;
  }
  -ms-overflow-style: none;
  ::-webkit-scrollbar-track {
    display: none;
  }
  scrollbar-width: none;
`
export const SidebarBtnContainer = styled.div`
  position: relative;

  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.3rem;
  max-height: calc(100vh - 18rem);
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
    height: none;
  }
  z-index: -1;
`
interface SideBarBtnProps {
  readonly active: boolean
}

export const SidebarBtn = styled.div<SideBarBtnProps>`
  display: flex;
  font-weight: ${({ active }) => (active ? '500' : '400')};
  gap: 1.25rem;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => (props.active ? props.theme.colorOrange : 'none')};
  color: ${(props) => (props.active ? '#ffffff' : props.theme.colorGray)};
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 0.6rem;
  width: 100%;
  height: 3.4rem;
  padding: 0 1.2rem;
  /* transition: all 1 ease; */
  span {
    transition: all 0.6s ease-in-out;
  }
  :hover {
    span {
      transform: rotate(-20deg);
      transition: all 0.6s ease-in-out;
    }
  }

  span {
    margin-bottom: -0.3rem;
  }

  svg {
    * {
      fill: ${(props) => (props.active ? '#ffffff' : props.theme.colorGray)}!important;
    }
  }

  .chev {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease;
    transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
    svg {
      * {
        fill: none !important;
        stroke: ${(props) => (props.active ? 'white' : props.theme.colorGray)}!important;
      }
    }
  }
`

export const SideBarList = styled.ul`
  list-style: none;
  margin-left: 3.1rem;
  margin-top: 1.5rem;
`
interface SideBarOptionProps {
  readonly active: boolean
}
export const SideBarOption = styled.li<SideBarOptionProps>`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(props) => (props.active ? props.theme.colorOrange : props.theme.colorGray)};
  cursor: pointer;
  margin-bottom: 1.5rem;
`

export const SidebarFooter = styled.div`
  background-color: #f5f5f5;
  font-weight: 400;
  position: fixed;
  bottom: 6%;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colorOrange};
  padding: 1rem 1rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
`
