import { baseUrl } from './../../utils/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { ISupplier, ISuppliersSlice } from 'types/suppliers.interface'
import { handleErrors, formatParams } from 'utils/helpers'
import { AxiosResponse } from 'axios'
import { IPaginatedResponse } from 'types/api/general.interface'
import { IGetAllSuppliersSingleResponse } from 'types/api/suppliersApi.interface'

const initialState:ISuppliersSlice = {
  isLoading: false,
  suppliers: [],
  singleSupplier: null,
  noSuppliers: false,
  totalCount: 0,
  totalPages: 0,
}

export const getSuppliers = createAsyncThunk(
  'suppliers/getSuppliers',
  async (params: any, thunkAPI) => {
    try {
      params = formatParams(params)
      const resp:AxiosResponse<IPaginatedResponse<IGetAllSuppliersSingleResponse>> = await baseUrl.get('inventory-suppliers', { params })
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const addSupplier = createAsyncThunk(
  'suppliers/addSupplier',
  async (supplier: ISupplier, thunkAPI) => {
    try {
      const resp = await baseUrl.post('/inventory-suppliers', supplier, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
export const deleteSupplier = createAsyncThunk(
  'suppliers/deleteSupplier',
  async (id: number, thunkAPI) => {
    try {
      await baseUrl.delete('/inventory-suppliers/' + id, thunkAPI)
      return id
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getSingleSupplier = createAsyncThunk(
  'suppliers/getSingleSupplier',
  async (id: number, thunkAPI) => {
    try {
      const resp = await baseUrl.get('/inventory-suppliers/' + id)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const updateSupplier = createAsyncThunk(
  'suppliers/updateSupplier',
  async (supplier: ISupplier, thunkAPI) => {
    try {
      const resp = await baseUrl.patch('/inventory-suppliers/' + supplier.id, supplier, thunkAPI)
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    clearInventorySupplier: (state) => {
      state.singleSupplier = null
    },
    clearInventorySuppliersList: (state) => {
      state.suppliers = []
      state.totalCount = 0
      state.totalPages = 0
    },
  },
  extraReducers: {
    [getSuppliers.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSuppliers.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.suppliers = payload.data
      state.totalCount = payload.totalCount
      state.totalPages = payload.totalPages
      state.noSuppliers = state.suppliers.length < 1
    },
    [getSuppliers.rejected.type]: (state) => {
      state.isLoading = false
    },
    [addSupplier.pending.type]: (state) => {
      state.isLoading = true
    },
    [addSupplier.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      if (state.suppliers.length < 5) {
        state.suppliers = [...state.suppliers, payload]
      }
      state.noSuppliers = false
      toast.success('Supplier added')
    },
    [addSupplier.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [getSingleSupplier.pending.type]: (state) => {
      state.isLoading = true
    },
    [getSingleSupplier.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.singleSupplier = payload
    },
    [getSingleSupplier.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [updateSupplier.pending.type]: (state) => {
      state.isLoading = true
    },
    [updateSupplier.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      const foundIndex = state.suppliers.findIndex((item) => item.id === payload.id)
      state.suppliers[foundIndex] = payload
      toast.success('Supplier updated')
    },
    [updateSupplier.rejected.type]: (state, { payload }) => {
      state.isLoading = false
      handleErrors(payload)
    },
    [deleteSupplier.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false
      state.suppliers = state.suppliers.filter((item) => item.id !== payload)
      state.noSuppliers = state.suppliers.length < 1
      toast.success('Supplier deleted')
    },
  },
})

export default suppliersSlice.reducer
export const { clearInventorySupplier, clearInventorySuppliersList } = suppliersSlice.actions
