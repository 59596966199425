import React, { useState, useEffect } from 'react'
import { AppDispatch, RootState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import {
  ContentWrapper,
  Flex,
  H1WithBack,
  PageWrapper,
  Tabs,
  TabsContainer,
} from 'styling/GlobalStyles'
import { NavBar } from 'components/NavBar/NavBar'
import { useNavigate, useParams } from 'react-router-dom'
import { TaskTriggersBasicInfo } from './TaskTriggersBasicInfo'
import {
  clearTaskTrigger,
  getSingleTaskTrigger,
  updateTaskTrigger,
} from 'features/triggers/tasksTriggersSlice'
import { toast } from 'react-toastify'
import { ISingleTaskTrigger } from 'types/task-triggers.interface'
import { TaskTriggersCompaniesTab } from './TaskTriggersCompaniesTab'

export const EditTaskTriggers = () => {
  const [active, setActive] = useState('Basic Info')
  const { singleTaskTrigger } = useSelector((store: RootState) => store.taskTriggers)
  const navigate = useNavigate()
  const [invalidFields, setInvalidFields] = useState([])
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams()
  const [state, setState] = useState<ISingleTaskTrigger>({
    name: '',
    type: null,
    minutes: null,
    companies: [],
    taskTemplate: { id: null, name: '' },
    nextTaskTemplate: { id: null, name: '' },
    nextTaskType: { id: 1, name: 'Task' },
    nextWorkflowTemplate: { id: null, name: '' },
    id,
  })

  const handleSubmit = async () => {
    if (invalidFields.length > 0) {
      toast.warn('All basic info fields must be valid.')
      return
    }

    const resp = await dispatch(updateTaskTrigger(state))

    if (resp.meta.requestStatus === 'fulfilled') {
      navigate('/triggers-task')
    }
  }

  useEffect(() => {
    dispatch(getSingleTaskTrigger(id))
    return () => {
      dispatch(clearTaskTrigger())
    }
  }, [])

  useEffect(() => {
    if (singleTaskTrigger) {
      setState(singleTaskTrigger)
    }
  }, [singleTaskTrigger])

  const renderTabs = () => {
    if (active === 'Basic Info') {
      return (
        <TaskTriggersBasicInfo
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
        />
      )
    } else if (active === 'Companies') {
      return <TaskTriggersCompaniesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
  }

  return (
    <>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack mb='1.5' arrow title={'Edit ' + (singleTaskTrigger?.name ? singleTaskTrigger?.name : '')} />
          <TabsContainer>
            <Tabs active={active === 'Basic Info'} onClick={() => setActive('Basic Info')}>
              Basic Info
            </Tabs>
            <Tabs active={active === 'Companies'} onClick={() => setActive('Companies')}>
              Companies
            </Tabs>
          </TabsContainer>
        </Flex>
        <Flex between></Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </>
  )
}
