import React from 'react'

interface IClose {
  white?: boolean
  black?: boolean
  onClick?: any
}

export const CloseIcon = ({ white, black, onClick }: IClose) => {
  let color = '#c3c0cc'
  if (white) {
    color = '#fff'
  }
  if (black) {
    color = '#464255'
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' onClick={onClick} >
      <path
        d='M18 6L6 18'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 6L18 18'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
