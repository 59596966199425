import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Check from '../../../assets/icons/Check'
import { NavBar } from '../../../components/NavBar/NavBar'
import { Pagination } from '../../../components/Pagination/Pagination'
import { clearFieldsList, getFields } from '../../../features/fields/fieldsSlice'
import {
  Button,
  Flex,
  GridItem,
  H1WithBack,
  ItemsGrid,
  MainHeadline,
  PageFooter,
  PageWrapper,
  Separator,
} from '../../../styling/GlobalStyles'
import { useNavigate, useParams } from 'react-router-dom'
import { getSingleCategory, updateCategory } from '../../../features/categories/categoriesSlice'
import { AppDispatch, RootState } from 'store'
import { CancelBtn } from 'components/Button/CancelBtn'
import { getParams, handlePagination } from 'utils/helpers'
import { NoItems } from 'components/NoItems-fallback/NoItems'
import useQueryPagination from 'hooks/useQueryPagination'

export const AddFieldsCategory = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { fields, totalCount, totalPages, noFields } = useSelector((state: RootState) => state.fields)
  const { category } = useSelector((state: RootState) => state.categories)
  const [array, setArray] = useState([])
  const navigate = useNavigate()
  const { id } = useParams()
  const [params, setParams] = useState<any>(getParams({ location }))


  const handleSubmit = () => {
    dispatch(updateCategory({ id, fields: array, name: category.name }))
    navigate('/resources-categories')
  }

  const handleAdd = (id: number) => {
    if (array.some((i) => i == id)) {
      setArray((prev) => prev.filter((i) => i !== id))
    } else {
      setArray((prev) => [...prev, id])
    }
  }

  useEffect(() => {
    category && Array.isArray(category.fields)
      ? setArray(() => {
        const newAr = []
        for (const a of category.fields) {
          newAr.push(a.id)
        }
        return newAr
      })
      : null
  }, [category])

  useEffect(() => {
    dispatch(getSingleCategory(Number(id)))
  }, [])

  useQueryPagination({ fetchFunctions: [getFields], cleanUpFunctions: [clearFieldsList], params })

  return (
    <>
      <NavBar input placeholder='Search fields..' setParams={setParams} />
      <PageWrapper>
        <MainHeadline>Add fields</MainHeadline>
        <Separator />
        {noFields ? <NoItems title='fields' /> :
          <>
            <H1WithBack title='All Fields' />
            <ItemsGrid columns={3} mt='1.5rem'>
              {fields.map((item) => {
                return (
                  <GridItem
                    key={item.id}
                    valid={array.some((i) => i == item.id)}
                    onClick={() => handleAdd(item.id)}
                  >
                    {item.name}
                    <Check valid={array.some((i) => i == item.id)} />
                  </GridItem>
                )
              })}
            </ItemsGrid>
          </>
        }
      </PageWrapper>
      <PageFooter>
        {totalCount > 30 ? (
          <Flex width='100%' between center>
            <Pagination
              setPage={(page) => handlePagination(page, setParams)}
              pageCount={Math.ceil(totalPages)}
              forcePage={(params.page ? params.page : 1) - 1}
            />
            <Flex gap='1rem'>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button orange onClick={handleSubmit}>
                Save
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex gap='1rem'>
            <CancelBtn />
            <Button orange onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        )}
      </PageFooter>
    </>
  )
}
