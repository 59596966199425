import { HonestSelectInput } from 'components/HonestSelect/HonestSelectInput'
import { Input } from 'components/Input/Input'
import { ToggleButton } from 'components/Input/ToggleButton'
import { ISelectValue } from 'constants/globalTypes'
import { Button, Flex, H2, Separator } from 'styling/GlobalStyles'
import { ICycleState } from 'types/cycles.interface'

export const SingleStep = ({ index, state, setState, view }: { index: number, view?: boolean, setState: React.Dispatch<React.SetStateAction<ICycleState>>, state: ICycleState }) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.steps[index].name = e.target.value
            return copy
        })
    }

    const getDefaultValue = () => {
        return state.steps[index].componentIndexForLocation?.id
    }

    const handleAddStep = () => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.steps.push({
                name: '',
                isStepForFinish: false,
                componentIndexForLocation: null
            })
            return copy
        })
    }


    const handleSelect = (value: ISelectValue) => {

        setState(prev => {
            const copy = structuredClone(prev)
            copy.steps[index].componentIndexForLocation = { id: value.value, name: value.label }
            return copy
        })

    }

    const handleToggle = () => {
        setState(prev => {
            const copy = structuredClone(prev)
            copy.steps = copy.steps.map(s => ({ ...s, isStepForFinish: false }))
            copy.steps[index].isStepForFinish = !copy.steps[index].isStepForFinish
            return copy
        })
    }

    return <Flex style={{ pointerEvents: view ? 'none' : 'all' }} gap='1rem' width='15rem' column>
        <H2>Step: {index + 1}</H2>
        <Input
            type='text'
            value={state?.steps[index]?.name}
            onChange={handleChange}
            placeholder='Enter step name'
            labelText='Step name'
            invalid={state?.steps[index]?.name?.length < 2 ? 'true' : ''}
            nomarg
        />

        <HonestSelectInput
            name='indexForLocation'
            onChange={(value: ISelectValue) => handleSelect(value)}
            labelText='Component for location'
            defaultValue={getDefaultValue()}
            options={state.components.map(c => ({ label: c.name, value: state.components.findIndex(cmp => cmp.name == c.name) }))}
            nomarg
            errorWhenEmpty='Components must be selected first.'
        // invalid={invalidCategoryFields.includes(field.name)}
        />
        <ToggleButton
            state={state.steps[index].isStepForFinish || false}
            handleChange={handleToggle}
            maxWidth
            label='Is Step For Finish'
        />
        <Separator />
        {state.steps.length - 1 == index && !view && <Button orange onClick={handleAddStep}>Add Step</Button>}

    </Flex>
}