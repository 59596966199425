import React from 'react'
import { TabFooter, Button, Flex, Animation } from 'styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import useFocusFirstInput from 'utils/useFocusFirstInput'
import { ICycleTab } from 'types/cycles.interface'
import { SingleComponent } from './SingleComponent'

export const CycleComponentsTab = ({ state, setState, handleSubmit, view }: ICycleTab) => {
    useFocusFirstInput()

    const renderComponents = () => {
        return state?.components.map((c, i: number) => <SingleComponent key={i} setState={setState} state={state} index={i} view={view} />)
    }

    return (
        <Animation >
            <Flex style={{ minHeight: 'calc(100vh - 17rem)' }} gap='1rem' wrap  >
                {renderComponents()}
            </Flex>

            {handleSubmit && (
                <TabFooter contract >
                    <Flex width='100%' end='true'>
                        <CancelBtn />
                        <Button ml='15' height='40' onClick={handleSubmit} orange>
                            Save
                        </Button>
                    </Flex>
                </TabFooter>
            )
            }
        </Animation >
    )
}
