import React from 'react'
import { CollapseItem } from '../../../components/CollapseItem/CollapseItem'
import {
  Flex,
  Title,
  Button,
  TabFooter,
  SelectedItemsContainer,
  Animation,
} from '../../../styling/GlobalStyles'
import { CancelBtn } from 'components/Button/CancelBtn'
import { ItemsPick } from 'components/ItemsPick/ItemsPick'
import { IOperation } from 'types/operations.interface'
import { IMachineModelTab } from 'types/machineModels.interface'

export const ModelsCapabilitiesTab = ({ handleSubmit, state, setState }: IMachineModelTab) => {
  const handleAdd = (item: IOperation) => {
    if (!state?.operations?.some((i) => i.id == item.id)) {
      const parsedTypes = item.types.map((type) => {
        return {
          ...type,
          id: Number(type.id),
          parameters: type.parameters.map((param) => {
            return { ...param, value: null, id: Number(param.id) }
          }),
        }
      })

      const parsedItem = { ...item, types: parsedTypes }
      setState((prev) => {
        const copy = { ...prev }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // TODO: Nisu do kraja kompatibine operacija, ispraviti koliko je moguce
        copy.operations = [...copy.operations, parsedItem]
        return copy
      })
    } else {
      setState((prev) => {
        const copy = { ...prev }
        copy.operations = copy.operations.filter((it) => it.id !== item.id)
        return copy
      })
    }
  }

  return (
    <Animation>
      <>
        <Flex between gap='2rem'>
          <SelectedItemsContainer>
            <Title pad='0 20px'>Selected Capabilities</Title>
            {state?.operations.map((item) => {
              return <CollapseItem fuel params setState={setState} key={item.id} data={item} />
            })}
          </SelectedItemsContainer>
          <ItemsPick
            selectedList={state?.operations}
            handleAdd={handleAdd}
            link='operations/filled'
          />
        </Flex>
        <TabFooter>
          <Flex gap='1rem'>
            <CancelBtn />
            <Button orange onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        </TabFooter>
      </>
    </Animation>
  )
}
