import React, { useEffect, useState } from 'react'
import { NavBar } from '../../../components/NavBar/NavBar'
import {
  Flex,
  TabsContainer,
  Tabs,
  H1WithBack,
  PageWrapper,
  ContentWrapper,
} from '../../../styling/GlobalStyles'
import { ModelsCapabilitiesTab } from './ModelsCapabilitiesTab'
import { ModelsSpecificationTab } from './ModelsSpecificationTab'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'store'
import {
  getSingleMachineModel,
  clearModel,
  updateMachineModel,
} from 'features/machineModels/machineModelsSlice'
import { IMachineModel } from 'types/machineModels.interface'


export const EditModel = () => {
  const [active, setActive] = useState('Specification')
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { singleModel } = useSelector((state: RootState) => state.machineModels)
  const [state, setState] = useState<IMachineModel>({
    category: null,
    fields: [],
    fuelType: null,
    id: null,
    make: '',
    model: '',
    operations: [],
  })
  const { id } = useParams()
  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const [invalidCategoryFields, setInvalidCategoryFields] = useState<string[]>([])

  const handleSubmit = async () => {

    if (state.operations?.some(op => op.types.some(t => t.parameters.some(p => !p.value)))) {
      toast.warn('All operation type parameters must have set values.')
      return
    }

    if (invalidFields.length < 1 && invalidCategoryFields.length < 1) {
      const resp = await dispatch(updateMachineModel(state))
      if (resp.meta.requestStatus === 'fulfilled') {
        navigate('/resources-models')
      }
    } else {
      toast.warn('All fields must be valid')
    }
  }

  useEffect(() => {
    dispatch(getSingleMachineModel(id))
    return () => {
      dispatch(clearModel())
    }
  }, [])


  useEffect(() => {
    if (singleModel) {
      setState(singleModel)
    }
  }, [singleModel])

  const renderTabs = () => {
    if (active === 'Specification') {
      return (
        <ModelsSpecificationTab
          state={state}
          setState={setState}
          handleSubmit={handleSubmit}
          invalidFields={invalidFields}
          setInvalidFields={setInvalidFields}
          invalidCategoryFields={invalidCategoryFields}
          setInvalidCategoryFields={setInvalidCategoryFields}
        />
      )
    } else if (active === 'Capabilities') {
      return <ModelsCapabilitiesTab state={state} setState={setState} handleSubmit={handleSubmit} />
    }
  }

  return (
    <div>
      <NavBar />
      <PageWrapper noFooter>
        <Flex between main>
          <H1WithBack
            mb='1.5'
            arrow
            title={'Edit ' + (singleModel?.model ? singleModel?.model : '')}
          />
          <TabsContainer>
            <Tabs active={active === 'Specification'} onClick={() => setActive('Specification')}>
              Specification
            </Tabs>
            <Tabs active={active === 'Capabilities'} onClick={() => setActive('Capabilities')}>
              Capabilities
            </Tabs>
          </TabsContainer>
        </Flex>
        <ContentWrapper>{renderTabs()}</ContentWrapper>
      </PageWrapper>
    </div>
  )
}
